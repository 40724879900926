import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/notify`;
} else {
  apiEndpoint = `${herokuUrl}/notify`;
}

export async function getAlerts() {
  const data = await httpService.get(`${apiEndpoint}/getAlerts`);
  // console.log(data);
  return data;
}
export async function getnotifications() {
  const data = await httpService.get(`${apiEndpoint}/getNotifications`);
  // console.log(data);
  return data;
}
export async function readnotification(data) {
  const details = await httpService.post(
    `${apiEndpoint}/readNotifications`,
    data
  );
  console.log("25", details);
  return details;
}
export async function readalerts(data) {
  const details = await httpService.post(`${apiEndpoint}/readAlerts`, data);
  console.log("33", details);
  return details;
}
export default {
  getAlerts,
  getnotifications,
  readnotification,
  readalerts
};
