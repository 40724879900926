import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loader from "../utils/loader";
import InvoiceServices from "../services/invoiceService";
import authService from "../services/authService";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import NoPayment from "../static/noreports.png";
import HeadingB from "./Cards/HeadingB";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    textAlign: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = (makeStyles) => ({
  table: {
    minWidth: 700,
  },
});

function PaymentReport({ data, currentUser }) {
  const classes = useStyles();
  let [loading, setLoading] = useState(false);
  const [payments, setpayments] = useState([]);
  const [filterPayments, setfilterPayments] = useState([]);
  const [status, setstatus] = useState("");
  const [rightUser, setCurrentUser] = useState("");
  const [noPayment, setnoPayment] = useState(false);

  const planID = currentUser.planID ? currentUser.planID._id : "";

  useEffect(() => {
    getUserPaymentDetails();
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const user = await authService.getCurrentUser();
    setCurrentUser(user);
  };

  // Get status
  const getUserPaymentDetails = async () => {
    setLoading(true);
    const details = await InvoiceServices.getUserInvoices();
    console.log(details);

    if (details.status === 200) {
      if (details.data.length < 1) {
        setnoPayment(true);
        setLoading(false);
      } else {
        setnoPayment(false);
        setpayments(details.data);
        setfilterPayments(details.data);
        console.log(payments);

        for (let i = 0; i < details.data.length; i++) {
          let orderDate = details.data.orderDate;
          let expiredDate = details.data.expireDate;
          console.log(details.data[i].expireDate);
          if (orderDate > expiredDate) {
            setstatus("Expired");
          } else {
            setstatus("Active");
          }
        }

        setLoading(false);
      }
    } else {
      toast.error(details.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleFilter = (e) => {
    setLoading(true);
    let selectedPlan = e;

    if (selectedPlan === "all") {
      setfilterPayments(payments);
      console.log(payments);
    } else if (selectedPlan === "1") {
      let filterMonthPayments = payments.filter(
        (payment) => payment.planType === "Monthly"
      );

      console.log(filterMonthPayments);
      setfilterPayments(filterMonthPayments);
    } else if (selectedPlan === "2") {
      let filterMonthPayments = payments.filter(
        (payment) => payment.planType === "Yearly"
      );
      console.log(filterMonthPayments);
      setfilterPayments(filterMonthPayments);
    } else {
      let filterMonthPayments = payments.filter(
        (payment) => payment.planType === "Quarterly"
      );
      console.log(filterMonthPayments);
      setfilterPayments(filterMonthPayments);
    }
    setLoading(false);
  };

  const downloadPaymentReport = async () => {
    setLoading(true);
    const doc = new jsPDF();
    const user = rightUser.username;
    const useremail = rightUser.email;
    console.log(user);
    console.log(useremail);

    doc.setFontType("bold");
    doc.text("Payment Report", 10, 10);

    doc.setFontType("normal");
    doc.setFontSize(13);
    doc.text("Username: ", 10, 20);
    doc.text(user, 35, 20);
    doc.text("Email: ", 10, 30);
    doc.text(useremail, 25, 30);
    doc.autoTable({ html: "#my-table", margin: { top: 45 } });
    doc.save("PaymentReport.pdf");
    toast.success("Payment report downloaded!")
    setLoading(false);
  };

  return (
    <div>
      <ToastContainer />
      <Loader loading={loading} />

      {noPayment ? (
        <div className="text-center mt-4">
          <img
            className=" "
            style={{ height: "40%", width: "40%" }}
            src={NoPayment}
            alt="404"
          ></img>
          <HeadingB className="alertHead" text="No Payment Reports Found..!" />
        </div>
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-md-4">
              {/* <h4 style={{ fontWeight: "bold" }}>Payment Report</h4> */}
              <HeadingB className="alertHead" text="Payment Report" />

            </div>

            <div className="col-md-6"></div>
            <div className="col-md-2">
              <select
                className="browser-default custom-select dropdown-primary"
                onChange={(e) => handleFilter(e.target.value)}
              >
                <option value="all" selected>
                  All
                </option>
                <option value="1" id="soflow-color">
                  Monthly
                </option>
                <option value="2" id="soflow-color">
                  Annually
                </option>
                <option value="3" id="soflow-color">
                  Quarterly
                </option>
              </select>
            </div>
          </div>

          <Table
            className={classes.table}
            aria-label="customized table"
            id="my-table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Plan Name</StyledTableCell>
                <StyledTableCell align="right">Order ID</StyledTableCell>
                <StyledTableCell align="right">Order Date</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right">Paid Amount</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filterPayments.map((payment) => (
                <StyledTableRow key={payment.name}>
                  <StyledTableCell component="th" scope="row">
                    {payment.planName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{payment._id}</StyledTableCell>
                  <StyledTableCell align="right">
                    {new Date(payment.orderDate).toString().slice(0, 15)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {payment._id === planID ? "Active" : "Expired"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    ${payment.amount}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <div className="row mt-4">
            <div
              className="ml-auto mr-3 text-primary"
              onClick={() => downloadPaymentReport()}
            >
              <i
                className="fa fa-download pr-1"
                style={{
                  cursor: "pointer",
                }}
              />
              <label
                className="downloadBtn"
                style={{
                  cursor: "pointer",
                }}
              >
                Download Payment Report
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(PaymentReport));
