import React from "react";
// import data from "../../test";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RadialGraph from "./RadialGraph";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 380,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Statistics({ source, data, clearResult }) {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root} >
        <CardHeader 
          title="Plagiarism Statistics" 
          action={
            <IconButton
              onClick={() => clearResult()}
              aria-label="settings"
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <hr />

        <CardContent>
          <div className="ml-2">
            <div className="row">
              <div className="col-md-5">
                <RadialGraph radialstat={data} />
              </div>

              <div className="col-md-4 text-center">
                <h4 style={{ color: "#3ba500" }}>{data.plagPercent}%</h4>
                <small>Plagiarized Content</small>
                <br />

                <h4 style={{ color: "#3ba500" }}>{data.uniquePercent}%</h4>

                <small>Unique Content</small>
              </div>

              <div className="col-md-3 pt-5 text-center">
                {/* <h4 style={{ color: "#0870e7" }}>{100-data.uniquePercent}%</h4>
                <small>Exact Phrase Match</small>
                <br /> */}

                <h4 style={{ color: "#0870e7" }}>{data.paraphrasePercent}%</h4>

                <small>Paraphrased Match</small>
              </div>
            </div>
          </div>

          <hr />

          <div
            className="pr-4 pl-2"
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              height: "25vh",
              maxHeight: "25vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="row pl-3" style={{ opacity: "0.6" }}>
              {source.length} matches
            </div>
            <br />

            {source.map((url, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-md-9">
                    <p
                      style={{
                        color: "blue",
                        fontSize: "15px",
                        lineHeight: "14px",
                        textAlign: "justify",
                        marginRight: "1%",
                        overflowWrap: "break-word",
                      }}
                    >
                      {url.link}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <Button
                      target="_blank"
                      href={url.link}
                      size="small"
                      style={{ marginTop: "-1%" }}
                    >
                      Open
                    </Button>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row pl-3 pt-2">
                  <p>{url.percent} Similar</p>
                  <br />
                </div>
              </div>
            ))}

            <br />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
