import React from "react";
import DashboardServices from "../services/dashboardServices";
import { ToastContainer } from "react-toastify";
import Loader from "../utils/loader";
import NumberWidget from "./NumberWidget";
import Apex from "./ApexChartsUserFn";
import DashboardCard from "../components/Cards/DasboardCard";
import { Dropdown } from "react-bootstrap";
import Doc from ".././static/Doc.png";
import Group from ".././static/Group.png";
import Member from ".././static/Member.png";
import Rate from ".././static/Rate.png";
import Total from ".././static/Total.png";
import ReactTour from "./ReactTour";

const tourSteps = [
  {
    selector: ".reactour-1",
    content: () => (
      <div>
        <b>Widget Bar</b>
        <p>Select Different widgets to visualize various metrics.</p>
      </div>
    ),
    position: "bottom"
  },
  {
    selector: ".reactour-2",
    content: "Select year to visualize the performance metrics of that year.",
    position: "left"
  },
  {
    selector: ".reactour-3",
    content: "Graphical representation of metrics.",
    position: "top"
  }
];

class DashboardPage extends React.Component {
  state = {
    loading: true,
    reactTour: false,
    selectYear: new Date().getFullYear(),
    selectMonth: new Date().getMonth() + 1,
    years: [...Array(Number(new Date().getFullYear()) - 2018)].map(
      (year, i) => 2019 + i
    ),
    selected: new Date().getFullYear(),
    Total_Docs: "",
    Total_Reviews: "",
    Total_Teams: "",
    Month_Wise_Document: [],
    Month_Wise_Ratings: [],
    MonthWise: [],
    RatingWise: [],
    Year: "",
    isSelected: false,
    borderColor: "",
    dashboardCardSetails: [],
    cardmargin: 1,
    monthWiseAverageRatings: [],
    monthWiseTotalNoOfRatings: [],
    monthWiseTotalRatings: [],
    cardSelected: "",
    lineData: [],
    bar1Data: [],
    bar2Data: [],
    lineColor: "#ff3a29",
    linen: "",
    monthWiseTeams: [],
    monthWiseRatedDocuments: []
  };

  componentDidMount() {
    let year = new Date().getFullYear();
    this.getMOnthwiseRatings(year);
    this.getWidgetDetails(year);
    this.getmonthwiseTeams(year);
  }

  getWidgetDetails = async year => {
    let widgdetails = await DashboardServices.getWidgetDetails(year);
    console.log(widgdetails);
    let widgData = [
      {
        title: "Total Documents",
        count: widgdetails.noOfDocuments,
        colors: "#ff3a29",
        img: Doc,
        sparkData: widgdetails.monthWiseDocuments
      },
      {
        title: "Document's Rated",
        count: widgdetails.noOfDocumentsRated,
        colors: "#ffb200",
        img: Rate,
        sparkData: widgdetails.monthWiseRatings
      },
      {
        title: "Total Ratings",
        count: widgdetails.totalRatings,
        colors: "#34b53a",
        img: Group,
        sparkData: this.state.monthWiseTotalNoOfRatings
      },
      {
        title: "Average Ratings",
        count: widgdetails.AverageRatings,
        colors: "#4339f2",
        img: Total,
        sparkData: this.state.monthWiseAverageRatings
      },
      {
        title: "Total Members",
        count: widgdetails.noOfTeams,
        colors: "#af39f2",
        img: Member,
        sparkData: widgdetails.monthWiseTeams
      }
    ];
    this.setState({
      selected: year,
      dashboardCardSetails: widgData,
      linen: "reviews",
      bar1Data: widgdetails.monthWiseDocuments,
      loading: false
    });
  };
  // getdetails = async (year) => {
  //   let details = await DashboardServices.getDashboardDetails(year);
  //   console.log(details);
  //   let dashData=[{
  //     title: "Documents",
  //     count: details.data.noOfDocuments,
  //     colors: "#ff3a29",
  //     img: Doc,
  //     sparkData:details.data.monthWiseDocuments
  //   },{
  //     title: "Document Rated",
  //     count: details.data.totalRatings,
  //     colors: "#ffb200",
  //     img: Rate,
  //     sparkData: details.data.monthWiseRatings
  //   },
  //   {
  //     title: "Ratings",
  //     count: details.data.noOfDocuments,
  //     colors: "#34b53a",
  //     img: Group,
  //     sparkData:[5,15,15,25,10,30,20,60,55,80]
  //   },
  //   {
  //     title: "Average",
  //     count: details.data.noOfDocuments,
  //     colors: "#4339f2",
  //     img: Total,
  //     sparkData:[5, 10, 30,20,70]
  //   },
  //   {
  //     title: "Members",
  //     count: details.data.noOfDocuments,
  //     colors: "#af39f2",
  //     img: Member,
  //     sparkData:[5, 10, 30,20,70]
  //   }
  // ]
  //   this.setState({
  //     selected: year,
  //     Total_Docs: details.data.noOfDocuments,
  //     Total_Reviews: details.data.totalRatings,
  //     Total_Teams: details.data.noOfTeams,
  //     Month_Wise_Document: details.data.monthWiseDocuments,
  //     Month_Wise_Ratings: details.data.monthWiseRatings,
  //     MonthWise: details.data.monthWiseDocuments,
  //     RatingWise: details.data.monthWiseRatings,
  //     dashboardCardSetails:dashData,
  //     loading: false,
  //   });
  // };

  increment = () => {
    const selectYear = this.state.selectYear;
    const Year = selectYear + 1;
    console.log(Year);
    this.setState({
      selectYear: Year
    });
    this.getdetails(Year);
  };

  decrement = () => {
    const selectYear = this.state.selectYear;
    const Year = selectYear - 1;
    console.log(Year);
    this.setState({
      selectYear: Year
    });
    this.getdetails(Year);
  };

  getMOnthwiseRatings = async year => {
    let data = await DashboardServices.getMonthWiseRatings(year);

    this.setState({
      monthWiseAverageRatings: data.monthWiseAverageRatings,
      monthWiseTotalNoOfRatings: data.monthWiseTotalNoOfRatings,
      monthWiseTotalRatings: data.monthWiseTotalRatings,
      lineData: data.monthWiseTotalRatings,
      bar1Data: [],
      bar2Data: []
    });
  };

  getmonthwiseTeams = async year => {
    let admindata = await DashboardServices.getMonthWiseTeamDetails(year);
    this.setState({ monthWiseTeams: admindata.monthWiseTeams });
  };

  selecctCard = async details => {
    this.setState({ cardSelected: details.title });
    let year = this.state.selected;
    if (details.title === "Average Ratings") {
      this.setState({
        lineData: this.state.monthWiseAverageRatings,
        bar1Data: [],
        bar2Data: [],
        lineColor: details.colors,
        linen: details.title,
        loading: false
      });
      // if (this.state.monthWiseAverageRatings.length === 0 && this.state.monthWiseTotalNoOfRatings.length === 0 && this.state.monthWiseTotalRatings.length === 0) {
      //   this.setState({ loading: true })
      //   let data = await DashboardServices.getMonthWiseRatings(year);
      //   console.log(data)
      //   this.setState({
      //     monthWiseAverageRatings: data.monthWiseAverageRatings,
      //     monthWiseTotalNoOfRatings: data.monthWiseTotalNoOfRatings,
      //     monthWiseTotalRatings: data.monthWiseTotalRatings,
      //     lineData: data.monthWiseAverageRatings,
      //     bar1Data: [],
      //     bar2Data: [],
      //     lineColor: details.colors,
      //     loading: false
      //   })
      // } else {
      //   this.setState({
      //     lineData: this.state.monthWiseAverageRatings,
      //     bar1Data: [],
      //     bar2Data: [],
      //     lineColor: details.colors
      //   })
      // }
    } else if (details.title === "Total Ratings") {
      this.setState({
        bar1Data: [],
        bar2Data: this.state.monthWiseTotalNoOfRatings,
        lineColor: details.colors,
        linen: details.title
      });
    } else if (details.title === "Total Documents") {
      let data = await DashboardServices.getWidgetDetails(year);
      this.setState({
        Month_Wise_Document: data.monthWiseDocuments,
        lineData: data.monthWiseDocuments,
        bar1Data: data.monthWiseDocuments,
        bar2Data: [],
        lineColor: details.colors,
        linen: details.title
      });
    } else if (details.title === "Total Members") {
      let data = await DashboardServices.getMonthWiseTeamDetails(year);
      this.setState({
        monthWiseTeams: data.monthWiseTeams,
        lineData: data.monthWiseTeams,
        bar1Data: [],
        bar2Data: [],
        lineColor: details.colors,
        linen: details.title
      });
    } else if (details.title === "Document's Rated") {
      let data = await DashboardServices.getRatedDocumentMonthwise(year);
      this.setState({
        monthWiseRatedDocuments: data.monthWiseRatedDocuments,
        lineData: data.monthWiseRatedDocuments,
        bar1Data: [],
        bar2Data: [],
        lineColor: details.colors,
        linen: details.title
      });
    }
  };

  getDocumentRatedMonthwise = async year => {
    let admindata = await DashboardServices.getRatedDocumentMonthwise(year);
    this.setState({
      monthWiseRatedDocuments: admindata.monthWiseRatedDocuments,
      selected: year
    });
  };

  _changeYear = async year => {
    this.setState(
      { selected: year, cardSelected: "", lineColor: "#ff3a29" },
      () => {
        this.getMOnthwiseRatings(year);
        this.getDocumentRatedMonthwise(year);
        this.getWidgetDetails(year);
        this.getmonthwiseTeams(year);
      }
    );
  };

  render() {
    let {
      Total_Teams,
      Total_Docs,
      Total_Reviews,
      Month_Wise_Document,
      selected,
      Month_Wise_Ratings,
      selectYear,
      monthList,
      years,
      dashboardCardSetails,
      cardSelected,
      cardmargin,
      lineData,
      bar1Data,
      bar2Data,
      lineColor,
      linen
    } = this.state;

    return (
      <div>
        <ToastContainer />
        <ReactTour
          steps={tourSteps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
        <Loader loading={this.state.loading} />
        <div className="">
          <div className="row p-3">
            <div className="col-11">
              <h4 className="ft_lg" style={{ color: "#3e80f1" }}>
                Overview
              </h4>
            </div>
            <div className="col-1">
              <i
                className="fa fa-question-circle mt-2"
                aria-hidden="true"
                data-toggle="tooltip"
                title="Need Help ?"
                style={{
                  cursor: "pointer",
                  color: "dodgerblue",
                  fontSize: "15px"
                }}
                onClick={() => this.setState({ reactTour: true })}
              ></i>
            </div>
          </div>
          <div className="row reactour-1 ">
            {dashboardCardSetails.map((details, index) => (
              <div className="col-md-2 dashcol">
                <DashboardCard
                  info={details}
                  index={index}
                  select={this.selecctCard}
                  selectedCard={cardSelected}
                  margin={cardmargin}
                />
              </div>
            ))}
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="ft_sm m-0" style={{ color: "#4d4f5c" }}>
              Documents and Reviews
            </h4>
            <div className="d-flex align-items-center reactour-2">
              <h4 className="ft_sm m-0" style={{ color: "#4d4f5c" }}>
                Select Year :{" "}
              </h4>
              <Dropdown
                onMouseDown={e => {
                  e.preventDefault();
                }}
              >
                <Dropdown.Toggle variant="white">{selected}</Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ width: "auto", minWidth: "auto" }}
                  className="m-0"
                >
                  {years &&
                    years.map(year => (
                      <Dropdown.Item
                        key={year}
                        onMouseDown={() => this._changeYear(year)}
                      >
                        {" "}
                        {year}{" "}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <br />
          <div className="reactour-3">
            <Apex
              cmp1="Documents"
              cmp2="Reviews"
              bar1={bar1Data}
              bar2={bar2Data}
              line={lineData}
              linen={linen}
              lineDataColor={lineColor}
              title="Documents and Reviews"
              monthList={monthList}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardPage;
