import React from "react";
import SideNavBar from "../SideNavBar";
import Citation from "../Forms/CitationList";

const MyCitationsList = (props) => {
    let id;
  if (props.history.location.data) {
     id = props.history.location.data.projectId;
    console.log(id);
  }

  return (
    <SideNavBar>
      <Citation prjId={id} />
    </SideNavBar>
  );
};

export default MyCitationsList;
