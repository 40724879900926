import React from "react";
import classNames from "classnames";
const InputFeedback = ({ error }) => {
    if (error) {
        return (
            <div className={classNames("input-feedback")}>{error}</div>
        )
    } else {
        return null;
    }
}
export default InputFeedback;
