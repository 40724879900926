import React, { Component } from "react";
import { css } from "@emotion/core";
import { BeatLoader, ClimbingBoxLoader } from "react-spinners";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import "./loader.css";

const override = css`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  z-index: 9999;
`;

const onlineOverride = css`
  position: absolute;
  left: 47%;
  top: 47%;
  margin: 0 auto;
  z-index: 9999;
  color: white;
`;

export class Loader extends Component {
  render() {
    return (
      <div className={this.props.loading ? "loading-overlay" : ""}>
        <React.Fragment>
          {this.props.online ? (
            <>
              <div>
                <Card className="load">
                  <CardContent className="lostConnectionCard ">
                    <div className="row" style={{ color: "white" }}>
                      <div
                        className="col-2"
                        id="exCircle"
                        style={{ marginTop: "7%" }}
                      >
                        <i
                          className="fa fa-exclamation-circle fa-2x"
                          style={{ marginLeft: "20%" }}
                        ></i>
                      </div>

                      <div className="col-10" style={{ marginTop: "1%" }}>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{ marginBottom: "2%", fontSize: "16px" }}
                        >
                          Lost Connection
                        </Typography>
                        <Typography variant="body2" component="p">
                          Your internet session was interrupted. We'll try to
                          reconnect...
                        </Typography>
                      </div>
                    </div>

                    <ClimbingBoxLoader
                      css={onlineOverride}
                      sizeUnit={"px"}
                      size={20}
                      color={"#123abc"}
                      loading={this.props.loading}
                      style={{ marginRight: "4%" }}
                    />
                  </CardContent>
                </Card>
              </div>
            </>
          ) : (
              <BeatLoader
                css={override}
                sizeUnit={"px"}
                size={20}
                color={"#123abc"}
                loading={this.props.loading}
              />
            )}
        </React.Fragment>
      </div>
    );
  }
}
export default Loader;
