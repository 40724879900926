import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/reports";
} else {
  apiEndpoint = herokuUrl + "/reports";
}

export async function getReportDetails(id) {
  console.log(id);
  let data = await httpService.post(`${apiEndpoint}/getReportDetails`, { id });
  return data;
}



export async function addGrammerError(error){
    const response = await httpService.post(`${apiEndpoint}/addGrammarError`,{error});
    return response;
  }

export async function addGrammarError2(error){
  console.log("addGrammarError2",error);
    const response = await httpService.post(`${apiEndpoint}/addGrammarError2`,{error});
    return response;
  }


  export async function getGrammarReportByDocsId(doc){
    let data = await httpService.post(`${apiEndpoint}/getGrammarReportByDocsId`, doc);
    return data;
  }
  export default {
    getReportDetails,
    addGrammerError,
    addGrammarError2,
    getGrammarReportByDocsId
  };
