import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import ProgressBar from "react-bootstrap/ProgressBar";
import MixedLineAreaGraph from "./MixedLineAreaGraph";
import Piechart from "./Piechart";
import ScoreGraph from "./ScoreGraph";
import PlagiarismGraph from "./PlagiarismGraph";
import PerformanceGraph from "./PerformanceGraph";
import reportServices from "../services/reportServices";
import {
  downloadGrammarReportPdf,
  downloadGrammarReport,
} from "../services/adminServices";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "./Editor/utilize";
// import Loader from "../utils/loader";
import Loader from "./Semantic_loader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkPlagiarism } from "../services/plagiarismServices";

export class GrammarReport extends Component {
  state = {
    scores: "",
    document: this.props.document,
    plagScore: "",
    plagSource: "",
    table: [],
    loading: false,
    userFeatures: {},
    rawData: ""
  };

  async componentDidMount() {
    console.log(this.props);
    // const { features, data, document, plagSource, plagscores:plagScore } = this.props;
    const { features, data, document } = this.props;

    if(data){
      var keys = data.errorName;
      var values = data.errorCount;
      var result = [];
      keys.forEach((key, i) => result.push([key, values[i]]));
      // this.setState({ table: result, userFeatures: features, scores: data, plagSource, plagScore });
      this.setState({ table: result, userFeatures: features, scores: data, loading: true },
        ()=>{
          this.getPlagiarismReport(document);
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      var keys = this.props.data.errorName;
      var values = this.props.data.errorCount;
      var result = [];
      keys.forEach((key, i) => result.push([key, values[i]]));

      this.setState({ table: result, userFeatures: this.props.features, scores: this.props.data, loading: true },
        ()=>{
          this.getPlagiarismReport(this.props.document);
        });
    }
  }

  convertToHtml = (data) => {
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    return editorContentHtml;
  };

  getPlagiarismReport = async (data) => {
    const { scores } = this.state;
    let Originaldata = this.convertToHtml(data.onlyDocumentContent);
    Originaldata = Originaldata.replace(/(<([^>]+)>)/gi, "");
    let plagiarismdata = await checkPlagiarism(Originaldata);
    plagiarismdata = JSON.parse(plagiarismdata.response);
    console.log(plagiarismdata);
    scores["plagScore"] = plagiarismdata.plagPercent;
    scores["plagSource"] = plagiarismdata.sources.length;
    this.setState({
      plagScore: plagiarismdata.plagPercent,
      plagSource: plagiarismdata.sources.length,
      scores: scores,
      loading: false,
    });
  };

  editdoc = async (doc) => {
    console.log(doc);
    this.props.history.push({
      pathname: `/texteditor`,
      data: { document: doc },
    });
  };
  downloadReport = async () => {
    this.setState({ loading: true });
    const { scores } = this.state;
    console.log("scores", scores);
    // let scores = this.props.scores;
    // if (scores.performance === "NaN") {
    //   scores.performance = 0;
    // }
    let res = await downloadGrammarReportPdf(scores, this.state.document);
    if (res.status === 200) {
      let stamp = new Date().valueOf();
      downloadGrammarReport("GrammarReport");
      toast.success("Grammar report downloaded!")
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      toast.error("Oops something went wrong");
    }
  };

  render() {
    const { scores, table, userFeatures , plagScore , plagSource} = this.state;
    // const classes = useStyles();

    // const scores = this.props.data;
    console.log(scores);
    return (
      <div className="col-md-8">
        <Loader loading={this.state.loading} />

        {userFeatures.ReportModule === true ? (
          <>
            {scores.noOfWords >= 30 ? (
              <>
                <div
                  id="graphReport"
                  className=" container bg-white rounded shadow"
                  style={{ height: "1500px" }}
                >
                  <div className="row m-3">
                    {" "}
                    <h4 style={{marginTop: "2%"}}>Grammar Report : {this.state.document.documentName}</h4>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div id="chart">
                        <h5
                          style={{
                            textAlign: "center",
                            marginTop: "6%",
                          }}
                        >
                          Plagiarism Check <br />
                          <br /> {plagSource ? plagSource : 0} - sources
                        </h5>
                        { plagScore ? 
                          <PlagiarismGraph scoredata={plagScore} sourcedata={plagSource}/> : 
                          <PlagiarismGraph scoredata={0} sourcedata={0}/> 
                        }
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row">
                        <div className="chart">
                          <h5
                            style={{
                              textAlign: "center",
                              marginTop: "4%",
                              marginBottom: "28%",
                            }}
                          >
                            Writing Issues
                          </h5>
                          <Piechart data={scores} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "black" }} />
                  <div className="row">
                    <div className="col-7">
                      <div style={{ marginTop: "10%" }}>
                        <h4>Performance</h4>
                        <p>
                          This text scores better than{" "}
                          {scores.performance === "NaN"
                            ? 0
                            : scores.performance}{" "}
                          % of all text checked by PET where comparable goals
                          were set.
                        </p>
                      </div>
                    </div>
                    <div className="col-5">
                      <PerformanceGraph data={scores.performance} />
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: "1%" }}>
                    <h4>Word Count</h4>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div style={{ marginTop: "8%" }}>
                        <h6>Characters &nbsp; {scores.characterCount}</h6>
                        <ProgressBar
                          variant="danger"
                          now={scores.characterCount}
                          style={{ width: "80%" }}
                        />
                      </div>
                      <div style={{ marginTop: "8%" }}>
                        <h6>Words &nbsp;{scores.noOfWords}</h6>
                        <ProgressBar
                          variant="info"
                          now={scores.noOfWords}
                          style={{ width: "80%" }}
                        />
                      </div>
                      <div style={{ marginTop: "8%" }}>
                        <h6>Sentences &nbsp; {scores.sentenceLength}</h6>
                        <ProgressBar
                          variant="success"
                          now={40}
                          style={{ width: "80%" }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row" style={{ marginTop: "7%" }}>
                        <div className="col">
                          <h5>Reading Time</h5>
                        </div>
                        <div className="col">
                          <h6>{scores.readingTime} </h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "7%" }}>
                        <div className="col">
                          <h5>Speaking Time</h5>
                        </div>
                        <div className="col">
                          <h6>{scores.speakingTime} </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "black" }} />
                  <div className="row" style={{ marginLeft: "1%" }}>
                    <h4>Readibility</h4>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div style={{ marginTop: "8%" }}>
                        <ProgressBar>
                          {/* <ProgressBar
                            variant="success"
                            now={scores.wordLength}
                            key={1}
                          />
                          <ProgressBar
                            variant="warning"
                            now={scores.sentenceLength}
                            key={2}
                          /> */}
                          <ProgressBar
                            variant="danger"
                            now={scores.score}
                            key={3}
                          />
                        </ProgressBar>
                      </div>
                      <div style={{ marginTop: "12%" }}>
                        <p>
                          Your text is likely to be understood by a reader who
                          has at least a {scores.schoolLevel} education 
                          and should be easy for most adults to read
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div style={{ marginLeft: "20%" }}>
                        {/* <div className="row">
                          <div
                            className="foo"
                            style={{
                              float: "left",
                              width: "20px",
                              height: "20px",
                              margin: "5px",
                              border: "1px solid rgba(0, 0, 0, .2)",
                              backgroundColor: "#3ba500",
                            }}
                          />
                          <h6 style={{ marginTop: "3%" }}>
                            {" "}
                            {scores.wordLength} - Word Length
                          </h6>
                        </div>
                        <div className="row">
                          <div
                            className="foo"
                            style={{
                              float: "left",
                              width: "20px",
                              height: "20px",
                              margin: "5px",
                              border: "1px solid rgba(0, 0, 0, .2)",
                              backgroundColor: "#ffcd00",
                            }}
                          />
                          <h6 style={{ marginTop: "3%" }}>
                            {" "}
                            {scores.sentenceLength} - Sentence Length
                          </h6>
                        </div> */}
                        <div className="row">
                          <div
                            className="foo"
                            style={{
                              float: "left",
                              
                              width: "20px",
                              height: "20px",
                              margin: "17px",
                              border: "1px solid rgba(0, 0, 0, .2)",
                              backgroundColor: "#dc3545",
                            }}
                          />
                          <h6 style={{ marginTop: "8%" }}>
                            {" "}
                            {scores.score}- Readability
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "black" }} />
                  {scores.errorCount.length > 0 ? (
                    <>
                      <div className="row" style={{ marginLeft: "1%" }}>
                        <h4>Grammar</h4>
                      </div>
                      <div>
                        <MixedLineAreaGraph data={scores} />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="row"
                  style={{ marginTop: "8%", marginBottom: "8%" }}
                >
                  <div className="col-4"></div>
                  <div className="col-4"></div>
                  <div className="col-4">
                    <button
                      onClick={this.downloadReport}
                      type="button"
                      className="btn btn-primary"
                    >
                      <i
                        className="fa fa-download pr-1"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <label className="downloadBtn">Download PDF Report</label>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="container bg-white rounded shadow mt-5">
                <div className="pt-3 pb-3" style={{ textAlign: "center" }}>
                  <h3> Write Away!!</h3>
                  <h4>
                    PET needs at least 30 words to calculate document
                    statistics.
                  </h4>

                  {/* <Link to={{ pathname: "/pricing" }}> */}
                  <button
                    className="btn btn-primary hvr-float-shadow p-3 buysub"
                    onClick={() => this.editdoc(this.state.document)}
                  >
                    <i className="fa fa-edit" aria-hidden="true"></i>
                    &nbsp; Edit Document
                  </button>
                  {/* </Link>  */}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="container bg-white rounded shadow mt-5">
              <div className="pt-3 pb-3" style={{ textAlign: "center" }}>
                <h3>Please Upgrade your Plan. </h3>
                <h4>Inorder to visualize the graphical report of Grammar</h4>

                <Link to={{ pathname: "/pricing" }}>
                  <button className="btn btn-primary hvr-float-shadow p-3 buysub">
                    <i className="fa fa-diamond" aria-hidden="true"></i>
                    Buy Subscription
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GrammarReport));
