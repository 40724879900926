import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BreadCrumb from "../Cards/BreadCrumbs";
import Swal from "sweetalert2";
import countSyllables from "syllable";
import readability from "readability-meter";
import { toast, ToastContainer } from "react-toastify";
import style from "./para.module.css";
import Highlighter from "react-highlight-words";
import TextareaAutosize from "react-textarea-autosize";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  PetAssistanceBtn,
  SideBtn,
  RightBottomBtn,
  Statistics,
  Feedback,
} from "./ParaComponent";
import { paraphrase } from "../../services/englishCheckers";
import { Loader } from "../../utils/loader";
import { connect } from "react-redux";
import HeadingB from "../Cards/HeadingB";
import Skeletoncomp from "../Skeleton";
import { Typography } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { MdMessage, MdDelete } from "react-icons/md";
import { RiBarChartBoxLine } from "react-icons/ri";
import { Editor, EditorState } from "draft-js";
// import "../Editor/draft.css";
import "./para.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: -40,
  },
  tab: {
    width: 650,
  },
  slider: {
    width: 300,
  },
}));

function valuetext(value) {
  return `${value}°C`;
}

function Paraphrasetwo({ features }) {
  const [characters, setCharacters] = useState({ max: 1500, now: 0 });
  const [rightEditorText, setRightEditorText] = useState("");
  const [leftEditorText, setLeftEditorText] = useState("");
  const [updatedTextResponse, setUpdatedTextResponse] = useState({
    suggestWords: null,
    text: null,
    leftEditorText: null,
  });
  const [loading, setLoading] = useState(false);
  const [selectedText, setSelectedText] = useState([]);
  const [isLocked, setIsLocked] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [column, setColumn] = useState({
    column: "col-md-6",
    feedback: false,
    statistics: false,
  });
  const [persistence, setPersistence] = useState([]);
  const [selectionTextFromOld, setSelectionTextFromOld] = useState("");
  const [isSelectedTextUpdated, setIsSelectedTextUpdated] = useState(true);
  const [historyIndex, setHistoryIndex] = useState(null);
  const [statistics, setStatistics] = useState({});
  const [sentanceNav, setSentanceNav] = useState({
    total: 0,
    current: 0,
    selected: "",
    index: -1,
  });
  const [isParaphrase, setisParaphrase] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [mode,setMode] = useState("");
  const [wordflip]= useState("fewer synonyms")
  const [value, setValue] = React.useState(2);
  const classes = useStyles();

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setIsSelectedTextUpdated(true);
  }, [selectedText[0]]);

  const onPetAssistant = async () => {
    if (features.ParaPhrase !== true) {
      toast("Please Upgrade your plan to enable Paraphrase Feature");
      return;
    }

    setisParaphrase(true);
    console.log("init onPetAssistant", isParaphrase);
    // updated the old text to new
    if (updatedTextResponse.leftEditorText === "leftEditorText") {
      const editorRight = updatedTextResponse.text;

      if (isLocked) {
        let persistData = historyIndex
          ? persistence[historyIndex]
          : persistence.length > 0 && persistence[persistence.length - 1];
        checkSelection(persistData);
      } else {
        const updatedSentence = updateSentence(editorRight);
        setRightEditorText(updatedSentence);
        // setHistory([...history, { text:updatedSentence, suggestion:{} }])
      }
    }
    // call backend for first time
    else if (leftEditorText) {
      setLoading(true);
      const datas ={
        text : leftEditorText,
        mode : mode,
        wordflip : wordflip
      }
      const result = await paraphrase(datas);
      console.log(result);
      if (result.status === 200) {
        let text = result.text;
        text = text.replace(/\n+/g, ""); // remove enter or next line
        text = text.replace(/[()||']/g, ""); // remove special character
        console.log(text);
        setRightEditorText(text);
        // setHistory([...history, { text, suggestion:{} }])
        setUpdatedTextResponse({
          text,
          suggestWords: result.suggestWords,
          leftEditorText,
        });
        // remove the text for selection

        const sentanceList = text.split(/\.+/);
        setSentanceNav({ ...sentanceNav, total: sentanceList.length - 1 });
        setPersistence([
          ...persistence,
          { text: leftEditorText, updatedtxt: text },
        ]);
        setIsLocked(false);
        setSelectedText([]);
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
    setHistoryIndex(null);
  };

  const checkSelection = (persistData) => {
    console.log("init checkSelection", persistData);
    // selected updated sentence to old sentence
    const currentSuggestions = persistData.currentSuggestions;
    console.log("currentSuggestions", currentSuggestions);
    let selectedOldText = selectionTextFromOld;
    console.log("selectedOldText", selectedOldText);
    // console.error(currentSuggestions)
    if (isSelectedTextUpdated && currentSuggestions) {
      selectedOldText = selectedText[0];
      console.warn("inside if conditon");
      currentSuggestions.forEach((suggestion) => {
        const pattern = `\\b(${suggestion.selectedWord})\\b`;
        const regex = new RegExp(pattern, "g");
        selectedOldText = selectedOldText.replace(regex, suggestion.keyWord);
      });
      console.log("selectedOldText==>", selectedOldText);
      setIsSelectedTextUpdated(false);
      setSelectionTextFromOld(selectedOldText);
    }

    // checkSelection call only if text selected
    if (!selectedOldText) selectedOldText = selectedText[0];

    let editorRight = updatedTextResponse.text;
    // editorRight = editorRight.replace(/[()||']/g, '')
    // console.log(selectedText)
    // console.error('editorRight', editorRight)
    // console.error('selectedOldText', selectedOldText);
    // const pattern = `\\b(${selectedOldText})\\b`;
    const regex = new RegExp(selectedOldText, "g");
    const splitText = editorRight.replace(regex, "_____________________");
    console.warn(splitText);
    const updatedSentence = updateSentence(splitText, selectedText[0]);
    setRightEditorText(updatedSentence);
    // setHistory([...history, updatedSentence])
  };

  const updateSentence = (text, replacText = "") => {
    const words = updatedTextResponse.suggestWords;
    // const text = updatedTextResponse.text;

    let currentSuggestions = [];
    // console.log(text)
    const keyWords = Object.keys(words);
    let newParagraph = keyWords.reduce((sentance, keyWord, i) => {
      const synonyms = words[keyWord];
      const randomNo = Math.floor(Math.random() * synonyms.length);
      const selectedWord = synonyms[randomNo];
      const pattern = `\\b(${keyWord})\\b`;
      const regex = new RegExp(pattern, "g");
      let updatedtxt = sentance.replace(regex, selectedWord);
      console.log(keyWord, selectedWord);
      currentSuggestions.push({ keyWord, selectedWord });
      return updatedtxt;
    }, text);

    if (replacText) {
      newParagraph = newParagraph.replace("_____________________", replacText);
    }

    setPersistence([
      ...persistence,
      { text, currentSuggestions, selectedText, updatedtxt: newParagraph },
    ]);

    // console.log(newParagraph);
    return newParagraph;
  };

  const characterLimit = (maxLength, charaLength) => {
    if (maxLength >= charaLength) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };

  const onLeftEditorChange = (event) => {
    const text = event.target.value;
    const length = text.length;
    const max = characters.max;
    characterLimit(max, length);
    if (max >= length) {
      setLeftEditorText(text);
      setCharacters({ ...characters, now: length });
    } else {
      toast.error("Character limit exceeded.");
    }
  };

  const onSelectedTextCopy = () => {
    console.log("onHandleCopy", selectedText);
    if (!selectedText[0]) {
      toast.warn("You need to select and lock the text to copy!");
      return;
    }
    navigator.clipboard.writeText(selectedText).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        toast.info("Your selected text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const onHandleNavigation = (status) => {
    // up, down
    console.log("init onHandleArrowUp");
    const editorRight = document.getElementById("editorRight").innerText;
    if (!editorRight) return;

    const sentance = editorRight.split(/\.+/);
    let index = sentanceNav.index;
    if (status === "up") index++;
    else index--;

    if (index < sentance.length - 1 && index >= 0) {
      const selectedText = sentance[index];
      console.log(selectedText);
      setSentanceNav({
        ...sentanceNav,
        index,
        selected: selectedText,
        current: index + 1,
      });
      setSelectedText([selectedText]);
      setIsLocked(true);
    }
  };

  const onHandleClear = () => {
    console.log("init onHandleClear");
    Swal.fire({
      title: "Can we clear your editor ?",
      type: "warning",

      showCancelButton: true,
      showCloseButton: true,

      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value === true) {
        document.getElementById("editorLeft").innerHTML = "";
        setUpdatedTextResponse({
          suggestWords: null,
          text: null,
          leftEditorText: null,
        });
        setLeftEditorText("");
        setRightEditorText("");
        setSelectedText([]);
        setPersistence([]);
        setSentanceNav({ total: 0, current: 0, selected: "", index: -1 });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("cancel");
      }
    });
  };

  const onHandleLike = () => {
    console.log("onHandleLike");
    setIsLike(!isLike);
  };

  const unlock = () => {
    setSelectedText([]);
    setIsLocked(false);
  };

  const onHandleLock = () => {
    const selectedText_ = sentanceNav.selected;
    console.log("init onHandleLock", selectedText_);

    if (isLocked) {
      unlock();
    }
    // below command will not work
    else if (selectedText_) {
      const editorRight = document.getElementById("editorRight").innerText;
      const n = editorRight.search(selectedText_);
      console.log("checking", selectedText_.length, n);
      if (selectedText_.length < 12) {
        Swal.fire(
          "Oops...",
          "Please select minimum 12 characte and click lock!",
          "warning"
        );
        return;
      }
      if (n >= 0) {
        setIsLocked(true);
        setSelectedText([selectedText_]);
      } else
        Swal.fire("Oops...", "Please select any text and click lock!", "error");
    } else
      Swal.fire("Oops...", "Please select any text and click lock!", "error");
  };

  const showHistory = (status) => {
    // inc dec
    // console.log(persistence)
    // console.log(history)
    const length = persistence.length;
    console.log("length", length);
    console.log("historyIndex==>", historyIndex);
    let index = typeof historyIndex === "number" ? historyIndex : length;
    console.log("index==>", index);

    if (status === "inc") {
      index++;
    } else if (status === "dec") {
      index--;
    }

    if (index < 0 || index > length - 1) {
      toast.error("Nothing to Show..!");
      return;
    }
    console.log("Second IF=>", index);
    console.log(persistence);
    console.log(persistence[index].updatedtxt);
    setHistoryIndex(index);
    setRightEditorText(persistence[index].updatedtxt);
  };

  const statisticsFn = async () => {
    console.log("init statisticsFn");
    const editorRight = document.getElementById("editorRight").innerText;
    const editorLeft = leftEditorText;

    if (!editorRight || !editorLeft) {
      let statistic = {
        sentance: 0,
        words: 0,
        avgWords: 0,
        percentChange: 0,
        syllables: 0,
        readability: 0,
      };
      console.log("statistic", statistic);
      setStatistics(statistic);
      return;
    }

    const sentance = editorRight.split(/\.+/);
    const words = editorRight.split(/\s+/);
    const avgWords = ((sentance.length / words.length) * 100).toFixed(2);
    const count = words.reduce((count, word, i) => {
      const regex = RegExp(word);
      if (!regex.test(editorLeft)) count++;
      return count;
    }, 0);
    const percentChange = ((count / words.length) * 100).toFixed(2);
    let syllables = countSyllables(editorRight);
    const grade = await readability.ease(editorRight);
    const score = grade.score.toFixed(2);

    let statistic = {
      sentance: sentance.length,
      words: words.length,
      avgWords,
      percentChange,
      syllables,
      readability: score,
    };
    console.log("statistic", statistic);
    setStatistics(statistic);
  };

  const colChange = (status) => {
    console.log(status); // feedback, statistics
    let feedback = false;
    let statistics = false;
    let mycol = "col-md-4";
    if (status === "feedback") {
      feedback = !column.feedback;
      if (!feedback) mycol = "col-md-6";
    } else if (status === "statistics") {
      statistics = !column.statistics;
      if (!statistics) mycol = "col-md-6";
      else statisticsFn();
    }
    setColumn({ column: mycol, feedback, statistics });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const standard = () => {
    setMode("Standard")
    console.log("Standard mode!");
  };

  const fluency = () => {
    setMode("Fluency")
    console.log("Fluency mode!");
  };

  const creative = () => {
    setMode("Creative")
    console.log("Creative mode!");
  };

  const suggestive = () => {
    setMode("Suggestive")
    console.log("Suggestive mode!");
  };

  const concision = () => {
    setMode("Concision")
    console.log("Concision mode!");
  };

  return (
    <div>
      <Loader loading={loading} />
      <ToastContainer />
      <BreadCrumb l1="Dashboard" l2="Paraphraser" dashboardlink="dashboard" />
      <HeadingB text="Paraphraser"></HeadingB>

      <div className="ml-3 mr-3">
        <div
          className="row"
          style={{
            backgroundColor: "white",
            // marginLeft: "1px",
            // marginRight: "1px",
            height: "50px",
            borderBottom: "1px solid #777274",
          }}
        >
          <div className="col-md-1" style={{ marginTop: "15px" }}>
            <h2 style={{ color: "#777274", fontSize: "15px" }}>Modes:</h2>
          </div>

          <div className="col-md-6">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              className={classes.tab}
            >
              <Tab label="Standard" onClick={standard} />
              <Tab label="Fluency" className={classes.root} onClick={fluency} />
              <Tab
                label="Creative"
                className={classes.root}
                onClick={creative}
              />
              <Tab
                label="Suggestive"
                className={classes.root}
                onClick={suggestive}
              />
              <Tab
                label="Concision"
                className={classes.root}
                onClick={concision}
              />
            </Tabs>
          </div>

          <div className="col-md-3">
            <div
              className="row mt-2"
              style={{ borderRadius: "25px", border: "1px solid #e0e0e0" }}
            >
              <div className="col-md-5 pt-2">
                <Typography id="discrete-slider" gutterBottom>
                  Word Flipper
                </Typography>
              </div>
              <div className="col-md-7">
                <Slider
                  defaultValue={10}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={10}
                  marks
                  min={10}
                  max={40}
                />
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="mt-3" style={{ paddingLeft: "60%" }}>
              <button
                className={style.btn_trns}
                onClick={() => colChange("feedback")}
              >
                <MdMessage
                  size={"24px"}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Feedback"
                  style={{ color: "#777274" }}
                />
              </button>

              <span className="mx-2"></span>

              <button
                className={style.btn_trns}
                onClick={() => colChange("statistics")}
              >
                <RiBarChartBoxLine
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Statistics"
                  size={"24px"}
                  style={{ color: "#777274" }}
                />
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className={column.column}
            style={{
              width: "100%",
              padding: 0,
              borderRight: "1px solid #777274",
            }}
          >
            {characters.now > 0 ? (<>
            <button
            className={style.trash} 
            onClick={onHandleClear}
            style={{ position: "absolute", top: "12px", right: "10px" }}
            >
            <MdDelete 
              data-toggle="tooltip"
              data-placement="top"
              title="Clear editor"
              size={"24px"}
              />
            </button>
          </>) : ("")}
            
            <TextareaAutosize
              onChange={onLeftEditorChange}
              id="editorLeft"
              className={style.editable}
              value={leftEditorText}
              spellCheck={false}
              placeholder="PET paraphraser will rewrite your text. Start by writing or pasting something here and then press praphraser It."
            />

            <PetAssistanceBtn
              onPetAssistant={onPetAssistant}
              onHandleClear={onHandleClear}
              characters={characters}
              isPara={isParaphrase}
              limitChara={buttonDisable}
            />
          </div>

          <div className={column.column} style={{ width: "100%", padding: 0 }}>
            {/* <SideBtn
            onHandleLike={onHandleLike}
            onSelectedTextCopy={onSelectedTextCopy}
            isLocked={isLocked}
            isLike={isLike}
            onHandleLock={onHandleLock}
            showHistory={showHistory}
          /> */}
            {/* <div id="editorId" className="editor ft_md">
            <Typography>
              <Skeletoncomp width={600} />
              <Skeletoncomp width={500} />
              <Skeletoncomp width={400} />
            </Typography>
            <Editor editorState={editorState} onChange={setEditorState} />
          </div> */}
            <div
              id="editorRight"
              className={style.editable}
              contentEditable={false}
            >
              <Typography>
                <Skeletoncomp width="100%" />
                <Skeletoncomp width="90%" />
                <Skeletoncomp width="80%" />
              </Typography>
            </div>
            <RightBottomBtn
              sentanceNav={sentanceNav}
              onHandleNavigation={onHandleNavigation}
              colChange={colChange}
            />
          </div>

          <div className="col-md-4">
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionEnter={true}
              transitionLeave={true}
            >
              {column.statistics && (
                <Statistics statistics={statistics} colChange={colChange} />
              )}
              {column.feedback && <Feedback colChange={colChange} />}
            </ReactCSSTransitionGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});

export default connect(mapStateToProps)(Paraphrasetwo);
