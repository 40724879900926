import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import deletes from "../../static/delete.png";
import plus from "../../static/plus.png";
import InputFeedback from "../Forms/FormComponents/InputFeedBack";
import { withRouter } from "react-router-dom";
import Loader from "../../utils/loader";
import { toast } from "react-toastify";
import { postPrivacyCMS, getPrivacyCMS } from "../../services/adminServices"
import HeadingB from "../Cards/HeadingB";

const TextArea = (props) => {
  return <textarea {...props} className="form-control" rows="4" />;
};

const schema = Yup.object({
  privacy: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required("Mandatory"),
      answer: Yup.string().required("Mandatory"),
    })
  ),
});

function PrivacyCMS({ cmsData }) {
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState(cmsData.data);

  let value = {
    // faq: [
    //   {
    //     question: "",
    //     answer: "",
    //   },
    // ],
    privacy: cmsData.data
  }

  useEffect(() => {
    
  }, []);

  return (
    <div>
      <div className="container-fluid h-100 bg-white rounded shadow mt-5 mb-5 pt-4">
        <div className="text-center">
          <HeadingB className="text-center alertHead pt-5 mb-5" text="PRIVACY POLICY" />
        </div>
        {/* <ToastContainer /> */}
        <Formik
          initialValues={value}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
              setLoading(true)
            console.log(values.privacy);
            // let finalData = mainData;
            //     finalData['question'] = values.faq.question;
            //     finalData['answer'] = values.faq.answer;
            //     console.log(finalData);
                let post = await postPrivacyCMS(values);
                if(post.status === 200){
                    toast.success("Changes Updated");
                }else{
                    toast.error("Failed To Save");
                }
            resetForm({});
            setLoading(false)
          }}
          render={({ values }) => (
            <Form>
              <Loader loading={loading} />

              <FieldArray
                name="privacy"
                render={(arrayHelpers) => (
                  <div>
                    {values.privacy && values.privacy.length > 0
                      ? values.privacy.map((privacy, index) => (
                          <div
                            key={index}
                            style={{ marginLeft: "180px", marginTop: "10px" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "20px", marginLeft: "-10px" }}
                            >
                              <div className="col-md-5">
                                <h6>Question</h6>
                                <Field
                                  name={`privacy[${index}].question`}
                                  as={TextArea}
                                />
                                <ErrorMessage
                                  as={InputFeedback}
                                  name="privacy[0].question"
                                />
                              </div>

                              <div className="col-md-5">
                                <h6>Answer</h6>
                                <Field
                                  name={`privacy[${index}].answer`}
                                  as={TextArea}
                                />
                                <ErrorMessage
                                  as={InputFeedback}
                                  name="privacy[0].answer"
                                />
                              </div>
                              {values.privacy && values.privacy.length == 1 ? (
                                ""
                              ) : (
                                <div className="col-md-2">
                                  <div className="row">
                                    <img
                                      alt="delete"
                                      src={deletes}
                                      style={{
                                        height: "20px",
                                        width: "15px",
                                        marginTop: "18%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                      : ""}
                    <div
                      className="row"
                      style={{ marginTop: "20px", marginLeft: "180px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outlined-primary"
                        style={{ color: "#1976d2" }}
                        onClick={() =>
                          arrayHelpers.push({
                            question: "",
                            answer: "",
                          })
                        }
                      >
                        <img src={plus} className="ft_sm mr-2" alt="plus" />
                        Add another Question
                      </button>
                    </div>
                  </div>
                )}
              />

              <div
                className="row"
                style={{ marginTop: "7%", paddingBottom: "5%" }}
              >
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        width: "15%",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}

export default PrivacyCMS;
