import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.actions";

function AdminAuthenticatedRoute({ component: C, appProps,isAuth,currentUser, ...rest }) {
    console.log(isAuth);
    return (
      <Route
        {...rest}
        render={props =>
          (isAuth && currentUser.isAdmin)
            ? <C {...props} {...appProps} />
            : <Redirect
                to={`/login?redirect=${props.location.pathname}${props.location.search}`}
              />}
      />
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
    isAuth: state.user.isAuth,
  });
  const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(AdminAuthenticatedRoute);