module.exports = function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;
  // console.log(previous);
  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " secs ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " mins ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hrs ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
};

//new Date('2019-11-18T08:10:58.235Z');
// 2019-11-18T08:10:58.235Z
