import React from "react";
import ResetPass from "../ResetPass";
import queryString from "query-string";
const ResetPassPage = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("ForgotEmail", values.email);
  }
  return (
    <React.Fragment>
      <ResetPass />
    </React.Fragment>
  );
};

export default ResetPassPage;
