import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/foldercite`;
} else {
  apiEndpoint = `${herokuUrl}/foldercite`;
}


//  Create Folder
export async function createFolder(data) {
  const response = await httpService.post(`${apiEndpoint}/createFolder`, data);
  return response;
}
// Get ALl Folder
export async function getAllFolders() {
  const response = await httpService.get(`${apiEndpoint}/getAllFolders`);
  return response;
}

// Edit folder name
export async function editFoldername(data) {
  let response = await httpService.post(`${apiEndpoint}/editFoldername`, data);
  return response;
}


//  Delete Folder
export async function deleteFolder(data) {
    const response = await httpService.post(`${apiEndpoint}/deleteFolder`, data);
    return response;
  }

export default {
  createFolder,
  getAllFolders,
  editFoldername,
  deleteFolder
}
