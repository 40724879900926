import React from "react";
import Alert from "../Alerts";
import SideNavBar from "../SideNavBar";
const AlertsPage = () => {

  return (
    <SideNavBar>
      <Alert />
    </SideNavBar>
  );

};

export default AlertsPage;
