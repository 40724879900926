import React, { useState ,useEffect} from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import authService from "../services/authService";
import Group244 from "../static/Group244.png";
import Group5702x from "../static/Group5702x.png";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./LoginForm.css";
import loginBackground from "../static/loginBackground.png";
import Login1 from "../static/login-3.png";
import { getHomeCMS } from "../services/adminServices";
import {
  Card,
  InputGroup,
  FormControl,
  Form,
  Button,
  ButtonGroup,
  Carousel
} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  dense: {
    marginTop: 19
  }
}));
// const headOneStyle = {
//   fontSize: "42px",
//   lineheight: "0.88",
//   textAlign: "left",
//   color: "#ffffff"
// };
// const headTwoStyle = {
//   fontSize: "23px",
//   fontWeight: "300",
//   lineHeight: "0.91",
//   textAlign: "left",
//   color: "#ffffff"
// };
export default function ForgotPassNew() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [cms, setCms] = useState({});

  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    console.log(cms);
  };

  useEffect(() => {
    
    getCms();
    // return () => toast.dismiss();
  }, []);

  async function handleForgotPassword() {
    setLoading(true);
    if (!email) {
      setLoading(false);
      toast.warn("Please enter the email");
      return;
    }
    const result = await authService.forgotpasssword({ email });
    console.log(result);
    if (result.status === 200) {
      setLoading(false);
      toast.success(result.message);
    } else {
      setLoading(false);
      toast.error(result.message);
    }
  }

  
  return (
    <div>
      <ToastContainer />
      <Loader loading={loading} />
     
        <div className="row">
          {/* Flex Two */}

          <div className="col-md-5 p-4"
          style={{
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          >
          {/* Flex One */}
          <div className="text-center mt-2">
              <Link to="/">
                <img
                  // src={image1}
                  // alt="image1"
                  className="petLogo"
                  alt="Group570"
                  src={
                    cms && cms.Navbar_logo
                      ? cms.Navbar_logo.Image
                      : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+571.png"
                  }
                  style={{
                    width: "40%",
                    height: "10%",
                    // objectFit: "contain",
                  }}
                ></img>
              </Link>
            </div>

            <div
              style={{
                paddingLeft: "15%",
                paddingRight: "15%",
                paddingTop: "8%",
                paddingBottom: "2%",
                marginTop: "-1%"
              }}
            >
                <Card className="text-center" style={{ border: "none", borderRadius: "8px" }}>
                <div className="text-center pt-5">
                  <h3 className="mainHeading">Forgot your password</h3>
                  <p
                    style={{
                      opacity: "0.5",
                      fontSize: "12px",
                      lineHeight: "1.52",
                      // textAlign: "center",
                      color: "#4d4f5c"
                    }}
                  >
                    Reset your password through email id
                  </p>
                  <div className="loginForm">
                  <Form
                    // className={classes.container}
                    noValidate
                    autoComplete="off"
                  >

                    <InputGroup size="lg" className="mb-1 loginInput">
                      
                      <FormControl
                      type="email"
                      label="Email"
                        value={email}
                        className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      onChange={e => setEmail(e.target.value)}
                        placeholder="Email"
                        aria-describedby="basic-addon1"
                      />
                    
                  </InputGroup>
                  </Form>
                    </div>
                    <div className="row" style={{ marginTop: "8%" ,marginBottom:"15%"}}>
                  <div className="col-md-6">
                  <button
                    type="button"
                    className="btn fpBtn1"
                    style={{
                      color: "white",
                      backgroundColor: "#1976d2",
                    }}
                    onClick={handleForgotPassword}
                  >
                    Send
                  </button>
                  </div>
                  <div className="col-md-6">
                  <Link to={{ pathname: "/login" }}>
                    <button
                      type="button"
                      className="btn fpBtn2"
                      style={{
                        border: "1px solid #1976d2",
                        color: "#1976d2",
                      }}
                      onClick={handleForgotPassword}
                    >
                      Back
                    </button>
                  </Link>
                  </div>
                </div>
                </div>
                </Card>
                <div className="text-center mt-4">
                <Link to="/privacypolicy" className="privacyLink">
                  Term of use. Privacy policy
                </Link>
              </div>
                </div>
            
              <div className="row" style={{ marginTop: "60%" }}>
                <div className="col-md-12 text-center">
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "1.67",
                      textAlign: "center",
                      color: "#43425d"
                    }}
                  >
                    <a href="/privacypolicy" style={{ textDecoration: "none" }}>
                      Term of use. Privacy policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          
          {/* Flex Two */}
          <div
            className="col-md-7"
            style={{
              backgroundImage: `url(${Login1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/* Flex One Content */}
          </div>
        </div>
      </div>
    
  );
}
