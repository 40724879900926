import React, { Component } from "react";
import CompareDocument from "../CompareDocument";
import SideNavBar from "../SideNavBar";

export default class CompareDocumentPage extends Component {
  render() {
    return (
      <SideNavBar>
        <CompareDocument />
      </SideNavBar>
    );
  }
}
