import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DefaultBlogImage from "../../static/defaultBlogThree.jpg";
import "./BlogCard.css";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import {
    camelCase,
    capitalCase,
    constantCase,
    dotCase,
    headerCase,
    noCase,
    paramCase,
    pascalCase,
    pathCase,
    sentenceCase,
    snakeCase,
} from "change-case";
import { FacebookIcon, FacebookShareButton } from 'react-share'
import { ShareableLink } from "../../services/blogServices";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: 215,
        marginTop: 20,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
});
export default function SEOBlogCard({
    data,
    showBlog,
    permanentDelete,
    search
}) {
    const classes = useStyles();
    let content = getTextFromEditorState(data.onlyDocumentContent, true);
    let link = ShareableLink(data._id, paramCase(data.documentName));
    console.log(data.Date);
    const mdate = new Date(data.date || data.Date);
    let blogDate = new Date(mdate).toString().split(" ").splice(1, 3).join(" ");
    const newCont = (data) => {
        let newContent = data + " ";
        let length = 100 - data.length;
        for (let i = 0; i < length / 2; i++) {
            newContent = newContent + " \xa0";
        }
        return newContent;
    };
    return (
        <div>
            <div className="row" style={{ padding: "2%" }}>
                <div className="col-sm-2">
                    {data.imageUrl ? (
                        <img
                            src={
                                "https://petblogbucket.s3.us-east-2.amazonaws.com/" +
                                data.imageUrl[0]
                            }
                            alt="BlogImage"
                            style={{ width: "100%", height: "140px" }}
                        />
                    ) : (
                            <img
                                src={DefaultBlogImage}
                                alt="BlogImage"
                                style={{ width: "100%", height: "140px" }}
                            />
                        )}
                </div>
                <div className="col-sm-6">
                    <h2 className="head">
                        <b>{data.documentName}</b>
                    </h2>
                    <div
                        className="row headtwo mb-1"
                        style={{ marginLeft: "0%", marginTop: "2%" }}
                    >
                        <small> {data.editor ? data.editor.username : "PET User"} &nbsp; - &nbsp;</small>
                        <small>{blogDate}</small>
                    </div>
                    <p style={{ fontSize: "15px" }}> {newCont(content)} </p>
                    <br />
                    <br />
                    <p
                        onClick={() => showBlog(data)}
                        style={{ color: "#3b81f2", cursor: "pointer", marginTop: "-5%" }}
                    >
                        Continue Reading
          </p>
                </div>
                <div className="col-sm-4">
                    <div className="row">
                        <div className="col-md-5">
                            {/* // <FacebookShareButton
                //   url={link}
                //   hashtag={`#PET_Tool`}
                //   quote={data.documentName}
                //   className="Demo__some-network__share-button"
                // >
                //   <FacebookIcon size={32} round />
                // </FacebookShareButton> */}
                            <br />
                            {data.tags ? 
                                (data.tags.slice(0, 3).map((tag) => (
                                    <button
                                        key={tag}
                                        type="button"
                                        className="border ft_xs mr-2 p-2 mb-1"
                                        onClick={() => search(tag)}
                                        style={{
                                            backgroundColor: "#007bff",
                                            borderRadius: "20px",
                                            color: "white",
                                        }}
                                    >
                                        {capitalCase(tag)}
                                    </button>
                                ))) : ("")
                            }
                        </div>
                        <div className="col-sm-4" style={{ float: "right" }}>
                            <Tooltip title="Permanent Delete">
                                <DeleteIcon
                                    className="point ml-2"
                                    style={{ fontSize: "25px", color: "#2196f3" }}
                                    onClick={() => permanentDelete(data)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
