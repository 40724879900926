export const option_ = {
  inlineStyles: {
    red: {
      style: {
        color: "red"
      }
    },
    handwritten: {
      style: {
        fontFamily: "hand-writing"
      }
    },
    sans_serif: {
      style: {
        fontFamily: "Arial, Helvetica, sans-serif"
      }
    },
    serif: {
      style: {
        fontFamily: "Times New Roman, Times, serif"
      }
    },
    slab_serif: {
      style: {
        fontFamily: "Josefin-Slab"
      }
    },
    monospace: {
      style: {
        fontFamily: "Courier New, Courier, monospace"
      }
    },
    black: {
      style: {
        color: "#343a40"
      }
    },
    red: {
      style: {
        color: "red"
      }
    },
    blue: {
      style: {
        color: "blue"
      }
    },
    indigo: {
      style: {
        color: "indigo"
      }
    },
    purple: {
      style: {
        color: "purple"
      }
    },
    pink: {
      style: {
        color: "pink"
      }
    },
    orange: {
      style: {
        color: "orange"
      }
    },
    green: {
      style: {
        color: "green"
      }
    },
    yellow: {
      style: {
        color: "yellow"
      }
    },
    sizeA: {
      style: {
        fontSize: 8
      }
    },
    sizeB: {
      style: {
        fontSize: 10
      }
    },
    sizeC: {
      style: {
        fontSize: 12
      }
    },
    sizeD: {
      style: {
        fontSize: 14
      }
    },
    sizeE: {
      style: {
        fontSize: 16
      }
    },
    sizeF: {
      style: {
        fontSize: 18
      }
    },
    sizeG: {
      style: {
        fontSize: 20
      }
    },
    sizeH: {
      style: {
        fontSize: 22
      }
    },
    sizeI: {
      style: {
        fontSize: 24
      }
    },
    sizeJ: {
      style: {
        fontSize: 26,
        textAlign: "center"
      }
    },
    sizeK: {
      style: {
        fontSize: 28
      }
    },
    SUPERSCRIPT: {
      style: {
        fontSize: "0.6em",
        verticalAlign: "super"
      }
    },
    citations:{
      style:{
        // fontSize: 28
      textDecorationLine: "underline",
      textDecorationStyle: "wavy",
      textDecorationColor: "#7D8FFD"
      }
    }
  }
};

export const styleMap = () => {
  const inlineStyles = option_.inlineStyles;
  const styleMap = {};
  for (const key in inlineStyles) {
    const element = inlineStyles[key];
    styleMap[key] = element.style;
  }
  return styleMap;
};

export const FONT_TYPES = [{
    label: "Handwritten",
    style: "handwritten"
  },
  {
    label: "Sans Serif",
    style: "sans_serif"
  },
  {
    label: "Serif",
    style: "serif"
  },
  {
    label: "Slab Serif",
    style: "slab_serif"
  },
  {
    label: "Monospace",
    style: "monospace"
  }
];

export const FONT_COLOR = [{
    label: "Black",
    style: "black"
  },
  {
    label: "Red",
    style: "red"
  },
  {
    label: "Blue",
    style: "blue"
  },
  {
    label: "Indigo",
    style: "indigo"
  },
  {
    label: "Purple",
    style: "purple"
  },
  {
    label: "Pink",
    style: "pink"
  },
  {
    label: "Orange",
    style: "orange"
  },
  {
    label: "Green",
    style: "green"
  },
  {
    label: "Yellow",
    style: "yellow"
  }
];

export const FONT_SIZE = [{
    label: "8",
    style: "sizeA"
  },
  {
    label: "10",
    style: "sizeB"
  },
  {
    label: "12",
    style: "sizeC"
  },
  {
    label: "14",
    style: "sizeD"
  },
  {
    label: "16",
    style: "sizeE"
  },
  {
    label: "18",
    style: "sizeF"
  },
  {
    label: "20",
    style: "sizeG"
  },
  {
    label: "22",
    style: "sizeH"
  },
  {
    label: "24",
    style: "sizeI"
  },
  {
    label: "26",
    style: "sizeJ"
  },
  {
    label: "28",
    style: "sizeK"
  }
];

export const BLOCK_TYPES = [{
    label: "Normal Text",
    style: "Normal Text"
  },
  {
    label: "H1",
    style: "header-one"
  },
  {
    label: "H2",
    style: "header-two"
  },
  {
    label: "H3",
    style: "header-three"
  },
  {
    label: "H4",
    style: "header-four"
  },
  {
    label: "H5",
    style: "header-five"
  },
  {
    label: "H6",
    style: "header-six"
  }
];

export const errorList = [{
  label: "passive",
  content: "Passive voice: Use active voice",
  label: "error",
  content: "Correctness: Spelling"
}];

export const grammerColor= (GrammarChecker) => {
  console.log('GrammarChecker', GrammarChecker)
 return [
    {
      secition: "Correctness",
      color: "#ff0000",
      list: ["GRAMMAR", "CASING", "CONFUSED_WORDS", "SEMANTIC", "TYPOS", "passive", "MISC", "effectivevocabulary", "NONSTANDARD_PHRASES", "REDUNDANCY"],
      status: true,
      eligible: true
    },
    {
      secition: "Check",
      color: "#3282b8",
      list: ["error"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Sentence Structure",
      color: "cyan",
      list: ["weaken", "hard", "complex", "COLLOCATIONS", 'whitespace'],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Improper Formating",
      color: "green",
      list: ["space", "TYPOGRAPHY", "PUNCTUATION"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Inappropriate Colloquialisms",
      color: "orange",
      list: ["STYLE"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Overused Words",
      color: "purple",
      list: ["REPETITION", "overuse"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Qualifiers",
      color: "blue",
      list: ["qualifier"],
      status: false,
      eligible: GrammarChecker
    }
  ];
}




export const headingList = {
  CASING: {
    head: "CASING",
    body: "Capitalization of first word"
  },
  TYPOS: {
    head: "TYPOS",
    body: "TYPOS body"
  },
  error: {
    head: "error",
    body: "Mispelled words"
  },
  weaken: {
    head: "weaken",
    body: "Words which can be eliminated without changing the interpretation."
  },
  passive: {
    head: "passive",
    body: "Action and object is been emphasized more rather than the subject."
  },
  overuse: {
    head: "overuse",
    body: "Words are being repeated making their meaning obfuscated. "
  },
  space: {
    head: "space",
    body: "wide blank spaces"
  },

  qualifier: {
    head: "qualifier",
    body: "word that limits or enhances another words meaning, while affecting the certainty and specificity of a statement."
  },
  effectivevocabulary: {
    head: "effectivevocabulary",
    body: "words which are more efficacious making the statements more constructive"
  },
  hard: {
    head: "hard",
    body: ""
  },
  complex: {
    head: "complex",
    body: "Sentence consisting of an independent clause plus a dependent clause."
  },
  COLLOCATIONS: {
    head: "COLLOCATIONS",
    body: "Collocation is a series of words or terms that co-occur more often than would be expected by chance."
  },
  CONFUSED_WORDS: {
    head: "CONFUSED_WORDS",
    body: "Words which sound the same or similar yet have different meaning."
  },
  GRAMMAR: {
    head: "GRAMMAR",
    body: " Set of structural rules governing the composition of clauses, phrases and words in a natural language."
  },
  MISC: {
    head: "MISC",
    body: "Odd bunch of words — words you might not expect to go together"
  },

  NONSTANDARD_PHRASES: {
    head: "NONSTANDARD_PHRASES",
    body: "Commonly used but are not necessarily correct"
  },
  PUNCTUATION: {
    head: "PUNCTUATION",
    body: "The marks, such as full stop, comma, and brackets, used in writing to separate sentences and their elements and to clarify meaning"
  },
  REDUNDANCY: {
    head: "  REDUDANCY",
    body: "Information is been expressed more than once"
  },

  REPETITION: {
    head: "REPETITION",
    body: "Often repeated words."
  },
  SEMANTIC: {
    head: "SEMANTIC",
    body: ""
  },
  STYLE: {
    head: "STYLE",
    body: ""
  },
  TYPOGRAPHY: {
    head: "TYPOGRAPHY",
    body: "Improper formatting of the setence."
  },
  whitespace: {
    head: "BLANK SPACE",
    body: "Improper spacing between the Words."
  }
};


// the value return only selected by true
export const filterList = (grammerList, suggesions) => {
  let totList = [];
  grammerList.filter(obj => {
    if (obj.status) totList = totList.concat(obj.list);
  });
  let filterSuggesion = {};
  for (const key in suggesions) {
    let suggestList = suggesions[key];
    let filt = suggestList.filter(obj => {
      if (totList.indexOf(obj.pgm) >= 0) {
        obj.anchorKey = key; // anchorKey = 2j6hs
        obj.span_id = `${key}_${obj.offset}_${obj.offset + obj.length}` // id = "2j6hs_14_18"
        return obj;
      }
    });
    filterSuggesion[key] = filt;
  }
  return filterSuggesion;
};

export const strCount = (str) => {
  const wordArray = str.match(/\S+/g); // matches words according to whitespace
  const wordsCount = wordArray ? wordArray.length : 0;
  const characterCount = str.length;
  // console.log("wordsCount", wordsCount, characterCount);
  return [wordsCount, characterCount]
}