import React, { useEffect, useState } from "react";
import Loader from "../utils/loader";
import { makeStyles } from "@material-ui/core/styles";
import HeadingB from "./Cards/HeadingB";
import BreadCrumb from "./Cards/BreadCrumbs";
import { feature } from "caniuse-lite";
import {
    submitContactForm
} from "../services/userService";
import contactImg from "../static/contactUs.png" 
import { toast, ToastContainer } from "react-toastify";
const useStyles = makeStyles({
    root: {
        minWidth: 150,
        width: 150,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


function ContactUs({ }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [email, setemail] = useState("");
    const [feature, setfeature] = useState("");
    const [message, setmessage] = useState("");


    useEffect(() => {

    }, [])


    const handleSubmit = async () => {
        if (!email) {
            toast.warn("Please Enter Email.");
        } else if (!feature) {
            toast.warn("Please Select Feature.");
        } else if (!message) {
            toast.warn("Please write message to us.");
        }
        else {
            let details = {
                userEmail: email,
                selectedFeature: feature,
                message: message,

            };
            console.log(details)
            let response = await submitContactForm(details);
            console.log(response)
            if (response.status === 200) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }

        }
        setfeature("")
        setmessage("")
        setemail("");
    };



    const options = [
        {
            text: "Newsletter",
            value: "newsletter",
            icon: "sticky note outline"
        },
        { text: "Alerts", value: "alert", icon: "bell outline" }
    ];

    return (
        <div>
            
            <BreadCrumb
            // style={{backgroundColor:"white !important"}}
            l1="Home"
            l2="Support"
            dashboardlink="/dashboard"
          />
        <div className="container-fluid h-100" id="DocCard">
            <Loader loading={loading} />
            <ToastContainer />
            <div className="container" >
            
                <HeadingB className="text-center alertHead pt-5" text="SUPPORT" />
               <div className="row">
                   <div className="col-md-6"> <div className="mt-5">
                    <label for="introTitle">Email</label>
                    <hr />
                    <input
                        type="email"
                        className="form-control w-100  mt-2"
                        id="introTitle"
                        onChange={(e) => setemail(e.target.value)}
                        value={email || ""}
                        name="Email"
                        placeholder="Enter Email ID"
                        maxLength="50"
                    />


                </div>
                <div className="mt-5">
                    <label for="introSubtext">What's this about?</label>
                    <hr />
                    <select
                        class="browser-default custom-select dropdown-primary w-100"
                        value={feature}
                        onChange={e => {
                            setfeature(e.target.value);
                            //   onChange(e.target.value);
                        }}
                    >
                        <option value="" selected>
                            Choose your option
        </option>
                        <option value="textEditor" id="soflow-color">
                            Text Editor
        </option>
                        <option value="teams" id="soflow-color">
                            Teams
        </option>
                        <option value="blogs" id="soflow-color">
                            Blogs
        </option>
                        <option value="citation" id="soflow-color">
                            Citation
        </option>
                        <option value="paraphrasing" id="soflow-color">
                            Paraphrasing
        </option>
                        <option value="plagarism" id="soflow-color">
                            Plagarism
        </option>
                        <option value="reports" id="soflow-color">
                            Reports
        </option>
                        <option value="subscription" id="soflow-color">
                            Subscription
        </option>
                        <option value="invoices" id="soflow-color">
                            Invoices
        </option>
                        <option value="other" id="soflow-color">
                            Other
        </option>
                    </select>

                </div>

                <div className="mt-5">
                    <label for="introSubtext">How can we help??</label>
                    <hr />
                    <textarea
                        class="form-control w-100  mt-2"
                        id="introSubtext"
                        placeholder="Write here! We are listening..."
                        onChange={(e) => setmessage(e.target.value)}
                        value={message || ""}
                        name="Project_subtext"
                        rows="4"
                        maxLength="350"
                    ></textarea>

                </div>

                <div className="mt-3 text-center">

                    <button
                        className="btn btn-primary mb-4"
                        type="submit"
                        id="backBtn"
                        onClick={handleSubmit}
                        style={{
                            width: "20%",
                        }}
                    // disabled={buttonDisable}
                    >
                        Submit
          </button>
                </div></div>
                <div className="col-md-6">
                <img
                src={contactImg}
                style={{ width: "100%", height: "100%" }}
                alt="Contact Us"
              />
                </div>
               </div>
            </div>
        </div>
        </div>
    );
}
export default ContactUs;
