import { apiUrl, herokuUrl } from "./config.json";
import http from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/invites";
} else {
  apiEndpoint = herokuUrl + "/invites";
}

//  Send Invite
export async function inviteMember(email) {
  const response = await http.post(`${apiEndpoint}/sendInvite`, {
    email: email
  });
  return response;
}

//  Create Group
export async function createGroup(data) {
  const response = await http.post(`${apiEndpoint}/createGroup`, data);
  return response;
}

// Get ALl Group
export async function getAllGroups() {
  const response = await http.get(`${apiEndpoint}/getAllGroups`);
  return response;
}

// Get Shared Groups
export async function getGroupById(data) {
  const response = await http.post(`${apiEndpoint}/getGroupById`, data);
  return response;
}

// Get Group Members Data
export async function getGroupMembers(data) {
  const response = await http.post(`${apiEndpoint}/getGroupMembers`, data);
  return response;
}

// Add Member to Group
export async function addMemberToGroup(data) {
  const response = await http.post(`${apiEndpoint}/addMemberToGroup`, data);
  return response;
}

// Remove member from group
export async function removeMemberFromGroup(data) {
  const response = await http.post(`${apiEndpoint}/removeMemberFromGroup`, data);
  return response;
}

// Leave group
export async function leaveGroup(data) {
  const response = await http.post(`${apiEndpoint}/leaveGroup`, data);
  return response;
}

//  Delete Group
export async function deleteGroup(data) {
  const response = await http.post(`${apiEndpoint}/deleteGroup`, data);
  return response;
}

export default {
  inviteMember,
  createGroup,
  getAllGroups,
  getGroupById,
  getGroupMembers,
  addMemberToGroup,
  removeMemberFromGroup,
  leaveGroup,
  deleteGroup,
  //export all the above mentioned api call functions here
};
