import {documentActionTypes} from './document.types';
export const setUserDocument = document => ({
    type : documentActionTypes.SET_USER_DOCUMENT,
    payload : document
})

export const setUserDeletedDocument = document => ({
    type : documentActionTypes.SET_USER_DELETED_DOCUMENT,
    payload : document
})

export const setSharedToUserDocument = document => ({
    type : documentActionTypes.SET_SHARED_TO_USER_DOCUMENT,
    payload : document
})

export const addCreatedDocument = document => ({
    type : documentActionTypes.ADD_CREATED_DOCUMENT,
    payload : document
})
export const removeDeletedDocument = document => ({
    type : documentActionTypes.REMOVE_DELETED_DOCUMENT,
    payload : document
})