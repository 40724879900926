import React from "react";
import MyDocuments from "../MyDocuments";
import AdminNavBar from "../AdminSideNavBar";
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import authService from "../../services/authService";

const MyDocumentsPage = props => {
  const user = authService.getCurrentUser();
  let userData = {};
  if (props.location.state) {
    userData = props.location.state.userData;
    console.log("myDocumentPage", userData);
  }
  if (!user.isAdmin) {
    return (
      <SideNavBar>
        <MyDocuments />
      </SideNavBar>
    );
  } else {
    return (
      <AdminNavBar>
        <MyDocuments data={userData} />
      </AdminNavBar>
    );
  }
};

export default NetworkDetector(withRouter(MyDocumentsPage));
