import React from "react";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import { ToastContainer } from "react-toastify";
import "./LoginForm.css";
import { Link } from "react-router-dom";
import { getFaqCMS, getHomeCMS } from "../services/adminServices";

class FAQ extends React.Component {
  state = {
    cmsFaq: [],
    cms: {},
  };

  async componentDidMount() {
    let cms = await getHomeCMS();
    this.setState({ cms: cms.data });
    console.log(this.state.cms);

    let cmsFaq = await getFaqCMS();
    this.setState({ cmsFaq: cmsFaq.data });
    console.log(this.state.cmsFaq);
  }

  render() {
    let { cmsFaq, cms } = this.state;

    return (
      <div>
        <ToastContainer />
        <div className="container " style={{ width: "100%" }}>
          <div
            className="row d-inline-flex p-3 text-white"
            style={{ width: "100%" }}
          >
            {/* Flex One */}
            <div
              className="col-md-6 p-4 border"
              style={{
                // backgroundColor: "#3b81f3",
                width: "100%",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height:"94vh"
              }}
            >
              {/* Flex One Content */}
              <div
                style={{
                  paddingLeft: "19.8%",
                  paddingRight: "14.6%",
                  paddingTop: "16%",
                  
                }}
              >
                <div className="row">
                  <div>
                    <Link to="/">
                      <img
                        className="petLogo"
                        alt="Group570"
                        src={
                          cms && cms.Navbar_logo
                            ? cms.Navbar_logo.Image
                            : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+571.png"
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      ></img>
                    </Link>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20%" }}>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "30px",
                      fontWeight: "500",
                      lineHeight: "1.35",
                      textAlign: "left",
                      color: "#ffffff",
                    }}
                  >
                    {cms && cms.Project_title
                      ? cms.Project_title
                      : "Welcome to Professional Editing Tool"}
                  </p>
                </div>

                <div className="row" style={{ marginTop: "7%" }}>
                  <p style={{ lineHeight: "1.72", textAlign: "justify" }}>
                    {cms && cms.Project_subtext
                      ? cms.Project_subtext
                      : `PET is the industry-leading document editing and team
                        collaboration software for Businesses, Educators,
                        Students and Researchers. PET, integration with other
                        apps and services, with the power of AI brings all the
                        state-of-the-art, extensive features which are available
                        in multiple writing tools. &nbsp;
                          With PET, you can create and edit new projects from
                          any device. Develop a writing education that meets
                          students’ and professionals’ contemporary writing
                          needs.`}
                  </p>
                </div>
              </div>
            </div>

            {/* Flex Two */}

            <div className="col-md-6 p-4 border" style={{ width: "100%" , height:"94vh" }}>
              {/* Flex Two Content */}

              <div
                style={{
                  paddingLeft: "7%",
                  paddingRight: "7%",
                  paddingTop: "7%",
                  paddingBottom: "4%",
                }}
              >
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3
                      style={{
                        fontSize: "35px",
                        fontWeight: "bold",
                        lineHeight: "1.26",
                        color: "#43425d",
                      }}
                    >
                      Frequently Asked Questions
                    </h3>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10%" }}>
                  <div className="col-md-12">
                    <p
                      style={{
                        // opacity: "0.5",
                        fontSize: "15px",
                        lineHeight: "1.72",
                        textAlign: "justify",
                        color: "black",
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "54vh",
                        padding: "5%",
                      }}
                    >
                      {cms && cmsFaq ? (
                        <>
                          {cmsFaq.map((faqs) => (
                            <ul>
                              <li>
                                {/* Question 1 */}
                                <b>{faqs.question}</b>
                              </li>

                              <ul style={{ paddingLeft: "5%" }}>
                                {faqs.answer.split("\n").map((ans) => (
                                  <li>{ans}</li>
                                ))}
                              </ul>
                              <br />
                            </ul>
                          ))}
                        </>
                      ) : (
                        <ul>
                          <li>
                            {/* Question 1 */}
                            <b>
                              Using Professional Editing Tool (PET) on multiple
                              computers
                            </b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              You can use Professional Editing Tool (PET) on
                              multiple computers!
                            </li>
                            <br />
                            <li>
                              The license is a "per user" license, and you may
                              install it on multiple computers as long as you
                              are the only person using it. If other people are
                              using it then you will need to buy an extra
                              license for them.
                            </li>
                            <br />
                          </ul>

                          {/* Question 2 */}
                          <li>
                            <b>
                              Does Professional Editing Tool (PET) work offline?
                            </b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>No</li>
                          </ul>
                          <br />

                          {/* Question 3 */}
                          <li>
                            <b>
                              What is the maximum word limit Professional
                              Editing Tool (PET) can check?
                            </b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              There is no maximum word limit, however, requests
                              over 150,000 characters (about 30,000 words) are
                              broken down into sections.
                            </li>
                            <br />
                            <li>
                              This is done by chapter if the document includes
                              chapter headings, or into chunks of 100,000
                              characters otherwise.
                            </li>
                            <br />
                            <li>
                              Please bear in mind, our app works best when
                              looking at fewer than 10,000 words at a time. This
                              is because we have numerous high-powered reports
                              which must contact our servers to function. Many
                              customers break their documents up by chapter to
                              get under that word count and to avoid becoming
                              overwhelmed with suggestions.
                            </li>
                            <br />
                          </ul>

                          {/* Question 4 */}
                          <li>
                            <b>
                              How to use Professional Editing Tool (PET) on a
                              Mac
                            </b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              We have many Mac users. Mac users can use our app:
                            </li>
                            <br />
                            <li>Online using our online editor;</li>
                            <br />
                            <li>
                              In Google Docs using our Google Docs add-on;
                            </li>
                            <br />
                            <li>On their desktop using our Desktop app;</li>
                            <br />
                            <li>
                              Microsoft Word for Mac doesn't support advanced
                              add-ins but if you want to edit your Word document
                              without losing your formatting then you can use
                              our Desktop app.
                            </li>
                            <br />
                          </ul>

                          {/* Question 5 */}
                          <li>
                            <b>How do I get a refund?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              We're sorry to hear that you want a refund! We
                              have a 14-day full refund policy. You can email us
                              at hello@professionaleditingtool.com to receive
                              your refund.
                            </li>
                            <br />
                          </ul>

                          {/* Question 6 */}
                          <li>
                            <b>Do you have an iOS app?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              We don't currently have an iOS app. However, you
                              can use the web editor with your mobile device's
                              browser.
                            </li>
                            <br />
                          </ul>

                          {/* Question 7 */}
                          <li>
                            <b>How secure is my work?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              We never keep your work. We run thousands of
                              analyses requests every day, the data is sent to
                              our servers to be processed but it is never stored
                              on our servers. We give you our word as fellow
                              writers.
                            </li>
                            <br />
                            <li>
                              Your documents are created and uploaded to your
                              account, but you are the only one who has access
                              to them.
                            </li>
                            <br />
                            <li>The data is also encrypted.</li>
                            <br />
                            <li>Here is a link to our Privacy Policy.</li>
                            <br />
                          </ul>

                          {/* Question 8 */}
                          <li>
                            <b>What does Professional Editing Tool (PET) Do?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              Professional Editing Tool (PET) provides more
                              features than any other editing software on the
                              market, including many that are unique to our
                              product.
                            </li>
                            <br />
                            <li>
                              We want to help you make your writing the best it
                              can be.
                            </li>
                            <br />
                            <li>
                              Professional Editing Tool (PET) analyzes your
                              writing and presents its findings in over 20
                              different reports (more than any other editing
                              software). Each writer has their own strengths and
                              weaknesses and so different reports will appeal to
                              different users.
                            </li>
                            <br />
                          </ul>

                          {/* Question 9 */}
                          <li>
                            <b>Is there an extension for FireFox?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>Yes</li>
                          </ul>
                          <br />

                          {/* Question 10 */}
                          <li>
                            <b>
                              How much does Professional Editing Tool (PET)
                              cost?
                            </b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              Please visit our purchase page for all the
                              different license options and our costs.
                            </li>
                            <br />
                            <li>Purchase Premium Plan</li>
                            <br />
                            <li>Purchase Professional Plan</li>
                            <br />
                            <li>Purchase Business Plan</li>
                            <br />
                            <li>
                              We offer student/educational discounts and
                              group/bulk purchasing discounts. Information can
                              also be found on our purchase page.
                            </li>
                            <br />
                            <li>
                              If you would like to contact us for a specific
                              quote please email us at
                              hello@professionaleditingtool.com.
                            </li>
                            <br />
                          </ul>

                          {/* Question 11 */}
                          <li>
                            <b>Is there an extension for Safari?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>Yes</li>
                          </ul>
                          <br />

                          {/* Question 12 */}
                          <li>
                            <b>How do I use Professional Editing Tool (PET)?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>Create Videos to show use of the tool.</li>
                            <br />
                          </ul>

                          {/* Question 13 */}
                          <li>
                            <b>
                              Does the Chrome Extension work on all websites?
                            </b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>Yes</li>
                          </ul>
                          <br />

                          {/* Question 14 */}
                          <li>
                            <b>Why haven't I received my activation email?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              Please check your spam folder. If you signed up
                              through PayPal your account may have been set up
                              using the email associated with your PayPal
                              account so please check there also. If you still
                              can't find it please contact support at
                              hello@professionaleditingtool.com and we will
                              happily resend it.
                            </li>
                            <br />
                          </ul>

                          {/* Question 15 */}
                          <li>
                            <b>How do I delete my account?</b>
                            <br />
                            We're sad to see you go. Here's how to delete your
                            account:
                          </li>
                          <ol
                            style={{
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>Log in to your account.</li>
                            <br />
                            <li>
                              Click on your username in the top right-hand
                              corner of the screen.
                            </li>
                            <br />
                            <li>Click on 'Delete my account'.</li>
                            <br />
                            <li>
                              Confirm the deletion by clicking on 'Delete my
                              account'.
                            </li>
                            <br />
                            Please note that cancelling your account will not
                            generate a refund. If you need to request a refund
                            please do not delete your account and contact
                            hello@professionaleditingtool.com for assistance.
                          </ol>
                          <br />

                          {/* Question 16 */}
                          <li>
                            <b>Can I upgrade my license?</b>
                          </li>
                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              I'm afraid we don’t offer the ability to upgrade a
                              subscription after it has been purchased, but we
                              do offer a full 14-day money-back guarantee.
                            </li>
                            <br />

                            <li>
                              If you purchase an additional license the extra
                              months will automatically be added to your
                              existing license.
                            </li>
                            <br />
                          </ul>

                          {/* Question 17 */}
                          <li>
                            <b>Will my license auto-renew?</b>
                          </li>

                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              Yes, we do automatically renew your licenses and
                              you will be automatically charged.
                            </li>
                            <br />

                            <li>
                              New licenses will be added to any existing
                              licenses
                            </li>
                            <br />
                          </ul>

                          {/* Question 18 */}
                          <li>
                            <b>
                              Do you offer a discount for bulk/group purchases?
                            </b>
                          </li>

                          <ul
                            style={{
                              listStyleType: "circle",
                              paddingLeft: "5%",
                              marginTop: "5%",
                            }}
                          >
                            <li>
                              Please contact us at
                              hello@professionaleditingtool.com to discuss any
                              specific requirements or to obtain a customized
                              quote.
                            </li>
                            <br />
                          </ul>
                        </ul>
                      )}
                    </p>
                  </div>
                </div>

                <div className="text-center">
                  <Link to="/">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        width: "50%",
                        marginTop: "10%",
                      }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
