import React from "react";
import TextEditor from "../TextEditor";
import { withRouter } from "react-router-dom";
import SideNavBar from "../SideNavBar";
import authService from "../../services/authService";
import AdminNavBar from "../AdminSideNavBar";

const TextEditorPage = (props) => {
  let document, isNew, documentType;
  const user = authService.getCurrentUser();

  console.log(props);
  if (undefined !== props.history.location.data) {
    documentType = props.history.location.data.documentType;
    if (undefined !== props.history.location.data.document) {
      document = props.history.location.data.document;
      isNew = props.history.location.data.isNew;
    }
  }
  if (!user.isAdmin) {
    return (
      <SideNavBar>
        <TextEditor data={document} isNew={isNew} documentType={documentType} />
      </SideNavBar>
    );
  } else {
    return (
      <AdminNavBar>
        <TextEditor data={document} isNew={isNew} documentType={documentType} />
      </AdminNavBar>
    );
  }
  // return (<SideNavBar><TextEditor data={document} isNew={isNew} documentType={documentType} /></SideNavBar>);
};

export default withRouter(TextEditorPage);
