import React, { Component, useS } from "react";
import Loader from "../utils/loader";
import InvoiceCard from "./Cards/InvoiceCard";
import InvoiceService from "../services/invoiceService";
import BreadCrumb from "./Cards/BreadCrumbs";
import { toast, ToastContainer } from "react-toastify";
import NoInvoices from "../static/noInvoices.png";
import HeadingB from "./Cards/HeadingB";
class ManageInvoices extends Component {
  state = {
    loading: true,
    invoices: [],
    expanded: [],
    filterInvoice: [],
    totalInvoices: 0,
    dashLink: "/admindashboard",
    noInvoice: false,
  };

  filterOnChange = (e) => {
    if (e.target.value === "") {
      this.setState({ filterInvoice: this.state.invoices });
    } else {
      let value = e.target.value;
      let invoices = this.state.invoices;
      let result = [];
      result = invoices.filter((invoice) => {
        console.log(invoice);
        if (
          invoice.userID.username.toLowerCase().search(value.toLowerCase()) !==
          -1
        ) {
          return invoice;
        }
      });
      this.setState({ filterInvoice: result });
    }
  };

  componentDidMount() {
    this.getAllpayments();
  }

  // For admin
  getAllpayments = async () => {
    let details = await InvoiceService.getAllpaymentsForAdmin();
    if (details.status !== 200) {
      toast.error(details.message);
    } else {
      
      if (details.data.length < 1) {
        this.setState({ loading: false, noInvoice: true });
      } else {
        const expanded = new Array(details.length);
        expanded.fill(false);
        this.setState({
          filterInvoice: details.data,
          invoices: details.data,
          expanded,
          loading: false,
        });
        console.log(this.state.invoices);
      }
    }
  };

  handleExpand = (panel, index) => {
    const { expanded } = this.state;
    if (!expanded[index]) {
      expanded.fill(false);
    }
    expanded[index] = !expanded[index];
    this.setState({ expanded });
  };

  render() {
    const { expanded, dashLink, filterInvoice, noInvoice } = this.state;
    console.log(expanded);
    return (
      <div>
        <Loader loading={this.state.loading} />
<ToastContainer/>
        {/* <div className="container"> */}
          <BreadCrumb
            l1="Dashboard"
            l2="Manage Invoice"
            dashboardlink={dashLink}
          />
          {/* <NumberWidget totalInvoice={this.state.totalInvoices} /> */}

          <div className="row">
            <div className="col-md-2">
              <h4 className="ft_lg" style={{ color: "#3e80f1" }}>Manage Invoice</h4>
            </div>

            <div className="col-md-5">
              <input
                className="form-control "
                name="searchQuery"
                onChange={this.filterOnChange}
                type="text"
                placeholder="&#xF002;  Search Invoice"
                aria-label="Search"
                style={{
                  fontFamily: "FontAwesome",
                  border: "none",
                  float: "left",
                }}
              />
            </div>
          </div>
          {noInvoice ? (
            <div className="text-center mt-4">
              <img
                className=" mt-5"
                style={{ height: "150px" }}
                src={NoInvoices}
                alt="404"
              ></img>
              <HeadingB className="alertHead" text="No Invoice Found..!" />
            </div>
          ) : (
            <div className="row mt-2">
              <div className="col-md-12 mt-2 ">
                {filterInvoice.length > 0 &&
                  filterInvoice.map((order, index) => (
                    <InvoiceCard
                      key={index}
                      data={order}
                      index={index}
                      expand={this.handleExpand}
                      expanded={expanded[index] ? `panel${index}` : false}
                    />
                  ))}
              </div>
            </div>
          )}
        {/* </div> */}
      </div>
    );
  }
}
export default ManageInvoices;
