import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast } from "react-toastify";
import DropDown from "./Cards/DropDown";
import UserCard from "./Cards/UserCard";
import {
  getAllManageUsers,
  searchUser,
  planFilter,
} from "../services/adminServices";
import { withRouter } from "react-router-dom";
import "./ManageUser.css";
import BreadCrumb from "./Cards/BreadCrumbs";
import SearchBox from "./SearchBox";
import { Typography } from "@material-ui/core";

const ManageUser = ({ history }) => {
  let [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersBackup, setusersBackup] = useState([]);
  let [chk, setChk] = useState([]);
  const [drop, setDrop] = useState("");
  const [page, setPage] = useState(0);
  const [value, setvalue] = useState("");
  const [filter, setfilter] = useState([]);
  const [dashLink] = ["/admindashboard"];
  const [LastPage, setLastPage] = useState(0);
  const [planFilterDropDown, setPlanFilterDropDown] = useState("all");

  // const [result, setresult] = useState([]);  ***db.users.find({name: /^${string}/i})
  const showCard = 15;

  const getAllUsers = async () => {
    setLoading(true);
    const res = await getAllManageUsers(showCard, page);
    if (res.status === 200) {
      setUsers(res.data);
      setusersBackup(res.data);
      setLastPage(Math.floor(res.pages - 1));
      setfilter(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      console.log("Oops something went worng");
    }
  };

  const handleCheck = (getObj) => {
    if (!drop) return;
    if (chk.some((obj) => obj._id === getObj._id)) {
      chk = chk.filter((obj) => obj._id !== getObj._id);
    } else {
      chk.push(getObj);
    }

    setChk([...chk]);
    console.log(chk);
  };

  useEffect(() => {
    getAllUsers();
  }, [page]);

  const handleSubmit = () => {
    if (chk.length < 1) {
      if (!drop) {
        toast.error("Please select dropdown");
        return;
      }
      toast.error("Please select any one of the User");
      return;
    }
    const sendData = chk;
    console.log(sendData, drop);
    history.push({
      pathname: `/Newsletter`,
      state: { sendData, type: drop },
    });
  };

  const goToSetting = (obj) => {
    // console.log(obj);
    history.push({
      pathname: `/accountsettings`,
      state: { userData: obj },
    });
  };

  const goToDocument = (obj) => {
    history.push({
      pathname: `/mydocuments`,
      state: { userData: obj },
    });
  };

  // const searchDoc = search => {
  //   console.log(search);
  // };
  const filterOnChange = async (e) => {
    if (e.target.value === "") {
      // console.log(e.target.value);
      setPage(0);
      setfilter(users);
    }
    setvalue(e.target.value);
  };

  const searchUsername = async () => {
    let result = await searchUser(value);
    setfilter(result);
  };

  const setDropDowm = (selection) => {
    console.log(selection);
    setDrop(selection);
    if (selection === "newsletter") {
      let filterData = usersBackup.filter((obj) => obj.subscription === true);
      setUsers(filterData);
    } else setUsers(usersBackup);
  };

  const FilterPlan = async (value) => {
    setLoading(true);
    setPlanFilterDropDown(value)
    if (value === "all") {
      setfilter(users);
    } else {
      let data = await planFilter(value);
      setfilter(data.data);
    }
    setLoading(false);
  };

  return (
    <div>
      <Loader loading={loading} />
        <BreadCrumb l1="Dashboard" l2="Manage Users" dashboardlink={dashLink} />

        <div className="row">
          <div className="col-md-2">
            <h4 className="ft_lg" style={{ color: "#3e80f1" }}>Manage Users​</h4>
          </div>

          <div className="col-md-5 input-group mb-3">
            <input
              type="text"
              name="searchQuery"
              value={value}
              onChange={filterOnChange}
              className="form-control"
              placeholder="User's username"
              aria-label="User's username"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={searchUsername}
              >
                <i className="fa fa-search"></i> Search
              </button>
            </div>
          </div>

          <div className="col-md-2">
            <DropDown onChange={(selected) => setDropDowm(selected)} />
          </div>

          <div className="col-md-1">
            <button
              id="send"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Send
            </button>
          </div>

          <div className="col-md-2">
            <DropdownButton
              id="dropdown-basic-button"
              title="User Filter"
            >
              <Dropdown.Item style={{ fontSize: "16px" }} onClick={() => FilterPlan("all")}>
                <Typography> All users </Typography>
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "16px" }} onClick={() => FilterPlan("paid")}>
                <Typography> Paid users </Typography>
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "16px" }} onClick={() => FilterPlan("free")}>
                <Typography> Free users </Typography>
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "16px" }} onClick={() => FilterPlan("freeze")}>
                <Typography> Freezed users </Typography>
              </Dropdown.Item>
              <Dropdown.Item style={{ fontSize: "16px" }} onClick={() => FilterPlan("confirm")}>
                <Typography> Confirm users </Typography>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>

        <div className="row" style={{ marginTop: "2%", marginBottom: "2%" }}>
          {filter.map((user, index) => (
            <div className="col-4 pt-2 pb-3 userCard-res-col">
              <UserCard
                key={user._id}
                data={user}
                index={index}
                dropVal={drop}
                refresh={() => getAllUsers()}
                setting={goToSetting}
                document={goToDocument}
                handleCheckFn={handleCheck}
                isChked={chk}
                filterValue={planFilterDropDown}
              />
            </div>
          ))}
        </div>

        {planFilterDropDown === "all" ? (
          <div className="d-flex justify-content-end mx-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {page > 0 && (
                  <li className="page-item" onClick={() => setPage(page - 1)}>
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;Previous</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )}

                {/* {pages === page && ( */}
                {page >= 0 && page < LastPage && (
                  <li className="page-item" onClick={() => setPage(page + 1)}>
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true"> Next&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        ) : ("")}

    </div>
  );
};

export default withRouter(ManageUser);
