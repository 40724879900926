import React from "react";
import { withRouter } from "react-router-dom";
import BreadCrumb from "./Cards/BreadCrumbs";
import "./Payments.css";
import paymentPic from "../static/payment.png";
import Paypal from "./paypalButton";
import { ToastContainer } from "react-toastify";

export function Payment({
  location: {
    state: { data },
  },
}) {
  return (
    <div className="bg-gray round">
      <div className="container maincont">
        <BreadCrumb l1="Subscription" l2="Payment" dashboardlink="/pricing" />
        <div
          className="row"
          style={{ marginTop: "2%", fontSize: "28px", marginLeft: "0.3%" }}
        >
          Payment
        </div>
        <ToastContainer />
        <div style={{ marginTop: "5%",borderRadius:"15px" }} className="bg-white shadow">
          <div className="row">
            <div className="col-md-6">
              <div style={{ textAlign: "center", marginTop: "10%" }}>
                <p style={{ fontSize: "18px" }}>Your Payment Amount</p>
                <br />
                <h1>
                  <sub style={{ fontSize: "38px" }}>$ </sub>
                  <sub style={{ fontSize: "50px" }}> {data.price} </sub>
                  {data.period === "Monthly" ? (
                    <sub style={{ fontSize: "38px" }}> /Month</sub>
                  ) : data.period === "Quarterly" ? (
                    <sub style={{ fontSize: "38px" }}> /Quater</sub>
                  ) : data.period === "Semester" ? (
                    <sub style={{ fontSize: "38px" }}> /Semester</sub>
                  ) : data.period === "Trimester" ? (
                    <sub style={{ fontSize: "38px" }}> /Trimester</sub>
                  ) : (
                    <sub style={{ fontSize: "38px" }}> /Year</sub>
                  )}
                </h1>

                <hr style={{ margin: "10%" }} />
              </div>

              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "18px", marginTop: "5%" }}>
                  PAYMENT METHOD
                </p>
                <br />
                <div style={{ marginLeft: "10%", marginRight: "10%" }}>
                  <Paypal detail={data}  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={paymentPic}
                style={{ width: "100%", height: "100%"  ,borderRadius:"15px"}}
                alt="payment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default Payment;
export default withRouter(Payment);
