import React from "react";
import image1 from "../static/Group457.png";
import bgImage from "../static/Group244.png";
import { ToastContainer } from "react-toastify";
import "./LoginForm.css";
import { Link } from "react-router-dom";
function Disclaimer() {
  return (
    <div>
      <ToastContainer />
      <div className="container mt-3" style={{ width: "100%" }}>
        <div
          className="row d-inline-flex p-3 text-white"
          style={{ width: "100%" }}
        >
          {/* Flex One */}
          <div
            className="col-md-6 p-4 border"
            style={{
              // backgroundColor: "#3b81f3",
              width: "100%",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
          >
            {/* Flex One Content */}
            <div
              style={{
                paddingLeft: "19.8%",
                paddingRight: "14.6%",
                paddingTop: "30%",
                paddingBottom: "30%"
              }}
            >
              <div className="row">
                <div>
                  {/* <h3 className="text-center" style={headOneStyle}>
                    PROFESSIONAL
                  </h3>
                  <h3 style={headTwoStyle}>EDITING TOOL</h3> */}
                  <img
                    src={image1}
                    alt="image1"
                    //         srcset="img/group-457@2x.png 2x,
                    //  img/group-457@3x.png 3x"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain"
                    }}
                  ></img>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20%" }}>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "30px",
                    fontWeight: "500",
                    lineHeight: "1.35",
                    textAlign: "left",
                    color: "#ffffff"
                  }}
                >
                  Welcome to Professional Editing Tool
                </p>
              </div>

              <div className="row" style={{ marginTop: "7%" }}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </p>
              </div>
            </div>
          </div>

          {/* Flex Two */}

          <div className="col-md-6 p-4 border" style={{ width: "100%" }}>
            {/* Flex Two Content */}

            <div
              style={{
                paddingLeft: "17%",
                paddingRight: "17%",
                paddingTop: "27%",
                paddingBottom: "4%"
              }}
            >
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3
                    style={{
                      fontSize: "35px",
                      fontWeight: "bold",
                      lineHeight: "1.26",
                      color: "#43425d"
                    }}
                  >
                    Disclaimer
                  </h3>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10%" }}>
                <div className="col-md-12">
                  <p
                    style={{
                      opacity: "0.5",
                      fontSize: "15px",
                      lineHeight: "1.72",
                      textAlign: "justify",
                      color: "black"
                    }}
                  >
                    Most companies have an FAQ — or Frequently Asked Questions —
                    page on their website. Some may even have a few pages
                    depending on the products or services they provide. FAQ
                    pages include a series of questions that are commonly asked
                    by customers. They cover a variety of topics including
                    product or service usage, business hours, prices, and more.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-center">
                  <Link to="/">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        borderRadius: "4px",
                        border: "1px solid #1976d2",
                        color: "#2196f3",
                        width: "27%"
                      }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Disclaimer;
