import React, { Component, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const CustomModal = ({ isOpen,unLockAllSentenceAndRephrase }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Unlock All Sentences</h2>
                        <p id="transition-modal-description">Clicking the Rephrase button will unlock all locked sentences.</p>
                        <div class="d-flex justify-content-end mt-3">
                            <div><button type="button" class="btn mx-2  btn-danger" value="true" onClick={handleClose} >Cancel</button></div>
                            <div><button type="button" class="btn  btn-success" value="true" onClick={unLockAllSentenceAndRephrase} >Continue</button></div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}
export default CustomModal;