import React from "react";
import SubAdminNavBar from "../SubAdminSideBar";
import authService from "../../services/authService";
import Invoice from "../Invoice";
import SideNavBar from "../SideNavBar";
const UserInvoice = () => {
  const user = authService.getCurrentUser();
  console.log(user);
  if (!user.isAdmin && user.isSubAdmin) {
    return (
      <SubAdminNavBar>
        <Invoice />
      </SubAdminNavBar>
    );
  } else {
    return (
      <SideNavBar>
        <Invoice />
      </SideNavBar>
    );
  }
};

export default UserInvoice;
