import React, { Component } from "react";
import AdminSideNavBar from "../AdminSideNavBar";
import ManageUser from "../ManageUser";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";

class ManageUserPage extends Component {
  state = {};
  componentDidMount() {
    console.log(this.props.location.aboutProps);
  }
  render() {
    return (
      <AdminSideNavBar>
        <ManageUser />
      </AdminSideNavBar>
    );
  }
}

export default NetworkDetector(ManageUserPage);
