import React, { Component, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import Doc from "../../static/Doc.png";

export default function DasboardCard({ info, select, selectedCard, margin }) {
  return (
    <div
      style={{
        // height: "28vh",
        // marginRight: `${margin}%`,
        border: `2px solid ${
          selectedCard === info.title ? info.colors : "white"
        }`
      }}
      onClick={() => select(info)}
      className="DashboardCardHover"
      // onClick={()=>setBorder(!border)}
    >
      <div className="row ImageTextRow">
        <div className="col-md-7">
          <h4>{info.title}</h4>
        </div>
        <div className="col-md-5 ">
          <img className=" dashImage" src={info.img} />
        </div>
      </div>

      <div className="row pl-3">
        <text style={{ color: info.colors, fontSize: "39px" }}>
          {info.count}
        </text>
      </div>

      <Sparklines data={info.sparkData} className="SparkLinesCss">
        <SparklinesLine
          color={info.colors}
          style={{ fill: "none", strokeWidth: 2 }}
        />
      </Sparklines>

      {/* <Card style={{width:"135%",height:"120%"}}>
                    <CardContent>
                        
                        <Typography variant="h6" component="h6" >
                        Documents
                        </Typography>
                        <Typography variant="h3" component="h3" style={{color:"#dc3545"}}>
                        36
                        </Typography>
                        <Sparklines data={[5,10,5,20]}>
                            <SparklinesLine color="red" style={{ fill: "none", }} />
                            
                        </Sparklines>
                    </CardContent>
                    
                    </Card>  */}
    </div>
  );
}
