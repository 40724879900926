import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
export default class MixedLineAreaGraph extends Component {
  state = {
    series: [
      {
        name: "Count",
        data: this.props.data.errorCount
      }
    ],
    options: {
      chart: {
        width: 200,
        height: 250,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        style: { colors: ["#000000"] },
        formatter: function(value) {
          return value;
        }
      },
      stroke: {
        curve: "smooth",
        colors: ["#a3a0fb"]
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        labels: { show: false },
        categories: this.props.data.errorName
        // categories: ['PUNCTUATION', 'REDUDANCY', 'NONSTANDARD_PHRASES', 'REPETITION', 'GRAMMAR', 'CONFUSED_WORDS', 'COLLOCATIONS', 'TYPOGRAPHY', 'STYLE','SEMANTIC','MISC','COLLOCATIONS','complex','hard','effectivevocabulary','CASING','TYPOS','error','weaken','passive','overuse','space','qualifier','whitespace'],
      },
      yaxis: {
        labels: { show: false }
      }
    }
  };

  async componentDidMount() {
    console.log(this.props.data.errorCount);
    console.log(this.props.data.errorName);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ 
          series : [
            {
              name: "Count",
              data: this.props.data.errorCount
            }
          ],
          options: {
            chart: {
              width: 200,
              height: 250,
              type: "line",
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: true,
              style: { colors: ["#000000"] },
              formatter: function(value) {
                return value;
              }
            },
            stroke: {
              curve: "smooth",
              colors: ["#a3a0fb"]
            },
      
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              labels: { show: false },
              categories: this.props.data.errorName
              // categories: ['PUNCTUATION', 'REDUDANCY', 'NONSTANDARD_PHRASES', 'REPETITION', 'GRAMMAR', 'CONFUSED_WORDS', 'COLLOCATIONS', 'TYPOGRAPHY', 'STYLE','SEMANTIC','MISC','COLLOCATIONS','complex','hard','effectivevocabulary','CASING','TYPOS','error','weaken','passive','overuse','space','qualifier','whitespace'],
            },
            yaxis: {
              labels: { show: false }
            }
          }
       })
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={350}
        />
      </div>
    );
  }
}
