import React, { Component } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import teamService from "../services/teamService";
import UserCard from "./Cards/UserCard";
import userService from "../services/userService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import team from "../static/noteams.png"

class SubAdminManageUser extends Component {
  state = {
    inviteEmails: [],
    loading: true,
    teamMembers: [],
    inviteLimit: this.props.currentUser.userCount,
    modalShow: false,
    filterTeam: [],
  };

  async componentDidMount() {
    const { currentUser } = this.props;
    console.log(currentUser);
    this.getTeam();
  }

  //  Get All Team Members
  getTeam = async () => {
    const teamMembers = await userService.getTeam();
    if (teamMembers.status === 200) {
      this.setState({
        teamMembers: teamMembers.data.user,
        filterTeam: teamMembers.data.user,
        loading: false,
      });
    } else if (teamMembers.status === 404) {
      this.setState({ loading: false, teamMembers: teamMembers.data.user, filterTeam: teamMembers.data.user });
    } else {
      this.setState({ loading: false });
    }
  };

  handleCheckFn = async (data) => {
    // console.dir(data);
  };

  // Send Invite
  sendInvite = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    let email = this.state.inviteEmails;

    if (this.state.inviteLimit < email.length + this.state.teamMembers.length) {
      this.setState({ loading: false, modalShow: true, inviteEmails: [] });
      return;
    }

    if (email.length < 1) {
      this.setState({ loading: false });
      toast.error("Please enter valid email!");
    } else {
      const team = await teamService.inviteMember(email);
      if (team.status !== 200) {
        this.setState({ loading: false });
        toast.error(team.message);
      } else {
        this.setState({ loading: false });
        toast.success(team.message, {});
        this.getTeam();
      }
    }
    this.setState({ inviteEmails: [] });
  };

  // Delete Member
  handleDelete = async (user) => {
    this.setState({ loading: true });
    let data = {
      id: user.id,
    };

    const team = await userService.deleteTeam(data);
    if (team.status !== 200) {
      this.setState({ loading: false });
      toast.error(team.message);
    } else {
      toast.success(team.message);
      this.getTeam();
    }
  };

  handleSearch = (e) => {
    if (e.target.value === "") {
      this.setState({
        filterTeam: this.state.teamMembers,
      });
    } else {
      let value = e.target.value;

      let teamMembers = this.state.teamMembers;

      let result = [];

      result = teamMembers.filter((member) => {
        console.log(member);
        if (member.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return member;
        }
      });

      this.setState({
        filterTeam: result,
      });
    }
  };

  render() {
    let { teamMembers, filterTeam } = this.state;

    const popover = (
      <Popover id="popover-basic" style={{ zIndex: "9999", padding: "2px" }}>
        <Popover.Content>
          <p>*NOTE - Multiple invites can be sent</p>

          <ReactMultiEmail
            placeholder="Invite Members"
            emails={this.state.inviteEmails}
            onChange={(_emails) => {
              this.setState({ inviteEmails: _emails });
            }}
            validateEmail={(email) => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />

          <Button
            className="btn btn-outline w-100 mt-2"
            onClick={this.sendInvite}
          >
            Invite
          </Button>
        </Popover.Content>
      </Popover>
    );

    const pop = (
      <Popover id="popover-basic" style={{ zIndex: "1010", padding: "5px" }}>
        <Popover.Content>
          <h6 style={{ fontFamily: "Roboto" }}>Create a new group</h6>
          {/* <button className="btn btn-outline-danger" onClick={this.ok}>Create Group</button> */}
          <form>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                name="groupName"
                // value={this.state.groupName}
                // onChange={this.handleGroupName}
                placeholder="Group Title"
                aria-label="Group Title"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Append>
                <Button variant="outline-primary" type="submit">
                  Create
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </form>
        </Popover.Content>
      </Popover>
    );

    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />

        <div className="container mt-5" style={{ background: "#f0f0f7" }}>
          {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
          <div className="row ">
            {/* Title */}
            <div className="col-md-3">
              <HeadingB text="Sub Admin" />
            </div>

            {/* Search Bar */}
            <div className="col-md-7">
              <input
                className="form-control searchBar"
                name="searchQuery"
                onChange={this.handleSearch}
                type="text"
                placeholder="&#xF002; Search group or Team Member"
                aria-label="Search"
                style={{
                  fontFamily: "FontAwesome",
                  border: "none",
                  width: "60%",
                  float: "left",
                }}
              />
            </div>

            {/* Send Invite Button */}
            <div className="col-sm-2">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
                rootClose={true}
              >
                <button
                  className="btn btn-outline-primary inviteMember"
                  style={{ width: "90%" }}
                >
                  Invite
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            {filterTeam.length === 0 ? (
              <div className="col-md-12 text-center">
                  <img src={team} alt="No Teams" />
                  <h3>No teams found!</h3>
              </div>
            ) : (
              <>
                {filterTeam.map((user, index) => (
                  <div className="col-4 p-2">
                    <UserCard
                      isSubAdmin={true}
                      key={user._id}
                      data={user}
                      index={index}
                      handleCheckFn={this.handleCheckFn}
                      handleDelete={this.handleDelete}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        {/* Modal */}
        <Modal
          isOpen={this.state.modalShow}
          toggle={() => this.setState({ modalShow: !this.state.modalShow })}
          style={{
            position: "absolute",
            left: "43%",
            top: "35%",
          }}
        >
          <ModalHeader
            toggle={() => this.setState({ modalShow: !this.state.modalShow })}
          >
            Upgrade Plan ?
          </ModalHeader>
          <ModalBody>
            <div style={{ textAlign: "center" }}>
              <p>
                You Have Reached the User Limit ! <br />
                Please Upgrade the Plan.
              </p>
              <Link to={{ pathname: "/pricing" }}>
                <button className="btn btn-primary hvr-float-shadow p-3 buysub">
                  <i class="fa fa-diamond" aria-hidden="true"></i>
                  Buy Subscription
                </button>
              </Link>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubAdminManageUser));
