import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import "./TeamCollaboration.css";
import { Avatar } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import BreadCrumb from "../Cards/BreadCrumbs";
import Loader from "../../utils/loader";
import { withRouter } from "react-router-dom";
import TeamCollaborationServices from "../../services/teamCollbarationServices";
import { connect } from "react-redux";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "../Editor/utilize";
import ReactHtmlParser from "react-html-parser";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Diff from "../ReactDiff";
import isEmptyObject from "../../utils/isEmptyObject";
import swal from "sweetalert";
import userService from "../../services/userService";
import ReactTour from "../ReactTour";

export class TeamCollaboration extends Component {
  state = {
    username: "",
    display_picture: "",
    originalDocument: "",
    mergeDocuments: [],
    htmlContent: null,
    compareID: "",
    compareDP: "",
    compareUserID: "",
    compareName: "",
    compareRAW: null,
    comapreContent: null,
    leftCell: [],
    rightCell: [],
    changeCount: 0,
    merging: false,
    loading: true,
    reactTour: false,
    tourChange: false,
    steps1: [
      {
        selector: '.reactour-1',
        content: 'Displays all the collaborators for current document. By clicking on it user can manually remove the collaborators from popup box',
        position: 'left',
      },
      {
        selector: '.reactour-2',
        content: () => (
          <div>
            <p>Left editor displays the current document content</p>
            <b>Legend :</b>
            <p>
              Content displayed in <span style={{ backgroundColor: "lightgreen" }}>Green Background</span> is marked as <b>new</b> content that can be added from collaborator's document.
            </p>
            <p>
              Content displayed in <span style={{ backgroundColor: "salmon" }}>Red Background</span> is marked as <b>deleted/removed</b> content which will remove that content from current document.
            </p>
          </div>
        ),
        position: 'right',
      },
      {
        selector: '.reactour-3',
        content: "Right editor displays the collaborator's document content",
        position: 'left',
      },
      {
        selector: '.reactour-4',
        content: 'This will reset all the changes made to current document during collaboration',
        position: 'top',
      },
      {
        selector: '.reactour-5',
        content: 'Save all the changes made to current document during collaboration',
        position: 'top',
      }
    ],
    steps2: [
      {
        selector: '.reactour-1',
        content: 'Displays all the collaborators for current document. By clicking on it user can manually remove the collaborators from popup box',
        position: 'left',
      },
      {
        selector: '.reactour-2',
        content: () => (
          <div>
            <p>Left editor displays the current document content</p>
            <b>Legend :</b>
            <p>
              1. Content displayed in <span style={{ backgroundColor: "lightgreen" }}>Green Background</span> is marked as <b>new</b> content that can be added from collaborator's document.
            </p>
            <p>
              2. Content displayed in <span style={{ backgroundColor: "salmon" }}>Red Background</span> is marked as <b>deleted/removed</b> content which will remove that content from current document.
            </p>
            <p>
              3. Content displayed in normal text denotes matching content
            </p>
          </div>
        ),
        position: 'right',
      },
      {
        selector: '.reactour-3',
        content: "Right editor displays the collaborator's document content",
        position: 'left',
      },
      {
        selector: '.reactour-4',
        content: "Collab toolbar",
        position: 'top',
      },
      {
        selector: '.reactour-5',
        content: "Accept all the changes made by collaborator into current document",
        position: 'top',
      },
      {
        selector: '.reactour-6',
        content: "Reject all the changes made by collaborator into current document",
        position: 'top',
      },
      {
        selector: '.reactour-7',
        content: "Merge all content made by collaborator with current content",
        position: 'top',
      },
      {
        selector: '.reactour-8',
        content: 'This will reset all the changes made to current document during collaboration',
        position: 'top',
      },
      {
        selector: '.reactour-9',
        content: 'Save all the changes made to current document during collaboration',
        position: 'top',
      }
    ],
  };

  async componentDidMount() {
    let { document } = this.props.location.state;
    let { currentUser } = this.props;
    let data;
    if (currentUser && !isEmptyObject(currentUser)) {
      data = { ...currentUser };
    } else {
      let user = await userService.getProfile();
      console.log(user);
      data = { ...user.data };
    }
    this.setState(
      {
        originalDocument: document,
        username: data.username,
        display_picture: data.display_picture,
      },
      async () => {
        await this.convertToHtml(
          this.state.originalDocument.onlyDocumentContent,
          true
        );
        await this.getCollabDocuments();
        await this.cellDetails();
      }
    );
  }

  cellDetails = async () => {
    let { originalDocument, compareRAW } = this.state;
    let OGeditor = await JSON.parse(originalDocument.onlyDocumentContent);
    let collabEditor = await JSON.parse(compareRAW);
    let leftCell = await JSON.parse(originalDocument.onlyDocumentContent)
      .blocks;
    let rightCell = await JSON.parse(compareRAW).blocks;

    if (leftCell.length < rightCell.length) {
      for (let i = leftCell.length; i < rightCell.length; i++) {
        // fakeCell.key = await rightCell[i].key;
        let fakeCell = {
          // key: rightCell[i].key,
          key: Math.random().toString(20).substr(2, 5),
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
          done: false,
          reject: false,
        };
        await leftCell.push(fakeCell);
      }
      OGeditor.blocks = leftCell;
    } else {
      for (let i = rightCell.length; i < leftCell.length; i++) {
        // fakeCell.key = leftCell[i].key;
        let fakeCell = {
          // key: leftCell[i].key,
          key: Math.random().toString(20).substr(2, 5),
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
          done: false,
          reject: false,
        };
        rightCell.push(fakeCell);
      }
      collabEditor.blocks = rightCell;
    }

    console.log(leftCell);
    console.log(rightCell);

    this.setState({
      OGeditor: OGeditor,
      collabEditor: collabEditor,
      leftCell: leftCell,
      rightCell: rightCell,
    });
  };

  getCollabDocuments = async () => {
    let doc = this.state.originalDocument;
    let data = await TeamCollaborationServices.getCollabDocuments(
      doc.pendingDocuments
    );
    if (data.status === 200) {
      // let mergedDocs = await [].concat.apply([], data.data);
      let mergedDocs = data.data;
      this.setState(
        {
          mergeDocuments: mergedDocs,
          compareID: mergedDocs[0]._id,
          compareDP: mergedDocs[0].editor.display_picture,
          compareUserID: mergedDocs[0].editor._id,
          compareName: mergedDocs[0].editor.username,
          compareRAW: mergedDocs[0].onlyDocumentContent,
          loading: false,
        },
        () => this.convertToHtml(mergedDocs[0].onlyDocumentContent, false)
      );
    } else {
      console.log(data.message);
    }
  };

  convertToHtml = (data, original) => {
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    let htmlContent = ReactHtmlParser(editorContentHtml);
    if (original === true) {
      this.setState({ htmlContent });
    } else {
      this.setState({ comapreContent: htmlContent });
    }
  };

  compareData = async (document) => {
    this.setState(
      {
        changeCount: 0,
        merging: false,
        compareID: document._id,
        compareDP: document.editor.display_picture,
        compareUserID: document.editor._id,
        compareName: document.editor.username,
        compareRAW: document.onlyDocumentContent,
      },
      async () => {
        await this.convertToHtml(document.onlyDocumentContent, false);
        await this.cellDetails();
      }
    );
  };

  onAccept = (key, text) => {
    const { OGeditor, changeCount } = this.state;
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        OGeditor.blocks[i].text = text;
        OGeditor.blocks[i].done = true;
      }
    }

    this.setState({
      OGeditor,
      leftCell: OGeditor.blocks,
      changeCount: changeCount + 1,
      merging: true,
    });
  };

  onReject = (key, text) => {
    const { OGeditor, changeCount } = this.state;
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        OGeditor.blocks[i].reject = true;
        OGeditor.blocks[i].done = true;
      }
    }

    this.setState({
      OGeditor,
      leftCell: OGeditor.blocks,
      changeCount: changeCount + 1,
      merging: true,
    });
  };

  onBoth = (key, text) => {
    const { OGeditor, changeCount } = this.state;
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        OGeditor.blocks[i].text += text;
        OGeditor.blocks[i].reject = true;
        OGeditor.blocks[i].done = true;
      }
    }

    this.setState({
      OGeditor,
      leftCell: OGeditor.blocks,
      changeCount: changeCount + 1,
      merging: true,
    });
  };

  undoChange = async (key, text) => {
    const { OGeditor, originalDocument, changeCount } = this.state;
    let og = await JSON.parse(originalDocument.onlyDocumentContent);
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        if (i > og.blocks.length - 1) {
          OGeditor.blocks[i].text = "";
        } else {
          OGeditor.blocks[i].text = og.blocks[i].text;
        }
        OGeditor.blocks[i].reject = false;
        OGeditor.blocks[i].done = false;
      }
    }

    this.setState(
      {
        OGeditor,
        leftCell: OGeditor.blocks,
        changeCount: changeCount - 1,
        merging: true,
      },
      () => {
        if (changeCount - 1 === 0) {
          this.setState({ merging: false });
        }
      }
    );
  };

  resetChanges = async () => {
    this.setState({ changeCount: 0 });
    let document = {
      _id: this.state.compareID,
      editor: {
        _id: this.state.compareUserID,
        display_picture: this.state.compareDP,
        username: this.state.compareName,
      },
      onlyDocumentContent: this.state.compareRAW,
    };
    await this.compareData(document);
  };

  removeCollaborater = async (doc) => {
    swal({
      title: "Remove Collaborater",
      text: "Are you sure ? You want to Remove Collaborater for this Document",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (removeCollab) => {
      if (removeCollab) {
        let data = await TeamCollaborationServices.removeCollaborater(doc._id);
        if (data.status === 200) {
          toast.success(data.message);
          setTimeout(() => {
            this.props.history.push({ pathname: "/mydocuments" });
          }, 1000);
        } else {
          toast(data.message);
        }
      } else {
        // Nothing
      }
    });
  };

  mergeDocument = async () => {
    let { originalDocument, compareID, compareUserID, OGeditor } = this.state;

    for (let i = 0; i < OGeditor.blocks.length; i++) {
      OGeditor.blocks[i].done = false;
      OGeditor.blocks[i].reject = false;
    }

    let doc = {
      originalID: originalDocument._id,
      draftID: compareID,
      draftUserId: compareUserID,
      formattedDocumentContent: JSON.stringify(OGeditor),
      onlyDocumentContent: JSON.stringify(OGeditor),
    };
    console.log(doc);

    swal({
      title: "Merge Document",
      text: "Are you sure ? You want to Merge this document",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (mergeDoc) => {
      if (mergeDoc) {
        let data = await TeamCollaborationServices.mergeDocument(doc);
        if (data.status === 200) {
          toast.success(data.message);
          setTimeout(() => {
            this.props.history.push({ pathname: "/mydocuments" });
          }, 1000);
        } else {
          toast(data.message);
        }
      } else {
        // Nothing
      }
    });
  };

  reactTour = async () => {
    let { leftCell, rightCell } = this.state;
    if(leftCell.length !== rightCell.length){
      this.setState({ tourChange: true });
    }else{
      for(let i=0; i < leftCell.length; i++){
        if(leftCell[i].text !== rightCell[i].text){
          this.setState({ tourChange: true });
          break;
        }
      }
    }
    this.setState({ reactTour: true });
  }

  render() {
    let {
      username,
      display_picture,
      originalDocument,
      mergeDocuments,
      compareDP,
      compareName,
      leftCell,
      rightCell,
      steps1,
      steps2,
      tourChange
    } = this.state;

    return (
      <div className="pt-2 pb-2">
        <Loader loading={this.state.loading} />
        <div>
          <ToastContainer />
          <ReactTour 
            steps={tourChange ? steps2 : steps1} 
            isOpen={this.state.reactTour} 
            closeTour={()=>this.setState({ reactTour: false })}
          />
          <BreadCrumb
            l1="Dashboard"
            l2="Documents"
            l3="Team Collaboration"
            dashboardlink="/dashboard"
            documentLink="/mydocuments"
          />
          <div className="row">
            <div className="col-sm-6">
              <h3>Team Collaboration
                <i 
                  className="fa fa-question-circle mt-2 ml-3" 
                  aria-hidden="true"
                  data-toggle="tooltip"
                  title="Need Help ?"
                  style={{
                    cursor: "pointer",
                    color: "dodgerblue",
                    fontSize: "15px"
                  }}
                  onClick={this.reactTour}
                ></i>
              </h3>
            </div>
            <div className="col-sm-6">
              <AvatarGroup
                style={{ float: "right" }}
                className="point reactour-1"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                {mergeDocuments.slice(0, 3).map((face) => (
                  <Avatar alt="Remy Sharp" src={face.editor.display_picture} />
                ))}
                {mergeDocuments.length > 3 ? (
                  <Tooltip title="Show more collaborators">
                    <Avatar>+{mergeDocuments.length - 3}</Avatar>
                  </Tooltip>
                ) : (
                  ""
                )}
              </AvatarGroup>
            </div>
          </div>
        </div>
        <React.Fragment>
          <table
            class="table"
            border="0"
            style={{ minHeight: 100, width: "100%", marginBottom: "3%" }}
          >
            <tr>
              <th style={{ border: 0 }}>
                <div className="row">
                  <div className="col-sm-1">
                    <Avatar alt="Cindy Baker" src={display_picture} />
                  </div>
                  <div className="col-sm-11 pl-4 mt-2">
                    <span> {username} </span>
                  </div>
                </div>
              </th>
              <th style={{ border: 0, textAlign: "center" }}>
                {/* <h3>{originalDocument.documentName}</h3> */}
              </th>
              <th style={{ border: 0 }}>
                <div className="row">
                  <div className="col-sm-1">
                    <Avatar alt="Cindy Baker" src={compareDP} />
                  </div>
                  <div className="col-sm-11 pl-4 mt-2">
                    <span>{compareName}</span>
                  </div>
                </div>
              </th>
            </tr>

            <tbody>
              {/* <tr>
                <td style={{ border: "1px solid #f0f0f7" }}></td>
                <td style={{ border: "1px solid #f0f0f7", textAlign: "center" }}>
                  <h3>Document : {originalDocument.documentName}</h3>
                </td>
                <td style={{ border: "1px solid #f0f0f7" }}></td>
              </tr> */}
              <tr>
                <td
                  style={{
                    border: "0px solid #f0f0f7",
                    backgroundColor: "#fff",
                    width: "40%",
                  }}
                >
                  {" "}
                  <div
                    className="container"
                    style={{
                      marginTop: "3%",
                      textAlign: "justify",
                    }}
                  >
                    <h3> {originalDocument.documentName}</h3>
                  </div>
                </td>
                <td style={{ border: "1px solid #f0f0f7" }}></td>
                <td
                  className=" ml-2"
                  style={{
                    border: "0px solid #f0f0f7",
                    backgroundColor: "#fff",
                    width: "40%",
                  }}
                >
                  <div
                    className="container"
                    style={{
                      marginTop: "3%",
                      textAlign: "justify",
                    }}
                  >
                    <h3>{originalDocument.documentName}</h3>
                  </div>
                </td>
              </tr>
              {/* <h3 style={{ textAlign: "center" }}>{originalDocument.documentName}</h3> */}

              {/* ***** Collaborator ***** */}
              {leftCell.map((editor, index) => (
                <tr>
                  <td
                    className="reactour-2"
                    style={{
                      border: "0px solid #E0E1E3",
                      width: "40%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      className="container"
                      style={{
                        marginTop: "3%",
                        textAlign: "justify",
                      }}
                    >
                      {/* <h3>{originalDocument.documentName}</h3> */}

                      {/* <text>{htmlContent}</text> */}

                      <text key={leftCell[index].key}>
                        {leftCell[index] && (
                          <Diff
                            inputA={leftCell[index].text}
                            inputB={rightCell[index].text}
                            type="words"
                            color={true}
                            reject={leftCell[index].reject || false}
                          />
                        )}
                      </text>
                    </div>
                  </td>

                  <td style={{ border: "0px" }} key={editor.key}>
                    {leftCell[index].done ? (
                      <Card style={{ width: "300px" }} color="#CDE4FA">
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() =>
                              this.undoChange(
                                leftCell[index].key,
                                rightCell[index].text
                              )
                            }
                          >
                            UNDO
                          </Button>
                        </CardActions>
                      </Card>
                    ) : (
                      <>
                        {leftCell[index].text !== rightCell[index].text && (
                          <Card
                            className="reactour-4"
                            style={{ width: "300px" }}
                            color="#CDE4FA"
                          >
                            {/* <CardContent>
                      <Typography variant="body2" component="p">
                        Team Collaboration
                      </Typography>
                    </CardContent> */}
                            <CardActions>
                              <div className="row">
                                <div className="col-sm-4">
                                  <Button
                                    className="reactour-5"
                                    size="small"
                                    // color="primary"
                                    style={{ color: "green" }}
                                    onClick={() =>
                                      this.onAccept(
                                        leftCell[index].key,
                                        rightCell[index].text
                                      )
                                    }
                                  >
                                    Accept
                                  </Button>
                                </div>
                                <div className="col-sm-4">
                                  <Button
                                    className="reactour-6"
                                    size="small"
                                    style={{ color: "red" }}
                                    onClick={() =>
                                      this.onReject(
                                        leftCell[index].key,
                                        leftCell[index].text
                                      )
                                    }
                                  >
                                    Reject
                                  </Button>
                                </div>
                                <div className="col-sm-4">
                                  <Button
                                    className="reactour-7"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      this.onBoth(
                                        leftCell[index].key,
                                        rightCell[index].text
                                      )
                                    }
                                  >
                                    BOTH
                                  </Button>
                                </div>
                              </div>
                            </CardActions>
                          </Card>
                        )}
                      </>
                    )}
                  </td>
                  <td
                    className="reactour-3"
                    style={{
                      border: "0px solid #E0E1E3",
                      width: "40%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      className="container"
                      style={{
                        marginTop: "3%",
                        textAlign: "justify",
                      }}
                    >
                      {/* <h3> {originalDocument.documentName} </h3> */}
                      {/* <text>{comapreContent}</text> */}

                      <text key={rightCell[index].key}>
                        {rightCell[index] && (
                          <Diff
                            inputA={leftCell[index].text}
                            inputB={rightCell[index].text}
                            type="chars"
                            color={false}
                            right={true}
                          />
                        )}
                      </text>
                    </div>
                  </td>
                </tr>
              ))}
              {/* ***** Collaborator ***** */}
            </tbody>
          </table>
          <br />
          <br />

          <div className="row">
            {/* <div className="col-md-10"></div> */}
            <div className="col-md-12 text-center">
              <button
                type="button"
                className={tourChange ? "btn btn-primary reactour-8" : "btn btn-primary reactour-4"}
                onClick={this.resetChanges}
                style={{
                  marginRight: "5%",
                }}
              >
                Reset Changes
              </button>
              <button
                type="button"
                className={tourChange ? "btn btn-outline-primary reactour-9" : "btn btn-outline-primary reactour-5"}
                onClick={this.mergeDocument}
                disabled={!this.state.merging}
              >
                Done Merging
              </button>
            </div>
          </div>
        </React.Fragment>

        {/* <!-- Modal --> */}
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Collaborators
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {mergeDocuments.map((doc) => (
                  <div className="row mt-1 point" data-dismiss="modal">
                    <div
                      className="col-sm-2"
                      onClick={() => this.compareData(doc)}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={doc.editor.display_picture}
                      />
                    </div>
                    <div
                      className="col-sm-6"
                      onClick={() => this.compareData(doc)}
                    >
                      <small>
                        <strong>{doc.editor.username}</strong>
                      </small>
                    </div>
                    <div className="col-sm-4 ml-auto">
                      <i
                        class="fa fa-trash"
                        style={{ color: "red", fontSize: "20px" }}
                        aria-hidden="true"
                        onClick={() => this.removeCollaborater(doc)}
                      ></i>
                    </div>
                  </div>
                ))}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(TeamCollaboration));
