import React, { Component } from "react";
import {
  EditorState, CompositeDecorator, SelectionState, ContentState,
  Modifier, RichUtils, convertFromRaw, convertToRaw, AtomicBlockUtils, KeyBindingUtil
} from "draft-js";
import Editor, { createEditorStateWithText, composeDecorators } from "draft-js-plugins-editor";
import createCounterPlugin from "draft-js-counter-plugin";
import createImagePlugin from 'draft-js-image-plugin';
import CircularProgress from "@material-ui/core/CircularProgress";
// import _ from "lodash";
import { OrderedSet } from "immutable";

import debounce from "lodash/debounce";
import "./draft.css";
import { sentimental, grammerChecker, readabilityCheck, restError, concotArray } from "./draftApi";
import { SearchHighlight, borderColorUpdated } from "./highlight";
import { BlockStyleControls, InlineStyleControls } from "./StyleButton";
import DraftFn, { getCurrentAnchorKey, getTextFromAnchorKey } from './DraftFn';
import { toast } from "react-toastify";
import adminService from '../../services/adminServices';
import { getError } from "../../services/document";
import { Popover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import { InlineSelect, InlineColor, InlineSize } from "./SelectItem";
import { FONT_TYPES, styleMap, FONT_COLOR, FONT_SIZE, grammerColor, filterList, strCount } from "./utilize";
import ItemList from "./ItemList";
import Correction from "./Correction";
import Tags from "../Tags";
import englishCheckers from "../../services/englishCheckers";
import reportServices from "../../services/reportServices";
import Loader from "../../utils/loader";
import DocUpload from "../DocUpload";
import ImageUpload from "./ImageUpload";
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import ReactTour from "../ReactTour";
import HeadlinesButton from "./HeadlinesButton"
// import { connect  }  from 'react-redux';
// import { addCreatedDocument } from '../../redux/document/document.actions';

const { hasCommandModifier } = KeyBindingUtil;
const counterPlugin = createCounterPlugin();
const resizePlugin = createResizeablePlugin({
  vertical: "absolute",
  horizontal: "absolute"
});

const decorator = composeDecorators(
  resizePlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });

const { CharCounter, WordCounter, LineCounter, CustomCounter } = counterPlugin;
const plugins = [counterPlugin, imagePlugin, resizePlugin];

class GrammerCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gm_color: grammerColor(props.features.GrammarChecker),
      updateStatus: true,
      search: "",
      replace: "",
      hoverHead: "Heading",
      hoverBody: "",
      editorState: EditorState.createEmpty(),
      tempEditorState: {},
      show: false,
      inside: false,
      x: 0, y: 0,
      suggesion: [],
      ignore: null,
      allSuggesion: {},
      dictionary: [],
      spanList: [],
      overUsedSuggestion: [],
      all_anchorkey: [], anchorKey: "",
      errorList: {},
      errorCount: {},
      correctionList: [],
      correctionObj: {},
      popoverOpen: false, anchorOpen: false, blockType: null,
      url: "", list: [],
      toogleClassName: { editor: 'col-md-10', correction: 'hideCorr', status: false },
      sent: { emojiStatus: "Normal", emojiImg: "" },
      selectedGrammerItem: [],
      loading: false,
      onupdate: true,
      docTitle: "",
      grade: { notes: "", schoolLevel: "", score: 0, grade: 0 },
      character_limit: null,
      reactTour: false,
      steps: [
        {
          selector: '.reactour-1',
          content: 'Give document title',
          position: 'bottom',
        },
        {
          selector: '.reactour-2',
          content: 'Write your document content here',
          position: 'top',
        },
        {
          selector: '.reactour-3',
          content: 'Document Toolbar',
          position: 'bottom',
        },
        {
          selector: '.reactour-4',
          content: 'Add tags to current document',
          position: 'bottom',
        }
        ,
        {
          selector: '.reactour-5',
          content: 'Utility bar displaying the total characters, words, paragraphs, sentences',
          position: 'top',
        },
        {
          selector: '.reactour-6',
          content: 'Flesch-Kincaid Grade Level of content',
          position: 'top',
        },
        {
          selector: '.reactour-7',
          content: 'Tone Detector',
          position: 'top',
        },
        {
          selector: '.reactour-8',
          content: 'Correction bar displays all grammatical error of content. Closing the correction bar will toggle the on-hover correction display',
          position: 'left',
        },
        {
          selector: '.reactour-9',
          content: 'PET assistant bar',
          position: 'left',
        },
        {
          selector: '.reactour-10',
          content: 'Upload .txt or word file to extract content and display on editor',
          position: 'bottom',
        }
      ],
    };
    this.toggleBlockType = type => this._toggleBlockType(type);
    this.toggleInlineStyle = style => this._toggleInlineStyle(style);
  }

  async componentDidMount() {
    // this.state.gm_color = grammerColor(this.props.features.GrammarChecker)
    borderColorUpdated(this.state.gm_color);
    this.onSearch();
    this.decoratorInitialize();
  }

  componentWillUnmount() {
    // alert(this.props.documentId)
    console.log('allsug', this.state.allSuggesion, this.props.documentId);
    // send the balance error, that are only viewed by user
    restError(this.state.allSuggesion, this.props.documentId);
  }

  handleTab = (e) => {
    e.preventDefault();
    const tabCharacter = "    ";
    let currentState = this.state.editorState;
    let newContentState = Modifier.replaceText(
      currentState.getCurrentContent(),
      currentState.getSelection(),
      tabCharacter
    );
    this.setState({ editorState: EditorState.push(currentState, newContentState, 'insert-characters') });
  }

  myKeyBindingFn = (e) => {
    // if you paste thecommand this if conditon will invoke
    if (e.keyCode === 86 /* `V` key */ && hasCommandModifier(e)) {
      this.state.currentKey = e.keyCode;
    }
  }

  removeString = (event) => {
    let maxLength = this.state.character_limit;
    const contentState = event.getCurrentContent();
    let convertRaw = convertToRaw(contentState);
    console.log('convertRaw', convertRaw);
    const blocks = convertRaw.blocks;

    let end = 0;
    const updatedBlock = blocks.map((block) => {
      const blockText = block.text;
      const blockTextLen = blockText.length;

      if (maxLength < 0) {
        end = 0;
        toast(`You are reached maximum character limit ${this.state.character_limit}`);
      }
      else {
        if (blockTextLen < maxLength) {
          maxLength = maxLength - blockTextLen;
          console.log('maxLength', maxLength, 'blockTextLen', blockTextLen);
          end = blockTextLen;
        }
        else {
          end = Math.abs(maxLength);
          toast(`You are reached maximum character limit ${this.state.character_limit}`);
          maxLength = -1;
        }
      }
      console.log('endLen', end)
      const text = blockText.slice(0, end);
      block.text = text;
      return block;
    });

    convertRaw.blocks = updatedBlock;
    console.log('updatedRaw', convertRaw);
    const updatedContentState = convertFromRaw(convertRaw);
    const updatedEditorState = EditorState.createWithContent(updatedContentState);
    // setEditorState(updatedEditorState)
    return updatedEditorState;
  }

  // when you paste the conetent , need to check the grammer error
  pasteFn = (editorState_) => {
    if (this.state.currentKey !== 86)
      return

    const editorState = this.removeString(editorState_);
    this.state.currentKey = null;
    this.onChange(editorState);
    new Promise(() => {
      const anchorKeys = DraftFn.getAnchorKeys(editorState);
      console.log('anchorKeys', anchorKeys)
      anchorKeys.forEach((anchorKey) => {
        const text = DraftFn.getTextFromAnchorKey(editorState, anchorKey);
        grammerChecker(this, text, anchorKey)
      });
    })
  }

  getCharacterLimit = async () => {
    let data = await adminService.getCharacterLimit();
    this.setState({ character_limit: data.character_limit });
  }

  checkingTextGrammer = () => {
    let paraDetail = DraftFn.currentTextandAnchorKey(this.state.editorState);
    const currentPara = paraDetail.text;
    const anchorKey = paraDetail.anchorKey;
    // whenever they click go next line, the pervious line will go to gramer check
    if (this.state.anchorKey && (this.state.anchorKey !== anchorKey)) {
      try {
        const currentContent = this.state.editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(this.state.anchorKey);
        const text = currentContentBlock.getText()
        this.callAllGramerFn(text, this.state.anchorKey)
        console.log("yes_old", this.state.anchorKey, 'new', anchorKey)
      }
      catch (e) { console.log('error') }
    }

    this.state.anchorKey = anchorKey;
    this.initaialCheck(currentPara, anchorKey);

  };

  // rmove the anchor keydata, once the key not there in the draft then the data should be empty array
  anchorKeyRemove = debounce((current_content) => {
    const all_anchorkey_draft = this.getAllAnchorKey(current_content);
    const all_anchorkey_allsug = Object.keys(this.state.allSuggesion);

    if (all_anchorkey_draft.length >= all_anchorkey_allsug.length)
      return;

    const removed_key = all_anchorkey_allsug.filter((key) => {
      if (!all_anchorkey_draft.includes(key))
        return key
    });
    let { correctionList, allSuggesion, errorList } = this.state;
    removed_key.forEach((anchorKey) => {
      delete correctionList[anchorKey];
      delete allSuggesion[anchorKey];
      delete errorList[anchorKey];
    });
    this.state.correctionList = correctionList;
    this.state.allSuggesion = allSuggesion;
    this.state.errorList = errorList;
    this.onChange(this.state.editorState)
  }, 1000);

  // to call gramer function
  initaialCheck = debounce((text, anchorKey) => {
    this.callAllGramerFn(text, anchorKey);
    this.state.onupdate = !this.state.onupdate;
  }, 1000);

  callAllGramerFn = async (text, anchorKey) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    let editorFulltext = contentState.getPlainText();
    sentimental(this, editorFulltext);
    readabilityCheck(this, editorFulltext);
    const result = await grammerChecker(this, text, anchorKey);
    return result;
  }

  getErrorMod = (errors) => {
    console.log('allError', errors);
    const result = errors.reduce((obj, error) => {
      delete error.documentID;
      delete error.month;
      delete error.year;
      delete error._id;

      const { anchorKey } = error;
      if (!obj[anchorKey]) {
        obj[anchorKey] = [error]
      }
      else {
        obj[anchorKey] = [...obj[anchorKey], error]
      }
      return obj;
    }, {});
    // return result;
    Object.keys(result).forEach((anchorKey) => {
      concotArray(this, result[anchorKey], anchorKey);
      this.onSearch(anchorKey);
    })
  }

  setPropsValue = async (data, documentId, status = false) => {

    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({ loading: true });
    if (status === false) {
      const res = await getError(documentId);
      if (res.status === 200)
        this.getErrorMod(res.data);
    }
    else {
      const blockArray = contentState.getBlocksAsArray();
      const promiseArr = await blockArray.map(async (block) => {
        const res = await this.callAllGramerFn(block.getText(), block.getKey());
        return res
      });
      const values = await Promise.all([...promiseArr]);
      console.log('values', values);
    }

    this.setState({ editorState, loading: false });
  };

  recheckGrammar = async () => {
    console.log('recheckGrammar')
    this.setState({ loading: true })
    const contentState = this.state.editorState.getCurrentContent();
    const blockArray = contentState.getBlocksAsArray();
    const promiseArr = await blockArray.map(async (block) => {
      return await grammerChecker(this, block.getText(), block.getKey(), false);
      // return await this.callAllGramerFn(block.getText(), block.getKey());
    });
    const result = await Promise.all([...promiseArr]);
    console.log('myresutl', result)
    this.setState({ loading: false })
  }

  getAllAnchorKey = (current_content) => {
    const convertRaw = convertToRaw(current_content);
    const anchorKey = (convertRaw.blocks || []).map((obj) => obj.key);
    // console.log("anchorKey", anchorKey)
    return anchorKey;
  }

  addUploadedText = (text_str, file_name) => {
    console.log("text", text_str, file_name)
    if (!text_str) return;
    this.setState({ loading: true });

    if (!this.props.docTitle) {
      this.props.docTitleChange(file_name);
      this.props.createDocument();
    }

    let text = text_str.split(/\n+/g);
    const solt = '12345abcde';
    let convertRaw = convertToRaw(this.state.editorState.getCurrentContent());
    let blocks = convertRaw.blocks;
    text.forEach((str) => {
      const key = [...Array(5)].reduce((str) => str += solt[Math.floor(Math.random() * solt.length)], "")
      const structure = { data: {}, depth: 0, entityRanges: [], inlineStyleRanges: [], key, text: str, type: "unstyled" }
      blocks.push(structure);
    });
    convertRaw.blocks = blocks;
    this.setPropsValue(JSON.stringify(convertRaw), null, true)
  }

  _toggleBlockType(blockType) {
    // console.log("bolockType", blockType);
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    if (inlineStyle == "ANCHOR")
      this.setState({ anchorOpen: !this.state.anchorOpen });
    else
      this.onChange(
        RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
      );
  }

  checkLink = () => {
    const { url } = this.state;
    let check = url.match(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
    );
    if (check == null) {
      toast.error("Please enter valid URL");
      return;
    } else {
      this.createAnchorLink();
    }
  };

  createAnchorLink = () => {
    this.setState({ anchorOpen: false });
    const { editorState, url } = this.state;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    // remove anchor from tag
    //   if (!selection.isCollapsed()) {
    //     this.onChange(RichUtils.toggleLink(editorState, selection, null))
    //  }
    //  else{
    //  add anchor from tag
    const contentStateWithEntity = contentState.createEntity(
      "LINK", "MUTABLE", { url: `https://${url}` }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    this.onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
  };

  getHeight = () => {
    const element = document.getElementById("editorId");
    let height = element.offsetHeight;
    this.state.height = height;
  };

  filtUnselectedGrammar = () => {
    let { allSuggesion, gm_color } = this.state;
    const grammerList = this.state.selectedGrammerItem.length < 1 ? gm_color : this.state.selectedGrammerItem;
    const filteredSuggesion = filterList(grammerList, allSuggesion);
    this.state.correctionList = filteredSuggesion;
    this.setState({ correctionList: filteredSuggesion });
  }

  decoratorInitialize = () => {
    const { editorState } = this.state;
    this.setState({
      editorState: EditorState.set(editorState, {
        decorator: this.generateDecorator()
      })
    }, () => this.getCharacterLimit());
  }

  onSearch = (anchorKey) => {
    this.filtUnselectedGrammar();
    console.log('onSearch_called', anchorKey);
    this.insertEmptyChar(anchorKey);
  };

  gramerSelectionUpdate = () => {
    this.filtUnselectedGrammar();
    const anchorKeys = DraftFn.getAnchorKeys(this.state.editorState);
    anchorKeys.forEach((anchorKey) => {
      this.insertEmptyChar(anchorKey, false)
    });
    this.setState({ editorState: this.state.editorState })
  }

  insertEmptyChar = (anchorKey, status = true) => {
    if (!anchorKey) return
    let { editorState } = this.state;
    const text = DraftFn.getTextFromAnchorKey(editorState, anchorKey);
    if (!text) return
    const selectionState = editorState.getSelection()
    const updatedSelectionState = DraftFn.updatedSelectionState(anchorKey, 0, 1);
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      updatedSelectionState,
      ' '
    );
    editorState = EditorState.push(editorState, contentState, 'insert-characters');
    this.state.editorState = editorState;
    const removedTxt = text.slice(0, 1)
    // console.log('removedTxt', removedTxt);
    this.removeLastChar(anchorKey, editorState, removedTxt, status, selectionState);

  }

  removeLastChar = (anchorKey, editorState, removedTxt, status, selectionState) => {
    // console.log('removeLastChar', removedTxt)
    const updatedSelectionState = DraftFn.updatedSelectionState(anchorKey, 0, 1);
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      updatedSelectionState,
      removedTxt
    );
    editorState = EditorState.forceSelection(
      EditorState.push(editorState, contentState, 'insert-characters'),
      selectionState
    );
    if (status) {
      this.setState({ editorState })
    }
    else {
      this.state.editorState = editorState;
    }

  }

  replaceTxt = (toReplace, status, span_id, error_name, actual) => {
    const [anchorKey, start, end] = span_id.split('_');
    const myProps = { blockKey: anchorKey, start, end, decoratedText: actual }
    if (status === 'replace')
      this.onReplace(myProps, toReplace, error_name)
    else if (status === 'ignore') {
      this.ignore_(myProps, error_name)
    }
  };

  onReplace = async (props, suggestWord, errorName) => {
    const error = {
      documentID: this.props.documentId, anchorKey: props.blockKey, errorName,
      userAction: 'Accept', actual: props.decoratedText, suggested: suggestWord
    };
    reportServices.addGrammerError(error);
    const blockKey = props.blockKey,
      start = props.start,
      end = props.end;
    const { editorState } = this.state;
    const selectionsToReplace = [];
    const blockSelection = SelectionState.createEmpty(blockKey).merge({
      focusKey: props.blockKey,
      anchorOffset: start,
      focusOffset: end
    });
    selectionsToReplace.push(blockSelection);
    let contentState = editorState.getCurrentContent();

    let updatedEditorState = EditorState.acceptSelection(
      editorState,
      blockSelection
    );
    console.log("updatedEditorState", updatedEditorState.getCurrentInlineStyle())
    selectionsToReplace.forEach(selectionState => {
      contentState = Modifier.replaceText(
        contentState,
        selectionState,
        suggestWord,
        updatedEditorState.getCurrentInlineStyle()
      );
    });
    this.onChange(EditorState.push(editorState, contentState));
    this.setState({ show: false, inside: false });
  };

  getSizeHover = element => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const ele = element.target;
    const rect = ele.getBoundingClientRect();
    const size = { left: rect.left, bottom: rect.bottom + top };
    return size;
  };


  ignore_ = async (props, error_name) => {
    console.info('ignore__called')
    const { blockKey } = props;
    const error = {
      documentID: this.props.documentId, anchorKey: blockKey, errorName: error_name,
      userAction: 'Reject', actual: props.decoratedText, suggested: ""
    };
    const res = await reportServices.addGrammerError(error);
    console.log(res);
    if (res.status === 200) {
      toast.success(`${props.decoratedText} Ignored`);
      const text = DraftFn.getTextFromAnchorKey(this.state.editorState, blockKey);
      grammerChecker(this, text, blockKey, false)
    }
  };

  addDictionary = async (text, anchorKey) => {
    console.info('addDictionary_called', anchorKey, text);
    let res = await englishCheckers.addDictionary(text);
    if (res.status === 200) {
      toast.success(`${text} added in the dictionary`);
      const retext = DraftFn.getTextFromAnchorKey(this.state.editorState, anchorKey);
      grammerChecker(this, retext, anchorKey, false)
    }
  };
  limitTheCharacter = (currentCharCount, editorState) => {
    console.log("limitTheCharacter", currentCharCount);
    const { character_limit } = this.state;
    let currentContent = editorState.getCurrentContent();
    const firstBlock = currentContent.getBlockMap().first();
    const lastBlock = currentContent.getBlockMap().last();
    const firstBlockKey = firstBlock.getKey();
    const lastBlockKey = lastBlock.getKey();
    const firstAndLastBlockIsTheSame = firstBlockKey === lastBlockKey;

    const textStart = firstBlock.getText()
    const trimmedTextStart = textStart.trimLeft();
    const lengthOfTrimmedCharsStart = textStart.length - trimmedTextStart.length;
    let currentAchorKey = getCurrentAnchorKey(editorState);
    let currentText = getTextFromAnchorKey(editorState, currentAchorKey);
    let currentTextLength = currentText.length;
    console.log("currentAchorKey===>")
    let newSelection = new SelectionState({
      anchorKey: currentAchorKey,
      anchorOffset: character_limit,
      focusKey: currentAchorKey,
      focusOffset: currentCharCount
    });

    currentContent = Modifier.replaceText(
      currentContent,
      newSelection,
      '',
    )

    let newEditorState = EditorState.push(
      editorState,
      currentContent,
    )
    return newEditorState;

  }
  onChange = (editorState) => {
    console.log('onChange===>');
    const current_content = editorState.getCurrentContent();
    //get the count of words and charchers 
    const [wordsCount, characterCount] = strCount(current_content.getPlainText());
    if (wordsCount < 1) {
      this.state.correctionList = []
      this.state.allSuggesion = {}
      this.state.errorList = {}
    }
    // if (characterCount > this.state.character_limit) {
    // editorState = this.limitTheCharacter(characterCount, editorState);
    // console.log(editorState);
    // toast.info(`You reached your character limit ${this.state.character_limit}`);
    // return 'handled'; 
    // }
    new Promise(() => {
      if (this.props.docTitle) {
        // const currentAchorKey = DraftFn.getCurrentAnchorKey(editorState);
        console.log('anchor', this.state.anchorKey)
        let convertRaw = convertToRaw(current_content);
        // const currentObj = convertRaw.blocks.find(raw => raw.key === currentAchorKey);
        // console.log('convertRaw', currentObj)
        this.props.handleTextEditor(JSON.stringify(convertRaw), wordsCount, characterCount);
      }
    });

    this.setState({ editorState }, () => {
      new Promise(() => {
        this.afterOnchange(current_content);
        this.pasteFn(editorState)
      })
    });

    // Character Limit
    // const contentState = editorState.getCurrentContent();
    // const oldContent = this.state.editorState.getCurrentContent();
    // const editorStateLen = contentState.getPlainText().length;
    // if ( contentState === oldContent || editorStateLen <= this.state.character_limit ) {
    //   this.setState({ editorState });
    // } else {
    //   const editorState = EditorState.moveFocusToEnd(
    //     EditorState.push(
    //       this.state.editorState,
    //       ContentState.createFromText(oldContent.getPlainText())
    //     )
    //   );
    //   toast.info(`You reached your character limit ${this.state.character_limit}`);
    //   // alert("You've exceeded your limit");
    //   this.setState({ editorState });
    // }
  };

  afterOnchange = (current_content) => {
    this.checkingTextGrammer();
    this.anchorKeyRemove(current_content);
  }

  generateDecorator = () => {
    console.info('generateDecorator_called');
    return new CompositeDecorator([
      {
        strategy: (contentBlock, callback, contentState) => {
          this.findSome(callback, contentBlock, contentState);
        },
        component: props => SearchHighlight(this, props)
      }
    ]);
  };


  findSome = async (callback, contentBlock, contentState) => {
    const suggesion = this.state.correctionList;
    const anchorKey = contentBlock.getKey();
    // console.info("findSome_called", anchorKey, this.state.allSuggesion);

    const text = contentBlock.getText();
    let filt = suggesion[anchorKey];
    if (!filt) return;
    filt && filt.forEach(obj => {
      const { offset, length, actual } = obj;
      const start = offset;
      const end = offset + length;
      const sliceTxt = text.slice(start, end);
      if (actual === sliceTxt)
        callback(start, end);
    });

    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      if (entityKey !== null) {
        const entityType = contentState.getEntity(entityKey).getType();
        return entityType === "LINK" || entityType === "IGNORE" || entityType === "CITATION";
      }
    }, callback);

  };

  anchorTag = (contentBlock, callback, contentState) => {
    const anchorKey = contentBlock.getKey();
    let type = contentState.getEntity(anchorKey).getType();
    console.log("mytype", type);
  };

  onLeave = () => {
    setTimeout(() => {
      if (!this.state.inside) this.setState({ show: false });
    }, 20);
  };

  handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  focus = () => {
    if (!this.props.docTitle) {
      this.refs.documentName.focus();
      if (this.props.documentType === "blog") {
        toast.info("Please Enter Blog Name.");
      } else if (this.props.documentType === "document") {

        toast.info("Please Enter Document Name.");
      }
    }
  };

  _toggleColor = toggledColor => {
    // console.log("toggledColor", toggledColor);
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(styleMap).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      },
      editorState.getCurrentContent()
    );
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor
      );
    }
    this.onChange(nextEditorState);
    //  this.editor.focus();
    this.focus();
  };

  classChange = () => {
    let { toogleClassName } = this.state;
    if (toogleClassName.status)
      toogleClassName = {
        editor: "col-md-10",
        correction: "hideCorr",
        status: false
      };
    else
      toogleClassName = {
        editor: "col-md-7",
        correction: "col-md-3",
        status: true
      };

    this.setState({ toogleClassName });
  };


  onChangeTitle = ({ currentTarget: input }) => {
    this.props.docTitleChange(input.value);
  };

  saveDocumentOnBlur = () => {
    this.props.createDocument();
  };
  inputKeyDown = e => {
    this.props.tagInputKeyDown(e);
  };
  removeTag = i => {
    this.props.removeTag(i);
  };

  setSpanList = (list) => {
    this.setState({ spanList: list })
  }

  triggerLoading = (isLoading) => {
    console.log("triggerLoading");
    this.setState({ loading: isLoading });
  }

  insertImage = (base64, key) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: base64, name: key }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    this.props.onImageInsert(key);
    if (key) {
      toast.info('Image Uploaded Succesfully.Please wait till it finsihed loading');
      toast.info('Adjust the Image as per your wish', { autoClose: 8000, });
    }
    this.setState({ loading: false }, () => {
      this.onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " "));
    });
  };

  customCountFunction(str) {
    const wordArray = str.match(/\b((?!=|\.).)+(.)\b/g);
    return wordArray ? wordArray.length : 0;
  }


  handleBeforeInput = () => {
    console.log("handleBeforeInput");
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length
    console.log('length', currentContentLength, this.state.character_limit)
    if (currentContentLength > this.state.character_limit) {
      console.log('you can type max ten characters');
      toast(`You are reached maximum character limit ${this.state.character_limit}`);
      return 'handled';
    } else {
      console.log("u can type", this.state.character_limit);
    }
  }
  handlePastedText = (text, html, editorState) => {
    var content = editorState.getCurrentContent();
    var selection = editorState.getSelection();
    var newContent = Modifier.insertText(
      content,
      selection,
      text
    )
    var newEditorState = EditorState.push(editorState, newContent, "insert-characters")
    this.onChange(newEditorState);
    return 'handled'
  }
  render() {
    const {
      show, hoverHead, hoverBody, x, y, suggesion, toogleClassName,
      ignore, editorState, sent, add_dictionary, loading
    } = this.state;
    let { docTitle, saved, titleEdit } = this.props;
    if (titleEdit === false) {
      titleEdit = true;
    } else {
      titleEdit = false;
    }
    return (
      <div>
        <Loader loading={this.state.loading} />
        <ReactTour
          steps={this.state.steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
        <br />
        <div className="row">
          <div className={toogleClassName.editor}>
            <div className="d-flex">

              <div>
                <i
                  className="fa fa-question-circle mt-2"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  title="Need Help ?"
                  style={{
                    cursor: "pointer",
                    color: "dodgerblue",
                    fontSize: "15px"
                  }}
                  onClick={() => this.setState({ reactTour: true })}
                ></i>
              </div>

              <div className="flex-fill ml-3 reactour-4">
                <Tags
                  className="flex-fill"
                  tags={this.props.tags}
                  onKeyDown={this.inputKeyDown}
                  onFocus={this.focus}
                  ref={this.props.tagInputRef}
                  removeTags={this.removeTag}
                />
              </div>

              <div className="d-flex align-items-center justify-content-end reactour-10">
                <DocUpload getDoc={this.addUploadedText} />
              </div>

              <div className="d-flex align-items-center justify-content-end">
                {saved != null &&
                  (saved ? (
                    <CircularProgress className="mx-2" size={24} />
                  ) : (
                      <span className="mx-2 text-info">Saved</span>
                    ))}
              </div>

              {/* <button onClick={() => this.citations() }> click me</button> */}

            </div>
            <div className="d-sm-flex bg-white px-2 pt-3 mt-2 reactour-3" style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: "4rem",
              zIndex: "999"
            }}>
              <div className="d-flex">
                <InlineSelect
                  placeholder="sans_serif"
                  list={FONT_TYPES}
                  currentStyle={editorState.getCurrentInlineStyle()}
                  onToggle={this.toggleInlineStyle}
                />

                <InlineColor
                  placeholder="Black"
                  list={FONT_COLOR}
                  currentStyle={editorState.getCurrentInlineStyle()}
                  onToggle={this.toggleInlineStyle}
                />

                <InlineSize
                  placeholder="14"
                  list={FONT_SIZE}
                  currentStyle={editorState.getCurrentInlineStyle()}
                  onToggle={this.toggleInlineStyle}
                />
              </div>
              <div className="d-flex">
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                />

                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                />
                {this.props.documentType === "blog" && <ImageUpload triggerLoading={this.triggerLoading} insertImage={this.insertImage} />}
              </div>
            </div>
            <input
              id="docTitle"
              placeholder={this.props.documentType === "blog" ? "Blog Title" : this.props.documentType === "document" ? "Document Title" : ""}
              name="docTitle"
              value={docTitle}
              onChange={this.onChangeTitle}
              onBlur={this.saveDocumentOnBlur}
              ref="documentName"
              className="reactour-1"
            // readOnly={titleEdit}
            />



            <div id="editorId" className="editor ft_md reactour-2" onClick={this.focus}>
              <Editor
                placeholder="Write your story..."
                ref={element => {
                  this.editor = element;
                }}
                customStyleMap={styleMap()}
                editorState={editorState}
                onChange={(e) => this.onChange(e)}
                plugins={plugins}
                // handleKeyCommand={this.handleKeyCommand}
                onTab={this.handleTab}
                autoCapitalize="sentences"
                // keyBindingFn={this.myKeyBindingFn}
                // handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
              />
            </div>

            <div className="d-flex setBgA align-items-center justify-content-between px-2 py-1 rounded-bottom">
              <div className="d-flex identify">
                <div className="d-flex identify reactour-5">
                  <div>
                    Char <CharCounter
                      editorState={this.state.editorState}
                      limit={200}
                    />
                    / {this.state.character_limit}
                  </div>
                  {/* <div>
                  Limit: {this.state.character_limit}
                </div> */}
                  <div className="reactour-5">
                    words <WordCounter limit={30} />{" "}
                  </div>
                  <div>
                    Paragraph <LineCounter limit={10} />{" "}
                  </div>
                  <div>
                    Sentence <CustomCounter countFunction={this.customCountFunction} />{" "}
                  </div>
                </div>
                <div className="reactour-6">
                  Flesch-Kincaid Grade Level {!isNaN(this.state.grade.grade)
                    ? this.state.grade.grade.toFixed(2)
                    : 0}
                </div>
                <div onClick={this.recheckGrammar} data-toggle="tooltip" title='Recheck Grammar'>
                  <i
                    className="fa fa-refresh mx-2 point"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className='d-flex align-items-center reactour-7'>
                <img
                  id="Popover1"
                  alt='toneChecker'
                  onMouseEnter={() => this.setState({ popoverOpen: true })}
                  onMouseLeave={() => this.setState({ popoverOpen: false })}
                  className="imgSet"
                  src={sent.emojiImg}
                />
              </div>
            </div>
          </div>

          <div
            className={`${toogleClassName.correction} bg-white rounded border-right`}
          >
            <div className="sticky reactour-8">
              <Correction
                replaceAll={this.replaceTxt}
                addDictionary={this.addDictionary}
                ignore={this.ignore_}
                onUpdate={this.state.onupdate}
                correctionList={this.state.correctionList}
                spanProps={this.state.spanProps}
                classChange={this.classChange}
                grammerList={(this.state.selectedGrammerItem.length < 1) ? this.state.gm_color : this.state.selectedGrammerItem}
                allSuggesion={this.state.allSuggesion} />
            </div>
          </div>

          <div className="col-md-2 bg-white rounded border-left reactour-9">
            <div className="sticky">
              <ItemList
                onSelect={(grammerList) => {
                  this.setState({ selectedGrammerItem: grammerList });
                  this.editor.focus();
                  this.gramerSelectionUpdate();
                }}
                errorCount={this.state.errorCount}
                onUpdate={this.state.onupdate}
                classChange={this.classChange}
                allSuggesion={this.state.allSuggesion}
                gm_color={this.state.gm_color}
              />
            </div>
          </div>
        </div>

        <br />
        <br />

        {/* <CollapseReportCard /> */}
        <div>
          {show && !toogleClassName.status && (
            <div
              className="border suggest rounded shadow"
              style={{ left: x, top: y }}
              onMouseEnter={() => this.setState({ inside: true })}
              onMouseLeave={() => this.setState({ show: false, inside: false })}
            >
              <p className="m-0 py-2 px-3 ft_md bgSetB rounded-top">
                {hoverHead}
              </p>
              {hoverBody && (
                <p className="m-0 pt-2 px-3 bg-white"> {hoverBody} </p>
              )}
              <div className="text-left px-2 bg-white py-2 rounded-bottom">
                {suggesion}
              </div>
              <div className="bg-white">{ignore}</div>
              <div className="bg-white"> {add_dictionary} </div>
            </div>
          )}
        </div>

        <Popover
          placement="right"
          isOpen={this.state.popoverOpen}
          target="Popover1"
          style={{ padding: "1%", width: "20vw" }}
        >
          <PopoverHeader>Here's how your text sounds...</PopoverHeader>
          <PopoverBody>
            <div className="d-flex align-items-center">
              <span className="ft_sm mr-2">{sent.emojiStatus}</span>
              <img alt="imgSet" className="imgSet" src={sent.emojiImg} />
            </div>
          </PopoverBody>
        </Popover>

        <Popover
          placement="bottom"
          isOpen={this.state.anchorOpen}
          target="anchorFa"
        // style={{ width: "120%" }}
        >
          {/* <PopoverHeader>Here's how your text sounds...</PopoverHeader> */}
          <PopoverBody>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">http://</span>
              </div>
              <input
                onChange={e => this.setState({ url: e.target.value })}
                type="text"
                className="form-control"
                placeholder="www.yourwebsite.com"
              />
              <div className="input-group-append">
                <span
                  onClick={this.checkLink}
                  className="input-group-text point"
                >
                  Submit
                </span>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default GrammerCheck;