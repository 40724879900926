import React from "react";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import Settings from "../settings";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import authService from "../../services/authService";
import SubAdminNavBar from "../SubAdminSideBar";

const AccountSettingsPage = props => {
  const user = authService.getCurrentUser();
  let userData = {};
  let manageUser = false;
  if (props.location.state) {
    userData = props.history.location.state.userData;
    console.log("userData", userData);
    manageUser = true;
  }
  if (!user.isAdmin && !user.isSubAdmin) {
    return (
      <React.Fragment>
        <SideNavBar>
          <Settings />
        </SideNavBar>
      </React.Fragment>
    );
  } else if(user.isAdmin && !user.isSubAdmin) {
    return (
      <React.Fragment>
        <AdminNavBar>
          <Settings data={userData} manageUser={manageUser} />
        </AdminNavBar>
      </React.Fragment>
    );
  } else {
    return (
    <React.Fragment>
      <SubAdminNavBar>
        <Settings  data={userData} manageUser={true}/>
      </SubAdminNavBar>
    </React.Fragment>
     );
  }
};

export default NetworkDetector(AccountSettingsPage);
