import React, { useEffect } from "react";
import { Media } from "reactstrap";
import Avatar from "@material-ui/core/Avatar";
import userImage from "../../static/img_avatar.png";
import NotificationServices from "../../services/notficationService";
import { withRouter } from "react-router-dom";
const Notifications = ({ data, history }) => {
  // let history = useHistory();
  // let route = data.alert.page
  //console.log(data.notifications);
  //console.log(data.alert[0].from.display_picture);
  // const notificationsData = [
  //   {
  //     id: data.alert._id,
  //     avatar: userImage,
  //     message: data.alert.message,
  //     date: "3 min ago"
  //   }
  // ];
  const navigation = async notifications => {
    let details = NotificationServices.readnotification(notifications);
    console.log(notifications);
    history.push(`${notifications.page}`);
  };
  return (
    data.notifications &&
    data.notifications.length &&
    data.notifications.map(notify => (
      <Media key={notify._id} className="pb-2">
        <Media left className="align-self-center pr-3">
          <Avatar
            tag={Media}
            object
            src={notify.from ? notify.from.display_picture : userImage}
            title=""
            style={{ cursor: "pointer" }}
          />
        </Media>
        <Media
          style={{ cursor: "pointer" }}
          body
          middle
          className="align-self-center"
          onClick={() => navigation(notify)}
        >
          {notify.message}
        </Media>
        {/* <Media right className="align-self-center">
          <small className="text-muted">{date}</small>
        </Media> */}
      </Media>
    ))
  );
};

export default withRouter(Notifications);
