import React, { Component } from "react";
import AdminSideNavBar from "../AdminSideNavBar";
import ManageInvoices from "../ManageInvoices";

class ManageInvoicePage extends Component {
  // state = {};
  // componentDidMount() {
  //   console.log(this.props.location.aboutProps);
  // }
  render() {
    return (
      <React.Fragment>
        <AdminSideNavBar>
          <ManageInvoices />
        </AdminSideNavBar>
      </React.Fragment>
    );
  }
}

export default ManageInvoicePage;
