import React from "react";
import "./BreadCrumbs.css";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
const Example = ({ l1, l2, l3, dashboardlink, documentLink }) => {
  return (
    <div className="ft_sm">
      <Breadcrumb>
        <BreadcrumbItem active color="primary">
          <Link className="ft_sm" to={dashboardlink}>
            {l1}
          </Link>
        </BreadcrumbItem>
        {l3 ? <BreadcrumbItem active>
          <Link className="ft_sm m-0" to={documentLink}>
            {l2}
          </Link>
        </BreadcrumbItem> : <BreadcrumbItem>{l2}</BreadcrumbItem>}
        {l3 && <BreadcrumbItem>{l3}</BreadcrumbItem>}
      </Breadcrumb>
    </div>
  );
};

export default Example;
