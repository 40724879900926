import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "./Editor/utilize";
import macroServices from "../services/macroServices";
import "jspdf-autotable";
import {
  downloadDocumentAnalyzerPdf,
  downloadDocumentAnalyzer,
  getSentenceLimit,
} from "../services/adminServices";
import { toast } from "react-toastify";
import Loader from "../utils/loader";
import jsPDF from "jspdf";

export default class DocumentAnalyzer extends Component {
  state = {
    document: {},
    scores: {},
    wordFrequency: {},
    perspectiveWords: {},
    sentence_limit: [],
    actual_Limit: [],
    total_sentences: 0,
    color: ["primary", "secondary", "warning", "info"],
    loading: true,
  };

  async componentDidMount() {
    let { data, document } = this.props;
    console.log(document);
    let rawData = await this.convertToHtml(document.onlyDocumentContent);
    this.setState({ document, scores: data, loading: false }, () => {
      this._wordFrequency(rawData);
      this._perspectiveWords(rawData);
      this._sentenceCounter(rawData);
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.document !== prevProps.document) {
      let rawData = await this.convertToHtml(
        this.props.document.onlyDocumentContent
      );
      this.setState(
        {
          document: this.props.document,
          scores: this.props.data,
        },
        () => {
          this._wordFrequency(rawData);
          this._perspectiveWords(rawData);
          this._sentenceCounter(rawData);
        }
      );
    }
  }

  convertToHtml = (data) => {
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    return editorContentHtml;
  };

  getIndicesOf = (searchStr, str, caseSensitive) => {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  _wordFrequency = async (rawData) => {
    let content = rawData;
    content = content.replace(/(<([^>]+)>)/gi, "");
    content = content.replace(/\&nbsp;/g, "");

    let words = content
      .replace(/[\[\].();,#@$%&*^<>/`~+='"!?:1234567890]/g, "")
      .split(/\s/);
    let freqMap = {};

    words.forEach((w) => {
      if (w === "") {
        // Nothing
      } else {
        if (!freqMap[w]) {
          freqMap[w] = 0;
        }
        freqMap[w] += 1;
      }
    });

    let keysSorted = Object.entries(freqMap).sort((a, b) => {
      return b[1] - a[1];
    });
    let frequency = {};

    keysSorted.forEach((w) => {
      frequency[w[0]] = w[1];
    });

    console.log(frequency);
    this.setState({ wordFrequency: frequency });
  };

  _perspectiveWords = async (rawData) => {
    let content = rawData;
    content = content.replace(/(<([^>]+)>)/gi, "");
    content = content.replace(/\&nbsp;/g, "");

    let words = [
      ["I", "me", "my", "myself", "mine", "we", "us", "our", "ours"],
      ["you", "your", "yours", "yourself", "yourselves"],
      [
        "he",
        "him",
        "his",
        "himself",
        "she",
        "her",
        "hers",
        "herself",
        "it",
        "its",
        "itself",
        "they",
        "them",
        "their",
        "theirs",
        "themselves",
      ],
    ];

    let first = 0;
    let second = 0;
    let third = 0;
    let value = 0;
    let wordLength = 0;

    for (let i = 0; i < words.length; i++) {
      for (let j = 0; j < words[i].length; j++) {
        wordLength = words[i][j].length;
        let indices = this.getIndicesOf(words[i][j].trim(), content);
        for (let k = 0; k < indices.length; k++) {
          if (indices[k] === 0 && content[indices[k] + wordLength] === " ") {
            value += 1;
          } else if (
            content[indices[k] - 1] === " " &&
            content[indices[k] + wordLength] === " "
          ) {
            value += 1;
          } else if (
            content[indices[k] - 1] === " " &&
            indices[k] === content.length - 1
          ) {
            value += 1;
          }
        }
      }

      if (i === 0) {
        first = value;
      } else if (i === 1) {
        second = value;
      } else {
        third = value;
      }
      value = 0;
    }

    let final = {
      first: first,
      second: second,
      third: third,
    };
    console.log(final);
    this.setState({ perspectiveWords: final });
  };

  _sentenceCounter = async (rawData) => {
    let content = rawData;
    content = content.replace(/(<([^>]+)>)/gi, "");
    content = content.replace(/\&nbsp;/g, "");

    let sentenceLimit = await getSentenceLimit();
    sentenceLimit = sentenceLimit.sentence_limit;
    sentenceLimit = sentenceLimit.split(",");
    sentenceLimit = sentenceLimit.map(Number);

    let empty = new Array(sentenceLimit.length).fill(0);

    let { sentence: sentences } = await macroServices.executeRegex({
      sentence: content,
      pattern: "sentenceHighlighterRegex",
    });

    for (let i = 0; i < sentences.length; i++) {
      let wordCount = sentences[i].split(/[\s,]+/).length;

      for (let j = sentenceLimit.length - 1; j >= 0; j--) {
        if (sentenceLimit[j] === 0) {
          continue;
        }

        if (wordCount > sentenceLimit[j] - 1) {
          for (let k = j; k >= 0; k--) {
            empty[k] += 1;
          }
          break;
        } else {
          continue;
        }
      }
    }

    this.setState({
      sentence_limit: sentenceLimit,
      actual_Limit: empty,
      total_sentences: sentences.length,
    });
  };

  downloadDocumentAnalyzer = async () => {
    this.setState({ loading: true });
    const finalData = {
      document: this.state.document,
      scores: this.state.scores,
      wordFrequency: this.state.wordFrequency,
      perspectiveWords: this.state.perspectiveWords,
      sentence_limit: this.state.sentence_limit,
      actual_Limit: this.state.actual_Limit,
      total_sentences: this.state.total_sentences,
      color: ["pro1", "pro2", "pro3", "pro4"],
    };
    console.log(finalData);
    let res = await downloadDocumentAnalyzerPdf(finalData);
    if (res.status === 200) {
      downloadDocumentAnalyzer("DocumentAnalyzer");
      toast.success("Report downloaded!");
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      toast.error("Oops something went wrong");
    }
  };

  downloadWordFrequency = async () => {
    this.setState({ loading: true });
    console.log(this.state.wordFrequency);
    const doc = new jsPDF();
    const docName = this.state.document.documentName;
    // const useremail = rightUser.email;
    // console.log(user);
    // console.log(useremail);

    doc.setFontType("bold");
    doc.text("Word Frequency Report", 10, 10);

    doc.setFontType("normal");
    doc.setFontSize(13);
    doc.text("Document Name: ", 10, 20);
    doc.text(docName, 49, 20);
    // doc.text("Email: ", 10, 30);
    // doc.text(useremail, 25, 30);
    doc.autoTable({ html: "#my-table", margin: { top: 30 } });
    doc.save("WordFrequency.pdf");
    toast.success("Word frequency report downloaded!");
    this.setState({ loading: false });
  };

  render() {
    let {
      document,
      scores,
      wordFrequency,
      perspectiveWords,
      sentence_limit,
      actual_Limit,
      total_sentences,
      color,
    } = this.state;
    return (
      <div className="col-md-8 mt-3">
        <Loader loading={this.state.loading} />
        <div id="graphReport" className=" container bg-white rounded shadow">
          <div className="row pt-4 mb-4">
            <div className="col-md-6">
              <h2>Document Analyzer : {document.documentName}</h2>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div
                  className="ml-auto mr-3 text-primary"
                  onClick={this.downloadDocumentAnalyzer}
                >
                  <i
                    className="fa fa-download pr-1"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                  <label
                    className="downloadBtn"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Download Document Analyzer Report
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2 mt-5">
            <h4>Word Frequency</h4>
          </div>

          <div className="row">
            <div className="col-md-6 p-3">
              <table className="table">
                <thead className="table-primary">
                  <tr>
                    <th className="text-center" scope="col">
                      Word
                    </th>
                    <th className="text-center" scope="col">
                      Frequency
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(wordFrequency)
                    .slice(0, 5)
                    .map((word, index) => (
                      <tr key={index}>
                        <td className="text-center">{word}</td>
                        <td className="text-center">{wordFrequency[word]}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div style={{ display: "none" }}>
                <table className="table" id="my-table">
                  <thead className="table-primary">
                    <tr>
                      <th className="text-center" scope="col">
                        Word
                      </th>
                      <th className="text-center" scope="col">
                        Frequency
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(wordFrequency).map((word, index) => (
                      <tr key={index}>
                        <td className="text-center">{word}</td>
                        <td className="text-center">{wordFrequency[word]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div
                className="ml-auto mr-3 text-primary"
                onClick={this.downloadWordFrequency}
              >
                <i
                  className="fa fa-download pr-1"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <label
                  className="downloadBtn"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Download Word Frequency Report
                </label>
              </div>
            </div>
          </div>

          <div className="row m-2 ">
            <h4>Highlight Sentence</h4>
          </div>

          {/* <div className="row pt-3 mb-5">
            <div className="col-md-6 pl-4">
              <div>
                <h6>Total Words {scores ? scores.wordLength : 0}</h6>
                <ProgressBar
                  variant="danger"
                  now={scores ? scores.wordLength : 0}
                  style={{ width: "80%" }}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <h6>Total Sentences {scores ? scores.sentenceLength : 0}</h6>
                <ProgressBar
                  variant="success"
                  now={scores ? scores.sentenceLength : 0}
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </div> */}

          {sentence_limit
            .slice(1, sentence_limit.length)
            .map((limit, index) => (
              <div className="row pl-4 mt-5">
                <h6>
                  Sentences which contains {limit} words :{" "}
                  <b>{actual_Limit[index + 1]}</b>
                </h6>
                <ProgressBar
                  variant={color[index % 4]}
                  now={Math.round(
                    (actual_Limit[index + 1] / total_sentences) * 100
                  )}
                  style={{ width: "80%" }}
                  label={`${Math.round(
                    (actual_Limit[index + 1] / total_sentences) * 100
                  )}%`}
                />
              </div>
            ))}

          {/* <div className="row pl-4 mt-5">
            <h6>
              Sentences which contains 5 words : <b>{sentenceCounter.five}</b>
            </h6>
            <ProgressBar
              variant="primary"
              now={sentenceCounter.percentfive}
              style={{ width: "80%" }}
              label={`${sentenceCounter.percentfive}%`}
            />
          </div>

          <div className="row pl-4 pt-4">
            <h6>
              Sentences which contains 10 words : <b>{sentenceCounter.ten}</b>
            </h6>
            <ProgressBar
              variant="secondary"
              now={sentenceCounter.percentten}
              style={{ width: "80%", height: "50%" }}
              label={`${sentenceCounter.percentten}%`}
            />
          </div>

          <div className="row pl-4 pt-4">
            <h6>
              Sentences which contains 20 words :{" "}
              <b>{sentenceCounter.twenty}</b>
            </h6>
            <ProgressBar
              variant="warning"
              now={sentenceCounter.percenttwenty}
              style={{ width: "80%" }}
              label={`${sentenceCounter.percenttwenty}%`}
            />
          </div>

          <div className="row pl-4 pt-4 pb-5">
            <h6>
              Sentences which contains 30 words :{" "}
              <b>{sentenceCounter.thirty}</b>
            </h6>
            <ProgressBar
              variant="info"
              now={sentenceCounter.percentthirty}
              style={{ width: "80%", height: "50%"}}
              label={`${sentenceCounter.percentthirty}%`}
            />
          </div> */}

          <div className="row m-2 pt-5">
            <h4>Person Perspective Words</h4>
          </div>

          <div className="row pt-3 pb-5">
            <div className="col-md-4 pl-4">
              First Person Words: <b>{perspectiveWords.first}</b>
            </div>

            <div className="col-md-4 pl-3">
              Second Person Words: <b>{perspectiveWords.second}</b>
            </div>

            <div className="col-md-4 pl-3">
              Third Person Words: <b>{perspectiveWords.third}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
