import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { downloadInvoicePdf, downloadPdf } from "../../services/adminServices";
import Loader from "../../utils/loader";
import { toast } from "react-toastify";
import "./InvoiceCard.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    backgroundColor: "white",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

//handleGetInvoiceDetails = () => {};
const InvoiceCard = ({ data, expanded, expand, index }) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);

  const downloadInvoicePdf_ = async (data) => {
    setloading(true);
    console.log("sendData", data);
    let res = await downloadInvoicePdf(data);
    if (res.status === 200) {
      downloadPdf(data._id);
      toast.success("Invoice downloaded!");
      setloading(false);
    } else {
      setloading(false);
      toast.error("Oops something went wrong");
    }
  };

  return (
    <div>
      <Loader loading={loading} />

      <div class="accordion" id="accordionExample">
        <div
          class="card"
          data-toggle="collapse"
          data-target={`#collapse${index}`}
          aria-expanded="false"
          aria-controls={`collapse${index}`}
          style={{ cursor: "pointer" }}
        >
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button
                class="btn btn-link collapsed"
                type="button"
                style={{ textDecoration: "none", color: "black" }}
              >
                {data.userID && data.userID._id && data.userID.username} -{" "}
                <small>{data.paymentReference}</small>
              </button>
            </h2>
          </div>

          <div
            id={`collapse${index}`}
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div className="container p-1">
                <div className="row">
                  <div className="col-sm-6 ">
                    <h5>Company</h5>
                    <p>
                      JJFitzgerald Business Consultants, LLC, <br /> 22738 Maple
                      Rd, <br />
                      Maryland Lexington Park 20653
                    </p>
                  </div>
                  <br />
                  <div className="col-md-6 p-1">
                    <div>
                      <h5 style={{ float: "right" }}>Invoice</h5>
                    </div>
                    <div>
                      <br /> <br />
                      <button
                        type="button"
                        className="btn btn-primary float-right px-3"
                        onClick={() => downloadInvoicePdf_(data)}
                      >
                        <i
                          className="fa fa-download pr-1"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                        <label className="downloadBtn">Download</label>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h6>Invoice to</h6>
                    <p>
                      User ID : {data.userID && data.userID._id}
                      <br />
                      User Name : {data.userID && data.userID.username}
                      <br />
                      {/* User Address : {data.userID.address || "-"} */}
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <div style={{ float: "right" }}>
                      <h6>
                        Order ID : <small>{data.paymentReference}</small>
                      </h6>
                      <h6>
                        Order Status :<small> {data.status}</small>
                      </h6>
                      <h6>
                        Order Date :
                        <small>
                          {" "}
                          {new Date(data.orderDate).toString().slice(0, 15)}
                        </small>
                      </h6>
                      <h6>
                        Expiry Date :
                        <small>
                          {" "}
                          {new Date(data.expireDate).toString().slice(0, 15)}
                        </small>
                      </h6>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <div style={{ float: "right" }}>
                      <h6>
                        Sub-Total :<small> ${data.amount}</small>
                      </h6>
                      <h6>
                        Discount : <small> {data.discount || 1}%</small>
                      </h6>
                      <h6>
                        VAT : <small> 12.9%</small>
                      </h6>
                      <br />
                      <h5>
                        TOTAL : ${data.amount}
                        {/* TOTAL : ${data.amount - data.discount / 100 + 12.9 / 100} */}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
