import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputBoxOne from "./FormComponents/InputBoxOne";
import InputBoxTwo from "./FormComponents/InputBoxTwo";
import HeadOne from "./FormComponents/HeadOne";
import InputFeedback from "./FormComponents/InputFeedBack";
import { citationStructure } from "../../utils/citationWithRandomNumber";
import {
  savecitations,
  getStyle,
  updateCitations,
} from "../../services/citationService";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import Loader from "../../utils/loader";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Mandatory"),
  "container-title": Yup.string().required("Mandatory"),
  volume: Yup.string().required("Mandatory"),
  year: Yup.number().required("Mandatory").positive(),
  // issued: Yup.object({
  //   "date-parts": Yup.number().required("Required Field").positive(),
  // }),
});

let value = {};
function LegalCaseForm({ history, initialValues, projectID }) {
  const [loading, setLoading] = useState(false);

  console.log(initialValues);
  if (initialValues) {
    console.log("doneee...!");
    value = initialValues;
  } else {
    value = {
      "container-title": "",
      title: "",
      issued: {
        "date-parts": [["", "", ""]],
      },
      page: "",
      volume: "",
      year: "",
      // authority: "",
      type: "legal_case",
    };
  }
  return (
    <div>
      <div className="container bg-white rounded shadow">
        <div className="row pt-5">
          <HeadOne />
        </div>
        <ToastContainer />
        <Formik
          initialValues={value}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            // let skeleton = Skeleton
            // for (var key of Object.keys(skeleton)) {
            //   skeleton[key] = values[key];
            // }
            // skeleton.issued["date-parts"][0][0] = values.year;
            // console.log(skeleton);
            setLoading(true);
            let response = "";
            if (!values._id) {
              response = await savecitations(values, projectID);
            } else {
              response = await updateCitations({
                _id: values._id,
                data: values,
              });
            }
            if (response.status !== 200) {
              toast.error(response.message);
            } else {
              toast.info(response.message);
              // history.push('/citationList')
              history.push({
                pathname: `/citationList`,
                state: { projectId: projectID },
              });
              resetForm({});
            }
            setLoading(false);
          }}
          render={({ values, errors, touched, resetForm }) => (
            <Form>
              <Loader loading={loading} />
              <div>
                <div className="row mt-5" style={{ marginLeft: "142px" }}>
                  <h3>What I'm citing</h3>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: "142px", marginTop: "20px" }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      lineHeight: "19px",
                    }}
                  >
                    Article title
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "145px" }}>
                  <Field name={`title`} as={InputBoxOne} />
                  <ErrorMessage as={InputFeedback} name="title" />
                  {/* {errors.title && touched.title ? (
                    <div>{errors.title}</div>
                  ) : null} */}
                </div>
              </div>
              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* Legal publication info */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>Legal publication info</h3>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6
                      style={{
                        marginLeft: "-4px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "19px",
                      }}
                    >
                      Source title
                    </h6>
                    <br />
                    <div style={{ marginLeft: "-4px", marginTop: "-4px" }}>
                      <Field name="container-title" as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="container-title" />
                      {/* {errors.containertitle && touched.containertitle ? (
                        <div>{errors.containertitle}</div>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Advanced info
                  </h6>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "10px", marginLeft: "-4px" }}
                >
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Vol.</h6>
                      <br />
                      <Field name={`volume`} as={InputBoxOne} />
                      <ErrorMessage as={InputFeedback} name="volume" />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="row">
                      <h6>Edition</h6>
                      <br />
                      <Field name={`page`} as={InputBoxTwo} />
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Electronically Published
                  </h6>
                </div>

                <FieldArray
                  name="issued"
                  render={(arrayHelpers) => (
                    <div>
                      <div
                        className="row"
                        style={{ marginTop: "10px", marginLeft: "-4px" }}
                      >
                        {values.issued["date-parts"][0].map((date, index) => (
                          <div className="col-md-2">
                            <div className="row">
                              <h6>
                                {index == 0
                                  ? "Year"
                                  : index == 1
                                  ? "Month"
                                  : "Day"}
                              </h6>
                              <br />
                              <Field
                                name={
                                  index == 0
                                    ? "year"
                                    : `issued["date-parts"][0][${index}]`
                                }
                                as={index === 0 ? InputBoxOne : InputBoxTwo}
                              />
                              {index == 0 ? (
                                <ErrorMessage as={InputFeedback} name="year" />
                              ) : (
                                ""
                              )}

                              {/* <ErrorMessage
                                  as={InputFeedback}
                                  name="issued.date-parts[0][1]"
                                /> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                />
              </div>

              {/* Legal publication info */}

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              <div
                className="row"
                style={{ marginTop: "7%", paddingBottom: "5%" }}
              >
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        width: "15%",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}
export default withRouter(LegalCaseForm);
