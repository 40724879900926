import React, { useState, useEffect } from "react";
import {
  Formik,
  Field,
  Form,
  FieldArray,
  useField,
  ErrorMessage,
} from "formik";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { postHomeCMS } from "../../services/adminServices";
import imageCompression from "browser-image-compression";
import uploadImageServices from "../../services/uploadImageServices";
import HeadingB from "../Cards/HeadingB";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import LogoImg from "../../static/upl.png";
import * as Yup from "yup";

const InputField = ({ placeholder, className, maxLength, ...props }) => {
  const [field] = useField(props);
  return (
    <input
      type="text"
      {...field}
      className={className}
      placeholder={placeholder}
      maxLength={maxLength || 1000}
    />
  );
};

const TextArea = ({ placeholder, className, maxLength, ...props }) => {
  const [field] = useField(props);
  return (
    <textarea
      {...field}
      className={className}
      placeholder={placeholder}
      maxLength={maxLength || 1000}
      rows="5"
    />
  );
};

const validationSchema = Yup.object().shape({
  Feature_image: Yup.object().shape({
    height: Yup.number().required("*Not a Number"),
    width: Yup.number().required("*Not a Number"),
    image: Yup.string().required("Please Upload Image"),
  }),
  Features: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Required Field"),
      text: Yup.string().required("Required Field"),
      image: Yup.string().required("Please Upload Image"),
    })
  ),
});

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

let junkFile = [];
let DontDelete = [];

function FeaturesCMS({ cmsData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState(cmsData);
  const [leftImage, setLeftImage] = useState(cmsData.Feature_image.image);

  let value = {
    Feature_image: cmsData.Feature_image,
    Features: cmsData.Features,
  };

  useEffect(() => {
    return () => {
      _removeJunk();
    };
  }, []);

  const _removeJunk = async () => {
    console.log("junkFile===>", junkFile);
    if (junkFile.length > 0) {
      for (let i = 0; i < junkFile.length; i++) {
        await uploadImageServices.DeleteImageFromS3(junkFile[i]);
      }
    }
  };

  const uploadPic = (index) => {
    document.getElementById(`fileup[${index}]`).click();
  };

  const _handleImageChange = async (index, Features, setFieldValue, e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      // let uploadConfig = {};
      const options = {
        maxSizeMB: index === 100 ? 0.4 : 0.05,
        maxWidthOrHeight: 1920,
        maxIteration: 10,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(file, options);

      //   if(index === 100){
      //     uploadConfig = await uploadImageServices.UploadImageToS3(compressedImage, "");
      //   }else{
      //     uploadConfig = await uploadImageServices.UploadImageToS3(compressedImage, "");
      //   }

      const uploadConfig = await uploadImageServices.UploadImageToS3(
        compressedImage,
        ""
      );

      DontDelete.push(Features.image_key);
      junkFile.push(uploadConfig.data.key);

      let reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onload = function () {
        if (index === 100) {
          setLeftImage(uploadConfig.data.displayUrl);
          setFieldValue(`Feature_image.image`, uploadConfig.data.displayUrl);
          setFieldValue(`Feature_image.image_key`, uploadConfig.data.key);
        } else {
          setFieldValue(
            `Features[${index}].image`,
            uploadConfig.data.displayUrl
          );
          setFieldValue(`Features[${index}].image_key`, uploadConfig.data.key);
        }
        // console.log(reader.result);
      };
    }
  };

  const removeCard = async (index, Features, arrayHelpers) => {
    arrayHelpers.remove(index);
    if (Features.image_key) {
      // await uploadImageServices.DeleteImageFromS3(Features.image_key);
      DontDelete.push(Features.image_key);
    }
  };

  const ResetImage = async (Image, setFieldValue) => {
    setLeftImage(cmsData.Feature_image.deafult_image);
    if (Image.image_key) {
      // await uploadImageServices.DeleteImageFromS3(Image.image_key);
      DontDelete.push(Image.image_key);
    }
    setFieldValue(`Feature_image.image`, cmsData.Feature_image.deafult_image);
    setFieldValue(`Feature_image.image_key`, "");
  };

  const _resetChanges = async (values, setFieldValue) => {
    setLoading(true);

    let image = {
      deafult_image:
        "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group468.png",
      image:
        "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group468.png",
      image_key: "",
      width: "120",
      height: "55",
    };

    let features = [
      {
        image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group463.png",
        text:
          "The Professional Editing Tool (PET) is an editing software that incorporates AI to provide a whole new experience in making editing easier and thus improve the workflow. PET utilizes uses advanced machine learning algorithms solutions to analyze documents and grammar in seconds. PET’s Artificial intelligence advises users to rewrite their text whenever a complex phrase will be written. These features will assist writers in every aspect of writing a document.",
        title: "AI-Powered Products",
        image_key: "",
      },
      {
        image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group464.png",
        text:
          "The PET application brings all the state-of-the-art, extensive features which are available in multiple writing tools, under one platform to facilitate writers, researchers and students and cut down their cost spending on different platform for multiple features. PET digital writing features make it easy for users to 'write' in all sorts of new ways that enable better communications and collaborations for more productivity.",
        title: "Digital Writing",
        image_key: "",
      },
      {
        image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group465.png",
        text:
          "PET application helps to align teams with complete editing and writing, while providing Team Collaboration on each document. The PET application is a subscription based multiple users editing tool where users can create, manage, share the documents and write collaboratively with other users in groups or independently.",
        title: "Sharing with Team",
        image_key: "",
      },
      {
        image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group466.png",
        text:
          "Compose clear, mistake-free writing that makes the right impression with PET’s writing assistant. PET also provide plagiarism Checks to ensure originality and integrity of your writing comparing it with previously published content.",
        title: "Mistake - Free Writing",
        image_key: "",
      },
    ];

    console.log(values);
    setFieldValue(`Feature_image`, image);
    setFieldValue(`Features`, features);

    if (values.Features.length > 0) {
      for (let i = 0; i < values.Features.length; i++) {
        await uploadImageServices.DeleteImageFromS3(
          values.Features[i].image_key
        );
      }
    }

    if (DontDelete.length > 0) {
      for (let i = 0; i < DontDelete.length; i++) {
        await uploadImageServices.DeleteImageFromS3(DontDelete[i]);
      }
    }
    DontDelete = [];

    let finalData = mainData;
    finalData["Feature_image"] = image;
    finalData["Features"] = features;
    let post = await postHomeCMS(finalData);
    if (post.status === 200) {
      toast.success("Changes Updated");
    } else {
      toast.error("Failed To Save");
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid h-100">
      <Loader loading={loading} />
      <Formik
        initialValues={value}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log(values);
          setLoading(true);
          if (DontDelete.length > 0) {
            for (let i = 0; i < DontDelete.length; i++) {
              await uploadImageServices.DeleteImageFromS3(DontDelete[i]);
            }
          }
          junkFile = [];
          DontDelete = [];
          let finalData = mainData;
          finalData["Feature_image"] = values.Feature_image;
          finalData["Features"] = values.Features;
          console.log(finalData);
          let post = await postHomeCMS(finalData);
          if (post.status === 200) {
            toast.success("Changes Updated");
          } else {
            toast.error("Failed To Save");
          }
          setLoading(false);
        }}
        render={({ values, setFieldValue }) => (
          <Form className="container">
            <HeadingB className="text-center alertHead pt-5" text="FEATURE" />

            <div className="mt-5">
              <label for="introSubtext">Main Left Image</label>
              <hr />
              <div className="row mt-3">
                <div className="col-md-6 text-center">
                  <Card
                    className={classes.root}
                    variant="outlined"
                    style={{ textAlign: "center" }}
                  >
                    <CardContent>
                      <Icon
                        className="fa fa-plus-circle point"
                        style={{ fontSize: 30, color: "#007bff" }}
                        color="primary"
                        onClick={() => uploadPic(100)}
                      />
                      <Typography variant="body2" component="p">
                        Upload Image
                      </Typography>
                    </CardContent>
                  </Card>

                  <Button
                    variant="danger"
                    className="w-50 mt-3"
                    onClick={() =>
                      ResetImage(values.Feature_image, setFieldValue)
                    }
                  >
                    Reset Image
                  </Button>
                  <input
                    className="fileInput"
                    type="file"
                    id="fileup[100]"
                    accept="image/*"
                    onChange={(e) =>
                      _handleImageChange(
                        100,
                        values.Feature_image,
                        setFieldValue,
                        e
                      )
                    }
                    style={{ display: "none" }}
                  />
                </div>
                <div className="col-md-6 text-center">
                  {" "}
                  <img
                    id="backGroundPreview"
                    className="petLogo"
                    alt="Group570"
                    style={{ width: "auto", height: "100px" }}
                    src={leftImage || LogoImg}
                  ></img>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Width</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputField
                      name={`Feature_image.width`}
                      className="form-control"
                      placeholder="0"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>vw</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <ErrorMessage name={`Feature_image.width`}>
                    {(error) => <small style={{ color: "red" }}>{error}</small>}
                  </ErrorMessage>
                </div>
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Height</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputField
                      name={`Feature_image.height`}
                      className="form-control"
                      placeholder="0"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>vh</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <ErrorMessage name={`Feature_image.height`}>
                    {(error) => <small style={{ color: "red" }}>{error}</small>}
                  </ErrorMessage>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <FieldArray
                name="Features"
                render={(arrayHelpers) => (
                  <div>
                    <div className="row mt-5">
                      <div className="col-md-6">
                        <label>Features</label>
                      </div>
                      <div className="col-md-6">
                        {/* { values.Features.length === 4 || values.Features.length > 4 ? "" : ("") } */}
                        <Button
                          style={{ float: "right" }}
                          variant="success"
                          onClick={() =>
                            arrayHelpers.push({
                              image: "",
                              image_key: "",
                              text: "",
                              title: "",
                            })
                          }
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i> Add
                          Feature
                        </Button>
                      </div>
                    </div>
                    <hr />

                    {values.Features && values.Features.length > 0
                      ? values.Features.map((Features, index) => (
                          <div key={index}>
                            <h2 className="mt-5">Feature {index + 1}</h2>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <div className="text-center">
                                  <Button
                                    variant="primary"
                                    className="w-50 mt-4"
                                    onClick={() => uploadPic(index)}
                                  >
                                    <i
                                      className="fa fa-cloud-upload point"
                                      aria-hidden="true"
                                    ></i>
                                    <br />
                                    Upload Image
                                  </Button>
                                  <input
                                    type="file"
                                    id={`fileup[${index}]`}
                                    accept="image/*"
                                    onChange={(e) =>
                                      _handleImageChange(
                                        index,
                                        Features,
                                        setFieldValue,
                                        e
                                      )
                                    }
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 text-center">
                                <img
                                  id={`ImagePreview[${index}]`}
                                  name={`Features[${index}].image`}
                                  alt="Feature"
                                  style={{ width: "auto", height: "100px" }}
                                  src={
                                    values.Features[index].image
                                      ? values.Features[index].image
                                      : LogoImg
                                  }
                                ></img>
                                <br />
                                <ErrorMessage name={`Features[${index}].image`}>
                                  {(error) => (
                                    <small style={{ color: "red" }}>
                                      {error}
                                    </small>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                            <br />

                            <div className="mt-5 text-center">
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    Feature Title
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <InputField
                                  name={`Features[${index}].title`}
                                  className="form-control"
                                  maxLength="30"
                                  placeholder="Feature Title"
                                />
                              </InputGroup>

                              <small style={{ float: "left" }}>
                                Length: {Features.title.length}/30
                              </small>
                              <ErrorMessage name={`Features[${index}].title`}>
                                {(error) => (
                                  <small
                                    style={{ color: "red", float: "right" }}
                                  >
                                    {error}
                                  </small>
                                )}
                              </ErrorMessage>

                              <InputGroup className="mt-5">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    Feature Text
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <TextArea
                                  name={`Features[${index}].text`}
                                  className="form-control"
                                  maxLength="325"
                                  placeholder="Feature Text"
                                />
                              </InputGroup>

                              <small style={{ float: "left" }}>
                                Length: {Features.text.length}/325
                              </small>
                              <ErrorMessage name={`Features[${index}].text`}>
                                {(error) => (
                                  <small
                                    style={{ color: "red", float: "right" }}
                                  >
                                    {error}
                                  </small>
                                )}
                              </ErrorMessage>

                              <Button
                                variant="danger"
                                className="w-25 mt-5"
                                onClick={() =>
                                  removeCard(index, Features, arrayHelpers)
                                }
                              >
                                <i class="fa fa-trash" aria-hidden="true"></i>{" "}
                                Remove Feature
                              </Button>
                            </div>
                            <hr />
                          </div>
                        ))
                      : ""}
                  </div>
                )}
              />
            </div>

            {/* Save Button */}
            <div className="text-center">
              <Button
                variant="danger"
                onClick={() => _resetChanges(values, setFieldValue)}
                className="w-25 mr-5"
              >
                RESET ALL
              </Button>
              <Button type="submit" className="mt-5 mb-5 w-25">
                SAVE
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
}

export default FeaturesCMS;
