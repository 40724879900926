import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SearchBox from "../SearchBox";
import $ from "jquery";
import { connect } from "react-redux";
import { setUserDocument } from "../../redux/document/document.actions";
import documentService from "../../services/document";
import { paginate } from "../../utils/paginate";
import authService from "../../services/authService";
import Loader from "../../utils/loader";
import { Link } from "react-router-dom";
import PaginatioN from "react-js-pagination";
import { ToastContainer, toast } from "react-toastify";
import "../MyDocuments.css";
import NoDocuments from "../../static/No-documents-found.gif";
import ParaphraseDocCard from '../Paraphrase/ParaphraseDocCard';

export class ParaphraseDocs extends Component {
  state = {
    loading: true,
    documents: [],
    isAdmin: false,
    currentDocumentPage: 1,
    pageSize: 9,
    searchQuery: "",
    totalDocumentCount: "",
    NoDocs: false,
    userFeatures: {},
  };

  ///My Document
  async componentDidMount() {
    const { userDocuments, deletedDocuments, features } = this.props;
    // console.log("userDocuments",userDocuments)
    this.setState({ userFeatures: features }, () => this.initalCall());
  }

  async componentWillMount() {
    $("#open").click(function () {
      $("#fileup").trigger("click");
    });
  }

  initalCall = async () => {
    const { data: userData } = this.props;
    sessionStorage.setItem("userData", JSON.stringify(userData));
    const user = authService.getCurrentUser();
    if (userData && user.isAdmin) {
      // console.log(userData._id);
      this.getDocumentsForAdmin(userData._id);
      this.setState({
        dashLink: "/admindashboard",
      });
    } else {
      this.getDocuments("");
      this.setState({
        dashLink: "/dashboard",
      });
    }
  };

  ///get all my Document
  getDocuments = async () => {
    const { setUserDocument } = this.props;
    let document = await documentService.getAllDocuments();
    console.log("document", document);
    if (document.status === 200) {
      //   setUserDocument(document.data);
      this.setState({
        documents: document.data,
        totalDocumentCount: document.data.length,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleSearch = (e) => {
    this.setState({ searchQuery: e.target.value, currentPage: 1 });
  };

  handleDocumentPageChange = (page) => {
    this.setState({ currentDocumentPage: page });
  };

  getPagedData = () => {
    const {
      documents,
      currentDocumentPage,
      pageSize,
      searchQuery,
    } = this.state;

    let filteredDocument = documents;

    if (searchQuery) {
      filteredDocument = documents.filter((doc) =>
        doc.documentName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    const Docs = paginate(filteredDocument, currentDocumentPage, pageSize);

    return {
      totalDocumentCount: filteredDocument.length,
      documents: Docs,
    };
  };

  getUserDocs = () => {
    const { userDocuments, setUserDocument } = this.props;
    if (userDocuments.length > 0) {
      this.setState(
        {
          documents: userDocuments,
          totalDocumentCount: userDocuments.length,
        },
        () => this.getDeletedDocuments()
      );
    } else {
      this.initalCall();
    }
  };

  render() {
    const { currentDocumentPage, isAdmin, NoDocs } = this.state;

    console.log(NoDocs);

    const { totalDocumentCount, documents } = this.getPagedData();

    return (
      <div>
        <Loader loading={this.state.loading} />

        {/* {documents.length === 0 ? (
          <div className="text-center mb-2">
            <img
              className="img-fluid setImgMargin"
              src={NoDocuments}
              alt="404"
            ></img>
          </div>
        ) : ( */}

        <div className="row">
          <div className="col-md-4">
            <SearchBox
              value={this.state.searchQuery}
              onSearch={this.handleSearch}
              placeholder="&#xF002; Search document"
            />
          </div>
          <div className="col-md-6"></div>
        </div>
        <br />
        {documents.length === 0 ? (
          <div className="text-center mb-2">
            <img
              className="img-fluid setImgMargin"
              src={NoDocuments}
              alt="404"
            ></img>
          </div>
        ) : (
            <>
              <div className="row mt-4" style={{ paddingLeft: "0.6%" }}>
                {documents.length > 0 &&
                  documents.map((document) => (
                    <div className="col-sm-5 col-md-4 col-xl-4 p-2">
                        <p>
                          <ParaphraseDocCard 
                            key={document._id}
                            data={document}
                            isEdit={true}
                            isAdmin={this.state.isAdmin}
                            addParaContent={(data)=>this.props.ParaContent(data)}
                          />
                        </p>
                     
                    </div>
                  ))}
              </div>

              {totalDocumentCount > 9 ? (
                <>
                  <div
                    className="ml-auto"
                    style={{ float: "right", margin: "2% 2% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={currentDocumentPage}
                      itemsCountPerPage={this.state.pageSize}
                      totalItemsCount={totalDocumentCount}
                      pageRangeDisplayed={5}
                      onChange={this.handleDocumentPageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                </>
              ) : (
                  ""
                )}

            </>
          )}


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  userDocuments: state.document.userDocuments,
  features: state.features.features,
});

const mapDispatchToProps = (dispatch) => ({
  setUserDocument: (document) => dispatch(setUserDocument(document)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ParaphraseDocs));
