import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import BreadCrumb from "./Cards/BreadCrumbs";
import Diff from "./ReactDiff";
import ReactTour from "./ReactTour";

class CompareDocument extends Component {
  state = {
    loading: false,
    draftData: {},
    originalData: {},
    leftCell: [],
    rightCell: [],
    reactTour: false,
    steps: [
      {
        selector: '.reactour-1',
        content: 'Left editor displays current document content',
        position: 'right',
      },
      {
        selector: '.reactour-2',
        content: () => (
          <div>
            <p>Right editor displays the document content of original user</p>
            <b>Comparison :</b>
            <p>
              1. Content displayed in <span style={{ backgroundColor: "salmon" }}>Red Background</span> is marked as <b>deleted/removed</b> content from original document.
            </p>
            <p>
              2. Content displayed in <span style={{ backgroundColor: "lightgreen" }}>Green Background</span> is marked as <b>new</b> content compared to current document.
            </p>
            <p>
              3. Content displayed in normal text denotes matching content
            </p>
          </div>
        ),
        position: 'left',
      },
    ],
  };

  async componentDidMount() {
    let { draftData, originalData } = this.props.location.state;
    console.log(draftData);
    console.log(originalData.data);
    let draftCell = await JSON.parse(draftData.onlyDocumentContent).blocks;
    let originalCell = await JSON.parse(originalData.data.onlyDocumentContent)
      .blocks;
    console.log(draftCell);
    console.log(originalCell);
    this.setState(
      {
        draftData: draftData,
        originalData: originalData.data,
      },
      () => this.cellDetails(draftCell, originalCell)
    );
  }

  cellDetails = async (draftCell, originalCell) => {
    let leftCell = draftCell;
    let rightCell = originalCell;

    if (leftCell.length < rightCell.length) {
      for (let i = leftCell.length; i < rightCell.length; i++) {
        let fakeCell = {
          key: Math.random().toString(20).substr(2, 5),
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
          done: false,
          reject: false,
        };
        await leftCell.push(fakeCell);
      }
    } else {
      for (let i = rightCell.length; i < leftCell.length; i++) {
        let fakeCell = {
          key: Math.random().toString(20).substr(2, 5),
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
          done: false,
          reject: false,
        };
        rightCell.push(fakeCell);
      }
    }

    this.setState({
      leftCell: leftCell,
      rightCell: rightCell,
    });
  };

  render() {
    let { currentUser } = this.props;
    let { draftData, leftCell, rightCell } = this.state;
    return (
      <>
        <div className="pt-2 pb-2">
          <ReactTour 
            steps={this.state.steps} 
            isOpen={this.state.reactTour} 
            closeTour={()=>this.setState({ reactTour: false })}
          />
          <div>
            <BreadCrumb
              l1="Dashboard"
              l2="Documents"
              l3="Compare Document"
              dashboardlink="/dashboard"
              documentLink="/mydocuments"
            />
            <div className="row">
              <div className="col-sm-6">
                <h1>Compare Document</h1>
              </div>
              <div className="col-sm-6">
                <i 
                  className="fa fa-question-circle mt-2 mr-5" 
                  aria-hidden="true"
                  data-toggle="tooltip"
                  title="Need Help ?"
                  style={{
                    cursor: "pointer",
                    color: "dodgerblue",
                    fontSize: "15px",
                    float: "right"
                  }}
                  onClick={()=>this.setState({ reactTour: true })}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <table
          className="table"
          border="0"
          style={{ minHeight: 100, width: "100%", marginBottom: "3%" }}
        >
          <tr>
            <th style={{ border: 0 }}>
              <div className="row">
                <div className="col-sm-1">
                  <Avatar alt="Cindy Baker" src={currentUser.display_picture} />
                </div>
                <div className="col-sm-11 pl-4 mt-2">
                  <span> {currentUser.username} </span>
                </div>
              </div>
            </th>
            <th style={{ border: 0, textAlign: "center" }}></th>
            <th style={{ border: 0 }}>
              <div className="row">
                <div className="col-sm-1">
                  <Avatar alt="Cindy Baker" src={draftData.ownerDP} />
                </div>
                <div className="col-sm-11 pl-4 mt-2">
                  <span>{draftData.ownerName}</span>
                </div>
              </div>
            </th>
          </tr>

          <tbody>
            <tr>
              <td
                style={{
                  border: "0px solid #f0f0f7",
                  backgroundColor: "#fff",
                  width: "40%",
                }}
              >
                {" "}
                <div
                  className="container"
                  style={{
                    marginTop: "3%",
                    textAlign: "justify",
                  }}
                >
                  <h2>Title : {draftData.documentName}</h2>
                </div>
              </td>
              <td style={{ border: "1px solid #f0f0f7" }}></td>
              <td
                className=" ml-2"
                style={{
                  border: "0px solid #f0f0f7",
                  backgroundColor: "#fff",
                  width: "40%",
                }}
              >
                <div
                  className="container"
                  style={{
                    marginTop: "3%",
                    textAlign: "justify",
                  }}
                >
                  <h2>Title : {draftData.documentName}</h2>
                </div>
              </td>
            </tr>

            {leftCell.map((editor, index) => (
              <tr>
                <td
                  className="reactour-1"
                  style={{
                    border: "0px solid #E0E1E3",
                    width: "40%",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    className="container"
                    style={{
                      marginTop: "3%",
                      textAlign: "justify",
                    }}
                  >
                    <text key={leftCell[index].key}>
                      {leftCell[index] && (
                        <Diff
                          inputA={leftCell[index].text}
                          inputB={leftCell[index].text}
                          type="words"
                          color={false}
                        />
                      )}
                    </text>
                  </div>
                </td>

                <td></td>

                <td
                  className="reactour-2"
                  style={{
                    border: "0px solid #E0E1E3",
                    width: "40%",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    className="container"
                    style={{
                      marginTop: "3%",
                      textAlign: "justify",
                    }}
                  >
                    <text key={rightCell[index].key}>
                      {rightCell[index] && (
                        <Diff
                          inputA={leftCell[index].text}
                          inputB={rightCell[index].text}
                          type="words"
                          color={true}
                          compare={true}
                        />
                      )}
                    </text>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(CompareDocument));
