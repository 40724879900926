import React, { Component } from 'react'
import ReactApexChart from "react-apexcharts";
export default class PlagiarismGraph extends Component {
    state ={
        series: [this.props.scoredata],
        source : [this.props.sourcedata],
        options: {
          chart: {
            height: 40,
            type: 'radialBar',
          },
          labels : ['Plagiarism'],
          fill: {
            colors: ['#f14c4c']
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '48%',
              }
            },
          },
         
        },
    };

    componentDidMount() {
      console.log("PlagiarismGraph===>",this.props);
    }

    componentDidUpdate(prevProps) {
      if (this.props.scoredata !== prevProps.scoredata) {
        this.setState({
          series: [this.props.scoredata],
          source : [this.props.sourcedata],
        })
      }
    }

    render() {
        return (
            <div>
                  <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={250} />
                    <h6>{this.state.series} % of your text matches {this.state.source} sources on the web or in archives of academic publications</h6>
            </div>
        )
    }
}
