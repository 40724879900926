import React, { Component } from "react";
import SideNavBar from "../SideNavBar";
import PriceDetail from "../PriceDetail";
import SubAdminNavBar from "../SubAdminSideBar";
import AdminNavBar from "../AdminSideNavBar";

import authService from "../../services/authService";
export default class PriceDetails extends Component {
  render() {
    const user = authService.getCurrentUser();
    console.log(user);
    if ( !user) {
      return <PriceDetail breadCrumb={false} />;
    }
    if (!user.isAdmin && user.isSubAdmin) {
      return (
        <SubAdminNavBar>
          <PriceDetail breadCrumb={true} />
        </SubAdminNavBar>
      );
    } else if (user.isAdmin && !user.isSubAdmin) {
      return (<AdminNavBar>
        <PriceDetail breadCrumb={true} />
      </AdminNavBar>
        );
    } else {
      return (
        <SideNavBar>
          <PriceDetail breadCrumb={true} />
        </SideNavBar>
      );
    }
  }
}
