import React, { Component } from "react";
import Loader from "../../utils/loader";
import "../Cards/DocumentCard.css";
import { Detector } from "react-detect-offline";
import authService from "../../services/authService";
import { Redirect } from "react-router-dom";
export default function(ComposedComponent) {
  class NetworkDetector extends Component {
    state = {};
    renderRedirect = () => {
      if (window.location.pathname === "/") {
        return true;
      }
      if (window.location.pathname.includes('/dashboard')) {
        if (!this.props.location.search) {
          return false;
        } else {
          return true;
        }
      }
      if (!authService.getCurrentUser()) {
        return true;
      }
    };
    render() {
      return (
        <React.Fragment>
          <Detector
            render={({ online }) => (
              <React.Fragment>
                {!online && <Loader loading={true} online={true} />}
              </React.Fragment>
            )}
          />
          {this.renderRedirect() && <Redirect to='/login'/>}
          <ComposedComponent {...this.props} />
        </React.Fragment>
      );
    }
  }

  return NetworkDetector;
}
