import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import { FcFolder } from "react-icons/fc";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./FolderCard.css"

export default class FolderCard extends Component {
  state = {
    folder: [],
    folderName: "",
    folderId: "",
    anchorEl: null,
    editModal: false,
  };

  async componentDidMount() {
    let { data } = this.props;
    this.setState({
      folder: data,
      folderName: data.folder_title,
      folderId: data._id,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        folder: this.props.data,
        folderName: this.props.data.folder_title,
        folderId: this.props.data._id,
      });
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    let { data, folderDate, isSelected, projectType } = this.props;
    const mdate = new Date(data.date);
    console.log(isSelected);

    const { anchorEl, folder, folderId, folderName } = this.state;
    return (
      <div
        className={
          projectType === "folderProject" && isSelected === data._id
            ? "cardBorder"
            : ""
        }
        style={{width: "100%"}}
      >
        {/* <Card className="pb-2">
          <CardHeader
            style={{ width: "200px", cursor: "pointer" }}
            avatar={
              <FcFolder
                size="20px"
                onClick={() => this.props.moveProject(data)}
              />
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon onClick={this.handleClick} />
              </IconButton>
            }
            title={data.folder_title}
            subheader={new Date(mdate)
              .toString()
              .split(" ")
              .splice(1, 3)
              .join(" ")}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem
              Data-toggle="modal"
              Data-target="#exampleModalCenter2"
              onClick={() => this.setState({ editModal: true, anchorEl: null })}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({ anchorEl: null });
                this.props.delete(data);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Card> */}

        <div className="folderd" style={{ backgroundColor: "white", height: "58px", borderRadius: "5px" }}>
          <div className="row">
            <div className="col-sm-3" style={{ marginLeft: "14px", marginTop: "15px", cursor: "pointer" }}>
              <FcFolder
                size="22px"
                onClick={() => this.props.moveProject(data)}
              />
            </div>
            <div className="col-sm-6" style={{ marginTop: "8px", marginLeft: "-16px", cursor: "pointer" }} onClick={() => this.props.moveProject(data)}><text>{data.folder_title}</text>  <br />
              <text style={{ fontSize: "12px", opacity: "0.5" }}>{new Date(mdate).toString().split(" ").splice(1, 3).join(" ")}</text> </div>
            <div className="col-sm-3" style={{ marginTop: "15px", cursor: "pointer" }}><MoreVertIcon onClick={this.handleClick} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                style={{ height: "170px", width: "300px" }}
              >
                <MenuItem
                  Data-toggle="modal"
                  Data-target="#exampleModalCenter2"
                  onClick={() => this.setState({ editModal: true, anchorEl: null })}
                >
                  Edit
                        </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.setState({ anchorEl: null });
                    this.props.delete(data);
                  }}
                >
                  Delete
                        </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        {/* Edit Modal */}

        <Modal
          show={this.state.editModal}
          onHide={() => this.setState({ editModal: false })}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Folder
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Folder Name</h4>
            <input
              type="text"
              className="form-control"
              // name="newName"
              // value={newName}
              // onChange={(e) => this.setState({ newName: e.target.value })}
              name="folderName"
              value={folderName}
              maxLength={20}
              onChange={(e) => this.setState({ folderName: e.target.value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ editModal: false })}>
              Close
            </Button>
            <Button
              onClick={() => {
                this.setState({ editModal: false });
                this.props.editFolder(folderName, folderId);
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
      </div>
    );
  }
}
