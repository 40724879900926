import React, { Component } from "react";
import PropTypes from "prop-types";
import { Popover, OverlayTrigger } from "react-bootstrap";
const jsdiff = require("diff");
const fnMap = {
  chars: jsdiff.diffChars,
  words: jsdiff.diffWords,
  sentences: jsdiff.diffSentences,
  json: jsdiff.diffJson,
  line: jsdiff.diffTrimmedLines,
};

class ReactDiff extends Component {
  render() {
    const Apop = (
      <Popover
        id="popover-basic"
        style={{ zIndex: "999", borderTop: "5px solid lightgreen" }}
      >
        <Popover.Content>
          <div style={{ overflow: "hidden" }}>
            <p
              style={{
                float: "left",
                height: "15px",
                width: "15px",
                backgroundColor: "lightgreen",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            ></p>{" "}
            <p style={{ float: "right" }}>
              {this.props.compare === true
                ? " New Content"
                : " Add New Changes"}
            </p>
          </div>
        </Popover.Content>
      </Popover>
    );

    const Rpop = (
      <Popover
        id="popover-basic"
        style={{ zIndex: "999", borderTop: "5px solid salmon" }}
      >
        <Popover.Content>
          <div>
            <div style={{ overflow: "hidden" }}>
              <p
                style={{
                  float: "left",
                  height: "15px",
                  width: "15px",
                  backgroundColor: "salmon",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              ></p>{" "}
              <p style={{ float: "right" }}>
                {this.props.compare === true
                  ? " Deleted Content"
                  : " Remove your content"}
              </p>
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );

    const { inputA, inputB, type, color, right, reject } = this.props;
    let diff = "";
    if (right === true) {
      diff = fnMap[type](inputB, inputB);
    } else if (reject === true) {
      diff = fnMap[type](inputA, inputA);
    } else {
      diff = fnMap[type](inputA, inputB);
    }

    return (
      <>
        {diff.map((part, index) => (
          <>
            {part.added ? (
              <OverlayTrigger
                trigger="hover"
                placement="right"
                overlay={Apop}
                rootClose={true}
              >
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "13px",
                    cursor: "pointer",
                    whiteSpace: "pre-wrap",
                    backgroundColor: "lightgreen",
                  }}
                  key={index}
                >
                  {part.value}
                </span>
              </OverlayTrigger>
            ) : part.removed ? (
              <OverlayTrigger
                trigger="hover"
                placement="right"
                overlay={Rpop}
                rootClose={true}
              >
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "13px",
                    cursor: "pointer",
                    whiteSpace: "pre-wrap",
                    backgroundColor: "salmon",
                  }}
                  key={index}
                >
                  {part.value}
                </span>
              </OverlayTrigger>
            ) : (
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "13px",
                  cursor: "pointer",
                  whiteSpace: "pre-wrap",
                }}
                key={index}
              >
                {part.value}
              </span>
            )}
          </>
        ))}
      </>
    );
  }
}

export default ReactDiff;
