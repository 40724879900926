import { combineReducers } from 'redux';


import userReducers from './user/user.reducer';
import documentReducer from './document/document.reducer';
import featureReducer from './features/features.redcuer';

export default combineReducers({
    user : userReducers,
    document : documentReducer,
    features : featureReducer,
})