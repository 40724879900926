import React from "react";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import authService from "../../services/authService";
import HomeSubscription from "../HomeSubscription";
import SubAdminNavBar from "../SubAdminSideBar";

const PricingPage = props => {
  const user = authService.getCurrentUser();
  console.log(user);
  if (!user.isAdmin  && !user.isSubAdmin) {
    return (
      <SideNavBar>
        <HomeSubscription />
      </SideNavBar>
    );
  } else  if(user.isAdmin && !user.isSubAdmin){
    return (
      <AdminNavBar>
        <HomeSubscription />
      </AdminNavBar>
    );
  }else {
    return (
  
      <SubAdminNavBar>
        <HomeSubscription />
      </SubAdminNavBar>
  
     );
  }
};

export default PricingPage;
