import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/plans`;
} else {
  apiEndpoint = `${herokuUrl}/plans`;
}

export async function getAllPlan() {
    const data = await httpService.get(`${apiEndpoint}/getAllPlan`);
    return data;
  }

export async function deletePlanById(_id){
  const data = await httpService.delete(`${apiEndpoint}/deletePlanById/${_id}`);
  return data;
}

export async function addPlan(data){
  const result = await httpService.post(`${apiEndpoint}/addPlan`, data);
  return result;
}

export async function updatePlanById(data){
  const result = await httpService.post(`${apiEndpoint}/updatePlanById`, data);
  return result;
}
  
export default {
    getAllPlan,
    deletePlanById,
    addPlan,
    
}