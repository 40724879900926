import http from "./httpService";
import {
  apiUrl,
  herokuUrl
} from "./config.json";
import httpService from "./httpService";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/texteditor`;
} else {
  apiEndpoint = `${herokuUrl}/texteditor`;
}
export async function saveDocs(saveDocument) {
  const {
    id
  } = await httpService.post(
    `${apiEndpoint}/postDocs`,
    saveDocument
  );
  return id;
}

// API - Get All Tags by User
export async function getAllTags() {
  const data = await http.get(`${apiEndpoint}/getallTagsByUserId`);
  return data;
}

// API - Get All Documents
export async function getAllDocuments() {
  const data = await http.get(`${apiEndpoint}/getDocs`);
  return data;
}

// API - Get Document by ID
export async function getDocumentbyId(id) {
  const data = await http.post(`${apiEndpoint}/getDocsById`, {
    _id: id
  });
  return data;
}

// API - Get All Documents by User ID
export async function getAllDocumentsByUserId(id) {
  const data = await http.post(`${apiEndpoint}/getDocsByUserId`, {
    _id: id
  });
  return data;
}

export async function editAllDocuments(editDocument) {
  const data = await http.post(`/editDocs`, editDocument);
  return data;
}

export async function spellCheck(data) {
  const res = await http.post(`http://localhost:4000/spellCheck`, data);
  return res;
}

export async function deleteDocuments(docId) {
  const data = await httpService.post(`${apiEndpoint}/deleteDocs`, {
    id: docId
  });
  return data;
}

export async function restoreDocuments(docId) {
  const data = await httpService.post(`${apiEndpoint}/restoreDocs`, {
    id: docId
  });
  return data;
}

export async function permanentDelete(doc) {
  const response = await httpService.post(`${apiEndpoint}/permanentDelete`, doc);
  return response;
}

export async function shareDocument(document) {
  const response = await httpService.post(
    `${apiEndpoint}/shareDocument`,
    document
  );
  return response;
}

export async function getAccess(drafts) {
  const data = await http.post(`${apiEndpoint}/getAccess`, {
    drafts
  });
  return data;
}

export async function getDeletedDocuments() {
  const data = await http.get(`${apiEndpoint}/getDeletedDocs`);
  return data;
}

export async function getSharedDocuments() {
  const data = await http.get(`${apiEndpoint}/getsharedWithMe`);
  return data;
}

export async function getError(_id) {
  const data = await http.get(`${apiEndpoint}/getError?_id=${_id}`);
  return data;
}

export async function autoSaveDocument(document) {
  const data = await http.post(`${apiEndpoint}/autoSaveDocs`, document);
  return data;
}

export async function deleteCurrentDocument(document) {
  const data = await http.post(`${apiEndpoint}/deleteCurrentDocs`, document);
  return data;
}

export async function uploadDocument() {
  const data = await http.post(`${apiEndpoint}/uploadDocument`);
  console.log("service")
  return data;
}

export async function generateAutoTag(data){
  const response = await http.post(`${apiEndpoint}/autoTagGenerator`,data);
  return response;
}

export default {
  saveDocs,
  getAllDocuments,
  getDocumentbyId,
  getAllDocumentsByUserId,
  editAllDocuments,
  shareDocument,
  deleteDocuments,
  getDeletedDocuments,
  getSharedDocuments,
  spellCheck,
  getAllTags,
  autoSaveDocument,
  deleteCurrentDocument,
  restoreDocuments,
  uploadDocument,
  getError,
  permanentDelete,
  generateAutoTag,
  getAccess
};
