import React from "react";

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    const { label, font } = this.props;

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {font || label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  // { label: "H1", style: "header-one" },
  // { label: "H2", style: "header-two" },
  // { label: "H3", style: "header-three" },
  // { label: "H4", style: "header-four" },
  // { label: "H5", style: "header-five" },
  // { label: "H6", style: "header-six" },
  // {label: 'Blockquote', style: 'blockquote'},
  {
    label: "UL",
    style: "unordered-list-item",
    font: <i className="fa fa-list" aria-hidden="true"></i>
  },
  {
    label: "OL",
    style: "ordered-list-item",
    font: <i className="fa fa-list-ol" aria-hidden="true"></i>
  },
  // {
  //   label: "Code Block",
  //   style: "code-block",
  //   font: <i className="fa fa-file-code-o" aria-hidden="true"></i>
  // }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          font={type.font}
        />
      ))}
    </div>
  );
};


var INLINE_STYLES = [
  {
    label: "Bold",
    style: "BOLD",
    font: <i className="fa fa-bold" aria-hidden="true"></i>
  },
  {
    label: "Italic",
    style: "ITALIC",
    font: <i className="fa fa-italic" aria-hidden="true"></i>
  },
  {
    label: "Underline",
    style: "UNDERLINE",
    font: <i className="fa fa-underline" aria-hidden="true"></i>
  },
  {
    label: "Monospace",
    style: "CODE",
    font: <i className="fa fa-code" aria-hidden="true"></i>
  },
  {
    label: "Link",
    style: "ANCHOR",
    font: <i id="anchorFa" className="fa fa-link" aria-hidden="true"></i>
  },
  // {
  //   label: "subscript",
  //   style: "SUBSCRIPT",
  //   children: (
  //     <div className="SubButton-button ToolbarButton-button">
  //       x<sub>2</sub>
  //     </div>
  //   )
  // },
  {
    label: "superscript",
    style: "SUPERSCRIPT",
    children: (
      <div className="SupButton-button ToolbarButton-button">
        x<sup>4</sup>
      </div>
    ),
    font: <i className="fa fa-superscript" aria-hidden="true"></i>
  }
];


const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          font={type.font}
        />
      ))}
    </div>
  );
};

export { InlineStyleControls, BlockStyleControls };
