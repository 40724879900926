import React, { Component } from "react";
import FAQ from "../FAQ";
class FAQPage extends Component {
  state = {};
  componentDidMount() {
    console.log(this.props.location.aboutProps);
  }
  render() {
    return <FAQ />;
  }
}

export default FAQPage;
