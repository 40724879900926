import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { convertFromRaw } from "draft-js";
import { withRouter } from "react-router-dom";
import lastModified from "../../utils/lastModfied";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import "../Cards/DocumentCard.css";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import "../../css/hover-min.css";
import Loader from "../../utils/loader";
import { PlagiarismContext } from "../../contexts/PlagiarismContext";
import plagiarismServices from "../../services/plagiarismServices";
// import plagDummyResponse from '../../test';

const useStyles = makeStyles({
  card: {
    maxWidth: 360,
  },
});

const PlagiarismCard = ({
  data,
  //   showDocs,
  isEdit,
  isAdmin,
}) => {
  const { addPlagCheckContent, addPlagResponse } = useContext(
    PlagiarismContext
  );
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  console.log(data.documentName);
  let title = data.documentName.slice(0, 14);
  title = data.documentName.length === title.length ? title : `${title}...`;
  let content = getTextFromEditorState(data.onlyDocumentContent, true);
  const mdate = new Date(data.date);
  const today = new Date();
  let [doctitle, setdoctitle] = useState("");

  const newCont = (data) => {
    let newContent = data + " ";
    let length = 100 - data.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };

  const checkPlag = async (data) => {
    setLoading(true);
    data = getTextFromEditorState(data.onlyDocumentContent, false);
    // const plagResponse = await plagiarismServices.checkPlagiarism();
    // const plagResponse =
    addPlagCheckContent(data);
    // addPlagResponse(plagDummyResponse);
    setLoading(false);
  };

  return (
    <div key={data._id} className="DocumentCardHover">
      <Card
        id="DocCard"
        className={classes.card}
        style={{ height: "100%", paddingBottom: "3%" }}
      >
        <CardHeader
          title={
            <h6
              component={"span"}
              gutterBottom
              style={{ fontWeight: "900", overflowWrap: "break-word" }}
            >
              {title}
            </h6>
          }
        ></CardHeader>
        <CardActionArea
        // onClick={() => showDocs(data)}
        >
          <CardContent style={{ height: 80 }}>
            <small
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ overflowWrap: "break-word", fontSize: "13px" }}
            >
              {newCont(content)}
            </small>
            <div
              className="MuiCardActions-root "
              style={{ display: "none" }}
            ></div>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <div className="pl-2">
            {isEdit ? (
              <small style={{ fontSize: "10px" }}>
                {lastModified(today, new Date(mdate))}
              </small>
            ) : (
              <small style={{ fontSize: "10px" }}>
                {lastModified(today, mdate)}
              </small>
            )}
          </div>

          <i
            style={{
              color: "orange",
            }}
            className="fa fa-star ml-3"
            aria-hidden="true"
          ></i>
          <p className="m-1">{data.ratings}</p>

          <div className="ml-auto pr-2">
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => checkPlag(data)}
              >
                CHECK PLAGIARISM
              </button>
              {/* <React.Fragment>
                <Button size="small" variant="outlined" onClick={() => checkPlag(data)}>CHECK PLAGIARISM</Button>
              </React.Fragment> */}
            </div>
          </div>
        </CardActions>
      </Card>
    </div>
  );
};

export default withRouter(PlagiarismCard);
