import React, { useState, useEffect } from 'react'
import { TextField, Typography } from '@material-ui/core';
import { Dropdown } from 'react-bootstrap'

export function InputText({ required = false, label, empty_string, submit, value = "", style,
    numberOnly,
    className, getValue, name, min = null, max = null, min_error = "", clear }) {

    let [value_, setvalue] = useState(value);
    const [helperText, sethelperText] = useState("");
    const [error, seterror] = useState(false);
    let [submited, setsubmited] = useState(submit);

    useEffect(() => {
        getValue({ [name]: "" })
        if (submit)
            onBlur_under()
    }, [submit]);

    // set the value for the text box
    useEffect(() => {
        if (value)
            setvalue(value)
    }, [value])

    // clear the text box
    useEffect(() => {
        if (clear)
            setvalue("");
    }, [clear])

    const onHandleChange = (val) => {
        if (numberOnly)
            value_ = numberOnlyCheck(val)
        else
            value_ = val;
        setvalue(value_);
        getValue({ [name]: "" })
        if (submited)
            onBlur_under()
    }

    const onBlur_under = () => {
        if (!required) {
            getValue({ [name]: value_ })
            return
        }
        seterror(true);
        if (!value_) {
            sethelperText(empty_string || `Please enter the ${label}`);
        }
        else {
            if (onValidation()) {
                seterror(false);
                getValue({ [name]: value_ });
                sethelperText("");
                // console.log("inside success")
            }

        }
        setsubmited(true);
    }

    const onValidation = () => {

        if (min) {
            if (value_.length <= min) {
                sethelperText(min_error || `${label} should be more then ${min} charcter`);
                return false;
            }

        }
        if (max) {
            if (value_.length < max)
                sethelperText(min_error || `${label} should be more then ${min} charcter`);
            return false
        }

        return true;
    }

    const numberOnlyCheck = (text) => {
        const pattern = /[^0-9]+/g;
        return text.replace(pattern, '')
    }

    return (
        <div>

            <TextField
                style={style}
                className={className}
                error={error}
                value={value_}
                // defaultValue={value_}
                required={required}
                helperText={helperText}
                label={label}
                onChange={(e) => onHandleChange(e.target.value)}
                onBlur={onBlur_under}
            />

        </div>
    )
}


export function DropDownComp({ errorSelect = "", selected, terms, handleFn, placeholder, className = '' }) {
    return (
        <Dropdown className={`${!selected && errorSelect} ${className} mr-5`} onMouseDown={(e) => { e.preventDefault() }}>
            <Dropdown.Toggle className="mydrop p-0" variant="white">
                {selected || placeholder}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{width:'500'}}>
                {
                    terms.map((term) => <Dropdown.Item
                        onMouseDown={() => handleFn({ type: 'drop_down', value: term })}  ><Typography variant="caption" >{term}</Typography></Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}


export default InputText