import React, { Component } from "react";
import Report from "../Report";
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
const ReportsPage = props => {
  return (
    <SideNavBar>
      <Report />
    </SideNavBar>
  )
};
export default NetworkDetector(ReportsPage);
