import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default class RadialGraph extends Component {
  state = {
    series: [this.props.radialstat.plagPercent],
    options: {
      chart: {
        height: 55,
        type: "radialBar",
      },
      fill: {
        colors: ["#1976d2"],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "45%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 4
            },
          },
        },
      },
      // labels: ['Percent'],
    },
  };

  render() {
    return (
      <div className="text-center">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={190}
        />
        <small>Plagiarized</small>
      </div>
    );
  }
}
