import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Button, Card } from "@material-ui/core";
import DefaultBlogImage from "../static/defaultBlogThree.jpg";
import BlogImage from "../static/blogfour.jpg";
import Defblog from "../static/blogssdef.jpg";
import { getTextFromEditorState } from "../utils/getTextFromEditorState";
import { ShareableLink } from "../services/blogServices";
import lastModified from "../utils/lastModfied";
import { withRouter } from "react-router-dom";
import { camelCase, paramCase } from "change-case";
import "./Item.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    margin: 1,
    maxWidth: 600,
    maxHeight: 400,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 1,
    display: "block",
    maxWidth: "90%",
    maxHeight: "100%",
  },
}));

function Item({ item, history, allBlogs }) {
  const classes = useStyles();
  const theme = useTheme();

  let content = getTextFromEditorState(item.onlyDocumentContent, true);

  const mdate = new Date(item.date);
  const today = new Date();
  // let blogDate = new Date(mdate).toString().split(" ").splice(1, 3).join(" ");
  const newCont = (item) => {
    let newContent = item + " ";
    let length = 100 - item.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };

  const viewBlog = async (data) => {
    console.log("viewBlog===>", data);
    history.push({
      pathname: `/${paramCase(data.urlValue)}`,
      state: { detail: data, blogs: allBlogs },
    });
    console.log(data);
    // let link = await ShareableLink(data._id, paramCase(data.documentName));
    // window.open(link, "_blank");
  };

  return (
    <div>
      <div className="card mb-4" id="blogcard">
        <div className="parent">
          {item.imageUrl.length > 0 ? (
            <div className="child">
              <img
                class="card-img-top"
                src={
                  "https://petblogbucket.s3.us-east-2.amazonaws.com/" +
                  item.imageUrl[0]
                }
                alt="BlogImage"
                style={{ height: "250px" }}
              />
            </div>
          ) : (
            <div className="child">
              <img
                className="card-img-top"
                src={Defblog}
                alt="BlogImage"
                style={{ height: "250px" }}
              />
            </div>
          )}
        </div>
        <div className="card-body" style={{ backgroundColor: "#f5f5f5" }}>
          <div className="row">
            <div className="col-sm-2 mt-5"></div>
            <div className="col-sm-10">
              <h5
                className="card-title"
                id="blogtitle"
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={() => viewBlog(item)}
              >
                {item.documentName}
              </h5>
              <p className="card-text">{newCont(content)}</p>
              <p className="card-text">
                <small className="text-muted">
                  Last updated {lastModified(today, mdate)}
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Item);
