import React from "react";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import { ToastContainer } from "react-toastify";
import "./LoginForm.css";
import { Link } from "react-router-dom";
import { getHomeCMS } from "../services/adminServices";
import { getPrivacyCMS} from "../services/adminServices";

class PrivacyPolicy extends React.Component {
  state = {
    cms: {},
  };

  async componentDidMount() {
    let cms = await getHomeCMS();
    this.setState({ cms: cms.data });
    console.log(this.state.cms);

    let cmsPrivacy = await getPrivacyCMS();
    this.setState({ cmsPrivacy: cmsPrivacy.data });
    console.log(this.state.cmsPrivacy);
  }

  render() {
    let { cmsPrivacy, cms } = this.state;

    return (
      <div>
        <ToastContainer />
        <div className="container" style={{ width: "100%" }}>
          <div
            className="row d-inline-flex p-3 text-white"
            style={{ width: "100%" }}
          >
            {/* Flex One */}
            <div
              className="col-md-6 p-4 border"
              style={{
                // backgroundColor: "#3b81f3",
                width: "100%",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height:"94vh"
              }}
            >
              {/* Flex One Content */}
              <div
                style={{
                  paddingLeft: "19.8%",
                  paddingRight: "14.6%",
                  paddingTop: "16%",
                  
                }}
              >
                <div className="row">
                  <div>
                    {/* <h3 className="text-center" style={headOneStyle}>
                    PROFESSIONAL
                    </h3>
                  <h3 style={headTwoStyle}>EDITING TOOL</h3> */}
                    <Link to="/">
                      <img
                        // src={image1}
                        // alt="image1"
                        className="petLogo"
                        alt="Group570"
                        src={
                          cms && cms.Navbar_logo
                            ? cms.Navbar_logo.Image
                            : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+571.png"
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      ></img>
                    </Link>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20%" }}>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "30px",
                      fontWeight: "500",
                      lineHeight: "1.35",
                      textAlign: "left",
                      color: "#ffffff",
                    }}
                  >
                    {cms && cms.Project_title
                      ? cms.Project_title
                      : "Welcome to Professional Editing Tool"}
                  </p>
                </div>

                <div className="row" style={{ marginTop: "7%" }}>
                  <p style={{ lineHeight: "1.72", textAlign: "justify" }}>
                    {cms && cms.Project_subtext
                      ? cms.Project_subtext
                      : `PET is the industry-leading document editing and team
                        collaboration software for Businesses, Educators,
                        Students and Researchers. PET, integration with other
                        apps and services, with the power of AI brings all the
                        state-of-the-art, extensive features which are available
                        in multiple writing tools. &nbsp;
                          With PET, you can create and edit new projects from
                          any device. Develop a writing education that meets
                          students’ and professionals’ contemporary writing
                          needs.`}
                  </p>
                </div>
              </div>
            </div>

            {/* Flex Two */}

            <div className="col-md-6 p-4 border" style={{ width: "100%",height:"94vh" }}>
              {/* Flex Two Content */}

              <div
                style={{
                  paddingLeft: "7%",
                  paddingRight: "7%",
                  paddingTop: "5%",
                  paddingBottom: "2%",
                }}
              >
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3
                      style={{
                        fontSize: "35px",
                        fontWeight: "bold",
                        lineHeight: "1.26",
                        color: "#43425d",
                      }}
                    >
                      Privacy Policy
                    </h3>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10%" }}>
                  <div className="col-md-12">
                    <p
                      style={{
                        // opacity: "0.5",
                        fontSize: "15px",
                        lineHeight: "1.72",
                        textAlign: "justify",
                        color: "black",
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "60vh",
                        padding: "5%",
                      }}
                    >
                        {cms && cmsPrivacy ? (
                        <>
                          {cmsPrivacy.map((privacy) => (
                            <ul>
                              <li>
                                {/* Question 1 */}
                                <b>{privacy.question}</b>
                              </li>

                              <ul style={{ paddingLeft: "5%" }}>
                                {privacy.answer.split("\n").map((ans) => (
                                  <li>{ans}</li>
                                ))}
                              </ul>
                              <br />
                            </ul>
                          ))}
                        </>
                      ) : (
                      <>
                      {/* Question 1 */}
                      <b>What information do we collect?</b>
                      <br /> We collect information from you when you register
                      on our website, place an order, create user content or
                      subscribe to our newsletter. <br />
                      <br />
                      {/* Question 2 */}
                      <b>Information provided by you:</b>
                      <br />
                      <br />
                      <ul>
                        <li>
                          Name, email, profile picture - When you register for
                          Professional Editing Tool (PET) we will ask for your
                          email. Through your profile settings you may
                          additionally provide a user name and profile picture.
                          If you login using a social network (Facebook,
                          LinkedIn or Bitbucket), we may ask permission to
                          access basic information from that account, such as
                          your name, email address and profile picture. You can
                          stop sharing information with us at any time using the
                          social network settings to disable access.
                        </li>
                        <br />
                        <li>
                          Payment information (Paid Users) - Payment information
                          is required to fulfil the transaction. If you pay
                          using a payment card at checkout, you will provide
                          your payment card number to Stripe® (our card payment
                          provider). The Stripe® privacy policy will apply to
                          this information. If you checkout using PayPal®, the
                          PayPal® privacy policy will apply to the payment
                          information you provide.
                        </li>
                        <br />
                        <li>
                          User Content - This includes documents or any other
                          content provided by you while using our website or
                          apps.
                        </li>
                        <br />
                      </ul>
                      {/* Question 3 */}
                      <b>Information automatically collected:</b>
                      <br />
                      <br />
                      <ul>
                        <li>
                          IP Address - For determining the location of the
                          customer – place of supply – as required by rules on
                          broadcasting, telecommunications and e-services.
                        </li>
                        <br />
                        <li>
                          Logging - It is very common for websites to record
                          diagnostic data in log files. This log data may
                          include the IP address, date, time, browser
                          information and cookie data.
                        </li>
                        <br />

                        <li>
                          Website Usage - To help understand and improve the
                          performance of our service, we collect statistics such
                          as how many users visit a page on our website or how
                          many times a link was clicked.
                        </li>
                        <br />

                        <li>
                          Cookies - We use cookies to understand and save your
                          preferences for future visits and compile aggregate
                          data about site traffic and site interaction so that
                          we can offer better site experiences and tools in the
                          future. Please see our cookie policy.
                        </li>
                        <br />
                      </ul>
                      {/* Question 4 */}
                      <b>What do we use your information for?</b>
                      <br />
                      <br />
                      <ul>
                        <li>
                          To authenticate users, personalize your experience,
                          improve customer service and process transactions.
                        </li>
                        <br />

                        <li>
                          To communicate with you about your use of our website
                          or apps, product announcements, software updates, as
                          well as respond to your requests for support.
                        </li>
                        <br />

                        <li>
                          To send periodic emails including direct marketing and
                          special offers about Professional Editing Tool (PET),
                          from which you can unsubscribe at any time. EU users:
                          We will only send you marketing emails with your prior
                          consent.
                        </li>
                        <br />
                      </ul>
                      {/* Question 5 */}
                      <b>Do we review your content?</b>
                      <br />
                      <br />
                      We never review your content, except in circumstances
                      where it’s required in order to: comply with the law,
                      enforce our site policies, protect our or others’ rights,
                      property, or safety.
                      <br />
                      <br />
                      We do not retain any rights to any of the documents or
                      other content uploaded into our system.
                      <br />
                      <br />
                      {/* Question 6 */}
                      <b>Do we disclose any information to outside parties?</b>
                      <br />
                      <br />
                      We do not sell, trade, or otherwise transfer to outside
                      parties your personally identifiable information. The only
                      exceptions to this rule are:
                      <br />
                      <br />
                      <ul>
                        <li>
                          Third parties who assist us in operating our website,
                          conducting our business, or servicing you, so long as
                          those parties agree to keep this information
                          confidential.
                        </li>
                        <br />

                        <li>
                          When we believe release is appropriate to comply with
                          the law, enforce our site policies, or protect ours or
                          others rights, property, or safety.
                        </li>
                        <br />

                        <li>
                          Non-personally identifiable visitor information may be
                          provided to other parties for marketing, advertising,
                          or other uses.
                        </li>
                        <br />
                      </ul>
                      {/* Question 7 */}
                      <b>Do we use cookies?</b>
                      <br />
                      <br />
                      Yes (Cookies are small files that a site or its service
                      provider transfers to your computer’s hard drive through
                      your Web browser (if you allow) that enables the sites or
                      service providers systems to recognize your browser and
                      capture and remember certain information).
                      <br />
                      <br />
                      We use cookies to understand and save your preferences for
                      future visits and compile aggregate data about site
                      traffic and site interaction so that we can offer better
                      site experiences and tools in the future.
                      <br />
                      <br />
                      {/* Question 8 */}
                      <b>Will we send you emails?</b>
                      <br />
                      <br />
                      Periodically we may send you emails about new features and
                      special offers. This may include information about product
                      and services from our business partners. You can
                      unsubscribe from these emails at any time by clicking the
                      “unsubscribe” link found in our emails or by changing your
                      account settings.
                      <br />
                      <br />
                      {/* Question 9 */}
                      <b>Do we collect information from children?</b>
                      <br />
                      <br />
                      We are in compliance with the requirements of COPPA
                      (Children’s Online Privacy Protection Act), we do not
                      collect any information from anyone under 13 years of age.
                      Our website, products and services are all directed to
                      people who are at least 13 years old or older.
                      <br />
                      <br />
                      {/* Question 10 */}
                      <b>Children's Online Privacy Protection Act Compliance</b>
                      <br />
                      <br />
                      We are in compliance with the requirements of COPPA
                      (Children’s Online Privacy Protection Act), we do not
                      knowingly collect any information from anyone under 13
                      years of age. Our website, products and services are all
                      directed to people who are at least 13 years old or older.
                      If we believe that personal information has been collected
                      from a child younger than 13 years of age, we will remove
                      it.
                      <br />
                      <br />
                      Please do not submit any personal information through our
                      website or apps if you are under the age of 13.
                      <br />
                      <br />
                      {/* Question 11 */}
                      <b>Where is my Information stored?</b>
                      <br />
                      <br />
                      Information that you transfer to our website and apps is
                      processed and stored in the United States. If you transfer
                      any Information to our website or apps, you agree to it
                      being hosted and accessed in the United States.
                      <br />
                      <br />
                      {/* Question 12 */}
                      <b>How secure is my Information?</b>
                      <br />
                      <br />
                      We implement a variety of security measures to maintain
                      the safety of your personal information when you place an
                      order or enter, submit, or access your personal
                      information. We offer the use of a secure server. All
                      supplied sensitive/credit information is transmitted via
                      Secure Socket Layer (SSL) technology and then encrypted
                      into our Payment gateway providers’ database only to be
                      accessible by those authorized with special access rights
                      to such systems and are required to keep the information
                      confidential.
                      <br />
                      <br />
                      During and after a transaction, your private information
                      (credit cards, social security numbers, financials, etc.)
                      are not stored on our servers (except the last 4 digits).
                      <br />
                      <br />
                      If we detect a system security breach, we may attempt to
                      contact you using your email address.
                      <br />
                      <br />
                      {/* Question 13 */}
                      <b>How can I delete my data?</b>
                      <br />
                      <br />
                      You can delete your data at any time by accessing the
                      account settings on our website and clicking the “Delete
                      Account” button.
                      <br />
                      <br />
                      {/* Question 14 */}
                      <b>How long is my data retained?</b>
                      <br />
                      <br />
                      We may keep some of your data for as long as it is
                      required to comply with our obligations including law, tax
                      and auditing.
                      <br />
                      <br />
                      {/* Question 15 */}
                      <b>
                        California Online Privacy Protection Act Compliance:
                      </b>
                      <br />
                      <br />
                      Because we value your privacy, we have taken the necessary
                      precautions to be in compliance with the California Online
                      Privacy Protection Act. We therefore will not distribute
                      your personal information to outside parties without your
                      consent. As part of the California Online Privacy
                      Protection Act, all users of our site may make any changes
                      to their information at any time by logging and clicking
                      on their user name and then 'Edit your user details' page.
                      <br />
                      <br />
                      {/* Question 16 */}
                      <b>What are my rights?</b>
                      <br />
                      <br />
                      Users in the EU have certain rights about their personal
                      data including the right to access, correct or delete data
                      we process through the use of our website or apps, you
                      can:
                      <br />
                      <br />
                      <ul>
                        <li>
                          Request a report of the personal data by using our
                          contact page.
                        </li>
                        <br />
                        <li>
                          Correct or delete your personal data using from our
                          account settings page. If you have any problems with
                          this let us know via the contact page.
                        </li>
                        <br />
                        <li>
                          Object to the processing your personal data. You can
                          request that we stop processing your personal data
                          (including to send marketing emails). Marketing emails
                          are only sent to users in the EU if we have consent to
                          do so. You can unsubscribe from marketing emails at
                          any time by clicking the “unsubscribe” link found in
                          our emails or by changing your account settings.
                        </li>
                        <br />
                        <li>
                          Complain to your supervisory authority. You also have
                          the right to lodge a complaint with the supervisory
                          authority of your country or EU member state about
                          Professional Editing Tool (PET)’s use of your personal
                          data.
                        </li>
                      </ul>
                      <br />
                      For any questions regarding this please contact us.
                      <br />
                      <br />
                      {/* Question 17 */}
                      <b>Online Privacy Policy Only:</b>
                      <br />
                      <br />
                      This online privacy policy applies only to information
                      collected through our website and not to information
                      collected offline.
                      <br />
                      <br />
                      {/* Question 18 */}
                      <b>Your Consent:</b>
                      <br />
                      <br />
                      By using our site, you consent to our privacy policy.
                      <br />
                      <br />
                      {/* Question 19 */}
                      <b>Changes to our privacy policy?</b>
                      <br />
                      <br />
                      If we decide to change our privacy policy, we will post
                      those changes on this page, and/or update the Privacy
                      Policy modification date below. This document was last
                      modified on 03 February 20.
                      <br />
                      <br />
                    </>
                      )}
                    </p>
                    
                  </div>
                </div>

                <div className="text-center">
                  <Link to="/">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        width: "30%",
                        marginTop: "10%",
                      }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
