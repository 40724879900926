import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
// import "./teamcard.css";

const faces = [
  "http://i.pravatar.cc/300?img=1",
  "http://i.pravatar.cc/300?img=2",
  "http://i.pravatar.cc/300?img=3",
  "http://i.pravatar.cc/300?img=4"
];
const color = ["#bff603", "yellow", "#80d4ff", "orange"];
const styles = muiBaseTheme => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  media: {
    paddingTop: "50.25%"
  },
  content: {
    textAlign: "center",
    padding: muiBaseTheme.spacing.unit * 2
  },
  divider: {
    margin: `${muiBaseTheme.spacing.unit * 2}px 0`
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.5
  },
  avatar: {
    backgroundColor: "#80d4ff",
    color: "black",
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {
      marginLeft: -muiBaseTheme.spacing.unit
    }
  }
});

function App({ classes, teamdata: detail, grpData, deleteMember }) {
  return (
    <div className="col-md-3 res-Team-card teamcard DocumentCardHover">
      <Avatar
        style={{ width: 55, height: 55 }}
        alt="Remy Sharp"
        src={detail.picture}
        className="teamDP"
      />
      <Card className={classes.card} style={{ height: 200 }} id="tapp">
        <CardContent className={classes.content}>
          <Typography
            className={"MuiTypography--heading"}
            variant={"h6"}
            gutterBottom
            id="teamMemName"
          >
            {detail.name}
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
            {detail.email}
          </Typography>
          <br />
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
            Phone: {detail.mobile || "xxxxxxxxxxx"}
          </Typography>
          <br />
          {/* <Divider className={classes.divider} light /> */}
          {/* <i
            className="fa fa-trash  "
            style={{ color: "#007bff", textAlign: "center"}}
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="right"
            title="Remove Member"
            onClick={deleteMember}
            data-dismiss="modal"
          ></i> */}

          <IconButton aria-label="settings">
            <i
              className="fa fa-trash"
              style={{ color: "#007bff", fontSize: "14px" }}
              aria-hidden="true"
              data-toggle="tooltip"
              data-placement="right"
              title="Remove Member"
              onClick={deleteMember}
              data-dismiss="modal"
            ></i>
          </IconButton>
          <Divider light />

          {grpData.length > 0
            ? grpData.slice(0, 5).map((group, i) =>
                group.members.map(member => (
                  <>
                    {member === detail.id && (
                      <Avatar
                        className={classes.avatar}
                        style={{
                          backgroundColor: `${
                            color[grpData.indexOf(group) % 4]
                          }`,
                          marginTop: "4%"
                        }}
                      >
                        <h5
                          style={{ marginTop: "9px" }}
                          data-toggle="tooltip"
                          title={group.name}
                        >
                          {group.name.charAt(0).toUpperCase()}
                        </h5>
                      </Avatar>
                    )}
                  </>
                ))
              )
            : ""}
        </CardContent>
      </Card>
    </div>
  );
}

export default withStyles(styles)(App);
// {/* {member.length > 0 ? (
//                         <Avatar style={{ backgroundColor: "#007bff" }}>
//                           <AddCircleOutlineIcon />
//                         </Avatar>
//                       ) : (
//                         ""
//                       )} */}
// <Avatar style={{ backgroundColor: "#007bff" }}>
//   <AddCircleOutlineIcon />
// </Avatar>
