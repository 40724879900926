import React, { Component } from "react";
import AfterRegistration from "../AfterRegistration";
class AfterRegistrationPage extends Component {
  render() {
    const { detail } = this.props.history.location.state;
console.log(detail)
    return (
     
        <AfterRegistration data={detail}/>
   
    );
  }
}

export default (AfterRegistrationPage);
