import React from "react";
import ReactDOM from "react-dom";
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react'
import Loader from './utils/loader';
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.render(
  <Provider store={store().store}>
    <PersistGate loading={<Loader/>} persistor={store().persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
