import React from "react";
import { useField } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import { OutlinedInput } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import star from "../../../static/noun.png";

export default function InputBoxOne({...props}) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <div>
      <OutlinedInput
        error={!!errorText}
        // helperText={errorText}
        {...field}
        style={{ height: "30px", width: "90%" }}
        id="input-with-icon-adornment"
        endAdornment={
          <InputAdornment position="end">
            <img alt="star" src={star} />
          </InputAdornment>
        }
      />
    </div>
  );
}
