import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Subscription from "./Subscription";
import { getAllPlan } from "../services/plans";
import { data } from "./PricePlans/data";
import BreadCrumb from "./Cards/BreadCrumbs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Loader from "../utils/loader";

function PricingPlan({ hide, currentUser }) {
  const [planList, setplanList] = useState([]);
  const [dashLink] = ["/admindashboard"];
  const [dashboardLink] = ["/subAdminManageUser"];
  const [loader,setLoader] = useState(true);

  useEffect(() => {
    initalCall();
  }, []);

  const initalCall = async () => {
    const data = await getAllPlan();
    if (data.status !== 200) return;

    const plans = data.plan;
    console.log("plans===>",plans);
    let html = plans.map((plan) => {
      const content = unOrderList(plan.content);
      const planName = plan.planName;
      const nameOfTerms = plan.nameOfTerms;
      const planMode = { mode: plan.planMode };
      const termDetails = convertToObj(plan.termDetails);
      const plan_id = plan._id;
      return {
        heading: planName,
        ul: content,
        btn_list: nameOfTerms,
        price_detail: termDetails,
        plan_mode: planMode,
        plan_id : plan_id,
      };
    });
    setplanList(html);
    setLoader(false);
  };

  const convertToObj = (termDetails) => {
    let termObj = termDetails.reduce((newObj, obj) => {
      const { term, price, user_count } = obj;
      if (!newObj[obj.term]) {
        newObj = { ...newObj, [term]: [] };
      }
      let term_arr = newObj[term];
      term_arr.push({ price, user_count });
      return newObj;
    }, {});

    return termObj;
  };

  const unOrderList = (contentObj) => {
    if (contentObj) {
      const ul = Object.keys(contentObj).map((key,index) => {
        if (key.includes("Subscription Cost")) {
          let get_data = contentObj[key];
          if (typeof get_data == "object") {
            const lh = <lh key={index}>{key}</lh>;
            const li = get_data.map((text,index) => <li key={index}>{text}</li>);
            return (
              <ul key={index}>
                {" "}
                {lh} {li}{" "}
              </ul>
            );
          }
        }
      });
      return ul;
    }
  };

  return (
    <div>
     
      {!hide && (
        <div className="d-flex justify-content-between align-items-center">
          {currentUser.isAdmin ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Subcriptions"
              dashboardlink={dashLink}
            />
          ) : currentUser.isSubAdmin ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Subcriptions"
              dashboardlink={dashboardLink}
            />
          ) : (
            <>
             <Loader loading={loader} />
            <BreadCrumb
              l1="Home"
              l2="Subcriptions"
              dashboardlink="dashboard"
            />
            </>
          )}

          <div>
            {currentUser && currentUser.isAdmin ? (
              <Link to={"/addsubscription"}>
                <button className="btn btn-primary">
                  <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                  Manage Plans
                </button>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      {currentUser && currentUser.isAdmin ? (
        <h1 className="text-center" style={{ color: "#3e80f1" }}>Plans</h1>
      ) : (
        <h1 className="text-center" style={{ color: "#3e80f1" }}>Select your plan</h1>
      )}

      <div className="row">
        {planList.map((obj,index) => {
          return (
            <Subscription
              key={index}
              price_detail={obj.price_detail}
              plan_id = {obj.plan_id}
              planName={obj.heading}
              planList={obj.ul}
              btn_list={obj.btn_list}
              plan_mode={obj.plan_mode}
              hide={hide}
            />
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PricingPlan));
