import React, { Component } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import { toast, ToastContainer } from "react-toastify";
import "./ReviewRating.css";
import Loader from "../utils/loader";
import AdminServices from "../services/adminServices";
import CommentCard from "../components/Cards/AdminCommentsCard";
import _ from 'lodash';

export class ReviewRating extends Component {
  state = {
    loading: true,
    dashlink: "./admindashboard",
    comments: [],
    filterComments: [],
    DocumentList: [],
    ratings: "all",
    search: "",
  };

  async componentDidMount() {
    await this.getallComments();
  }

  getallComments = async () => {
    let { Document_Comment, Blog_Comments, status } = await AdminServices.getAllComments();
    let data = _.concat(Document_Comment, Blog_Comments);

    if (status === 200) {
      this.getDocumentList(data);
      this.setState({
        comments: data,
        filterComments: data,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      toast.warn("No Comments Found !");
    }
  };

  getDocumentList = async (data) => {
    let DocumentList = [];
    let DocumentListType = [];
    let belongs = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i].documentID) {
        if (DocumentList.includes(data[i].documentID.documentName)) {
          continue;
        } else {
          DocumentList.push(data[i].documentID.documentName);
          DocumentListType.push((data[i].documentType).toUpperCase());
        }
      } else {
        belongs = 1;
      }
    }

    if (belongs === 1) {
      DocumentList.push("Additional Reviews");
    }

    this.setState({ DocumentList: DocumentList, DocumentListType: DocumentListType });
    console.log(DocumentList);
  };

  removecomment_ = async (id, belongsTo, rating, replies, documentType) => {
    let data = {
      document_id: id,
      comment_id: belongsTo,
      commentRating: rating,
      commentReplies: replies,
      documentType: documentType,
    };
    console.log(data);
    this.setState({ loading: true });
    // const res = await AdminServices.removecomment(id, belongsTo);
    const res = await AdminServices.removecomment(data);
    if (res.status === 200) {
      toast.success(res.message);
      this.getallComments();
    } else {
      toast.error("Oops something went wrong..!");
      this.setState({ loading: false });
    }
  };

  handleSearch = (e) => {
    let comments = this.state.comments;
    this.setState({ search: e.target.value });

    if (e.target.value === "") {
      if (this.state.ratings == "all") {
        this.getDocumentList(this.state.comments);
        this.setState({
          filterComments: this.state.comments,
        });
      } else {
        let result = comments.filter((member) => {
          if (member.postedBy && member.rating == this.state.ratings) {
            return member;
          }
        });
        this.getDocumentList(result);
        this.setState({ filterComments: result });
      }
    } else {
      let value = e.target.value;

      let result1 = [];
      result1 = comments.filter((member) => {
        // console.log(member);
        if (this.state.ratings != "all") {
          if (
            member.postedBy &&
            member.rating == this.state.ratings &&
            member.postedBy.username
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
          ) {
            return member;
          }
        } else {
          if (
            member.postedBy &&
            member.postedBy.username
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1
          ) {
            return member;
          }
        }
      });

      this.getDocumentList(result1);
      this.setState({ filterComments: result1 });
    }
  };

  handleRatingFilter = (e) => {
    let selectedRating = e;
    this.setState({ ratings: selectedRating });
    let comments = this.state.comments;
    let search = this.state.search;

    if (selectedRating === "all") {
      if (search === "") {
        this.getDocumentList(comments);
        this.setState({ filterComments: this.state.comments });
      } else {
        let result = comments.filter((member) => {
          if (
            member.postedBy &&
            member.postedBy.username
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
          ) {
            return member;
          }
        });
        this.getDocumentList(result);
        this.setState({ filterComments: result });
      }
    } else {
      if (search === "") {
        let filteredComments = comments.filter(
          (comment) => comment.rating == selectedRating
        );
        this.getDocumentList(filteredComments);
        this.setState({ filterComments: filteredComments });
      } else {
        let result1 = comments.filter((member) => {
          if (
            member.postedBy &&
            member.rating == selectedRating &&
            member.postedBy.username
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
          ) {
            return member;
          }
        });
        this.getDocumentList(result1);
        this.setState({ filterComments: result1 });
      }
    }
  };

  refreshContent = () => {
    this.getallComments();
  };

  render() {
    const { dashlink, filterComments, DocumentList, DocumentListType } = this.state;

    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />
        {/* <div className="container" style={{ background: "#f0f0f7" }}> */}
          {/* <BreadCrumb
            l1="Dashboard"
            l2=" Manage Reviews"
            dashboardlink={dashlink}
          /> */}

          <div className="row">
            <div className="col-md-4">
              <h4 className="ft_lg" style={{ color: "#3e80f1" }}>Manage Reviews</h4>
            </div>
            <div className="col-md-6">
              <input
                className="form-control"
                id="reviewSearch"
                name="searchQuery"
                onChange={(e) => this.handleSearch(e)}
                type="text"
                placeholder="&#xF002; User Name..."
                aria-label="Search"
                style={{
                  fontFamily: "FontAwesome",
                  border: "none",
                  width: "80%",
                  marginLeft: "-16%",
                }}
              />
            </div>

            <div className="col-md-2">
              <select
                className="browser-default custom-select dropdown-primary"
                id="starSelect"
                // ref="rating"
                onChange={(e) => this.handleRatingFilter(e.target.value)}
                style={{
                  marginLeft: "-13%",
                }}
              >
                <option value="all" selected>
                  All Reviews
                </option>
                <option value="1" id="soflow-color">
                  1 star
                </option>
                <option value="2" id="soflow-color">
                  2 star
                </option>
                <option value="3" id="soflow-color">
                  3 star
                </option>
                <option value="4" id="soflow-color">
                  4 star
                </option>
                <option value="5" id="soflow-color">
                  5 star
                </option>
              </select>
            </div>
          </div>


          {DocumentList.length > 0 ? (
            <>
              {DocumentList.map((document, index) => (
                <div className="row mt-2">
                  <div className="col-md-12 mt-2 ">
                    <div className="accordion" id="accordionExample">
                      <div className="card mt-3">
                        <div
                          className="card-header"
                          id="headingOne"
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                          style={{ cursor: "pointer" }}
                        >
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <strong>{`${DocumentListType[index]} : ${document}`}</strong>
                            </button>
                          </h2>
                        </div>

                        <div
                          id={`collapse${index}`}
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            {/* <div className="container p-1"> */}
                              <div className="row">
                                <div className="ml-5 mt-5">
                                  {filterComments.map((comment) => (
                                    <>
                                      {comment.documentID &&
                                        comment.documentID.documentName ===
                                          document && (
                                          <CommentCard
                                            data={comment}
                                            delete={this.removecomment_}
                                            adminPost={this.refreshContent}
                                          />
                                        )}
                                    </>
                                  ))}

                                  {document === "Additional Reviews" &&
                                    filterComments.map((comment) => (
                                      <>
                                        {!comment.documentID && (
                                          <CommentCard
                                            data={comment}
                                            delete={this.removecomment_}
                                            adminPost={this.refreshContent}
                                          />
                                        )}
                                      </>
                                    ))}
                                </div>
                              </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h1 className="mt-5" style={{ textAlign: "center" }}>
              No Reviews Found
            </h1>
          )}
        {/* </div> */}
      </div>
    );
  }
}

export default ReviewRating;
