import React, { createContext, useState } from 'react';
export const PlagiarismContext = createContext();

const PlagiarismContextProvider = (props) => {
    const [plagCheckContent, setplagCheckContent] = useState('');
    const [plagResponse, setplagResponse] = useState({});

    const addPlagCheckContent = (content) => {
        setplagCheckContent(content);
    }

    const addPlagResponse = (response) => {
        setplagResponse(response);
    }

    return (
        <PlagiarismContext.Provider value={{ plagCheckContent, addPlagCheckContent,addPlagResponse }}>
            {props.children}
        </PlagiarismContext.Provider>
    );
}

export default PlagiarismContextProvider;
