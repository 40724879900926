import React from "react";

const SearchBox = ({ value, onSearch, placeholder }) => {
  return (
    <input
      className="form-control "
      name="searchQuery"
      onChange={onSearch}
      type="text"
      // placeholder="&#xF002; Search Document"
      placeholder={placeholder}
      aria-label="Search"
      style={{
        fontFamily: "FontAwesome",
        border: "none",
        float: "right"
      }}
    />
  );
};

export default SearchBox;
