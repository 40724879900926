import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"

const SEO = ({ description, title, url,keywords }) => {
    document.getElementsByTagName("META")[1].content=description;
    document.getElementsByTagName("META")[2].content=keywords;
    // document.title =title; 
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords}/>
            <title>{title}</title>
            <link rel="canonical" href={url} />
        </Helmet>
    );
}
SEO.defaultProps = {
    url: `https://www.professionaleditingtool.com`,
    title: 'Professional Document Editing | Team Collaboration Services - Professional Editing Tool',
    description: `Professional Editing Tool (PET) provides professional editing and team collaboration services to all business professionals, students, educators, and writers. Our PET document editing and team collaboration makes your writing easy. We provide 24*7 support.`,
    keywords:`PET,Grammarchecker,Citation,Paraphrase`
}
export default SEO;
