import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dashboard from "@material-ui/icons/Equalizer";
import Review from "@material-ui/icons/Chat";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import logo from "../static/Group 570.png";
import { useHistory } from "react-router-dom";
import authService from "../services/authService";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import PaymentIcon from "@material-ui/icons/Payment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { getProfile } from "../services/userService";
import "./Navbar.css";
import "./AdminSideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationServices from "../services/notficationService";
import { getHomeCMS } from "../services/adminServices";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarB: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AdminHeader(props) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [emailId, setemailId] = useState("");
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setAdmin] = React.useState(false);
  const [notifications, setnotifications] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cms, setCms] = useState({});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCms = async () => {
    const { currentUser, setCurrentUser } = props;
    let cms = await getHomeCMS();
    setCms(cms.data);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };
  function handleNavigation(route) {
    history.push(`/${route}`);
  }

  useEffect(() => {
    const user = authService.getCurrentUser();
    //console.log(user);
    if (user) {
      if (user.isAdmin === true) {
        setAdmin(true);
      }
    }
    console.log(isAdmin);
  });

  useEffect(() => {
    displyPic();
    getNotifications();
    getCms();
  }, []);

  const getNotifications = async () => {
    let notifications = await NotificationServices.getnotifications();
    setnotifications(notifications);
  };

  const displyPic = async () => {
    let user;
    const { currentUser, setCurrentUser } = props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    console.log("users", user);
    const { display_picture } = user;
    setProfilePic(display_picture);
    // setemailId(user.data.email);
    // setUser(user.data.username)
    setemailId(user.email);
    setUser(user.username);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const notfication_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content
        style={{
          overflowY: "auto",
          maxHeight: "40vh",
          // scroll: "auto",
        }}
      >
        <NotificationCard data={notifications} />
      </Popover.Content>
    </Popover>
  );

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/accountsettings") && "background-grey"}
          >
            <Typography>Account Settings</Typography>
          </MenuItem>
        </NavLink>
        <NavLink
          to={{ pathname: "/pricing" }}
          style={{ color: "black", paddingTop: "2px" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/pricing") && "background-grey"}
          >
            Subscriptions
          </MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/invoices" }} style={{ color: "black" }}>
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/invoices") && "background-grey"}
          >
            Invoices
          </MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );
  const getThemeSwitch = () => {
    if (window.location.pathname.match("/accountsettings")) {
      return null;
    }
    return <GenericThemeSwitch />;
  };

  function isUrlMatch(url) {
    if (window.location.pathname.match(url)) {
      return true;
    }
    return false;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToastContainer />
      {getThemeSwitch()}
      <AppBar
        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            style={{ color: "#4d4f5c" }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap style={{ color: "#4d4f5c" }}>
            <p>
              PROFESSIONAL <small>EDITING TOOL</small>
            </p>
          </Typography> */}
          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              <text
                style={{
                  fontFamily: "ProximaNova-Semibold",
                  lineHeight: "1.18",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#3d83fa",
                  // backgroundColor: "#99ccff",
                }}
              >
                PROFESSIONAL
              </text>{" "}
              <small
                style={{
                  fontFamily: "ProximaNova-Light",
                  lineHeight: "1.18",
                  fontSize: "22px",
                  fontWeight: 300,
                  color: "#3d83fa",
                }}
              >
                EDITING TOOL
              </small>
            </p>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: "6%" }}>
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={openContactUs}
              >
                <ContactMailIcon />
              </IconButton> */}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={notfication_popover}
                rootClose={true}
              >
                <IconButton
                  style={{ color: "#4d4f5c" }}
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge
                    badgeContent={
                      notifications.notificationsCount === 0
                        ? "0"
                        : notifications.notificationsCount
                    }
                    color="secondary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </OverlayTrigger>
            </div>
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <Avatar
                    aria-label="account of current user"
                    aria-controls={menuId}
                    onClick={handleProfileMenuOpen}
                    aria-haspopup="true"
                    alt="Remy Sharp"
                    src={profilePic ? profilePic : avatar}
                    id="profilePic"
                    className={`${classes.avatar} profilePic point`}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={user}
                    color="inherit"
                  />
                </OverlayTrigger>
              </Grid>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={notfication_popover}
              rootClose={true}
            >
              <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge
                  badgeContent={
                    notifications.notificationsCount === 0
                      ? "0"
                      : notifications.notificationsCount
                  }
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <MoreIcon /> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt="Remy Sharp"
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={emailId}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        // open={open}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        id="normalsidenav"
      >
        <div className={classes.toolbarB} style={{ backgroundColor: "#3e80f1" }}>
          <img
            alt="logo"
            className="img-fluid lcss"
            src={
              cms && cms.Navbar_logo
                ? cms.Navbar_logo.Image
                : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+570.png"
            }
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
            }}
            // src={logo}
          ></img>

          <IconButton className="p-1" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "#fff" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
        </div>
        <Divider />
        {/* list starts */}
        <List className="drawerMenu pointer" id="menuList">
          {/* Link 1 */}
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/subAdminManageUser" }}
          >
            <ListItem
              className={isUrlMatch("/subAdminManageUser") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Dashboard"
                style={{ color: "black" }}
              >
                <PeopleAltIcon
                  className={isUrlMatch("/subAdminManageUser") ? "iconCol" : "hvr-pulse"}
                />
              </ListItemIcon>
              <Typography>

              Manage Users
              </Typography>
            </ListItem>
          </NavLink>
          ​{/* Link 2 */}
          {/* <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageuser" }}
          >
            <ListItem className={isUrlMatch("/manageuser") && "background-red"}>
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title=" Manage Users"
              >
                <PeopleAltIcon
                  className={isUrlMatch("/manageuser") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Users
            </ListItem>
          </Link> */}
          ​{/* Link 3 */}
          {/* <Link className="sidenavbar-textlink" to={{ pathname: "/pricing" }}>
            <ListItem className={isUrlMatch("/pricing") && "background-red"}>
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Manage Subscription"
              >
                <AttachMoneyIcon
                  className={isUrlMatch("/pricing") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Subscription
            </ListItem>
          </Link> */}
          ​{/* Link 4 */}
          {/* <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageinvoices" }}
          >
            <ListItem
              className={isUrlMatch("/manageinvoices") && "background-red"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Manage Invoices"
              >
                <ReceiptIcon
                  className={isUrlMatch("/manageinvoices") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Invoices
            </ListItem>
          </Link> */}
          {/* <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/managereviews" }}
          >
            <ListItem
              className={isUrlMatch("/managereviews") && "background-red"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Reviews"
              >
                <Review
                  className={isUrlMatch("/managereviews") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Reviews
            </ListItem>
          </Link> */}
        </List>
        {/* list over */}
        {/* <Divider /> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
