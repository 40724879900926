import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { convertFromRaw } from "draft-js";
import { withRouter } from "react-router-dom";
import lastModified from "../../utils/lastModfied";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import "./DocumentCard.css";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";

import Avatar from "@material-ui/core/Avatar";
import dateFn from "date-fn";
import CardHeader from "@material-ui/core/CardHeader";
import "../../css/hover-min.css";
import { getProfile } from "../../services/userService";
//import { withRouter } from "react-router-dom";
// state = {
//   documentName: ""
// };

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const DocumentCard = ({
  data,
  editDocs,
  shareDocs,
  deleteDocs,
  showDocs,
  isEdit,
  download,
  isAdmin,
  restoreDocs,
  permanentDelete,
  hide,
  handleTeamCollab,
}) => {
  console.log(isAdmin);
  const classes = useStyles();
  console.log(data.documentName);
  let title = data.documentName.slice(0, 14);
  title = data.documentName.length === title.length ? title : `${title}...`;
  let content = getTextFromEditorState(data.onlyDocumentContent,true);
  console.log(content);
  const mdate = new Date(data.date);
  const today = new Date();
  let [doctitle, setdoctitle] = useState("");

  const setDownload = async (name, id) => {
    await setdoctitle(name);
    console.log(doctitle);
  };

  const newCont = (data) => {
    let newContent = data + " ";
    let length = 100 - data.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };

  return (
    <div key={data._id} className="DocumentCardHover">
      <Card 
        id="DocCard" 
        // className={classes.card} 
        style={{ height: "100%" }}
      >
        <CardHeader
          // avatar={<Avatar aria-label="recipe" src={data.ownerDP}></Avatar>}
          title={
            <h6
              component={"span"}
              gutterBottom
              style={{ fontWeight: "900", overflowWrap: "break-word" }}
            >
              {title}
            </h6>
          }
          subheader={
            isEdit ? (
              <small style={{ fontSize: "10px" }}>
                {lastModified(today, new Date(mdate))}
              </small>
            ) : (
              <small style={{ fontSize: "10px" }}>
                {lastModified(today, mdate)}
              </small>
            )
          }
          action={
            <div className="p-2">
              {!isAdmin &&
              data.activeDoc === true &&
              data.pendingDocuments.length > 0 ? (
                <Tooltip title="Collaboration">
                  <i
                    className="fa fa-asterisk blurb-ripple-out point"
                    style={{ fontSize: "11px", color: "#00e396" }}
                    // data-toggle="tooltip"
                    // title="Collaboration"
                    onClick={() => handleTeamCollab(data)}
                    aria-hidden="true"
                  ></i>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          }
        ></CardHeader>
        <CardActionArea onClick={() => showDocs(data)}>
          <CardContent style={{ height: 80 }}>
            {/* <h6
              component={"span"}
              gutterBottom
              style={{ fontWeight: "900", overflowWrap: "break-word" }}
            >
              {title}
            </h6> */}
            {/* overflow-wrap  wordWrap   \xa0  newCont*/}
            <small
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ overflowWrap: "break-word", fontSize: "13px" }}
            >
              {newCont(content)}
            </small>
            {/* <p
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ overflowWrap: "break-word" }}
            >
              {newCont(content)}
            </p> */}
            {/* </Link> */}
            <div
              className="MuiCardActions-root "
              style={{ display: "none" }}
            ></div>
          </CardContent>
        </CardActionArea>

        <CardActions>
          {/* <small>last modified: {mdate}</small> */}
          {/* {isEdit ? (
            <small style={{ fontSize: "10px" }}>
              {lastModified(today, new Date(mdate))}
            </small>
          ) : (
            <small style={{ fontSize: "10px" }}>
              {lastModified(today, mdate)}
            </small>
          )} */}

          <i
            style={{
              color: "orange",
            }}
            className="fa fa-star ml-3"
            aria-hidden="true"
          ></i>
          <p className="m-1">{data.ratings}</p>
          {/* <small>last modified:{dateFn.date(mdate, 110)}</small> */}
          {isEdit ? (
            <div className="ml-auto text-primary ft_lg">
              <div>
                {!isAdmin && (
                  <React.Fragment>
                    {!data.readOnly && (
                      <Tooltip title="Edit">
                        <i
                          className="fa fa-pencil-square-o mr-3 point"
                          onClick={editDocs}
                          // id="popmodal"
                          // data-toggle="tooltip"
                          // title="Edit"
                          aria-hidden="true"
                        ></i>
                      </Tooltip>
                    )}

                    <Tooltip title="Share">
                      <i
                        className="fa fa-share-square-o mr-3 point"
                        onClick={shareDocs}
                        // data-toggle="tooltip"
                        // title="Share"
                        aria-hidden="true"
                      ></i>
                    </Tooltip>

                    <Tooltip title="Download">
                      <i
                        className="fa fa-download mr-3 point"
                        onClick={download}
                        aria-hidden="true"
                        data-toggle="modal"
                        data-target="#myModal"
                      ></i>
                    </Tooltip>
                  </React.Fragment>
                )}

                <Tooltip title="Delete">
                  <i
                    className="fa fa-trash mr-3 point"
                    // data-toggle="tooltip"
                    // title="Delete!"
                    onClick={() => deleteDocs(data)}
                    aria-hidden="true"
                  ></i>
                </Tooltip>
              </div>
            </div>
          ) : hide ? (
            ""
          ) : (
            <div className="ml-auto text-primary ft_lg">
              <Tooltip title="Restore">
                <RestoreFromTrashIcon
                  className="point mr-3"
                  style={{ fontSize: "25px" }}
                  // data-toggle="tooltip"
                  // title="Restore"
                  onClick={() => restoreDocs(data)}
                />
              </Tooltip>

              <Tooltip title="Permanent Delete">
                <DeleteIcon
                  className="point mr-3"
                  // data-toggle="tooltip"
                  // title="Permanent Delete"
                  style={{ fontSize: "25px" }}
                  onClick={() => permanentDelete(data)}
                />
              </Tooltip>
            </div>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default withRouter(DocumentCard);
