import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import authService from "../services/authService";
import Group244 from "../static/Group244.png";
import Group457 from "../static/Group457.png";
import { Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Router from "next/router";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  dense: {
    marginTop: 19
  }
}));

const headOneStyle = {
  fontSize: "42px",
  lineheight: "0.88",
  textAlign: "left",
  color: "#ffffff"
};

const headTwoStyle = {
  fontSize: "23px",
  fontWeight: "300",
  lineHeight: "0.91",
  textAlign: "left",
  color: "#ffffff"
};

function ResetPass({ history }) {
  const classes = useStyles();
  const [values, setValues] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [cpasserrors, setcpassErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [disable, setdisable] = useState(true);

  async function handlePassword(e) {
    let pass = e.target.value;
    if (pass.length > 0) {
      if (pass.length <= 7) {
        setdisable(true);
        setErrors("Password contains minimum 8 Characters");
      } else {
        setErrors("");

        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;

        if (!pass.match(upperCaseLetters)) {
          setdisable(true);
          setErrors("Password contains at least 1 Capital letter");
        } else {
          setErrors("");
          if (!pass.match(numbers)) {
            setdisable(true);
            setErrors("Password contains at least 1 number");
          } else {
            setErrors("");
            setPassword(pass);
            setdisable(false);
          }
        }
      }
    } else {
      setErrors("");
    }
  }

  async function onClickResetPassword() {
    setLoading(true);
    let user = {
      email: sessionStorage.getItem("ForgotEmail"),
      password: password,
      cpassword: confirmPassword
    };
    console.log(user);
    if (user.cpassword === "") {
      setLoading(false);
      // errors("Password Field Cant be Empty");
      setcpassErrors("Password Field Cant be Empty");
    } else if (user.cpassword.length <= 7) {
      setLoading(false);
      setcpassErrors("Password Must Contain at Least 8 Characters");
    } else if (user.password !== user.cpassword) {
      setLoading(false);
      setcpassErrors("Password & Confirm Password Field Should Match");
    } else {
      // console.log(user);
      const result = await authService.resetPassword(user);
      if (result.status === "good") {
        setLoading(false);
        toast.success(result.message);
        history.push("/login");
      } else {
        setLoading(false);
        toast.error(result.message);
      }
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div>
      <Loader loading={loading} />
      <div className="container mt-3" style={{ width: "100%" }}>
        <div className="d-inline-flex p-3 text-white" style={{ width: "100%" }}>
          {/* Flex One */}
          {/* <ToastContainer /> */}
          <div
            className="p-4 border"
            style={{
              // backgroundColor: "#3b81f3",
              width: "100%",
              backgroundImage: `url(${Group244})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
          >
            {/* Flex One Content */}
            <div
              style={{
                paddingLeft: "19.8%",
                paddingRight: "14.6%",
                paddingTop: "30%",
                paddingBottom: "30%"
              }}
            >
              <div className="row">
                <div>
                  <Link to="/">
                    <img
                      src={Group457}
                      alt="Group457"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                      }}
                    ></img>
                  </Link>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20%" }}>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "30px",
                    fontWeight: "500",
                    lineHeight: "1.35",
                    textAlign: "left",
                    color: "#ffffff"
                  }}
                >
                  Welcome to Professional Editing Tool
                </p>
              </div>

              <div className="row" style={{ marginTop: "7%" }}>
                <p style={{ lineHeight: "1.72", textAlign: "justify" }}>
                  Welcome to Your Professional Editing Tool (PET). PET is the
                  industry-leading document editing and team collaboration
                  software for Businesses, Educators, Students and Researchers.
                  PET, integration with other apps and services, with the power
                  of AI brings all the state-of-the-art, extensive features
                  which are available in multiple writing tools.
                </p>
              </div>
            </div>
          </div>

          {/* Flex Two */}

          <div className="p-4 border" style={{ width: "100%" }}>
            {/* Flex Two Content */}

            <div
              style={{
                paddingLeft: "18%",
                paddingRight: "22%",
                paddingTop: "27%",
                paddingBottom: "4%"
              }}
            >
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3
                    style={{
                      fontSize: "31px",
                      fontWeight: "bold",
                      lineHeight: "1.26",
                      color: "#43425d"
                    }}
                  >
                    Reset your password
                  </h3>
                </div>
              </div>

              <div className="row" style={{ marginTop: "5%" }}>
                <div className="col-md-12 text-center">
                  <p
                    style={{
                      opacity: "0.5",
                      fontSize: "15px",
                      lineHeight: "0.72",
                      // textAlign: "center",
                      color: "#4d4f5c"
                    }}
                  >
                    Reset your password through email id
                  </p>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10%" }}>
                <div className="col-md-12 text-center">
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <TextField
                      id="standard-dense"
                      label="Email"
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                    /> */}
                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        autoComplete="current-password"
                        margin="normal"
                        onChange={e => handlePassword(e)}
                        // onChange={e => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {/* <TextField
                      id="standard-password-input"
                      label="Reset password"
                      className={classes.textField}
                      type="password"
                      autoComplete="current-password"
                      margin="normal"
                      onChange={e => handlePassword(e)}
                    /> */}
                    <p
                      className="pl-2"
                      style={{ color: "red", fontSize: "10px" }}
                    >
                      {errors}
                    </p>

                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        autoComplete="current-password"
                        margin="normal"
                        onChange={e => setConfirmPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {/* {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )} */}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    {/* <TextField
                      id="standard-password-input"
                      label="Confirm password"
                      className={classes.textField}
                      type="password"
                      autoComplete="current-password"
                      margin="normal"
                      onChange={e => setConfirmPassword(e.target.value)}
                    /> */}
                    <p
                      className="pl-2"
                      style={{ color: "red", fontSize: "10px" }}
                    >
                      {cpasserrors}
                    </p>
                  </form>
                </div>
              </div>

              <div className="row" style={{ marginTop: "12%" }}>
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="btn"
                    disabled={disable}
                    onClick={onClickResetPassword}
                    style={{
                      color: "white",
                      backgroundColor: "#1976d2",
                      width: "50%"
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className="row" style={{ marginTop: "60%" }}>
                <div className="col-md-12 text-center">
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "1.67",
                      textAlign: "center",
                      color: "#43425d"
                    }}
                  >
                    Term of use. Privacy policy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ResetPass);
