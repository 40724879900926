import React from "react";
import { headingList } from "./utilize";


export const SearchHighlight = (this_, props) => {
    const suggesion_res = this_.state.correctionList;
    const { blockKey, start, end } = props;
    const data_int = `${blockKey}_${start}_${end}`;
    var pgm, suggest;
    if(props.entityKey){
      const type = props.contentState.getEntity(props.entityKey).getType();
      if(type === 'LINK')
        pgm = "link"
      if(type === 'CITATION')
        pgm = "CITATION"
    }
    else{
    const keyData = suggesion_res[blockKey];
    const suggest_ = keyData.find((obj) => obj.offset == start);
    // console.log(suggest)
    var {pgm, expected} = suggest_ && suggest_;
    suggest = expected;
    }

    switch (pgm) {
        case "link":
            const {url} = props.contentState.getEntity(props.entityKey).getData();
            return ( <a href={url} >{props.children}</a> );

        case "CITATION":
    return ( 	<span className="citations" contentEditable={false}>{props.children}</span> );

        default:
          let classname =pgm ,pointing = pgm;
            return (
              <span id={data_int}
                onMouseOver={() => this_.setState({ show:true })}
                onMouseEnter={e => mouseEnter(this_, props, suggest, e, pointing) }
                onMouseLeave={this_.onLeave}
                className={classname} >{props.children}</span>
            );
    }
  };


 const mouseEnter = (this_, props, suggesion, element, pointing) => {
  // if(!checkEnableStatus(this_, pointing))
  // return
   console.info('mouseEnter_called');
    let headings = cardData(pointing, props.decoratedText)
    let hoverHead = headings.head,
     hoverBody = headings.body , elementText;
    const size = this_.getSizeHover(element);
    let suggest = [];

          suggesion && suggesion.map(SuggestWord => {
            suggest.push(
              <span
                key={SuggestWord}
                onClick={() => this_.onReplace(props, SuggestWord, pointing)}
                className="p-2 m-0 select rounded-top"
              >
                {SuggestWord || 'omit'}
              </span>
            );
          });
    

    const ignore = (
      <p onClick={() => this_.ignore_(props, pointing)} className="p-2 mb-0 ignore rounded-bottom point" >
        <i className="fa fa-trash-o mx-2" aria-hidden="true"></i>ignore
      </p>
    );

    const add_dictionary = (pointing == 'TYPOS') ?  (
      <p onClick={() => this_.addDictionary(props.decoratedText, props.blockKey) } className="p-2 mb-0 ignore rounded-bottom point" >
        <i className="fa fa-book mx-2" aria-hidden="true"></i>Add Dictionary
      </p>
    ) :
    <span></span>;
    
    const spanProps = {
      props:{
        blockKey: props.blockKey,
        start: props.start,
        end: props.end,
      },
      pgm: pointing,
      text: props.decoratedText,
      span_id: `${props.blockKey}_${props.start}_${props.end}`
      // suggest,
    };

    this_.setState({
      show: true, x: size.left, y: size.bottom, suggesion: suggest,
      ignore, hoverBody, hoverHead, spanProps, add_dictionary
    });
  };

 export const cardData = (pgm, erroTxt) =>{
    // console.info("cardData_called")
    let headings = headingList[pgm];
    if(!headings)
      return {
        head:<span>empty body</span>,
        body:<span>empty body</span>
      }
    else
    return {
      head:<span> { headings['head'] }</span>,
      body: <span> <b> { (pgm!=='complex' && pgm !=='hard') && erroTxt}</b> { headings['body']}</span>
    }
  }

export const borderColorUpdated = (grammerList) =>{
   let createClass = ''
   const style = document.createElement('style');
   style.type = 'text/css';

    grammerList.forEach((obj) =>{
      const { status, list, color } = obj;
        list.forEach((value) => {
          document.documentElement.style.setProperty(`--${value}`, color)
          createClass += `.${value}{ border-bottom: solid 3px var(--${value}); }`
        });
    });
    style.innerHTML = createClass;
    document.getElementsByTagName('head')[0].appendChild(style);
}
