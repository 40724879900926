import React, { useEffect, useState } from "react";
import BlogCard from "./Cards/BlogCard";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Loader from "../utils/loader";
import { withRouter } from "react-router-dom";
import page0 from "./../../src/static/Group 745.png";
import page1 from "./../../src/static/Group 746.png";
import {
  getAllBlogs,
  getBlogTags,
  getAllUsersBlogs,
  searchBlogByName,
  getBlogById,
  publishMyBlogs,
  unpublishMyBlogs,
  getBlogByTags,
  ShareableLink,
  deletePermanentlyBlog

} from "../services/blogServices";
import { getProfile } from "../services/userService";
import PostTabs from "./PostTabs";
import { toast, ToastContainer } from "react-toastify";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import _ from "lodash";
import "./Blog.css";
import HeadingB from "./Cards/HeadingB";
import NoBlog from "../static/noBlog.png";
import swal from "sweetalert";
import { camelCase, paramCase } from "change-case";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Blog({ history }) {
  const classes = useStyles();
  const [values, setValue] = React.useState(0);
  const [blogs, setBlogs] = useState([]);
  const [blogTags, setblogTags] = useState([]);
  const [filterBlog, setfilterBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setsearchvalue] = useState("");
  const [tabValue, setSwitchTabValue] = useState(0);
  const [yourPost, setYourPost] = useState(false);
  const [page, setPage] = useState(0);
  const [blogValue, setblogValue] = useState("all");
  const [LastPage, setLastPage] = useState(0);
  const [noMyBlogs, setnoMyBlogs] = useState(false);
  const [noYRBlogs, setnoYRBlogs] = useState(false);

  const showCard = 5;
  let pages = 0;

  const steps = [
    {
      selector: ".reactour-3",
      content: "Click to get sharable link of this blog.",
      position: "bottom",
    },
  ];

  useEffect(() => {
    // getBlogs();
    getAllBlog(blogValue);
  }, [page, blogValue]);

  // handle publish

  const handlePublish = async (data) => {
    swal({
      title: "Send Blog for Admin Approval",
      text:
        "Are you sure ? You want to send the blog for Admin approval in-order to publish this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willPublish) => {
      if (willPublish) {
        let blog = await getBlogById(data._id);
        console.log(blog);
        if (blog.status === 200) {
          if (blog.data.activeDoc === true) {
            let responseBlog = await publishMyBlogs(data._id);
            toast.success("Blog sent for approval!");
            console.log(responseBlog);
          }
          getAllBlog("my");
        }
      }
    });
  };

  // handle unpublish

  const handleUnpublish = async (data) => {
    swal({
      title: "Unpublish Blog",
      text: "Are you sure ? You want to unpublish this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willunPublish) => {
      if (willunPublish) {
        let blog = await getBlogById(data._id);
        console.log(data);

        if (blog.status === 200) {
          if (blog.data.activeDoc === true) {
            let unpublishBlog = await unpublishMyBlogs(data._id);
            toast.success("Blog unpublished!");
            console.log(unpublishBlog);
          }
          getAllBlog("my");
        } else {
          toast.error("Sommething went wrong");
        }
      }
    });
  };

  // Get All Users Blogs
  const getAllBlog = async (value) => {
    setLoading(true);
    const res = await getAllUsersBlogs(showCard, page, value);
    console.log(res);
    if (res.status === 200) {
      if (res.data.length < 1) {
        if (value === "my") {
          setnoMyBlogs(true);
          setLoading(false);
        } else if (value === "all") {
          setnoYRBlogs(true);
          setLoading(false);
        }
      } else {
        setBlogs(res.data);
        setfilterBlog(res.data);
        setLastPage(Math.ceil(res.pages - 1));
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error(res.message);

      console.log("Oops something went worng");
    }
    getAllTags();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openTextEditor = () => {
    setLoading(true);
    history.push({
      pathname: `/texteditor`,
      data: { documentType: "blog" },
    });
    setLoading(false);
  };

  ///get all my blogs
  // const getBlogs = async () => {
  //   setLoading(true);
  //   let blogs = await getAllBlogs();
  //   console.log("blogs", blogs);
  //   if (blogs.status === 200) {
  //     setBlogs(blogs.data);
  //     setfilterBlog(blogs.data);
  //     setYourPost(true);
  //   } else {
  //     setLoading(false);
  //   }
  //   getAllTags();
  // };

  const getAllTags = async () => {
    let allTags = await getBlogTags();
    console.log("blogs", allTags);
    let tags = allTags.data;
    let freqMap = [];
    setLoading(true);

    tags.map((tag) => {
      tag = tag.toLowerCase();
      if (!freqMap[tag]) {
        freqMap[tag] = 0;
      }
      freqMap[tag] += 1;
    });
    console.log(freqMap);
    const object = Object.entries(freqMap).map(([key, val]) => ({
      name: key,
      index: val,
    }));
    console.log(object);
    const list = object
      .sort((a, b) => a.index - b.index)
      .map((exemple, index, array) => exemple.name)
      .reverse()
      .slice(0, 7);
    if (list) {
      let initial = ["Latest Blogs"];
      var merge = _.concat(initial, list);
      setblogTags(merge);
      // let blogs = await getAllBlogs();
      // let filterBlog = blogs.data.filter((ok) => {
      //   for (let i = 0; i < ok.tags.length; i++) {
      //     if (ok.tags[i] === list[0]) {
      //       return ok;
      //     }
      //   }
      // });
      // setfilterBlog(filterBlog);
    }
    setLoading(false);
    console.log(blogTags);
  };

  const searchBlog = async (tag, index) => {
    if (index !== 0) {
      console.log(index, tag);
      setLoading(true);
      setfilterBlog([]);
      let blog = blogs;
      let alltags = blogTags;
      // let filterBlog = blog.filter((ok) => {
      //   for (let i = 0; i < ok.tags.length; i++) {
      //     if (ok.tags[i] === tag) {
      //       return ok;
      //     }
      //   }
      // });
      let filterBlog = await getBlogByTags(tag);
      setfilterBlog(filterBlog.data);
      // console.log(filterBlog);
      setLoading(false);
    } else {
      getAllBlog("all");
    }
  };

  const viewBlog = (blog) => {
    console.log(blogValue);
    history.push({
      pathname: `/blogpreview/${blog._id}`,
      state: { detail: blog, blogType: blogValue, blogTags: blog.tags },
      data: { documentType: "blog" },
    });
  };

  const filterOnChange = async (e) => {
    if (e.target.value === "") {
      setPage(0);
      setfilterBlog(blogs);
    }
    setsearchvalue(e.target.value);
  };

  const searchBlogName = async () => {
    setLoading(true);
    console.log(value);
    if (value === "") {
      setLoading(false);
      toast.error("Please enter value to search!");
    } else {
      let result = await searchBlogByName(value, blogValue);
      console.log(result);
      setLoading(false);
      setfilterBlog(result);
    }
  };

  const switchTab = (value) => {
    setSwitchTabValue(value);
    if (value === 0) {
      setblogValue("all");
      setPage(0);
      setYourPost(false);
    } else {
      setblogValue("my");
      setPage(0);
      setYourPost(true);
      setValue(0);
    }
  };

  const shareableLink = async (data) => {
    let link = await ShareableLink(data._id, paramCase(data.documentName));

    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    console.log(el.value);

    window.open(link, "_blank");
  };

  const permanentDeleteBlog = async (blog) => {
    swal({
      title: "Permanent Deletion",
      text: "Do you want to permanently delete this blog ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (permanentDelete) => {
      if (permanentDelete) {
        setLoading(true);
        let data = {
          _id: blog._id,
        };
        let res = await deletePermanentlyBlog(data);
        if (res.status !== 200) {
          setLoading(false);
          toast.error("Blog Deletion Failed !");
        }
        else {
          toast.success("Blog deleted permanently!");
          setLoading(false)
          getAllBlog("my");
        }

      } else {
        swal("Your Blog is safe!");
      }
    });
  };

  return (
    <div>
      <ToastContainer />
      <Loader loading={loading} />
      <PostTabs handleChangeTab={switchTab} openEditor={openTextEditor} />
      {tabValue === 0 ? (
        <AppBar position="static">
          <Tabs
            value={values}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable prevent tabs example"
            variant="scrollable"
          >
            {blogTags.map((tag, index) => (
              <Tab
                label={tag}
                key={index}
                {...a11yProps(index)}
                // disabled={tabDisable}
                onClick={() => searchBlog(tag, index)}
              />
            ))}
          </Tabs>
        </AppBar>
      ) : (
          <div className="row" style={{ marginBottom: "2%" }}></div>
        )}

      {/* {blogs.length > 0 ? ( */}

      <div
        className="row pl-2 mb-2 mt-2"
      // style={{
      //   paddingLeft: "2%",
      //   marginBottom: "2%",
      //   marginTop: "2%",
      // }}
      >
        {blogs.length > 0 || !noMyBlogs || !noYRBlogs ? (
          <>
            <div className="col-md-6 input-group mb-3">
              <div style={{ float: "left", width: "80%" }}>
                <input
                  type="text"
                  name="searchQuery"
                  value={value}
                  onChange={filterOnChange}
                  className="form-control"
                  placeholder="search blog"
                  aria-label="User's Blog"
                  aria-describedby="To Search the User Blog"
                />
              </div>
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={searchBlogName}
                >
                  <i className="fa fa-search"></i> Search
                </button>
              </div>
            </div>
          </>
        ) : (
            ""
          )}
        {/* <div className="second-step col-sm-2">
          {tabValue === 1 ? (
            <button
              className="btn btn-primarry"
              style={{ opacity: "0.6", cursor: "pointer" }}
              onClick={openTextEditor}
            >
              Write Blog
            </button>
          ) : (
            ""
            // <button className="btn btn-primarry" onClick={getBlogs}>
            //   Your Posts
            // </button>
          )}
        </div> */}
      </div>

      {tabValue === 0 && noYRBlogs ? (
        <div className="text-center mt-4">
          <img
            className=" mt-5"
            style={{ height: "150px" }}
            src={NoBlog}
            alt="404"
          ></img>
          <HeadingB className="alertHead" text="No Blog Published Yet..!" />
        </div>
      ) : tabValue === 1 && noMyBlogs ? (
        <div className="text-center mt-4">
          <img
            className=" mt-5"
            style={{ height: "150px" }}
            src={NoBlog}
            alt="404"
          ></img>
          <HeadingB className="alertHead" text="No Blogs Found..!" />
        </div>
      ) : (
            <>
              <hr
                style={{ marginLeft: "2%", marginRight: "6%", marginTop: "-1%" }}
              />
              {filterBlog.map((blog, index) => (
                <BlogCard
                  key={index}
                  data={blog}
                  showBlog={() => viewBlog(blog)}
                  mypost={yourPost}
                  blogunpub={() => handleUnpublish(blog)}
                  blogPub={() => handlePublish(blog)}
                  shareableLink={shareableLink}
                  search={searchBlog}
                  permanentDelete={() => permanentDeleteBlog(blog)}
                />
              ))}
              <hr />

              <div className="row">
                {page > 0 && (
                  <div className="col-md-6" style={{ padding: "2%" }}>
                    <div
                      className="row point"
                      style={{ padding: "2%" }}
                      onClick={() => setPage(page - 1)}
                    >
                      <img src={page0} style={{ height: "50px", width: "50px" }} />{" "}
                      <h6 style={{ paddingTop: "17px" }}>Latest Posts</h6>
                    </div>
                  </div>
                )}
                {page >= 0 && page < LastPage && (
                  <div className="col-md-6 ml-auto" style={{ padding: "2%" }}>
                    <div
                      className="point"
                      style={{ padding: "2%", float: "right" }}
                      onClick={() => setPage(page + 1)}
                    >
                      <span style={{ paddingTop: "17px" }}>Older Posts</span>{" "}
                      <img
                        src={page1}
                        alt="older post"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
    </div>
  );
}
export default withRouter(Blog);
