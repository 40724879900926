import React, { createContext, useState } from 'react';
export const ParaphraseContext = createContext();

const ParaphraseContextProvider = (props) => {
    const [ParaphraseCheckContent, setParaphraseCheckContent] = useState('');
    const [paraphraseResponse, setparaphraseResponse] = useState({});

    const addparaphraseCheckContent = (content) => {
        console.log(content)
        setParaphraseCheckContent(content);
    }

    const addparaphraseResponse = (response) => {
        setparaphraseResponse(response);
    }

    return (
        <ParaphraseContext.Provider value={{ ParaphraseCheckContent, addparaphraseCheckContent, addparaphraseResponse }}>
            {props.children}
        </ParaphraseContext.Provider>
    );
}

export default ParaphraseContextProvider;
