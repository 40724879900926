import React, { Component } from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { toast, ToastContainer } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import "./ProjectCard.css"
import nostar from "../../../static/nostar.png"

class ProjectCard extends Component {
  state = {
    project: {},
    projectName: "",
    projectId: "",
    editName: "",
    editModal: false,
    moveModal: false,
    folderID: "",
    selected: "",
    movingproject: {},
    movedisable: false,
  };

  async componentDidMount() {
    let { saveEdit, project } = this.props;
    this.setState({
      project: project,
      projectName: project.prj_title,
      projectId: project._id,
      editName: project.prj_title,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.setState({
        project: this.props.project,
        projectName: this.props.project.prj_title,
        projectId: this.props.project._id,
        editName: this.props.project.prj_title,
      });
    }
  }

  viewCitation = (prjID) => {
    const { history } = this.props;
    history.push({
      pathname: `/citationList`,
      state: { projectId: prjID },
    });
  };

  handleOnChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  setMoveModalValue = async (id) => {
    let folders = this.props.folder
    console.log(folders)
    console.log(id)
    let detail = {}
    for (let i = 0; i < folders.length; i++) {
      if (folders[i]._id === id) {
        detail = folders[i]
      }
    }
    let project = this.state.movingproject;
    this.setState({ selected: detail.folder_title });

    if (detail.projects.length > 0) {
      for (let j = 0; j < detail.projects.length; j++) {
        if (project._id === detail.projects[j]) {
          toast.error("Project is already in a folder.");
          this.setState({
            movedisable: true,
          });
        } else {
          this.setState({
            folderID: detail._id,
            selected: detail.folder_title,
            projectId: project._id,
            movedisable: false,
          });
        }
      }
    } else {
      this.setState({
        folderID: detail._id,
        selected: detail.folder_title,
        projectId: project._id,
        movedisable: false,
      });
    }
  };
  render() {
    let { saveEdit, folder, move } = this.props;
    let {
      project,
      projectName,
      editName,
      projectId,
      folderID,
      selected,
      movingproject,
      movedisable,
    } = this.state;
    return (
      <div>
        {/* <div className="row">
          <div className="col-md-8"> */}
        <div className="card setShadow" id="prjcard">
          <div className="card-body">
            <div className="row">
              <div className="col-md-1">
                {project.starred === true ? (
                  <AiFillStar
                    className="point"
                    onClick={() => this.props.starProject(project._id, false)}
                    style={{ color: "orange" }}
                    size="20px"
                  />
                ) : (
                    <AiOutlineStar
                      className="point"
                      onClick={() => this.props.starProject(project._id, true)}
                      size="20px"
                    />
                  )}
              </div>
              <div
                className="col-md-7"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => this.viewCitation(project._id)}
              >
                <b>{project.prj_title}</b>
              </div>

              <div className="col-md-4">
                <div
                  style={{
                    float: "right",
                    fontSize: "16px",
                  }}
                  className="hvr-grow"
                >
                  {/* <i
                        className="fa fa-folder-open text-primary mr-3"
                        aria-hidden="true"
                        data-placement="right"
                        title="Move"
                       
                        style={{ cursor: "pointer" }}
                      ></i> */}
                  <Tooltip title="Move">
                    <CreateNewFolderIcon
                      className="text-primary mr-3"
                      onClick={() =>
                        this.setState({
                          moveModal: true,
                          movingproject: project,
                        })
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>

                  <Tooltip title="Edit">
                    <i
                      className="fa fa-pencil text-primary mr-3"
                      aria-hidden="true"
                      // data-placement="right"
                      // title="Edit"
                      onClick={() => this.setState({ editModal: true })}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <i
                      className="fa fa-trash text-primary mr-3"
                      aria-hidden="true"
                      onClick={() =>
                        this.props.deleteProject(
                          project._id,
                          project.citations,
                          project.folders
                        )
                      }
                      style={{ cursor: "pointer" }}
                    ></i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}

        {/* Folder Modal */}

        <Modal
          show={this.state.moveModal}
          onHide={() => this.setState({ moveModal: false })}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Move Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {folder.length === 0 ? (
              <h4>There are no folders to move this project!</h4>
            ) : (
                <>
                  <h4>
                    Where do you want to move this {movingproject.prj_title}{" "}
                  project?
                </h4>
                  <select className="browser-default custom-select dropdown-primary mt-3"
                    onChange={(e) => this.setMoveModalValue(e.target.value)}
                  // onChange = {(e)=>console.log(JSON.stringify(e.target.value))}
                  >
                    <option value="all" selected disabled>
                      Choose a Folder
              </option>
                    {folder.map((detail) => (
                      <option value={detail._id} id="soflow-color" >
                        {detail.folder_title}
                      </option>
                    ))}
                  </select>
                  {/* <DropdownButton
                  id="dropdown-basic-button"
                  title={selected === "" ? "Choose a Folder" : selected}
                  style={{ width: "100%" }}
                >
                  <Dropdown.Menu
                    style={{
                      height: "auto",
                      maxHeight: "200px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      width: "auto",
                      minWidth: "auto",
                    }}
                  >
                    {folder.map((detail) => (
                      <Dropdown.Item
                        style={{ width: "100%" }}
                        onClick={() => this.setMoveModalValue(detail)}
                        // onClick={() =>  this.setState({
                        //   folderID: detail._id,
                        //   selected: detail.folder_title,
                        // })}
                      >
                        {detail.folder_title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </DropdownButton> */}
                </>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ moveModal: false })}>
              Close
            </Button>
            {folder.length === 0 ? (
              ""
            ) : (
                <Button
                  disabled={movedisable}
                  onClick={() => {
                    this.setState({ moveModal: false });
                    move(folderID, projectId);
                  }}
                >
                  Move
                </Button>
              )}
          </Modal.Footer>
        </Modal>

        {/* Folder Modal */}

        {/* Edit Modal */}

        <Modal
          show={this.state.editModal}
          onHide={() => this.setState({ editModal: false })}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Project Name</h4>
            <input
              type="text"
              className="form-control"
              name="editName"
              value={editName}
              // placeholder={projectName}
              onChange={(e) => this.setState({ editName: e.target.value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ editModal: false })}>
              Close
            </Button>
            <Button
              onClick={() => {
                this.setState({ editModal: false });
                saveEdit(editName, projectId);
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
      </div>
    );
  }
}

export default withRouter(ProjectCard);
