import React, { Component } from "react";
import BreadCrumb from "../Cards/BreadCrumbs";
import HeadingB from "../Cards/HeadingB";
import userService from "../../services/userService";
import Citationproj from "./Citationproj.jsx";
import "./FormComponents/FolderCard.css"
import Loader from "../../utils/loader";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import PaginatioN from "react-js-pagination";

import ProjectCard from "./FormComponents/ProjectCard";
import { RiCheckboxMultipleBlankLine } from "react-icons/ri";
import { AiOutlineStar } from "react-icons/ai";
import ProjectService from "../../services/projectService";
import "./CitationList.css";
import citateFolder from "../../services/citateFolder";
import "./CitationList.css";
import FolderCard from "./FormComponents/FolderCard";
import { paginate } from "../../utils/paginate";
import NoProjects from "../../static/noprojects.png";
import NoProjectsCreated from "../../static/noprojectscreated.png";
import ReactTour from "../ReactTour";

const buttonStyle = {
  paddingTop: "2%",
  paddingBottom: "2%",
  paddingRight: "10%",
  paddingLeft: "10%",
};

const options = ["All projects", "Starred"];

class Citation extends Component {
  state = {
    project_title: "",
    loading: false,
    project: [],
    filterPrj: [],
    loading: false,
    folderName: "",
    folderId: "",
    userProfile: "",
    loading: true,
    folders: [],
    folderdate: [],
    project_title: "",
    selected: "",
    filterProj: [],
    filterFolder: [],
    currentPage: 1,
    pageSize: 14,
    prjcount: "",
    setActive: 0,
    title:"All Projects",
    proType:"all",
    reactTour: false,
    steps: [
      {
        selector: '.reactour-1',
        content: 'Add project to create and view citations',
        position: 'bottom',
      },
      {
        selector: '.reactour-2',
        content: 'Create new folder to keep the projects organized in each folder',
        position: 'bottom',
      },
    ],
  };

  async componentDidMount() {
    this.getFolder();
    this.getProject();
  }

  // Get All Projects
  getProject = async () => {
    this.setState({ loading: true, setActive: 1,title:"All Projects",proType:"all" });
    const project = await ProjectService.getAllProject();
    if (project.status === 200) {
      this.setState({
        project: project.data,
        filterPrj: project.data,
        filterProj: project.data,
        prjcount: project.data.length,
        loading: false
      });
      console.log(project.message);
    } else {
      toast.error(project.message);
    }
  };

  // Star Project
  starProject = async (id, value) => {
    const project = await ProjectService.starProject(id, value);
    if (project.status === 200) {
      this.getProject();
    }
  };

  // Delete Project
  deleteProject = async (id, citations, folders) => {
    Swal.fire({
      title: "Are you sure?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: '#ff1a1a',
      cancelButtonColor: '#4CAF50',
    }).then(async (result) => {
      if (result.value === true) {
        const project = await ProjectService.deleteProject(id, citations, folders);
        if (project.status === 200) {
          toast.success(project.message);
          this.getFolder();
          this.getProject();
        } else {
          toast.error(project.message);
        }
      }
    });
  };

  handleShowcite = () => {
    const { history } = this.props;
    history.push({
      pathname: `/citationproj`,
    });
  };

  // get profile

  getProfile = async () => {
    let user;
    user = await userService.getProfile();
    console.log("currentUser", user);
    user = user.data;
    this.setState({ userProfile: user });
  };

  // Get All Folders
  getFolder = async () => {
    this.setState({ loading: true });
    const folder = await citateFolder.getAllFolders();
    console.log(folder);
    let folderDates = [];
    // for (let i = 0; i < folder.data.length; i++) {
    //   let folderdate = folder.data[i].date;
    //   const mdate = new Date(folderdate);
    //   folderDates = new Date(mdate)
    //     .toString()
    //     .split(" ")
    //     .splice(1, 3)
    //     .join(" ");
    //   this.setState({ folderdate: folderDates });
    //   console.log(folderDates);
    // }
    if (folder.status === 200) {
      this.setState(
        {
          folders: folder.data,
          filterFolder: folder.data,
          folderdate: folderDates,
          loading: false
        },
        () => {
          this.getProfile();
        }
      );
    }
  };

  // Create Folder
  createFolder = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let folderName = this.state.folderName;
    let folders = this.state.folders;
    let userProfile = this.state.userProfile;

    if (folderName === "") {
      this.setState({ loading: false });
      toast.error(`Invalid Folder Name!`);
      return;
    }

    let match = 0;

    if (folders.length > 0) {
      for (let i = 0; i < folders.length; i++) {
        if (folderName === folders[i].folder_title) {
          this.setState({ loading: false, folderName: "" });
          toast(`${folderName} Already exists!`);
          match = 1;
          return;
        }
      }
    }

    if (match === 0) {
      let data = {
        folderName: folderName,
        user: userProfile._id,
      };
      const folders = await citateFolder.createFolder(data);
      console.log(folders);
      if (folders.status === 200) {
        this.getFolder();
        toast.success(folders.message);
      } else {
        this.setState({ loading: false });
        toast.error(folders.message);
      }
    }

    this.setState({ folderName: "" });
  };

  // Handle Group Name
  handleFolderName = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Delete Folder
  deleteFolder = async (folder) => {
    console.log(folder);

    console.log(this.state.folders);
    Swal.fire({
      title: "Are you sure?",
      text: `"Don't worry, only folder will be deleted. Your project is safe!"`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: '#ff1a1a',
      cancelButtonColor: '#4CAF50',
    }).then(async (result) => {
      if (result.value === true) {
        this.setState({ loading: true });

        let data = {
          _id: folder._id,
        };

        const response = await citateFolder.deleteFolder(data);
        if (response.status === 200) {
          this.getFolder();
          Swal.fire("Deleted!", `${response.message}`, "success");
        } else {
          this.setState({ loading: false });
          Swal.fire("Cancelled", `${response.message}`, "error");
        }
      } else {
        Swal.fire("Cancelled", "Your Folder is safe :)", "error");
      }
    });
  };

  // Edit folder name
  editFoldername = async (newName, folderId) => {
    this.setState({
      loading: true,
    });

    let data = {
      folderName: newName,
      folderId: folderId,
    };

    console.log(data);
    let user = await citateFolder.editFoldername(data);
    if (user.status !== 200) {
      this.setState({ loading: false });
      toast.error(user.message);
    } else {
      toast.success(user.message);
      this.setState({
        data,
        loading: false,
      });
      this.getFolder();
    }
  };

  handleEditProject = async (editedName, projectId) => {
    let data = {
      name: editedName,
      id: projectId,
    };
    const Project = await ProjectService.editProjectName(data);
    console.log(Project);
    if (Project.status === 200) {
      this.getProject();
      toast.success(Project.message);
    } else {
      this.setState({ loading: false });
      toast.error(Project.message);
    }
    console.log(editedName, projectId);
  };

  handleProjectName = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Create
  createProject = async (e) => {
    e.preventDefault();
    let project_title = this.state.project_title;

    if (project_title === "") {
      this.setState({ loading: false });
      toast.error(`Project Name is Empty!`);
      return;
    }

    let data = {
      project_title: project_title,
    };
    const project = await ProjectService.createcitationPrj(data);
    if (project.status === 200) {
      toast.success(project.message);
      this.getProject();
    } else {
      this.setState({ loading: false });
      toast.error(project.message);
    }

    this.setState({ project_title: "" });
  };

  getStarredProject = async () => {
    this.setState({ loading: true, setActive: 2 ,title:"Starred Projects",proType:"star"});
    let projects = this.state.project;
    console.log(projects);
    let data = [];
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].starred === true) {
        data.push(projects[i]);
      }
    }
    this.setState({ filterPrj: data, filterProj: data, loading: false });
    console.log(data);
  };

  handleMove = async (folderId, projectId) => {
    let data = {
      folderID: folderId,
      ProjectID: projectId,
    };
    const Project = await ProjectService.moveProject(data);
    if (Project.status === 200) {
      this.getFolder();
      this.getProject();
      toast.success(Project.message);
    } else {
      this.setState({ loading: false });
      toast.error(Project.message);
    }
  };

  getFolderProject = async (folder) => {
    this.setState({ selected: folder._id, proType:"folderProject", title:`${folder.folder_title} Projects` });
    console.log(folder);
    let projectIds = folder.projects;
    let projects = this.state.project;
    let data = [];
    if (projectIds.length > 0) {
      for (let i = 0; i < projects.length; i++) {
        for (let j = 0; j < projectIds.length; j++) {
          if (projects[i]._id === projectIds[j]) {
            data.push(projects[i]);
          }
        }
      }
      this.setState({ filterProj: data });
    } else {
      this.setState({ filterProj: [] });
      toast.error("Project Is not added in this Folder.");
    }
  };

  // Handle search

  handleSearch = (e) => {
    if (e.target.value === "") {
      this.setState({
        filterProj: this.state.project,
        filterFolder: this.state.folders,
        setActive: 1,
        title:"All Projects",
      });
    } else {
      let value = e.target.value;

      let project = this.state.project;
      let folders = this.state.folders;

      let result1 = [];
      let result2 = [];

      result1 = project.filter((project) => {
        console.log(project);
        if (
          project.prj_title.toLowerCase().search(value.toLowerCase()) !== -1
        ) {
          return project;
        }
      });

      result2 = folders.filter((folders) => {
        console.log(folders);
        if (
          folders.folder_title.toLowerCase().search(value.toLowerCase()) !== -1
        ) {
          return folders;
        }
      });

      this.setState({ filterProj: result1, filterFolder: result2,setActive: 1 , title:"All Projects",});
    }
  };

  // handle pagination

  handlePageChange = (page) => {
    console.log(page)
    this.setState({ currentPage: page });
    console.log(this.state.currentPage)
  };

  getPagedData = () => {
    let { filterProj, prjcount, pageSize } = this.state;

    const filterProjs = paginate(filterProj, this.state.currentPage, pageSize);
    return {
      prjcount: filterProj.length,
      filterProj: filterProjs,
    };
  };

  render() {
    const {
      folders,
      folderdate,
      filterPrj,
      filterFolder,
      selected,
      setActive,
      project,
      title,
      proType
    } = this.state;
    let { filterProj, prjcount, pageSize } = this.getPagedData();

    // Create project
    const pop = (
      <Popover id="popover-basic" style={{ zIndex: "1010", padding: "5px" }}>
        <Popover.Content>
          <h6 style={{ fontFamily: "Roboto" }}>Create a new project</h6>
          <form onSubmit={this.createProject}>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                name="project_title"
                value={this.state.projectName}
                onChange={this.handleProjectName}
                placeholder="Project Title"
                aria-label="Project Title"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Append>
                <Button variant="primary" type="submit">
                  Create
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </form>
        </Popover.Content>
      </Popover>
    );

    // Create Folder
    const popup = (
      <Popover id="popover-basic" style={{ zIndex: "1010", padding: "5px" }}>
        <Popover.Content>
          <h6 style={{ fontFamily: "Roboto" }}>Create a new folder</h6>
          <form onSubmit={this.createFolder}>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                name="folderName"
                value={this.state.folderName}
                onChange={this.handleFolderName}
                placeholder="Folder Title"
                aria-label="Folder Title"
                aria-describedby="basic-addon2"
                maxLength={20}
              />
              <InputGroup.Append>
                <Button variant="primary" type="submit">
                  Create
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </form>
        </Popover.Content>
      </Popover>
    );

    return (
      <div>
        <ToastContainer />
        <Loader loading={this.state.loading} />
        <div className="" style={{ background: "#f0f0f7" }}>
          <BreadCrumb
            l1="Home"
            l2="Citations"
            dashboardlink="/dashboard"
          />

          <div className="row ">
            {/* Title */}
            <div className="col-md-3">
              <HeadingB text="Citations" />
            </div>

            {/* Search Bar */}
            <div className="col-md-5">
              <input
                className="form-control searchBar"
                name="searchQuery"
                onChange={this.handleSearch}
                type="text"
                placeholder="&#xF002; Search folders or projects"
                aria-label="Search"
                style={{
                  fontFamily: "FontAwesome",
                  border: "none",
                  width: "60%",
                  float: "left",
                }}
              />
            </div>
            <div className="col-md-3">
              {/* Create Project Button */}

              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={pop}
                rootClose={true}
              >
                <button
                  className="btn btn-primary createGroup reactour-1"
                  style={{ width: "90%" }}
                >
                  + Create project
                </button>
              </OverlayTrigger>
            </div>

            <div className="col-md-1"> 
              <i 
                className="fa fa-question-circle mt-2" 
                aria-hidden="true"
                data-toggle="tooltip"
                title="Need Help ?"
                style={{
                  cursor: "pointer",
                  color: "dodgerblue",
                  fontSize: "15px"
                }}
                onClick={()=>this.setState({ reactTour: true })}
              ></i>
            </div>

          </div>
          <div className="row"></div>
          <br />

          <Divider light />

          <br />

          <div className="row">
            <div
              className="col-md-3 pt-2"
              style={{ height: "600px", borderRight: "2px solid #dee2e6" }}
            >
              <div class="vertical-menu active">
                <a
                  onClick={this.getProject}
                  className={setActive === 1 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <RiCheckboxMultipleBlankLine size="20px" />
                  &nbsp; All Projects
                </a>
                <a
                  onClick={this.getStarredProject}
                  className={setActive === 2 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineStar size="20px" />
                  &nbsp; Starred
                </a>
              </div>

              <br />
              <Divider light />
              <div className="mt-3">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popup}
                  rootClose={true}
                >
                  <button
                    className="btn btn-primary reactour-2"
                    style={{ width: "100%" }}
                  >
                    + Create folder
                  </button>
                </OverlayTrigger>
              </div>

              {/*//////////////////////////// Folder cards ////////////////////////////// */}
              <div
                style={{
                  height: "372px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {filterFolder.map((folder) => (
                  <div className="row mt-3">
                    <div className="col-12"> 
                      <FolderCard
                        data={folder}
                        moveProject={this.getFolderProject}
                        editFolder={this.editFoldername}
                        delete={this.deleteFolder}
                        folderDate={folderdate}
                        isSelected={selected}
                        projectType={proType}
                      />
                    </div>  
                  </div>
                ))}
              </div>

              {/*//////////////////////////// Folder cards ////////////////////////////// */}
            </div>

            {/* Right section: display of project cards */}

            <div className="col-md-9">
            <HeadingB text={title} />

              {project.length === 0 ? (
                <div className="text-center mb-5">
                  <img
                    className="img-fluid"
                    src={NoProjects}
                    alt="404"
                    style={{ height: "50%", width: "50%" }}
                  ></img>
                  
                  <h3>No projects added</h3>
                </div>
              ) : (
                <div className="row">
                  {filterProj.map((prj) => (
                    <div className="col-md-6 pt-3">
                      <ProjectCard
                        project={prj}
                        saveEdit={this.handleEditProject}
                        deleteProject={this.deleteProject}
                        starProject={this.starProject}
                        folder={folders}
                        move={this.handleMove}
                      />
                    </div>
                  ))}
                 
                </div>
              )}
              
              <div className="row">
                {/* Pagination */}

                {project.length > 14 && project.length != 0 ? (
                  <div
                    className="row ml-auto"
                    style={{ margin: "2% 1% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={this.state.currentPage}
                      itemsCountPerPage={pageSize}
                      totalItemsCount={prjcount}
                      pageRangeDisplayed={3}
                      onChange={(page) => this.setState({ currentPage: page })}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <ReactTour 
          steps={this.state.steps} 
          isOpen={this.state.reactTour} 
          closeTour={()=>this.setState({ reactTour: false })}
        />
      </div>
    );
  }
}

export default withRouter(Citation);
