import React, { Component } from "react";
import Loader from "../utils/loader";
import { InputGroup } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import userService from "../services/userService";
import teamService from "../services/teamService";
import { toast } from "react-toastify";
import documentService from "../services/document";
import Avatar from "@material-ui/core/Avatar";
import groupdp from "../static/groupdp.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "./shareFile.css";

export class ShareFile extends Component {
  state = {
    loading: true,
    data: [],
    groups: [],
    filter: [],
    filterGroup: [],
    color: ["#bff603", "yellow", "#80d4ff", "orange"],
    sharedWith: [],
    Access: [],
  };

  filterOnChange = (e) => {
    if (e.target.value === "") {
      this.setState({
        filter: this.state.data,
        filterGroup: this.state.groups,
      });
    } else {
      let value = e.target.value.toLowerCase();

      let data = this.state.data;
      let groups = this.state.groups;

      let result = [];
      let resultGroup = [];

      result = data.filter((user) => {
        return user.name.toLowerCase().search(value) !== -1;
      });

      resultGroup = groups.filter((user) => {
        return user.name.toLowerCase().search(value) !== -1;
      });

      this.setState({ filter: result, filterGroup: resultGroup });
    }
  };

  async componentDidMount() {
    const data = await userService.getTeam();
    let Access = [];

    if (data.status !== 200) {
      this.getGroup();
      toast.error(data.message);
    } else {
      // const {
      //   shareDocumentId: { sharedWith },
      // } = this.props;

      if (this.props.shareDocumentId.drafts.length > 0) {
        let draftData = await documentService.getAccess(
          this.props.shareDocumentId.drafts
        );
        Access = draftData.data;
        this.setState({ Access: Access });
      }

      let alreadyShared = [];
      let teamMember = [];
      console.log(Access);

      data.data.user.map((team) => {
        team["shared"] = false;
        team["readOnly"] = null;
        if (Access.length > 0) {
          Access.map((s) => {
            if (team.id === s.editor) {
              team["shared"] = true;
              team["readOnly"] = s.readOnly;
              alreadyShared.push(team);
            }
          });
        }
        teamMember.push(team);
      });
      console.log(teamMember);

      this.setState(
        { data: data.data.user, filter: teamMember, sharedWith: alreadyShared },
        () => this.getGroup()
      );
    }
  }

  // async componentWillUpdate(){
  //   console.log('console.log(')
  // }

  getGroup = async () => {
    const group = await teamService.getAllGroups();
    if (group.status === 200) {
      let myGroup = [];
      for (let i = 0; i < group.data.length; i++) {
        let response = {
          _id: group.data[i]._id,
          name: group.data[i].name,
          owner: group.data[i].owner,
          ownerName: group.data[i].ownerName,
          members: group.data[i].members,
          select: false,
          edit: false,
          selectToggle: false,
          editToggle: false,
        };
        myGroup.push(response);
      }
      this.setState({ groups: myGroup, filterGroup: myGroup, loading: false });
    } else {
      this.setState({ loading: false });
      console.log(group.message);
    }
  };

  shareFile = async () => {
    this.setState({ loading: true });

    let Access = this.state.Access;
    let draftOwner = [];
    if (Access.length > 0) {
      Access.map((item) => draftOwner.push(item.editor));
    }

    let team = [];

    let length = await this.state.filter.length;
    let data = await this.state.filter;

    let groupLength = this.state.groups.length;
    let group = this.state.groups;

    let newDocument = await this.props.shareDocumentId;

    for (let i = 0; i < length; i++) {
      if (data[i].select === true) {
        team.push({ id: data[i].id, readOnly: data[i].edit });
      }
    }

    for (let j = 0; j < groupLength; j++) {
      if (group[j].select === true) {
        for (let k = 0; k < group[j].members.length; k++) {
          let teamInc = 0;
          for (let z = 0; z < team.length; z++) {
            if (team[z].id !== group[j].members[k]) {
              teamInc++;
            }
          }
          if (teamInc === team.length) {
            team.push({ id: group[j].members[k], readOnly: group[j].edit });
          }
        }
      }
    }

    let x = team.length;
    while (x--) {
      for (let y = 0; y < Access.length; y++) {
        if (team.length > 0) {
          if (Access[y].editor === team[x].id) {
            if (Access[y].readOnly === !team[x].readOnly) {
              team.splice(x, 1);
            }
          }
        }
      }
    }

    if (team.length < 1) {
      this.setState({ loading: false });
      toast("Incorrect option selected");
      return;
    }

    const share = {
      document: newDocument,
      team: team,
      Access: Access,
      draftOwner: draftOwner,
      date: new Date(),
    };
    console.log(share);

    let response = await documentService.shareDocument(share);
    if (response.status === 200) {
      this.setState({ loading: false });
      toast.success(response.message);
    } else {
      this.setState({ loading: false });
      toast(response.message);
    }

    this.props.closeModal();
    this.props.getDocuments();
  };

  // onChangeGroupBox = (group, edit, toggle) => (e) => {
  onChangeGroupBox = (group, edit, toggle, e) => {
    // console.log(e.target.checked);
    // console.log(e.target.name);
    const groups = [...this.state.groups];
    const index = groups.indexOf(group);
    groups[index] = { ...groups[index] };

    groups[index].select = e.target.checked;
    groups[index].edit = edit;
    if (edit === true) {
      groups[index].editToggle = true;
    }
    if (edit === false) {
      groups[index].selectToggle = true;
    }

    if (toggle === "select") {
      if (toggle === "select" && e.target.checked === true) {
        groups[index].selectToggle = true;
      } else {
        groups[index].selectToggle = false;
      }
    }

    if (toggle === "edit") {
      if (toggle === "edit" && e.target.checked === true) {
        groups[index].editToggle = true;
      } else {
        groups[index].editToggle = false;
      }
    }

    if (groups[index].selectToggle === true) {
      groups[index].select = true;
      groups[index].edit = false;
    }

    if (groups[index].editToggle === true) {
      groups[index].select = true;
      groups[index].edit = true;
    }

    console.log(groups[index]);
    this.setState({ groups: groups });
    console.log(groups);
  };

  // onChangeTeamBox = (team, edit, toggle) => (e) => {
  onChangeTeamBox = (team, edit, toggle, e) => {
    const teamMembers = [...this.state.filter];
    const index = teamMembers.indexOf(team);
    teamMembers[index] = { ...teamMembers[index] };

    teamMembers[index].select = e.target.checked;
    teamMembers[index].edit = edit;

    if (toggle === "select") {
      if (toggle === "select" && e.target.checked === true) {
        teamMembers[index].selectToggle = true;
      } else {
        teamMembers[index].selectToggle = false;
      }
    }

    if (toggle === "edit") {
      if (toggle === "edit" && e.target.checked === true) {
        teamMembers[index].editToggle = true;
      } else {
        teamMembers[index].editToggle = false;
      }
    }

    if (teamMembers[index].selectToggle === true) {
      teamMembers[index].select = true;
      teamMembers[index].edit = false;
    }

    if (teamMembers[index].editToggle === true) {
      teamMembers[index].select = true;
      teamMembers[index].edit = true;
    }

    console.log(teamMembers[index]);
    this.setState({ filter: teamMembers });
  };

  render() {
    const { filter, groups, filterGroup } = this.state;
    console.log(filter);
    return (
      <div>
        <Loader loading={this.state.loading} />

        <input
          className="form-control search"
          type="text"
          onChange={this.filterOnChange}
          placeholder="&#xF002; Search Username / Group"
          aria-label="Search"
          style={{
            backgroundColor: "#f2f2f2",
            fontFamily: "FontAwesome",
            height: "50px",
            marginTop: "-16px",
            border: " 0px solid",
            paddingLeft: "25px",
            marginLeft: "-29px",
            marginBottom: "3%",
            width: "498px",
          }}
        />

        {/* MY Groups */}
        {filterGroup.length < 1 ? (
          <div style={{ marginTop: "2%" }}>
            <h6 style={{ textAlign: "center", color: "grey" }}>
              NO GROUPS ARE CREATED
            </h6>
          </div>
        ) : (
          <div
            style={{ overflowY: "auto", overflowX: "hidden", height: "20vh" }}
          >
            {filterGroup.map((group, i) => (
              <div key={group._id} className="row">
                <div className="col-2">
                  <Avatar
                    className="res-shareFile-dp"
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "black",
                      backgroundColor: `${
                        this.state.color[groups.indexOf(group) % 4]
                      }`,
                    }}
                  >
                    {group.name.charAt(0).toUpperCase()}
                  </Avatar>
                </div>
                <div className="col-5">
                  <p className="pt-1 res-shareFile-name">{group.name}</p>
                </div>
                {group.members.length === 0 ? (
                  ""
                ) : (
                  // <div className="col-3">
                  //   <span className="res-shareFile-option">View</span>
                  //   <Checkbox
                  //     color="primary"
                  //     onChange={this.onChangeGroupBox(group, false, "select")}
                  //   />
                  // </div>
                  // <div className="col-3">
                  //   <span className="res-shareFile-option">Edit</span>
                  //   <Checkbox
                  //     color="secondary"
                  //     onChange={this.onChangeGroupBox(group, true, "edit")}
                  //   />
                  // </div>
                  <div className="col-5">
                    <RadioGroup
                      row
                      aria-label={i}
                      name={i}
                      onChange={(e) => {
                        if (e.target.value === "false") {
                          this.onChangeGroupBox(group, false, "select", e);
                        } else {
                          this.onChangeGroupBox(group, true, "edit", e);
                        }
                      }}
                      defaultValue=""
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="View"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio color="secondary" />}
                        label="Edit"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <hr />

        {/* My Team List */}
        {filter.length < 1 ? (
          <div>
            <h6 style={{ textAlign: "center", color: "grey" }}>
              NO TEAM MEMBERS ARE ADDED
            </h6>
          </div>
        ) : (
          <div
            style={{ overflowY: "auto", overflowX: "hidden", height: "20vh" }}
          >
            {filter.map((team, i) => (
              <div key={team.id} className="row">
                <div className="col-2">
                  <img
                    className="res-shareFile-dp"
                    src={team.picture}
                    alt="dp"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="col-5">
                  <p className="pt-1 res-shareFile-name">
                    {team.name !== "" ? team.name : team.email}
                    {/* <b>{`${sharedWith[0]}-${team.id} `}</b> */}
                    <span>{team.shared && <b>{"[Shared]"}</b>}</span>
                  </p>
                </div>
                {/* <div className="col-3"> */}
                {/* {team.shared && <b>{"Already"}</b>} */}
                {/* {!team.shared ? (
                    <React.Fragment>
                      {" "} */}
                {/* <span className="res-shareFile-option">{"View"}</span>
                  <Checkbox
                    color="primary"
                    defaultChecked={
                      team.readOnly === true
                        ? true
                        : team.readOnly === null
                        ? false
                        : false
                    }
                    onChange={this.onChangeTeamBox(team, false, "select")}
                  /> */}
                {/* </React.Fragment>
                  ) : (
                    <></>
                  )} */}
                {/* </div>
                <div className="col-3"> */}
                {/* {team.shared && <b>{"Shared"}</b>} */}
                {/* {!team.shared || team.readOnly ? (
                    <React.Fragment> */}
                {/* <span className="res-shareFile-option">{"Edit"}</span>
                  <Checkbox
                    color="secondary"
                    defaultChecked={
                      team.readOnly === true
                        ? false
                        : team.readOnly === null
                        ? false
                        : true
                    }
                    onChange={this.onChangeTeamBox(team, true, "edit")}
                  /> */}
                {/* </React.Fragment>
                  ) : (
                    <></>
                  )} */}
                {/* </div> */}
                <div className="col-5">
                  <RadioGroup
                    row
                    aria-label={i}
                    name={i}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      if (e.target.value === "false") {
                        this.onChangeTeamBox(team, false, "select", e);
                      } else {
                        this.onChangeTeamBox(team, true, "edit", e);
                      }
                    }}
                    defaultValue={
                      team.readOnly === true
                        ? "false"
                        : team.readOnly === null
                        ? ""
                        : "true"
                    }
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="View"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio color="secondary" />}
                      label="Edit"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="text-center">
          <button
            className="btn btn-primary mt-3"
            onClick={this.shareFile}
          >
            Share
          </button>
        </div>
      </div>
    );
  }
}

export default ShareFile;
