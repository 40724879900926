import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/teams`;
} else {
  apiEndpoint = `${herokuUrl}/teams`;
}

export async function reviewDocument(id) {
  const data = await http.post(`${apiEndpoint}/reviewDocument`, {id});
  return data;
}

export async function getCollabDocuments(mergeDocuments) {
  const data = await http.post(`${apiEndpoint}/getCollabDocuments`,{mergeDocuments});
  return data;
}

export async function mergeDocument(Document) {
  const data = await http.post(`${apiEndpoint}/mergeDocument`,Document);
  return data;
}

export async function removeCollaborater(id) {
  const data = await http.post(`${apiEndpoint}/removeCollaborater`, {id});
  return data;
}

  export default{
    reviewDocument,
    getCollabDocuments,
    mergeDocument,
    removeCollaborater
  }