import React, { useState } from "react";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { toast, ToastContainer } from "react-toastify";
import { freezeuser,confirmUser } from "../../services/adminServices";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles({
  card: {
    maxWidth: 200
  },
  media: {
    height: 140
  }
});

export default function UserCard({
  isSubAdmin,
  data,
  index,
  handleCheckFn,
  isChked,
  dropVal,
  refresh,
  setting,
  document,
  handleDelete,
  filterValue
}) {
  const classes = useStyles();

  const handleFreezeuser = async email => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        let sendData = { email };
        const res = await freezeuser(sendData);
        if (res.status == 200) {
          swal(res.message, { icon: "success" });
          refresh();
        } else toast.error(res.message);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };
  const handleConfirmuser = async email => {
    swal({
      title: "Are you sure?",
      text: "Do you want to confirm the Email ID Of this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        let sendData = { email };
        const res = await confirmUser(sendData);
        if (res.status == 200) {
          swal(res.message, { icon: "success" });
          refresh();
        } else toast.error(res.message);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  return (

    <Card id="DocCard" className="DocumentCardHover" style={{ width: "100%" }}>
      <CardActionArea>
        <CardContent>
          <div className="row">
            <div className="col-12" onClick={() => handleCheckFn(data)}>
              <Typography gutterBottom variant="h6" component="h2">
                <div className="d-flex">
                  {dropVal && (
                    <Form.Check
                      custom
                      type="checkbox"
                      checked={isChked.some(obj => obj._id == data._id)}
                      onClick={() => handleCheckFn(data)}
                      id={data._id}
                      label=""
                    />
                  )}
                  <img
                    className="img-50 rounded-circle"
                    src={data.display_picture || data.picture}
                    alt="dp"
                  />
                  <div className="ml-3">
                    <p className="ft_lg m-0"> {data.username || data.name || "-"} </p>
                    <p className="ft_sm text-a m-0"> {data.email} </p>
                    {/* <p className='ft_sm text-a m-0'> { isChked.some(obj => obj._id == data._id).toString() } </p> */}
                  </div>
                </div>
              </Typography>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <div className="ml-auto text-primary ft_lg">
          <div>
            {!isSubAdmin ? (<>

              {filterValue === "confirm" ? (
                // <i
                //   className="fa fa-check mr-3 point"
                //   onClick={() => document(data)}
                //   data-toggle="tooltip"
                //   title="Documents"
                //   aria-hidden="true"
                // ></i>
                <Tooltip title="Confirm account">
                  <VerifiedUserIcon
                className="mr-3 point"
                onClick={()=>handleConfirmuser(data.email)}
                data-toggle="tooltip"
                  title="Confirm User"
                  aria-hidden="true"
                  style={{marginBottom:"6px"}}
                  
                />
                </Tooltip>
                 

              ):""
              }
              <Tooltip title="Documents">
              <i
                className="fa fa-folder mr-3 point"
                onClick={() => document(data)}
                data-toggle="tooltip"
                title="Documents"
                aria-hidden="true"
              ></i>
              </Tooltip>
              <Tooltip title="Account settings">
              <i
                className="fa fa-cog mr-3 point"
                onClick={() => setting(data)}
                data-toggle="tooltip"
                title="Account setting"
                aria-hidden="true"
              ></i>
              </Tooltip>
              {!data.freeze && (
                <Tooltip title="Freeze!">
                <i
                  className="fa fa-trash mr-3 point"
                  data-toggle="tooltip"
                  title="Freeze!"
                  onClick={() => handleFreezeuser(data.email)}
                  aria-hidden="true"
                ></i>
                </Tooltip>
              )}

            </>) : (
              <Tooltip title="Delete!">
                <i
                  className="fa fa-trash mr-3 point"
                  data-toggle="tooltip"
                  title="Delete!"
                  onClick={() => handleDelete(data)}
                  aria-hidden="true"
                ></i>
                </Tooltip>
              )}
          </div>

        </div>
      </CardActions>
    </Card>

  );
}
// {/* </div> */}