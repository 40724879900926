import React from 'react'

let link = "";
if (process.env.NODE_ENV !== "production") {
    link = "http://localhost:3000/"
} else {
    link = "https://www.professionaleditingtool.com/"
}

export default function FinalPreview() {
    return (
        <div>
            <iframe src={link} style={{height:"100vh", width:"100%"}} title="Iframe Example"></iframe>
        </div>
    )
}
