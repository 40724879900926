import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import dateFn from "date-fn";
import swal from "sweetalert";
import CardActionArea from "@material-ui/core/CardActionArea";
import PeopleIcon from "@material-ui/icons/People";
import CardActions from "@material-ui/core/CardActions";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import { toast } from "react-toastify";
import TeamCollaborationServices from "../../services/teamCollbarationServices";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "50%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

// const [  loading, setloading] = useState("");
//   const [documentName, setdocumentName] = useState("");
//   const [onlyDocumentContent, setonlyDocumentContent] = useState("");
//   const [tags, settags] = useState([]);
//   const [readonly, setreadonly] = useState("");
//   const [htmlContent, sethtmlContent] = useState("");

export default function RecipeReviewCard({
  data,
  showDocs,
  submitForReview,
  deleteDocs,
  editDocs,
  compareDocument,
}) {
  const classes = useStyles();

  let content = getTextFromEditorState(data.onlyDocumentContent,true);
  let title = data.documentName.slice(0, 14);
  title = data.documentName.length === title.length ? title : `${title}...`;
  const newCont = (data) => {
    let newContent = data + " ";
    let length = 120 - data.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };

  // async function submitForReview(document){

  //   swal({
  //     title: "Review Document",
  //     text: "Submit your Document for Review ?",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true
  //   }).then(async reviewDoc => {
  //     if (reviewDoc) {
  //       const response = await TeamCollaborationServices.reviewDocument(document._id);
  //       if(response.status !== 200){
  //         toast.error(response.message);
  //       }else{
  //         toast.info(response.message);
  //       }
  //       // this.initalCall();
  //     } else {
  //       // swal("Your imaginary file is safe!");
  //     }
  //   });

  // }

  return (
    <div className="col-sm-4 p-3 DocumentCardHover">
      <Card 
        id="DocCard" 
        // className={classes.card}
      >
        <CardHeader
          avatar={<Avatar aria-label="recipe" src={data.ownerDP}></Avatar>}
          title={<span>{data.ownerName}</span>}
          subheader={<small>{dateFn.date(data.date, 110)}</small>}
          //       action={
          //         <div className="p-2">
          //           <i
          //             className="fa fa-certificate "
          //             style={{ fontSize: "18px", color:"#00e396" }}
          //             data-toggle="tooltip"
          //             title="Merged!"
          //             // onClick={() => deleteDocs(data)}
          //             aria-hidden="true"
          //           ></i>

          //          </div>

          // }
        ></CardHeader>
        <CardActionArea onClick={() => showDocs(data)}>
          <CardContent style={{ height: 95 }}>
            <h6
              component={"span"}
              gutterBottom
              style={{ fontWeight: "900", overflowWrap: "break-word" }}
            >
              {title}
            </h6>
            <small
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: "13px" }}
            >
              {newCont(content)}
            </small>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <div className="ml-auto text-primary ft_lg">
            {data.reviewStatus === true ? (
              <>
                <i
                  className="fa fa-eye mr-3 point third-step"
                  onClick={() => compareDocument(data)}
                  data-toggle="tooltip"
                  title="Compare"
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-trash mr-3 point"
                  onClick={() => deleteDocs(data)}
                  data-toggle="tooltip"
                  title="Delete"
                  aria-hidden="true"
                ></i>
              </>
            ) : (
              <>
                {data.readOnly === false && (
                  <>
                    <span
                      data-toggle="tooltip"
                      title="Sumbit For Review"
                      aria-hidden="true"
                    >
                      <PeopleIcon
                        className="mr-3 point first-step"
                        onClick={() => submitForReview(data)}
                      />
                    </span>

                    <i
                      className="fa fa-pencil-square-o mr-3 point second-step"
                      onClick={() => editDocs(data)}
                      // id="popmodal"
                      data-toggle="tooltip"
                      title="Edit"
                      aria-hidden="true"
                    ></i>
                  </>
                )}
                <i
                  className="fa fa-trash mr-3 point"
                  onClick={() => deleteDocs(data)}
                  data-toggle="tooltip"
                  title="Delete"
                  aria-hidden="true"
                ></i>
              </>
            )}
          </div>
        </CardActions>
      </Card>
    </div>
  );
}
