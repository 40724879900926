import React, { Component } from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

class Tags extends React.Component {
  componentWillReceiveProps() {
    this.tagInput.value = "";
  }
  render() {
    let { onKeyDown, removeTags, onFocus } = this.props;
    let tags = [] ;
    if(this.props.tags){
      tags = Array.from(this.props.tags);
    }

    return (
      <div className="input-tag">
        <ul className="input-tag__tags">
          {tags.map((tag, i) => (
            <li key={tag}>
              {tag}
              <button type="button" onClick={() => removeTags(i)}>
                +
              </button>
            </li>
          ))}
          <li className="input-tag__tags__input">
            <input
              id="tags"
              type="text"
              placeholder="Add tags"
              onFocus={() => onFocus()}
              onKeyDown={e => onKeyDown(e)}
              ref={c => { this.tagInput = c; }}
            />
            <LocalOfferIcon />
          </li>
        </ul>
      </div>
    );
  }
}

export default Tags;
