import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dashboard from "@material-ui/icons/Equalizer";
import Review from "@material-ui/icons/Chat";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import logo from "../static/Group 570.png";
import { useHistory } from "react-router-dom";
import authService from "../services/authService";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import PaymentIcon from "@material-ui/icons/Payment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { getProfile } from "../services/userService";
import "./Navbar.css";
import "./AdminSideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import BookIcon from "@material-ui/icons/Book";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { getHomeCMS } from "../services/adminServices";
import Tooltip from "@material-ui/core/Tooltip";
const drawerWidth = 250;


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AdminHeader(props) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [emailId, setemailId] = useState("");
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState();
  const [isAdmin, setAdmin] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [cms, setCms] = useState({});

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };
  function handleNavigation(route) {
    history.push(`/${route}`);
  }

  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    console.log(cms);
  };

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      if (user.isAdmin === true) {
        setAdmin(true);
      }
    }
    displyPic();
    getCms();
  }, []);

  useEffect(() => {
    const { currentUser } = props;
    setOpen(currentUser.sidenavOption);
  }, [props]);

  const displyPic = async () => {
    let user;
    const { currentUser, setCurrentUser } = props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    console.log("users", user);
    const { display_picture } = user;
    setProfilePic(display_picture);
    setemailId(user.email);
    setUser(user.username);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem onClick={handleMenuClose}>Account Settings</MenuItem>
        </NavLink>

        <NavLink to={{ pathname: "/" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );
  const getThemeSwitch = () => {
    if (window.location.pathname.match("/accountsettings")) {
      return null;
    }
    return <GenericThemeSwitch />;
  };

  function isUrlMatch(url) {
    if (window.location.pathname.match(url)) {
      return true;
    }
    return false;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToastContainer />
      {getThemeSwitch()}
      <AppBar
        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            style={{ color: "#4d4f5c" }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap style={{ color: "#4d4f5c" }}>
            <p>
              PROFESSIONAL <small>EDITING TOOL</small>
            </p>
          </Typography> */}
          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              <text
                style={{
                  fontFamily: "ProximaNova-Semibold",
                  lineHeight: "1.18",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#3d83fa",
                  // backgroundColor: "#99ccff"
                }}
              >
                PROFESSIONAL
              </text>{" "}
              <small
                style={{
                  fontFamily: "ProximaNova-Light",
                  lineHeight: "1.18",
                  fontSize: "22px",
                  fontWeight: 300,
                  color: "#3d83fa",
                }}
              >
                EDITING TOOL
              </small>
            </p>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <LightTooltip title={user} onClick={handleProfileMenuOpen}>
                    <Avatar
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      alt="Profile Picture"
                      src={profilePic ? profilePic : avatar}
                      id="profilePic"
                      className={`${classes.avatar} profilePic point`}
                      color="inherit"
                    />
                  </LightTooltip>
                </OverlayTrigger>
              </Grid>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <MoreIcon /> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt="Remy Sharp"
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={emailId}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar} style={{ backgroundColor: "#3e80f1" }}>
          <img
            alt="logo"
            // src={logo}
            src={
              cms && cms.Navbar_logo
                ? cms.Navbar_logo.Image
                : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+570.png"
            }
            className="img-fluid lcss"
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
            }}
          ></img>

          <IconButton className="p-1" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "#fff" }} />
            ) : (
                <FormatAlignLeftIcon style={{ color: "#fff" }} />
              )}
          </IconButton>
        </div>
        <Divider />
        {/* list starts */}
        <List className="drawerMenu pointer" id="menuList">
          {/* Link 1 */}
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/admindashboard" }}
          >
            <ListItem
              className={
                isUrlMatch("/admindashboard") ? "background-red" : "hvr-pulse"
              }
            // style={{ marginTop: "15%" }}
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title="Dashboard">
                  <Dashboard
                    className={
                      isUrlMatch("/admindashboard") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Dashboard</Typography>
            </ListItem>
          </NavLink>
          {/* Link 2 */}
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageuser" }}
          >
            <ListItem
              className={
                isUrlMatch("/manageuser") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title="Manage Users">
                  <PeopleAltIcon
                    className={
                      isUrlMatch("/manageuser") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Users</Typography>
            </ListItem>
          </Link>
          {/* Link 3 */}
          <Link className="sidenavbar-textlink" to={{ pathname: "/pricing" }}>
            <ListItem
              className={
                isUrlMatch("/pricing") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title="Manage Subscription">
                  <AttachMoneyIcon
                    className={isUrlMatch("/pricing") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Subscription</Typography>
            </ListItem>
          </Link>
          {/* Link 4 */}
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageinvoices" }}
          >
            <ListItem
              className={
                isUrlMatch("/manageinvoices") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title="Manage Invoices">
                  <ReceiptIcon
                    className={
                      isUrlMatch("/manageinvoices") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Invoices</Typography>
            </ListItem>
          </Link>
          {/* Link 5 */}
          {/* <Link className="sidenavbar-textlink" to={{ pathname: "/" }}>
            <ListItem>
              <ListItemIcon className="drawerMenu">
                <AnnouncementIcon />
              </ListItemIcon>
              Alerts
            </ListItem>
          </Link> */}
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/managereviews" }}
          >
            <ListItem
              className={
                isUrlMatch("/managereviews") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title="Manage Reviews">
                  <Review
                    className={
                      isUrlMatch("/managereviews") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Reviews</Typography>
            </ListItem>
          </Link>
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/adminBlogs" }}
          >
            <ListItem
              className={
                isUrlMatch("/adminBlogs") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title="Manage Blogs">
                  <BookIcon
                    className={
                      isUrlMatch("/adminBlogs") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Blogs</Typography>
            </ListItem>
          </Link>
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/costumHome" }}
          >
            <ListItem
              className={
                isUrlMatch("/costumHome") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title=""
              >
                <LightTooltip title="Customize Landing Page">
                  <AccountBalanceIcon
                    className={isUrlMatch("/costumHome") ? "iconCol" : ""}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                Customize Landing <br /> Page
              </Typography>
            </ListItem>
          </Link>{" "}
          {/* <Link className="sidenavbar-textlink" to={{ pathname: "/newhome" }}>
            <ListItem className={isUrlMatch("/newhome") && "background-red"}>
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Costumize Landing Page"
              >
                <AccountBalanceIcon
                  className={isUrlMatch("/newhome") ? "iconCol" : ""}
                />
              </ListItemIcon>
              home
            </ListItem>
          </Link> */}
        </List>
        {/* list over */}
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
