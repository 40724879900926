import React, {useState, useEffect} from 'react'
import {MenuItem, Select} from '@material-ui/core';
import {Dropdown} from 'react-bootstrap'

function SelectItem({list, onBlockChange, currentBlockType, placeholder}) {

    const [selected, setSelected] = useState("");

    const onChangeFn = (style) =>{
        setSelected(style);
        onBlockChange(style);
    }

    useEffect(() => {
        // console.log("currentBlockType", currentBlockType)
        if(currentBlockType === "unstyled")
            setSelected(placeholder)
        else
            setSelected(currentBlockType)
    }, [currentBlockType])

    return (
        <Select value={ selected } className="mr-3" onChange={ (e)=> onChangeFn(e.target.value) } > 
            {
                list.map((item) => <MenuItem value={item.style}>{item.label}</MenuItem> )
            }
      </Select>
    )
}


function InlineSelect({list, placeholder, currentStyle, onToggle}){
    const [selected, setSelected] = useState("");
    const [label, setLabel] = useState("");

    const onChangeFn = (e, style) =>{
        e.preventDefault();
        // console.log("style", style);
        setSelected(style);
        onToggle(style);
    }

    useEffect(() =>{
        let getInlineStyle = null
        list.forEach( (obj) => {
            if(currentStyle.has(obj.style))
                getInlineStyle = obj.style
        });
        if(!getInlineStyle)
            setSelected(placeholder)
        else
            setSelected(getInlineStyle)
        // console.log("getInlineStyle", getInlineStyle)
    },[currentStyle])

    return (
        <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
        <Dropdown.Toggle className="mydrop" variant="white" id="dropdown-basic">
           {selected}
        </Dropdown.Toggle>

        <Dropdown.Menu className="setShadow">
            {
                list.map((item) =>  <Dropdown.Item onMouseDown={ (e) => onChangeFn(e, item.style) }  >{item.label}</Dropdown.Item>)
            }
        </Dropdown.Menu>
        </Dropdown>
    )
}


function InlineSize({list, placeholder, currentStyle, onToggle}){
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, style, label) =>{
      e.preventDefault();
    //   console.log("style", style);
      setSelected(label);
      onToggle(style);
  }

  useEffect(() =>{
      let getInlineStyle = null
      list.forEach( (obj) => {
          if(currentStyle.has(obj.style))
              getInlineStyle = obj.label
      });
      if(!getInlineStyle)
          setSelected(placeholder)
      else
          setSelected(getInlineStyle)
    //   console.log("getInlineStyle", getInlineStyle)
  },[currentStyle])

  return (
      <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
      <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic"> {selected} </Dropdown.Toggle>

      <Dropdown.Menu className="setShadow">
          {
              list.map((item) =>  <Dropdown.Item
               onMouseDown={ (e) => onChangeFn(e, item.style, item.label) }  >{item.label}</Dropdown.Item>)
          }
      </Dropdown.Menu>
      </Dropdown>
  )
}

function InlineColor({list, placeholder, currentStyle, onToggle}){
    const [selected, setSelected] = useState("");
  
    const onChangeFn = (e, style) =>{
        e.preventDefault();
        // console.log("style", style);
        setSelected(style);
        onToggle(style);
    }
  
    useEffect(() =>{
        let getInlineStyle = null
        list.forEach( (obj) => {
            if(currentStyle.has(obj.style))
                getInlineStyle = obj.style
        });
        if(!getInlineStyle)
            setSelected(placeholder)
        else
            setSelected(getInlineStyle)
        // console.log("getInlineStyle", getInlineStyle)
    },[currentStyle])
  
    return (
        <Dropdown onMouseDown={(e)=> {e.preventDefault() }}>
        <Dropdown.Toggle variant="green" id="dropdown-basic" className="mydrop">
           {selected}
        </Dropdown.Toggle>
  
        <Dropdown.Menu className="setShadow">
            {
                list.map((item) =>  <Dropdown.Item className="drop_set" style={{backgroundColor :item.style}}
                 onMouseDown={ (e) => onChangeFn(e, item.style) }  >{item.label}</Dropdown.Item>)
            }
        </Dropdown.Menu>
        </Dropdown>
    )
  }


export  {SelectItem, InlineSelect, InlineColor, InlineSize};