import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase
} from "change-case";

const useStyles = makeStyles({
  card: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  smallAvatar: {
    width: 30,
    height: 30,
    backgroundColor: "#1976d2 "
  }
});

const TagsCard = ({ data, abc }) => {
  const classes = useStyles();

  return (
    <div className="col-sm-3 p-2 DocumentCardHover">
      <Card className={classes.card} id="DocCard">
        <CardContent>
          <div className="row">
            <div className="col-2">
          
              <Avatar className={classes.smallAvatar}>{data.characters}</Avatar>
            </div>
            <div className="col-10">
              {data.Tags.map((tag, index) => (
                <div className="find p-2" >
               
                  <h6
                    component={"span"}
                    gutterBottom
                    style={{
                      fontWeight: "900",
                      overflowWrap: "break-word",
                      cursor: "pointer",
                      margin:0
                     
                    }}
                    onClick={() => abc(tag)}
                   
                  >
                    {capitalCase(tag)}
                  </h6>
                </div>
              ))}
            </div>
          </div>

         
        </CardContent>
      </Card>
    </div>
  );
};

export default withRouter(TagsCard);
