import React, { Component } from "react";
import Loader from "../utils/loader";
import NumberWidget from "../components/NumberWidget";
import AdminServices from "../services/adminServices";
import Apex from "./ApexCharts";
import { Dropdown } from "react-bootstrap";
import DashboardCard from "../components/Cards/DasboardCard";
import Doc from ".././static/Doc.png";
import Group from ".././static/Group.png";
import Member from ".././static/Member.png";
import Rate from ".././static/Rate.png";
import Total from ".././static/Total.png";
// import MonthYearPicker from "../utils/MonthYearPicker";
// import Chart from "../components/Stats";

export class AdminDashboard extends Component {
  state = {
    loading: true,
    totalPaidUser: 0,
    totalDocuments: 0,
    totalUsers: 0,
    monthWiseDocuments: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    monthWiseUser: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    MonthWise: [],
    UserWise: [],
    selectYear: new Date().getFullYear(),
    selectMonth: new Date().getMonth() + 1,
    years: [...Array(Number(new Date().getFullYear()) - 2018)].map(
      (year, i) => 2019 + i
    ),
    monthList: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ],
    cardmargin: 10,
    admindashdetail: [],
    monthwisePaidusers: [],
    monthwiseFreeusers: []
  };

  componentDidMount() {
    let year = new Date().getFullYear();
    this.getDetailsAdminDash(year);
    this.getmonthwisePaidusers(year);
    this.getmonthwiseFreeusers(year);
  }

  getDetailsAdminDash = async year => {
    this.setState({ loading: true });
    let {
      totalDocuments,
      totalFreeuser,
      totalPaidUser,
      monthWiseDocuments,
      monthWiseUser
    } = await AdminServices.getDetailsAdminDash(year);

    let dashData = [
      {
        title: "Total Free Users",
        count: totalFreeuser,
        colors: "#ff3a29",
        img: Doc,
        sparkData: this.state.monthwiseFreeusers
      },
      {
        title: "Total Paid Users",
        count: totalPaidUser,
        colors: "#ffb200",
        img: Rate,
        sparkData: this.state.monthwisePaidusers
      },
      {
        title: "Total Documents",
        count: totalDocuments,
        colors: "#34b53a",
        img: Group,
        sparkData: monthWiseDocuments
      }
    ];
    console.log(monthWiseUser);
    this.setState({
      totalPaidUser: totalPaidUser,
      totalDocuments: totalDocuments,
      totalUsers: totalFreeuser,
      monthWiseDocuments: monthWiseDocuments,
      monthWiseUser: monthWiseUser,
      MonthWise: monthWiseDocuments,
      UserWise: monthWiseUser,
      selectYear: year,
      admindashdetail: dashData,
      loading: false
    });
    this.getmonthwisePaidusers(year);
    this.getmonthwiseFreeusers(year);
  };

  getmonthwisePaidusers = async year => {
    let admindata = await AdminServices.getPaidUserDetails(year);
    console.log(admindata);
    this.setState({ monthwisePaidusers: admindata.monthWisePaidUser });
  };

  getmonthwiseFreeusers = async year => {
    let admindata = await AdminServices.getFreeUserDetails(year);
    console.log(admindata);
    this.setState({ monthwiseFreeusers: admindata.monthWiseFreeUser });
  };

  getMonthYear = async value => {
    console.log(value);
    let year = value;

    let full = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ];

    if (year !== this.state.selectYear) {
      await this.getDetailsAdminDash(year);
      await this.getmonthwisePaidusers(year);
      await this.getmonthwiseFreeusers(year);
      this.setState({ selectYear: year, monthList: full });
    }
  };

  increment = () => {
    const selectYear = this.state.selectYear;
    const Year = selectYear + 1;
    console.log(Year);
    this.setState({
      selectYear: Year
    });
    this.getDetailsAdminDash(Year);
  };

  decrement = () => {
    const selectYear = this.state.selectYear;
    const Year = selectYear - 1;
    console.log(Year);
    this.setState({
      selectYear: Year
    });
    this.getDetailsAdminDash(Year);
  };

  _changeYear = async year => {
    this.setState({ selectYear: year }, () => {
      this.getDetailsAdminDash(year);
      this.getmonthwisePaidusers(year);
      this.getmonthwiseFreeusers(year);
    });
  };

  render() {
    let {
      totalPaidUser,
      totalDocuments,
      totalUsers,
      monthWiseDocuments,
      monthWiseUser,
      selectYear,
      monthList,
      years,
      cardmargin,
      admindashdetail,
      monthwisePaidusers,
      monthwiseFreeusers
    } = this.state;

    return (
      <div>
        <Loader loading={this.state.loading} />
        <div className="row p-3">
          <h4 className="ft_lg" style={{ color: "#3e80f1" }}>
            Overview
          </h4>
        </div>
        <div className="row justify-content-center pl-5">
          {admindashdetail.map((details, index) => (
            <div className="col-md-2 dashcol">
              <DashboardCard
                info={details}
                index={index}
                margin={cardmargin}
                select={() => null}
              />
            </div>
          ))}
          {/* <NumberWidget title="Total Free User" count={totalUsers} color="#007bff" colorpb="primary"/>
          <NumberWidget title="Total Paid Users" count={totalPaidUser} color="#FFCA4B" colorpb="warning"/>
          <NumberWidget title="Total Documents" count={totalDocuments} color="#89B04E" colorpb="success"/> */}
        </div>

        <br />
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="ft_sm m-0" style={{ color: "#4d4f5c" }}>
            Documents and Reviews
          </h4>
          <div className="d-flex align-items-center">
            <h4 className="ft_sm m-0" style={{ color: "#4d4f5c" }}>
              Select Year :{" "}
            </h4>
            <Dropdown
              onMouseDown={e => {
                e.preventDefault();
              }}
            >
              <Dropdown.Toggle variant="white">{selectYear}</Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: "auto", minWidth: "auto" }}
                className="m-0"
              >
                {years &&
                  years.map(year => (
                    <Dropdown.Item onMouseDown={() => this._changeYear(year)}>
                      {" "}
                      {year}{" "}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* <label>
                <i
                  className="fa fa-chevron-left"
                  onClick={this.decrement}
                  style={{ opacity: "0.5" }}
                ></i>
                &nbsp;
                <label style={{ color: "rgba(0, 143, 251, 0.85)" }}>
                  {selectYear}
                </label>
                &nbsp;
                <i
                  className="fa fa-chevron-right"
                  onClick={this.increment}
                  style={{ opacity: "0.5" }}
                ></i>
              </label> */}
        <br />

        <Apex
          cmp1="Free Users"
          cmp2="Documents"
          cmp3="Paid Users"
          bar1={monthwiseFreeusers}
          bar2={monthWiseDocuments}
          linedata={monthwisePaidusers}
          monthList={monthList}
        />
      </div>
    );
  }
}

export default AdminDashboard;
// {
//   /* <Chart
//           cmp1="Users"
//           cmp2="Documents"
//           bar1={monthWiseUser}
//           bar2={monthWiseDocuments}
//           title=" Users and Documents Overview"
//         /> */
// }
