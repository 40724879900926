import React from "react";
import style from "../Paraphrase/paraphrase.module.css";
import { MdDelete } from "react-icons/md";
import { FcDoughnutChart } from "react-icons/fc";

export default function PlagButton({ dontShow, characters, onHandleClear, plagStats }) {
  return (
    <div className={`text-e d-flex justify-content-between ${style.moveup}`}>
      <div>
        <p className="ft_sm">
          <text style={{ color: "#4cb2ff" }}>{characters.now}</text>/
          <text style={{ color: "red" }}>{characters.max}</text> Characters
        </p>
      </div>
      <div className="d-flex align-items-center">
        <button
          className={style.btn_trns}
          onClick={() => plagStats()}
        >
          <FcDoughnutChart
            data-toggle="tooltip"
            data-placement="top"
            title="Statistics"
            size={"24px"}
          />
        </button>
        {dontShow ? (<button
          className={style.trash}
          onClick={onHandleClear}
          style={{ position: "absolute", top: "12px", right: "24px" }}
        >
          <MdDelete
            data-placement="top"
            title="Clear editor"
            size={"24px"}
          />
        </button>) : (<button
          className={style.trash}
          style={{ position: "absolute", top: "12px", right: "24px" }}
        >
          <MdDelete
            data-toggle="modal"
            data-target="#myModal"
            data-placement="top"
            title="Clear editor"
            size={"24px"}
          />
        </button>)}
        {/* <p
          onClick={onHandleClear}
          data-toggle="tooltip"
          data-placement="top"
          title="Clear editor"
        >
          {" "}
          <i className={`fa fa-trash ml-3 ${style.trash}`}></i>{" "}
        </p> */}
      </div>
    </div>
  );
}
