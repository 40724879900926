import {
  apiUrl,
  herokuUrl
} from "./config.json";
import http from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/projectcite";
} else {
  apiEndpoint = herokuUrl + "/projectcite";
}

//  Create Group
export async function createcitationPrj(data) {
  const response = await http.post(`${apiEndpoint}/createProject`, data);
  return response;
}

// Get ALl Projects
export async function getAllProject() {
  const response = await http.get(`${apiEndpoint}/getAllProjects`);
  return response;
}

export async function editProjectName(data) {
  const response = await http.post(`${apiEndpoint}/editProject`, data);
  return response;
}


export async function starProject(id, value) {
  const response = await http.post(`${apiEndpoint}/starProject`, {
    id,
    value
  });
  return response;
}

export async function deleteProject(id, citations, folders) {
  const response = await http.post(`${apiEndpoint}/deleteProject`, {
    id,
    citations,
    folders
  });
  return response;
}
export async function moveProject(data) {
  const response = await http.post(`${apiEndpoint}/moveProject`, data);
  return response;
}

export default {
  createcitationPrj,
  getAllProject,
  starProject,
  deleteProject,
  editProjectName,
  moveProject
  //export all the above mentioned api call functions here
};