import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/dashboard`;
} else {
  apiEndpoint = `${herokuUrl}/dashboard`;
}

export async function getDashboardDetails(ok) {
  let detail = { year: ok }
  const data = await http.post(`${apiEndpoint}/getUserDashBoardDetails`,detail);
  // console.log(data);
  return data;
}

export async function getWidgetDetails(year) {
  const data = await http.post(`${apiEndpoint}/getWidgetDetails`, { year });
  return data;
}



export async function getMonthWiseRatings(yr) {
  let detail = { year: yr }
  const data = await http.post(`${apiEndpoint}/getMonthwiseRatingsDetail`,detail);
  console.log(data);
  return data;
}

export async function getMonthWiseTeamDetails(year) {
  let detail = { year: year }
  const data = await http.post(`${apiEndpoint}/getMonthWiseTeamDetails`,detail);
  console.log(data);
  return data;
}

export async function getRatedDocumentMonthwise(yr) {
  let detail = { year: yr }
  const data = await http.post(`${apiEndpoint}/getRatedDocumentMonthwise`,detail);
  console.log(data);
  return data;
}

export default {
  getDashboardDetails,
  getWidgetDetails,
  getMonthWiseRatings,
  getMonthWiseTeamDetails,
  getRatedDocumentMonthwise
};
