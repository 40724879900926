import React, { Component, useS } from "react";
import Loader from "../utils/loader";
import InvoiceCard from "./Cards/InvoiceCard";
import InvoiceService from "../services/invoiceService";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import BreadCrumb from "./Cards/BreadCrumbs";
import "./Invoice.css";
import NoInvoices from "../static/noInvoices.png";
import HeadingB from "./Cards/HeadingB";
import { ToastContainer, toast } from "react-toastify";

class Invoice extends Component {
  state = {
    loading: true,
    invoices: [],
    expanded: [],
    totalInvoices: 0,
    isAdmin: false,
    dashLink: "/dashboard",
    noInvoice: false,
  };

  async componentDidMount() {
    const user = await authService.getCurrentUser();
    if (user.isAdmin == true) {
      this.getAllpayments();
      this.setState({
        dashLink: "/admindashboard",
      });
    } else if (user.isUser == true) {
      this.getAllUserInvoices();
    } else {
      this.setState({ dashLink: "/subAdminManageUser" });
      this.getAllUserInvoices();
    }
  }

  // For admin
  getAllpayments = async () => {
    let details = await InvoiceService.getAllpaymentsForAdmin();
    console.log(details);
    if (details.length > 0) {
      const expanded = new Array(details.length);
      expanded.fill(false);
      this.setState({
        invoices: details.data,
        expanded,
        totalInvoices: details.length,
        loading: false,
      });
    }
    this.setState({ loading: false });
  };

  //for particular User
  getAllUserInvoices = async () => {
    let details = await InvoiceService.getUserInvoices();
    console.log(details);
    if (details.status !== 200) {
      toast.error(details.message);
    } else {
      if (details.data.length < 1) {
        this.setState({ loading: false, noInvoice: true });
      } else {
        const expanded = new Array(details.length);
        expanded.fill(false);
        this.setState({
          invoices: details.data,
          expanded,
          loading: false,
        });
        console.log(this.state.invoices);
      }
    }
  };

  handleExpand = (panel, index) => {
    const { expanded } = this.state;
    if (!expanded[index]) {
      expanded.fill(false);
    }
    expanded[index] = !expanded[index];
    this.setState({ expanded });
  };

  render() {
    const { invoices, expanded, isAdmin, noInvoice } = this.state;
    console.log(expanded);
    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />
        <div className="">
          <BreadCrumb
            l1="Home"
            l2="Invoice"
            dashboardlink={this.state.dashLink}
          />

          <HeadingB text="Invoice" />

          {noInvoice ? (
            <div className="text-center mt-4">
              <img
                className=" mt-5"
                style={{ height: "150px" }}
                src={NoInvoices}
                alt="404"
              ></img>
              <HeadingB className="alertHead" text="No Invoice Found..!" />
            </div>
          ) : (
            <div className="row mt-2">
              <div className="col-md-12 mt-2 ">
                {invoices.length > 0 &&
                  invoices.map((order, index) => (
                    <InvoiceCard
                      key={index}
                      data={order}
                      index={index}
                      expand={this.handleExpand}
                      expanded={expanded[index] ? `panel${index}` : false}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Invoice;
