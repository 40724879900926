import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/users";
} else {
  apiEndpoint = herokuUrl + "/users";
}

export async function register(user) {
  const response = await httpService.post(`${apiEndpoint}/`, user);
  console.log(response);
  return response;
}
export async function resendEmail(email, actual) {
  
  const response = await httpService.post(
    `${apiEndpoint}/resendConfirmation`,
    {
      email: email,
      actual: actual
    }
  );
  console.log(response);
  return response;
}

export async function getTeam() {
  let data = await httpService.get(`${apiEndpoint}/getTeamMembers`);
  console.log(data);
  return data;
}

export async function getProfile() {
  let data = await httpService.get(`${apiEndpoint}/getProfile`);
  // console.log(data);
  return data;
}

export async function updateProfile(data) {
  let user = await httpService.post(`${apiEndpoint}/updateProfile`, data);
  console.log(user);
  return user;
}

export async function deleteAccount() {
  // console.log(sessionStorage.getItem('token'));
  let token = await sessionStorage.getItem("token");
  let user = await httpService.post(`${apiEndpoint}/deleteAccount`, {
    token: token,
  });
  console.log(user);
  return user;
}

export async function deleteTeam(data) {
  const team = await httpService.post(`${apiEndpoint}/deleteTeamMembers`, data);
  console.log(team);
  return team;
}

export async function getExpiryDate() {
  let data = await httpService.get(`${apiEndpoint}/getExpiryDate`);
  return data;
}


export async function submitContactForm(data) {
  let response = await httpService.post(`${apiEndpoint}/sendForm`,data);
  return response;
}

export async function addUserToTeam(data) {
  console.log('addUserToTeam',data);
  let response = await httpService.post(`${apiEndpoint}/addToTeam`,data);
  return response;
}
// Please add all the user related api call here as per the above format

export default {
  //export all the above mentioned api call functions here
  register,
  getTeam,
  getProfile,
  updateProfile,
  deleteTeam,
  deleteAccount,
  getExpiryDate,
  resendEmail,
  addUserToTeam
};
