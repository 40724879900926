import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
const tokenKey = "token";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/auth`;
} else {
  apiEndpoint = `${herokuUrl}/auth`;
}
export async function login(user) {
  const data = await http.post(apiEndpoint, user);
  console.log(data);
  sessionStorage.setItem("token", data.token);
  return data;
}

export async function loginWithFaceBook() {
  const { data } = await http.get(`${apiEndpoint}/facebook`);
  sessionStorage.setItem(tokenKey, data.token);
  http.setJwt(data.token);
  return data;
}

export async function loginWithGoogle() {
  const { data } = await http.get(`${apiEndpoint}/auth/google`);
  sessionStorage.setItem(tokenKey, data.token);
  http.setJwt(data.token);
  return data;
}

export function loginWithJwt(jwt) {
  localStorage.setItem("token", jwt);
}

export function logout() {
  sessionStorage.removeItem("token");
}

export function getJwt() {
  return sessionStorage.getItem("token");
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem("token") ? sessionStorage.getItem("token") : localStorage.getItem("token");
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

// export function getJwt() {
//     return localStorage.getItem('token');
// }


export async function forgotpasssword(email) {
  const data = await http.post(`${apiEndpoint}/forgotpasssword`, email);
  return data;
}

// export function getForgotData() {
//   http.get(`${apiEndpoint}/getForgotPassword`).then(res => {
//     sessionStorage.setItem("ForgotEmail", res.data);
//   });
// }

export async function resetPassword(user) {
  const data = await http.post(`${apiEndpoint}/resetpassword`, user);
  return data;
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  resetPassword,
  loginWithFaceBook,
  loginWithGoogle,
  forgotpasssword
};
