import React, { useState, useEffect } from "react";
import {
  Formik,
  Field,
  Form,
  FieldArray,
  useField,
  ErrorMessage,
} from "formik";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { postHomeCMS } from "../../services/adminServices";
import imageCompression from "browser-image-compression";
import uploadImageServices from "../../services/uploadImageServices";
import { Button, InputGroup } from "react-bootstrap";
import HeadingB from "../Cards/HeadingB";
import LogoImg from "../../static/upl.png";
import * as Yup from "yup";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const InputField = ({ placeholder, className, maxLength, ...props }) => {
  const [field] = useField(props);
  return (
    <input
      type="text"
      {...field}
      className={className}
      placeholder={placeholder}
      maxLength={maxLength || 1000}
    />
  );
};

const TextArea = ({ placeholder, className, maxLength, ...props }) => {
  const [field] = useField(props);
  return (
    <textarea
      {...field}
      className={className}
      placeholder={placeholder}
      maxLength={maxLength || 1000}
      rows="5"
    />
  );
};

const SelectField = ({ className, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControl variant="outlined" className={className}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        style={{ height: "40px" }}
        {...field}
      >
        {props.children}
      </Select>
    </FormControl>
  );
};

const validationSchema = Yup.object().shape({
  About_title: Yup.string().required("*Required Field"),
  About_text: Yup.string().required("*Required Field"),
  About_Cards: Yup.array().of(
    Yup.object().shape({
      Card_title: Yup.string().required("*Required Field"),
      Card_text: Yup.string().required("*Required Field"),
      Card_image: Yup.string().required("*Please Upload Image"),
    })
  ),
});

function AboutCMS({ cmsData }) {
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState(cmsData);
  let junkFile = [];
  let DontDelete = [];

  let value = {
    About_title: cmsData.About_title,
    About_text: cmsData.About_text,
    About_Cards: cmsData.About_Cards,
  };

  useEffect(() => {
    return () => {
      _removeJunk();
    };
  }, []);

  const _removeJunk = async () => {
    if (junkFile.length > 0) {
      for (let i = 0; i < junkFile.length; i++) {
        await uploadImageServices.DeleteImageFromS3(junkFile[i]);
      }
    }
  };

  const uploadPic = (index) => {
    document.getElementById(`fileup[${index}]`).click();
  };

  const _handleImageChange = async (index, About_Cards, setFieldValue, e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      const options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920,
        maxIteration: 10,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(file, options);

      const uploadConfig = await uploadImageServices.UploadImageToS3(
        compressedImage,
        ""
      );

      DontDelete.push(About_Cards.Card_imageKey);
      junkFile.push(uploadConfig.data.key);

      let reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onload = function () {
        setFieldValue(
          `About_Cards[${index}].Card_image`,
          uploadConfig.data.displayUrl
        );
        setFieldValue(
          `About_Cards[${index}].Card_imageKey`,
          uploadConfig.data.key
        );
        //   console.log(reader.result);
      };
    }
  };

  const removeCard = async (index, About_Cards, arrayHelpers) => {
    arrayHelpers.remove(index);
    if (About_Cards.Card_imageKey) {
      // await uploadImageServices.DeleteImageFromS3(About_Cards.Card_imageKey);
      DontDelete.push(About_Cards.Card_imageKey);
    }
  };

  const _resetChanges = async (values, setFieldValue) => {
    setLoading(true);

    let title = "About Professional Editing Tool";
    let text =
      "Professional Editing Tool (PET) is our industry-Leading Document Editing and Team Collaboration Solution that was built to help improve, integrate and govern document editing and team collaboration. Our Mission at PET is to provide industry with full featured multifunctional tools and services to meet each client's specific document editing needs. At PET, we understand the importance effective writing and clarity of ideas. For this reason, Your Professional Editing Tool (PET) was developed to provide professional editing and team collaboration services to all business professionals, students, educators, and writers. Simply put, we had the writers in mind!";
    let cards = [
      {
        Card_title: "24 / 7 Support",
        Card_text:
          "Since we want each individual person and organization to succeed in their Editing Needs, PET offers 24/7 technical support day or night. Our 24/7 support seamlessly transitions between getting support by virtual house calls, phone, chat, and/or DIY guides. Our Technical Support Professionals speak in everyday language and have the experience, know-how, and tools to solve your editing issues as quickly as possible.",
        Card_image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/feature1.png",
        Card_animation: "slideInLeft",
        Card_imageKey: "",
      },
      {
        Card_title: "Data Privacy",
        Card_text:
          "At PET, we act to protect data privacy online and improve personal data protection. We understand that data privacy protection concerns are driving new regulations around the world, and so we go beyond just complying with all the new requirements, but we ensure that we build trust with consumers and users and stand out from their competitors.",
        Card_image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/feature2.png",
        Card_animation: "slideInDown",
        Card_imageKey: "",
      },
      {
        Card_title: "Easy Write",
        Card_text:
          "Your PET document editing and team collaboration makes your writing easy. Your PET’s AI Algorithm know the English language inside out and will analyze your written English using over 160 English grammar rules. PET makes your writing easy by not just pointing out mistakes, but by actually fixing it for you, saving you the major headache and confusion of deciphering complex English rules. PET also provide the ability to collaborate live with other writers, which allow others to see what you missed and to ensure you are not alone.",
        Card_image:
          "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/feature3.png",
        Card_animation: "slideInRight",
        Card_imageKey: "",
      },
    ];

    console.log(values);
    setFieldValue(`About_title`, title);
    setFieldValue(`About_text`, text);
    setFieldValue(`About_Cards`, cards);

    if (values.About_Cards.length > 0) {
      for (let i = 0; i < values.About_Cards.length; i++) {
        await uploadImageServices.DeleteImageFromS3(
          values.About_Cards[i].Card_imageKey
        );
      }
    }

    if (DontDelete.length > 0) {
      for (let i = 0; i < DontDelete.length; i++) {
        await uploadImageServices.DeleteImageFromS3(DontDelete[i]);
      }
    }
    DontDelete = [];

    let finalData = mainData;
    finalData["About_title"] = title;
    finalData["About_text"] = text;
    finalData["About_Cards"] = cards;
    console.log(finalData);
    let post = await postHomeCMS(finalData);
    if (post.status === 200) {
      toast.success("Changes Updated");
    } else {
      toast.error("Failed To Save");
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid h-100" id="DocCard">
      <Loader loading={loading} />
      <Formik
        initialValues={value}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log(values);
          setLoading(true);
          if (DontDelete.length > 0) {
            for (let i = 0; i < DontDelete.length; i++) {
              await uploadImageServices.DeleteImageFromS3(DontDelete[i]);
            }
          }
          junkFile = [];
          DontDelete = [];
          let finalData = mainData;
          finalData["About_title"] = values.About_title;
          finalData["About_text"] = values.About_text;
          finalData["About_Cards"] = values.About_Cards;
          console.log(finalData);
          let post = await postHomeCMS(finalData);
          if (post.status === 200) {
            toast.success("Changes Updated");
          } else {
            toast.error("Failed To Save");
          }
          setLoading(false);
        }}
        render={({ values, setFieldValue }) => (
          <Form className="container">
            <HeadingB className="text-center alertHead pt-5" text="ABOUT" />

            {/* About Title */}
            <div class="form-group mt-5">
              <label>About Title</label>
              <hr />
              <InputField
                name="About_title"
                className="form-control w-50"
                placeholder="Title"
              />
              <ErrorMessage name="About_title">
                {(error) => <small style={{ color: "red" }}>{error}</small>}
              </ErrorMessage>
            </div>

            {/* About Sub-Text */}
            <div class="form-group mt-5">
              <label>About Sub-Text</label>
              <hr />
              <TextArea
                name="About_text"
                className="form-control w-50"
                placeholder="Sub-Text"
              />
              <ErrorMessage name="About_text">
                {(error) => <small style={{ color: "red" }}>{error}</small>}
              </ErrorMessage>
            </div>

            <FieldArray
              name="About_Cards"
              render={(arrayHelpers) => (
                <div>
                  {/* About Card */}
                  <div className="row mt-5">
                    <div className="col-md-6">
                      <label>About Card</label>
                    </div>
                    <div className="col-md-6">
                      {/* Add Card Button */}
                      <Button
                        style={{ float: "right" }}
                        variant="success"
                        onClick={() =>
                          arrayHelpers.push({
                            Card_title: "",
                            Card_text: "",
                            Card_image: "",
                            Card_animation: "slideInLeft",
                            Card_imageKey: "",
                          })
                        }
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i> Add Card
                      </Button>
                    </div>
                  </div>

                  <hr />

                  {values.About_Cards && values.About_Cards.length > 0
                    ? values.About_Cards.map((About_Cards, index) => (
                        <div key={index}>
                          <h2 className="mt-5">Card {index + 1}</h2>
                          <div className="row mt-5">
                            <div className="col-md-6">
                              <div className="text-center">
                                <Button
                                  variant="primary"
                                  className="w-50 mt-4"
                                  onClick={() => uploadPic(index)}
                                >
                                  <i
                                    className="fa fa-cloud-upload point"
                                    aria-hidden="true"
                                  ></i>
                                  <br />
                                  Upload Image
                                </Button>

                                <input
                                  type="file"
                                  id={`fileup[${index}]`}
                                  accept="image/*"
                                  onChange={(e) =>
                                    _handleImageChange(
                                      index,
                                      About_Cards,
                                      setFieldValue,
                                      e
                                    )
                                  }
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 text-center">
                              <img
                                id={`ImagePreview[${index}]`}
                                name={`About_Cards[${index}].Card_image`}
                                alt="Card"
                                style={{ width: "auto", height: "100px" }}
                                src={
                                  values.About_Cards[index].Card_image
                                    ? values.About_Cards[index].Card_image
                                    : LogoImg
                                }
                              ></img>
                              <br />
                              <ErrorMessage
                                name={`About_Cards[${index}].Card_image`}
                              >
                                {(error) => (
                                  <small style={{ color: "red" }}>
                                    {error}
                                  </small>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <br />

                          <div className="mt-5 text-center">
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>Card Title</InputGroup.Text>
                              </InputGroup.Prepend>
                              <InputField
                                name={`About_Cards[${index}].Card_title`}
                                className="form-control"
                                maxLength="20"
                                placeholder="Card Title"
                              />
                            </InputGroup>
                            <small style={{ float: "left" }}>
                              Length: {About_Cards.Card_title.length}/20
                            </small>
                            <ErrorMessage
                              name={`About_Cards[${index}].Card_title`}
                            >
                              {(error) => (
                                <small style={{ color: "red", float: "right" }}>
                                  {error}
                                </small>
                              )}
                            </ErrorMessage>

                            <InputGroup className="mt-5">
                              <InputGroup.Prepend>
                                <InputGroup.Text>Card Text</InputGroup.Text>
                              </InputGroup.Prepend>
                              <TextArea
                                name={`About_Cards[${index}].Card_text`}
                                className="form-control"
                                maxLength="350"
                                placeholder="Card Text"
                              />
                            </InputGroup>
                            <small style={{ float: "left" }}>
                              Length: {About_Cards.Card_text.length}/350
                            </small>
                            <ErrorMessage
                              name={`About_Cards[${index}].Card_text`}
                            >
                              {(error) => (
                                <small style={{ color: "red", float: "right" }}>
                                  {error}
                                </small>
                              )}
                            </ErrorMessage>
                            <br />

                            {/* <InputGroup className="mt-4">
                                                <InputGroup.Prepend>
                                                <InputGroup.Text>Animation</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Field
                                                    name={`About_Cards[${index}].Card_animation`}
                                                    as={SelectField}
                                                    className="w-50"
                                                >
                                                    <option className="point" value="slideInLeft">Slide In Left</option>
                                                    <option className="point" value="slideInDown">Slide In Down</option>
                                                    <option className="point" value="slideInRight">Slide In Right</option>
                                                </Field>
                                            </InputGroup> */}
                            <br />

                            <Button
                              variant="danger"
                              className="w-25 mt-3"
                              onClick={() =>
                                removeCard(index, About_Cards, arrayHelpers)
                              }
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>{" "}
                              Delete Card
                            </Button>
                          </div>
                          <hr />
                        </div>
                      ))
                    : ""}
                </div>
              )}
            />

            {/* Save Button */}
            <div className="text-center">
              <Button
                variant="danger"
                onClick={() => _resetChanges(values, setFieldValue)}
                className="w-25 mr-5"
              >
                RESET
              </Button>
              <Button type="submit" className="mt-5 mb-5 w-25">
                SAVE
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
}

export default AboutCMS;
