import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getHomeCMS } from "../../services/adminServices";
import { getFaqCMS } from "../../services/adminServices";
import {getTermsOfServiceCMS} from "../../services/adminServices";
import {getPrivacyCMS} from "../../services/adminServices";
import LOGO from "./LoGo";
import Introduction from "./Introduction";
import About from "./AboutCMS";
import FaqCMS from "./FaqCMS";
import FeaturesCMS from "./FeaturesCMS";
import TosCMS from "./TermsOfServiceCMS";
import PrivacyCMS from "./PrivacyCMS";
import FooterCMS from "./FooterCMS";
import FinalPreview from "./FinalPreview"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Navbar",
    "Introduction",
    "About",
    "Features",
    "Footer",
    "FAQ",
    "Terms Of Service",
    "Privacy Policy",
    "Final Preview",
  ];
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [cmsData, setCMSData] = useState({});
  const [faqData, setFaqData] = useState({});
  const [tosData, setTosData] = useState({});
  const [privacyData, setPrivacyData] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = async () => {
    let { data } = await getHomeCMS();
    setCMSData(data);
    console.log(data);

    let faqdata = await getFaqCMS();
    setFaqData(faqdata);
    console.log(faqdata);

    let tosdata = await getTermsOfServiceCMS();
    setTosData(tosdata);
    console.log(tosdata);

    let privacydata = await getPrivacyCMS();
    setPrivacyData(privacydata);
    console.log(privacydata);
  };

   
  const setActive = (index) => {
    setActiveStep(index);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel id="DocCard">
        {steps.map((label, index) => (
          <Step key={label} className="stepActive">
            <StepLabel onClick={() => setActive(index)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>{activeStep === 0 ? <LOGO /> : ""}</div>
      <div>{activeStep === 1 ? <Introduction cmsData={cmsData} /> : ""}</div>
      <div>{activeStep === 2 ? <About cmsData={cmsData} /> : ""}</div>
      <div>{activeStep === 3 ? <FeaturesCMS cmsData={cmsData} /> : ""}</div>
      <div>{activeStep === 4 ? <FooterCMS cmsData={cmsData}/> : ""}</div>
      <div>{activeStep === 5 ? <FaqCMS cmsData={faqData} /> : ""}</div>
      <div>{activeStep === 6 ? <TosCMS cmsData={tosData}/> : ""}</div>
      <div>{activeStep === 7 ? <PrivacyCMS cmsData={privacyData}/> : ""}</div>
      <div>{activeStep === 8 ? <FinalPreview/> : ""}</div>
    </div>
  );
}
