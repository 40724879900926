import React, { useEffect, useState } from "react";
import Logo from "../../static/upl.png";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import blueBG from "../../static/blue-bg.png";
import lappy from "../../static/lappy.png";
import { getHomeCMS, postHomeCMS } from "../../services/adminServices";
import imageCompression from "browser-image-compression";
import uploadImageServices from "../../services/uploadImageServices";
import HeadingB from "../Cards/HeadingB";
import { InputGroup, FormControl } from "react-bootstrap";
import { isNumeric } from "jquery";

const useStyles = makeStyles({
  root: {
    minWidth: 150,
    width: 150,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

let junkFile = [];
let DontDelete = [];

function Introduction({ cmsData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dataCMS, setCMSData] = useState(cmsData);
  const [imageBGURL, setImageBGURL] = useState(cmsData.Background_left.Image);
  const [compressedFile, setCompressedFile] = useState(null);
  const [errorTitle, setErrortitle] = useState("");
  const [errorSubtext, setErrorSubtext] = useState("");
  const [title, setIntroTitle] = useState(cmsData.Project_title);
  const [subtext, setIntroSubtext] = useState(cmsData.Project_subtext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [imageURL, setImageURL] = useState(cmsData.Background_right.Image);
  const [backgroundDetails, setBackgroundDetails] = useState(
    dataCMS.Background_right
  );
  const [errorDimension, seterrorDimension] = useState("");

  useEffect(()=>{
      return () => {
          _removeJunk();
      };
  }, [])

  const _removeJunk = async() => {
      console.log("junkFile===>",junkFile);
      if(junkFile.length > 0){
          for(let i=0; i<junkFile.length; i++){
              await uploadImageServices.DeleteImageFromS3(junkFile[i]);
          }
      }
  }

  const handleDimensions = ({ currentTarget: { value, name } }) => {
    const data = { ...dataCMS };
    let values = {
      Background_right: backgroundDetails,
    };
    console.log("value===>", value);
    if (isNumeric(value) === false) {
      seterrorDimension(`*${name} should be a number.`);
      setButtonDisable(true);
    } else {
      seterrorDimension("")
      setButtonDisable(false);

    }
    values.Background_right[name] = value;
    setBackgroundDetails(values.Background_right);
    data.Background_right = values.Background_right;
    setCMSData(data);
  };

  const handleTitleChange = (e) => {
    const data = { ...dataCMS };
    setIntroTitle(e.target.value);
    let titleIntro = e.target.value;
    console.log(title);
    if (titleIntro.length === 0) {
      setErrortitle("*Required Field");
      setButtonDisable(true);
    } else {
      setErrortitle("");
      if (title.length > 50) {
        setErrortitle("Character length Exceeding");
        setButtonDisable(true);
      } else {
        setErrortitle("");
        setButtonDisable(false);
        data["Project_title"] = titleIntro;
        setIntroTitle(titleIntro);
        setCMSData(data);
        console.log(dataCMS);
      }
    }
  };

  const handleSubtextChange = (e) => {
    setIntroSubtext(e.target.value);
    let textIntro = e.target.value;
    if (textIntro.length === 0) {
      setErrorSubtext("*Required Field");
      setButtonDisable(true);
    } else {
      setErrorSubtext("");
      if (textIntro.length > 350) {
        setErrorSubtext("Character length Exceeding");
        setButtonDisable(true);
      } else {
        setErrorSubtext("");
        setButtonDisable(false);
        setIntroSubtext(textIntro);
      }
    }
  };

  const uploadPic = (index) => {
    document.getElementById(`fileup[${index}]`).click();
  };
  const _handleImageChange = async (index, e) => {
    e.preventDefault();
    console.log(index);
    let file = e.target.files[0];
    let data = dataCMS;
    let Image_Key = "";
    if (file) {
      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        maxIteration: 10,
        useWebWorker: true,
      };
      setLoading(true);
      if (index === 0) {
        Image_Key = cmsData.Background_left.ImageKey;
      } else {
        Image_Key = cmsData.Background_right.ImageKey;
      }
      const compressedImage = await imageCompression(file, options);
      const uploadConfig = await uploadImageServices.UploadImageToS3(
        compressedImage,
        ""
      );

      DontDelete.push(Image_Key);
      junkFile.push(uploadConfig.data.key);

      let reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onload = function () {
        if (index === 0) {
          setImageBGURL(uploadConfig.data.displayUrl);
          data.Background_left.Image = uploadConfig.data.displayUrl;
          data.Background_left.ImageKey = uploadConfig.data.key;
          setCMSData(data);
        } else {
          setImageURL(uploadConfig.data.displayUrl);
          data.Background_right.Image = uploadConfig.data.displayUrl;
          data.Background_right.ImageKey = uploadConfig.data.key;
          setCMSData(data);
          console.log(dataCMS);
        }

        setLoading(false);
      };
    }
  };

  const saveData = async () => {
    setLoading(true);
    let data = dataCMS;
    if(DontDelete.length > 0){
        for(let i=0; i<DontDelete.length; i++){
            await uploadImageServices.DeleteImageFromS3(DontDelete[i]);
        }
    }
    junkFile = [];
    DontDelete = [];
    // if (compressedFile) {
    //   let key = document
    //     .getElementById("backGroundPreview")
    //     .getAttribute("name");
    //   if (!key) {
    //     key = null;
    //   }
    //   const uploadConfig = await uploadImageServices.UploadImageToS3(
    //     compressedFile,
    //     cmsData.Background_left.ImageKey
    //   );

    //   data.Background_left.Image = uploadConfig.data.displayUrl;
    //   data.Background_left.ImageKey = uploadConfig.data.key;
    // }
    data["Project_subtext"] = subtext;
    data.Background_right.height = backgroundDetails.height;
    data.Background_right.width = backgroundDetails.width;
    data.Background_right.margin_top = backgroundDetails.margin_top;
    data.Background_right.margin_bottom = backgroundDetails.margin_bottom;
    data.Background_right.margin_right = backgroundDetails.margin_right;
    data.Background_right.margin_left = backgroundDetails.margin_left;

    console.log(data);
    let post = await postHomeCMS(data);
    if (post.status === 200) {
      toast.success("Changes Updated");
    } else {
      toast.error("Failed To Save");
    }

    setLoading(false);
  };

  const _resetChanges = async () => {
    setLoading(true);
    if (cmsData.Background_left.ImageKey) {
      setImageBGURL("")
      await uploadImageServices.DeleteImageFromS3(cmsData.Background_left.ImageKey);
    }
    if (cmsData.Background_right.ImageKey) {
      setImageURL("");
      await uploadImageServices.DeleteImageFromS3(cmsData.Background_right.ImageKey);
    }
    let mainData = cmsData;
    mainData.Background_left.Image = mainData.Background_left.Default_Image;
    mainData.Background_left.ImageKey = "";
    mainData.Background_right.Image = mainData.Background_right.Default_Image;
    mainData.Background_right.ImageKey = "";
    mainData.Project_title="Welcome to Professional Editing Tools"
    mainData.Project_subtext=`PET is the industry-leading document editing and team collaboration software for Businesses, Educators, Students and Researchers. PET, integration with other apps and services, with the power of AI brings all the state-of-the-art, extensive features which are available in multiple writing tools. Some other features are coming up!`
    setIntroTitle(mainData.Project_title)
    setIntroSubtext( mainData.Project_subtext)
    mainData.Background_right.height ="75";
    mainData.Background_right.width ="130";
    mainData.Background_right.margin_top ="10" ;
    mainData.Background_right.margin_bottom = "0";
    mainData.Background_right.margin_right ="0" ;
    mainData.Background_right.margin_left = "50";
    console.log(mainData);
    let post = await postHomeCMS(mainData);
    if (post.status === 200) {
      toast.success("Changes Updated");
    } else {
      toast.error("Failed To Save");
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid h-100" id="DocCard">
      <Loader loading={loading} />
      <div className="container">
        <HeadingB className="text-center alertHead pt-5" text="INTRODUCTION" />
        <div className="mt-5">
          <label for="introTitle">Introduction Title</label>
          <hr />
          <input
            type="text"
            className="form-control w-50 ml-3 mt-2"
            id="introTitle"
            onChange={(e) => handleTitleChange(e)}
            value={title || ""}
            name="Project_title"
            placeholder="Enter Introduction Title"
            maxLength="50"
          />
          <small className="ml-3">Length: {title.length}/50</small>
          <small style={{ color: "red", marginLeft: "35%" }} class=" ">
            {errorTitle}
          </small>
        </div>
        <div className="mt-5">
          <label for="introSubtext">Introduction Subtext</label>
          <hr />
          <textarea
            class="form-control w-50 ml-3 mt-2"
            id="introSubtext"
            placeholder="Enter Introduction Subtext"
            value={subtext || ""}
            onChange={(e) => handleSubtextChange(e)}
            name="Project_subtext"
            rows="4"
            maxLength="350"
          ></textarea>
          <small className="ml-3">Length: {subtext.length}/350</small>
          <small style={{ color: "red", marginLeft: "35%" }} class=" ">
            {errorSubtext}
          </small>
        </div>
        <div className="mt-5">
          <label for="introSubtext">Introduction Background Image</label>
          <hr />
          <div className="row mt-3">
            <div className="col-md-6">
              <Card
                className={classes.root}
                variant="outlined"
                style={{ textAlign: "center", marginLeft: "35%" }}
              >
                <CardContent>
                  <Icon
                    className="fa fa-plus-circle point"
                    style={{ fontSize: 30, color: "#007bff" }}
                    color="primary"
                    onClick={() => uploadPic(0)}
                  />
                  <Typography variant="body2" component="p">
                    Upload Image
                  </Typography>
                </CardContent>
              </Card>
              <input
                className="fileInput"
                type="file"
                id="fileup[0]"
                accept="image/*"
                onChange={(e) => _handleImageChange(0, e)}
                style={{ display: "none" }}
              />
            </div>
            <div className="col-md-6 text-center">
              {" "}
              <img
                id="backGroundPreview"
                className="petLogo"
                alt="Group570"
                style={{ width: "auto", height: "100px" }}
                src={
                  // dataCMS.Background_left.Image
                  imageBGURL
                    ? imageBGURL
                    : // dataCMS.Background_left.Image
                      dataCMS.Background_left.Default_Image
                }
              ></img>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <label for="introSubtext">Introduction Right Image</label>
          <hr />
          <div className="row mt-3">
            <div className="col-md-6">
              <Card
                className={classes.root}
                variant="outlined"
                style={{ textAlign: "center", marginLeft: "35%" }}
              >
                <CardContent>
                  <Icon
                    className="fa fa-plus-circle point"
                    style={{ fontSize: 30, color: "#007bff" }}
                    onClick={() => uploadPic(1)}
                  />
                  <Typography variant="body2" component="p">
                    Upload Image
                  </Typography>
                </CardContent>
              </Card>
              <input
                className="fileInput"
                type="file"
                id="fileup[1]"
                accept="image/*"
                onChange={(e) => _handleImageChange(1, e)}
                style={{ display: "none" }}
              />
              <div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Width</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        className="form-control"
                        placeholder="0"
                        name="width"
                        value={dataCMS.Background_right.width}
                        onChange={handleDimensions}
                      />
                      {/* <InputField className="form-control" placeholder="0" /> */}
                      <InputGroup.Append>
                        <InputGroup.Text>vw</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>

                  <div className="col-md-6">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Height</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        className="form-control"
                        placeholder="0"
                        name="height"
                        value={dataCMS.Background_right.height}
                        onChange={handleDimensions}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>vh</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Margin Top</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        className="form-control"
                        placeholder="0"
                        name="margin_top"
                        value={dataCMS.Background_right.margin_top}
                        onChange={handleDimensions}
                      />
                      {/* <InputField className="form-control" placeholder="0" /> */}
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>

                  <div className="col-md-6">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Margin Bottom</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        className="form-control"
                        placeholder="0"
                        name="margin_bottom"
                        value={dataCMS.Background_right.margin_bottom}
                        onChange={handleDimensions}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Margin Left</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        className="form-control"
                        placeholder="0"
                        name="margin_left"
                        value={dataCMS.Background_right.margin_left}
                        onChange={handleDimensions}
                      />
                      {/* <InputField className="form-control" placeholder="0" /> */}
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>

                  <div className="col-md-6">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Margin Right</InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        className="form-control"
                        placeholder="0"
                        name="margin_right"
                        value={dataCMS.Background_right.margin_right}
                        onChange={handleDimensions}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="row ">
                  <small style={{ color: "red" }} class="ml-3 ">
                    {errorDimension}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img
                className="petLogo"
                alt="Group570"
                style={{ width: "auto", height: "100px" }}
                src={
                  // dataCMS.Background_left.Image
                  imageURL
                    ? imageURL
                    : // dataCMS.Background_left.Image
                      dataCMS.Background_right.Default_Image
                }
              ></img>
            </div>
          </div>
        </div>
        <div className="mt-3 text-center">
        
          <button
            className="btn btn-danger mr-2 mb-4"
            type="submit"
            id="backBtn"
            onClick={_resetChanges}
            style={{
              width: "20%",
            }}
            disabled={buttonDisable}
          >
            Reset Changes
          </button>

          <button
            className="btn btn-primary mb-4"
            type="submit"
            id="backBtn"
            onClick={saveData}
            style={{
              width: "20%",
            }}
            disabled={buttonDisable}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default Introduction;
