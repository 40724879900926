import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  }
}));

const color = ["#bff603", "yellow", "#80d4ff", "orange"];

export default function LetterAvatars({ colorIndex, name }) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
          <Avatar style={{ backgroundColor: `${color[colorIndex]}`, color:"black" }} >  
          {name}      
          </Avatar>
      </div>
  );
}
