import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import Radio from "@material-ui/core/Radio";
import InputBoxOne from "./FormComponents/InputBoxOne";
import InputBoxTwo from "./FormComponents/InputBoxTwo";
import HeadOne from "./FormComponents/HeadOne";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormButtons from "./FormComponents/FormButtons";
import deletes from "../../static/delete.png";
import plus from "../../static/plus.png";
import InputBoxThree from "./FormComponents/InputBoxThree";
import InputFeedback from "./FormComponents/InputFeedBack";
import { citationStructure } from "../../utils/citationWithRandomNumber";
import { savecitations, updateCitations } from "../../services/citationService";
import { ToastContainer, toast } from "react-toastify";
import { withRouter, Link } from "react-router-dom";
import Loader from "../../utils/loader";

const RadioField = (props) => {
  const [field] = useField(props);
  return <Radio {...field} color="primary" />;
};

const validationSchema = Yup.object().shape({
  articletitle: Yup.string().required("Mandatory"),

  author: Yup.array().of(
    Yup.object().shape({
      given: Yup.string().required("Mandatory"),
      family: Yup.string().required("Mandatory"),
    })
  ),

  title: Yup.string().required("Mandatory"),
  publisher: Yup.string().required("Mandatory"),
  year: Yup.number().required("Mandatory").positive(),
  displayURL: Yup.string().required("Mandatory"),
  URL: Yup.string().when("displayURL", {
    is: "1",
    then: Yup.string().required("Mandatory"),
  }),

  accessed: Yup.array().of(
    Yup.object({
      year: Yup.number().required("Mandatory").positive(),
    })
  ),
});

let value = {};

function WebsiteForm({ history, initialValues, projectID }) {
  const [url, seturl] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(initialValues);
  if (initialValues) {
    console.log("doneee...!");
    value = initialValues;
  } else {
    value = {
      articletitle: "",
      author: [
        {
          title: "Author",
          given: "",
          middlename: "",
          family: "",
          suffix: "",
        },
      ],
      title: "",
      URL: "",
      displayURL: "0",
      publisher: "",
      year: "",
      issued: {
        "date-parts": [["", "", ""]],
      },
      accessed: [
        {
          day: "",
          month: "",
          year: "",
        },
      ],
      note: "",
      type: "website",
    };
  }

  function resetForm() {
    value = {};
  }
  return (
    <div>
      <div
        className="container bg-white rounded shadow"
        // style={{ height: "1250px" }}
      >
        <div className="row pt-5">
          <HeadOne />
        </div>
        <ToastContainer />
        <Formik
          initialValues={value}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            let response = "";
            if (!values._id) {
              response = await savecitations(values, projectID);
            } else {
              response = await updateCitations({
                _id: values._id,
                data: values,
              });
            }
            if (response.status !== 200) {
              toast.error(response.message);
            } else {
              toast.info(response.message);
              history.push({
                pathname: `/citationList`,
                state: { projectId: projectID },
              });
            }
            setLoading(false);
          }}
          render={({ values, touched, errors, resetForm }) => (
            <Form>
              <Loader loading={loading} />
              <div>
                <div className="row mt-5" style={{ marginLeft: "142px" }}>
                  <h3>What I'm citing</h3>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: "142px", marginTop: "20px" }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      lineHeight: "19px",
                    }}
                  >
                    Article title
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "142px" }}>
                  <Field name={`articletitle`} as={InputBoxOne} />
                  {errors.articletitle && touched.articletitle ? (
                    <div>{errors.articletitle}</div>
                  ) : null}
                </div>
              </div>
              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* author */}

              <FieldArray
                name="author"
                render={(arrayHelpers) => (
                  <div>
                    <div style={{ marginLeft: "140px", marginTop: "10px" }}>
                      <h3>Authors</h3>
                    </div>
                    {values.author && values.author.length > 0
                      ? values.author.map((author, index) => (
                          <div
                            key={index}
                            style={{ marginLeft: "150px", marginTop: "10px" }}
                          >
                            <div
                              className="row"
                              style={{ marginTop: "20px", marginLeft: "-10px" }}
                            >
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>Article title</h6>
                                </div>
                                <div className="row">
                                  <Field
                                    name={`author[${index}].title`}
                                    as="select"
                                    placeholder="title"
                                  >
                                    <option value="Author">Author</option>
                                    <option value="Editor">Editor</option>
                                    <option value="Translator">
                                      Translator
                                    </option>
                                  </Field>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="row">
                                  <h6>First Name</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].given`}
                                    as={InputBoxOne}
                                  />
                                  <ErrorMessage
                                    as={InputFeedback}
                                    name="author[0].given"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>MI / Middle</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].middlename`}
                                    as={InputBoxTwo}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="row">
                                  <h6>Last Name</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].family`}
                                    as={InputBoxOne}
                                  />
                                  <ErrorMessage
                                    as={InputFeedback}
                                    name="author[0].family"
                                  />
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="row">
                                  <h6>suffix</h6>
                                  <br />
                                  <Field
                                    name={`author[${index}].suffix`}
                                    as={InputBoxTwo}
                                  />
                                </div>
                              </div>
                              {values.author && values.author.length == 1 ? (
                                ""
                              ) : (
                                <div className="col-md-1">
                                  <div className="row">
                                    <img
                                      alt="delete"
                                      src={deletes}
                                      style={{
                                        height: "20px",
                                        width: "15px",
                                        marginLeft: "8px",
                                        marginTop: "30%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="col-md-2"></div>
                            </div>
                          </div>
                        ))
                      : ""}
                    <div
                      className="row"
                      style={{ marginTop: "20px", marginLeft: "125px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outlined-primary"
                        style={{ color: "#1976d2" }}
                        onClick={() =>
                          arrayHelpers.push({
                            title: "Author",
                            given: "",
                            family: "",
                            suffix: "",
                            middle: "",
                          })
                        }
                      >
                        <img src={plus} className="ft_sm mr-2" alt="plus" />
                        Add another Author
                      </button>
                    </div>
                  </div>
                )}
              />

              {/* author */}

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* Online publication info */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Online publication info
                  </h3>
                </div>

                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <h6
                      style={{
                        marginLeft: "-3%",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "19px",
                        marginLeft: "-5px",
                      }}
                    >
                      Website title
                    </h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <Field name={`title`} as={InputBoxOne} />
                      {errors.title && touched.title ? (
                        <div>{errors.title}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h6
                      style={{
                        marginLeft: "-3%",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "19px",
                      }}
                    >
                      Publisher/sponsor
                    </h6>
                    <br />
                    <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                      <Field name={`publisher`} as={InputBoxOne} />

                      {errors.publisher && touched.publisher ? (
                        <div>{errors.publisher}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "20px" }}></div>
                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Electronically published
                  </h6>
                </div>

                <FieldArray
                  name="issued"
                  render={(arrayHelpers) => (
                    <div>
                      <div
                        className="row"
                        style={{ marginTop: "10px", marginLeft: "-8px" }}
                      >
                        {values.issued["date-parts"][0].map((date, index) => (
                          <div className="col-md-2">
                            <div className="row">
                              <h6>
                                {index == 0
                                  ? "Year"
                                  : index == 1
                                  ? "Month"
                                  : "Day"}
                              </h6>
                              <br />
                              <Field
                                name={
                                  index == 0
                                    ? "year"
                                    : `issued["date-parts"][0][${index}]`
                                }
                                as={index === 0 ? InputBoxOne : InputBoxTwo}
                              />
                              {index == 0 ? (
                                <ErrorMessage as={InputFeedback} name="year" />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                />

                <div className="row mt-4">
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Date accessed
                  </h6>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-2">
                    <div className="row" style={{ marginLeft: "-6px" }}>
                      <h6>Day</h6>
                      <br />
                      <Field as={InputBoxTwo} name="accessed[0].day" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Month</h6>
                      <br />
                      <Field as={InputBoxTwo} name="accessed[0].month" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <h6>Year</h6>
                      <br />
                      <Field as={InputBoxOne} name="accessed[0].year" />
                      <ErrorMessage
                        as={InputFeedback}
                        name="accessed[0].year"
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-3">
                      <div className="row">
                        <h6
                          style={{
                            fontSize: "10px",
                            lineHeight: "13px",
                            color: "#707070",
                            marginTop: "13%",
                          }}
                        >
                          Use Today's Date
                        </h6>
                      </div>
                    </div> */}
                </div>

                {/* <FieldArray
                  name="accessed"
                  render={(arrayHelpers) => (
                    <div>
                      {values.accessed.map((author, index) => (
                        <div className="row" style={{ marginTop: "10px",marginLeft:"-8px" }}>
                          <div className="col-md-2">
                            <div className="row">
                              <h6>Day</h6>
                              <br />
                              <Field
                                name={`accessed[${index}].day`}
                                as={InputBoxTwo}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <h6>Month</h6>
                              <br />
                              <Field
                                name={`accessed[${index}].month`}
                                as={InputBoxTwo}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <h6>Year</h6>
                              <br />
                              <Field
                                name={`accessed[${index}].year`}
                                as={InputBoxOne}
                              />
                              <ErrorMessage
                                as={InputFeedback}
                                name="accessed[0].year"
                              />
                              {errors.year && touched.year ? (
                                <div>
                                  {errors.year}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="row">
                              <h6
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "13px",
                                  color: "#707070",
                                  marginTop: "13%",
                                }}
                              >
                                Use Today's Date
                        </h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                /> */}
              

              <div className="row" style={{ marginTop: "20px" }}>
                      <h6
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "19px",
                          marginLeft: "8px",
                        }}
                      >
                        Display URL
                      </h6>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          row={true}
                          value={url}
                          onChange={(e) => seturl(e.target.value)}
                        >
                          <FormControlLabel
                            control={
                              <Field
                                name={`displayURL`}
                                type="radio"
                                value="1"
                                as={RadioField}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            control={
                              <Field
                                name={`displayURL`}
                                type="radio"
                                value="0"
                                as={RadioField}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>

                      <div className="col-md-6">
                        <h6
                          style={{
                            fontSize: "10px",
                            lineHeight: "13px",
                            color: "#707070",
                            marginTop: "2%",
                          }}
                        >
                          Leave out the URL unless the source cannot be located
                          without it.
                        </h6>
                      </div>
                    </div>

                    {url === "1" ? (
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <h6
                            style={{
                              marginLeft: "-3%",
                              fontWeight: "bold",
                              fontSize: "14px",
                              lineHeight: "19px",
                            }}
                          >
                            URL
                          </h6>
                          <br />
                          <div style={{ marginLeft: "-3%", marginTop: "-4%" }}>
                            <Field name="URL" as={InputBoxOne} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    </div>

              <hr
                style={{
                  marginTop: "30px",
                  width: "900px",
                  marginLeft: "100px",
                }}
              />

              {/* More Options */}

              <div style={{ marginLeft: "150px", marginTop: "10px" }}>
                <div className="row">
                  <h3 style={{ marginLeft: "8px" }}>More Options</h3>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "19px",
                      marginLeft: "8px",
                    }}
                  >
                    Annotation
                  </h6>
                </div>
                <div className="row" style={{ marginLeft: "-5px" }}>
                  <Field name={`note`} as={InputBoxThree} />
                </div>
              </div>

              {/* More Options */}

              <div
                className="row"
                style={{ marginTop: "7%", paddingBottom: "5%" }}
              >
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{
                        width: "15%",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
}
export default withRouter(WebsiteForm);
