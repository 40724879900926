import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import userService from "../../services/userService";
import authService from "../../services/authService";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.actions";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';

function PriceTag({
  price_detail,
  planPeriod,
  planName,
  planList,
  plan_mode,
  price_size = "80px",
  bg_color = "bg-gray",
  hide,
  history,
  currentUser,
  hide_mode,
  plan_id,
}) {
  let detail = { price: null, user_count: null, period: null, plan_mode: null };
  const [details, setdetails] = useState(detail);
  const [user_count_list, setuser_count] = useState([]);
  let [plan_period, setplan_period] = useState(planPeriod);
  const [location, setLocation] = useState(useLocation());

  useEffect(() => {
    console.log("priceTag=====>", planPeriod);
    console.log(hide_mode);
    setplan_period(planPeriod);
    plan_period = planPeriod;
    initialCall();
  }, [planPeriod]);

  const initialCall = () => {
    let time_period = Object.keys(price_detail); // ['monthly', 'yearly']
    if (!plan_period) {
      let first_plan_period = time_period[0];
      let { price, user_count } = price_detail[first_plan_period][0]; // {price:0, user_count:null}
      setdetails({ price, user_count, period: first_plan_period });
      setuser_count(price_detail[first_plan_period]);
      setplan_period(first_plan_period);
    } else {
      let { price, user_count } = price_detail[plan_period][0];
      setdetails({ price, user_count, period: plan_period });
      console.log(price_detail[plan_period]);
      setuser_count(price_detail[plan_period]);
    }
  };

  const dropOnChange = (count) => {
    let arr = price_detail[plan_period];
    let findObj = arr.find((obj) => obj.user_count == count); // {price: 2400, user_count: 20}
    const { price, user_count } = findObj;
    setdetails({ price, user_count, period: plan_period });
  };

  const buyPlan = () => {
    console.log(currentUser);
    let data = details;
    console.log(data);
    data.planName = planName;
    data["plan_id"] = plan_id;
    data.plan_mode = plan_mode.mode;
    const user = authService.getCurrentUser();

    if (
      currentUser.planName !== data.planName &&
      currentUser.planName !== "PET Basic Plan"
    ) {
      Swal.fire({
        title:
          `${currentUser.planName} features will no longer exist with  ${data.planName}`,
        type: "warning",

        showCancelButton: true,
        showCloseButton: true,

        confirmButtonText: "Okay!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value === true) {
          if (user) {
            history.push({
              pathname: `/payment`,
              state: { data: data },
            });
          } else {
            history.push({
              pathname: "/login",
            });
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel");
        }
      });
    } else {
      if (user) {
        history.push({
          pathname: `/payment`,
          state: { data: data },
        });
      } else {
        history.push({
          pathname: "/login",
        });
      }
    }
  };

  return (
    <div>
      <div className={`price_sticky ${bg_color} p-3 rounded`}>

          {location.pathname === "/pricedetails" || location.pathname === "/homepricedetails" ? (
            <h3 style={{textAlign: "center"}}>{planName}</h3>
          ) : ("")}
        <div className="price-tag d-flex justify-content-center">

          <sub className="d-flex align-items-center doller">$</sub>
          <h1 style={{ fontSize: price_size }}>{details.price}</h1>
          <sub className="d-flex align-items-center mon">
            /{details.period && details.period.slice(0, 3)}{" "}
          </sub>
        </div>
        <div style={{ height: "25px" }}>
          {plan_mode.mode === "Team" && user_count_list.length >= 1 && (
            <p className="text-center ft_md text-h">
              User count
              <select
                className="user_count"
                onChange={(e) => dropOnChange(e.target.value)}
              >
                {user_count_list.map((obj) => (
                  <option value={obj.user_count} key={obj.price}>
                    {obj.user_count}{" "}
                  </option>
                ))}
              </select>
            </p>
          )}
        </div>
        {/* to show the list in only priceing pricing page */}
        {planList && (
          <div>
            <br />
            <div style={{ height: "20vh", overflowY: "auto" }}>
              <p className="plans text-left">{planList}</p>
            </div>
            <br /> <br />
          </div>
        )}

        <div className="text-center" style={{ height: "40px" }}>
          {details.price > 0 ? (
            <>
              {hide === true || hide_mode === true ? (
                <Link to={{ pathname: "/login" }}>
                  <button type="button" class="btn btn-primary w-75">
                    SIGN IN
                  </button>
                </Link>
              ) : (
                <>
                  {currentUser && currentUser.isAdmin ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      class="btn px-4  btn-primary w-75"
                      onClick={buyPlan}
                    >
                      {currentUser.planName === planName ? "RE-BUY" : "BUY"}
                    </button>
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PriceTag));
