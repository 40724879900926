import React, { Component } from "react";
import Loader from "../utils/loader";
import { withRouter } from "react-router-dom";
import documentService from "../services/document";
import blogService from "../services/blogServices";
import { ToastContainer } from "react-toastify";
import "./TextEditor.css";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import authService from "../services/authService";
import GrammerCheck from "./Editor/GrammerCheck";
import debounce from "lodash/debounce";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { option_ } from "./Editor/utilize";
import { connect } from "react-redux";
import {
  addCreatedDocument,
  setUserDocument,
} from "../redux/document/document.actions";
import uploadImageServices from "../services/uploadImageServices";

class TextEditor extends Component {
  state = {
    loading: false,
    contentOnly: "",
    contentHtml: "",
    text: "",
    documents: [],
    documentId: "",
    docTitle: "",
    tagInputRef: "",
    content: "",
    tags: [],
    saved: null,
    editorId: "",
    editing: false,
    html: "",
    documentData: {},
    image: [],
    content: '',
    junk: []
  };

  onChangeTitle = (value) => {
    const { content } = this.state;
    this.setState({
      docTitle: value,
    }, () => {
      if (content) {
        const requestData = {
          documentName: this.state.docTitle,
          onlyDocumentContent: content,
          formattedDocumentContent: content,
        }
        this.saveOnTitleChange(requestData);
      }
    });
  };

  saveOnTitleChange = (data) => {
    const { documentId } = this.state;
    if(documentId){
      data["documentId"] = documentId;
      console.log("saveOnTitleChange===>Fn called with Document ID",documentId);
      this.setState({ saved: true }, () => {
        this.autoSaveContent(data);
      });
    }
  }


  autoSaveDocument = async () => {
    const { docTitle, editorId, documentId } = this.state;
    if (!documentId && editorId) {
      if (docTitle) {
        const docs = {
          documentName: docTitle,
          documentType: this.props.documentType
            ? this.props.documentType
            : "document",
        };
        this.setState({ saved: true }, () => {
          this.autoDocumentCreation(docs);
        });
      } else if (documentId) {
        const data = {
          documentId: documentId,
          documentName: docTitle,
        };
        this.setState({ saved: true }, () => {
          this.autoSaveContent(data);
        });
      }
    } else {
      // this.autoDocumentDeletion(data);
    }
  };
  componentDidMount() {
    const { data, isNew, features, documentType } = this.props;
    const user = authService.getCurrentUser();
    if (data) {
      this.child.setPropsValue(data.onlyDocumentContent, data._id);
      this.setState(
        {
          documentId: isNew ? "" : data._id,
          tags: data.tags,
          content: data.onlyDocumentContent,
          html: data.onlyDocumentContent,
          docTitle: isNew ? "" : data.documentName,
          image: data.imageUrl,
          editorId : user._id,
        },
        () => {
          if (isNew) {
            let docs = { ...data };
            docs['documentName'] = "dummyName";
            docs["date"] = new Date();
            docs["month"] = new Date().getMonth() + 1;
            docs["year"] = new Date().getFullYear();
            console.log("docs====>",docs)
            this.setState({ saved: true }, () => {
              this.autoDocumentCreation(docs);
            });
          }
        }
      );
    } else {
      this.initalCall();
    }
  }

  initalCall = async () => {
    const user = authService.getCurrentUser();
    this.setState({
      dashLink: "/dashboard",
      editorId: user._id,
    });
  };

  handleTextEditor = (data, wordsCount, characterCount) => {
    let { documentId, image } = this.state;
    const socketData = {
      documentName: this.state.docTitle,
      documentId: documentId,
      onlyDocumentContent: data,
      formattedDocumentContent: data,
      wordsCount,
      characterCount,
      imageUrl: image,
    };
    this.setState({ content: data, saved: true }, () =>
      this.autoSaveContent(socketData)
    );
  };

  autoSaveContent = debounce(async (data) => {
    const { addCreatedDocument, documentType, history } = this.props;
    let docs;
    if (this.props.documentType !== "blog") {
      if (this.state.documentId) {
        if (data.documentId) {
          const { document, status } = await documentService.autoSaveDocument(
            data
          );
          docs = document;
          if (status === 200) {
            if (docs.activeDoc) {
              this.setState({ saved: false, documentData: docs }, () => {
                if (docs) addCreatedDocument(docs);
              });
            } else {
              this.props.history.push({ pathname: `/mydocuments` });
            }
          } else {
            this.props.history.push({ pathname: `/mydocuments` });
          }
        }
      }
    } else {
      if (this.state.documentId) {
        data.documentId = this.state.documentId;

        if (data.imageUrl) {
          let  junk = [];
          const storedState = JSON.parse(data.onlyDocumentContent);
          const contentState = convertFromRaw(storedState);
          let RawData = stateToHTML(contentState, option_);
          let ImageURLS = [];
          let reg = "";
          let regex = /<img[^>]+src="(https:\/\/[^">]+)"/g;
          while ((reg = regex.exec(RawData))) {
            let length = reg[1].length;
            ImageURLS.push(reg[1].substring(49, length));
          }

          for(let i=0; i< this.state.image.length; i++){
            if(ImageURLS.includes(this.state.image[i])){
              // Nothing
            }else{
              junk.push(this.state.image[i]);
            }
          }
          this.setState({ junk });
          data.imageUrl = ImageURLS;
        }

        const { blog, status } = await blogService.autoSaveBlog(data);
        docs = blog;
        if (status === 200) {
          if (docs.activeDoc) {
            this.setState({ saved: false, documentData: docs }, () => {
              // if (docs) addCreatedDocument(docs);
            });
          } else {
            this.props.history.push({ pathname: `/blogpage` });
          }
        } else {
          this.props.history.push({ pathname: `/blogpage` });
        }
      }
    }
  }, 1000);

  removeTag = (i) => {
    const newTags = [...this.state.tags];
    const { documentId } = this.state;
    newTags.splice(i, 1);

    this.setState({ tags: newTags }, () => {
      const data = { documentId: documentId, tags: newTags, };
      this.setState({ saved: true }, () => this.autoSaveContent(data));
    });
  };

  inputKeyDown = (e) => {
    const { documentId, tags } = this.state;
    const val = e.target.value.toLowerCase();
    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ tags: [...tags, val], tagInputRef: null }, () => {
        const data = {
          documentId: documentId,
          tags: [...tags, val],
        };
        this.setState({ saved: true }, () => this.autoSaveContent(data));
      });
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  async componentWillUnmount() {
    const {  documentId, junk  } = this.state;
    const { documentType } = this.props;
    
    if(documentId){
      const response = await documentService.generateAutoTag({ documentId, documentType });
    }

    if(junk.length > 0){
      for(let i=0; i<junk.length; i++){
          await uploadImageServices.DeleteImageFromS3(junk[i]);
      }
    }

  }

  componentWillReceiveProps() {
  }

  autoDocumentCreation = async (data) => {
    console.log("autoDocumentCreation", data);
    if (this.props.documentType !== "blog") {
      const documentId = await documentService.saveDocs(data);
      console.log(documentId);
      this.setState({ documentId, saved: false });
    } else {
      const documentId = await blogService.saveBlogs(data);
      this.setState({ documentId, saved: false });
    }
  };

  autoDocumentDeletion = async (data) => {
    await documentService.deleteCurrentDocuments(data);
  };

  addImage = (imageUrl) => {
    const { image } = this.state;
    image.push(imageUrl);

    this.setState({ image });
  };

  render() {
    return (
      <div>
        <ScrollUpButton />
        <Loader loading={this.state.loading} />
        <ToastContainer />
        <div className="">
          <div className="row">
            <div className="col-md-7">
            </div>
          </div>
          <GrammerCheck
            html={this.state.html}
            documentId={this.state.documentId}
            setRef={() => this.refs.documentName.focus()}
            handleTextEditor={(data, wordsCount, characterCount) =>
              this.handleTextEditor(data, wordsCount, characterCount)
            }
            ref={(instance) => {
              this.child = instance;
            }}
            docTitleChange={(value) => this.onChangeTitle(value)}
            createDocument={this.autoSaveDocument}
            docTitle={this.state.docTitle}
            tagInputKeyDown={(value) => this.inputKeyDown(value)}
            tags={this.state.tags}
            tagInputRef={this.state.tagInputRef}
            removeTag={(value) => this.removeTag(value)}
            saved={this.state.saved}
            titleEdit={this.props.isNew}
            features={this.props.features}
            onImageInsert={(image) => this.addImage(image)}
            documentType={this.props.documentType}
            // documentType={this.props.documentType ? "blog" : "document"}
          />
          <br />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  features: state.features.features,
  userDocument: state.document.userDocuments,
});
const mapDispatchToProps = (dispatch) => ({
  addCreatedDocument: (document) => dispatch(addCreatedDocument(document)),
  setUserDocument: (document) => dispatch(setUserDocument(document)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TextEditor));
