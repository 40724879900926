import React, { Component } from "react";
import Payments from "../Payments";
import { withRouter } from "react-router-dom";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import SubAdminSideBar from "../SubAdminSideBar";
import { getCurrentUser } from "../../services/authService";
class PaymentPage extends Component {
  render() {
    const { detail } = this.props.history.location.state;
    const user = getCurrentUser();
    console.log(user);
    if (!user.isSubAdmin) {
      return (
        <SideNavBar>
          <Payments data={detail} />
        </SideNavBar>
      )
    } else {
      return (
        <SubAdminSideBar>
          <Payments data={detail} />
        </SubAdminSideBar>
      )
    }
  }
}

export default NetworkDetector(withRouter(PaymentPage));
