import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
import { async } from "q";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/admin`;
} else {
  apiEndpoint = `${herokuUrl}/admin`;
}
// ***  Admin Dashboard Services  ***//
export async function getDetailsAdminDash(year) {
  let data = await http.post(`${apiEndpoint}/adminDashDetails`, {
    year,
  });
  return data;
}

export async function getPaidUserDetails(year) {
  let detail = { year: year };
  const data = await http.post(`${apiEndpoint}/getPaidUserDetails`, detail);
  return data;
}

export async function getFreeUserDetails(year) {
  let detail = { year: year };
  const data = await http.post(`${apiEndpoint}/getFreeUserDetails`, detail);
  return data;
}

export async function getAllManageUsers(pageSize, currentPage) {
  const data = await http.get(
    `${apiEndpoint}/getAllUsers/${pageSize}/${currentPage}`
  );
  return data;
}

export async function getAllUsers() {
  let data = await http.get(`${apiEndpoint}/listUsers`);
  return data;
}

// *** Documents Services ~ START ***//
export async function getAllDocuments() {
  let data = await http.get(`${apiEndpoint}/listDocs`);
  return data;
}
// *** Documents Services ~ START ***//

// *** Subscription Services ~ START ***//
export async function getSubscription() {
  let data = await http.get(`${apiEndpoint}/getSubscription`);
  return data;
}

export async function addSubscription(data) {
  let response = await http.post(`${apiEndpoint}/addSubscription`, data);
  return response;
}

export async function deleteSubscription(data) {
  let response = await http.post(`${apiEndpoint}/deleteSubscription`, data);
  return response;
}

export async function freezeuser(data) {
  let response = await http.post(`${apiEndpoint}/freezeuser`, data);
  return response;
}
export async function confirmUser(data) {
  let response = await http.post(`${apiEndpoint}/confirmuser`, data);
  return response;
}
export async function updateSubscription(data) {
  let response = await http.post(`${apiEndpoint}/updateSubscription`, data);
  return response;
}
// *** Subscription Services ~ END ***

// *** Payment Services ~ START ***
export async function getPayments() {
  let data = await http.get(`${apiEndpoint}/listPayments`);
  return data;
}

export async function getPaymentsById(id) {
  let data = await http.get(`${apiEndpoint}/listPaymfentById/${id}`);
  return data;
}

export async function getPaymentsByUserId(id) {
  let data = await http.get(`${apiEndpoint}/listPaymentByUser/${id}`);
  return data;
}

// *** Payment Services ~ END ***

export async function removecomment(commentData) {
  const data = await http.post(`${apiEndpoint}/removecomment`, commentData);
  return data;
}

export async function downloadInvoicePdf(data) {
  let response = await http.post(`${apiEndpoint}/downloadInvoicePdf`, data);
  return response;
}

export function downloadPdf(_id) {
  fetch(`${apiEndpoint}/download/${_id}.pdf`).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf";
      a.click();
    });
  });
}

export async function downloadGrammarReportPdf(score, document) {
  let data = {
    score: score,
    document: document,
  };
  let response = await http.post(
    `${apiEndpoint}/downloadGrammarReportPdf`,
    data
  );
  return response;
}

export async function downloadGrammarReport(_id) {
  fetch(`${apiEndpoint}/downloadGrammarReport/${_id}.pdf`).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "GrammarReport.pdf";
      a.click();
    });
  });
}
export async function downloadDocumentAnalyzerPdf(data) {
  let response = await http.post(
    `${apiEndpoint}/downloadAnalyzerReportPdf`,
    data
  );
  return response;
}

export async function downloadDocumentAnalyzer(_id) {
  fetch(`${apiEndpoint}/downloadAnalyzerReport/${_id}.pdf`).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "documentAnalyzer.pdf";
      a.click();
    });
  });
}
export async function downloadDocPDF(data) {
  let response = await http.post(`${apiEndpoint}/downloadDocumentPdf`, data);
  return response;
}

export function downDocumentPdf(_id, name) {
  fetch(`${apiEndpoint}/download/${_id}.pdf`).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${name}.pdf`;
      a.click();
    });
  });
}

export async function sendAlerts(alerts) {
  const data = await http.post(`${apiEndpoint}/sendAlertToUser`, alerts);
  return data;
}

export async function sendNewsletter(news) {
  const data = await http.post(`${apiEndpoint}/sendNewsLetter`, news);
  return data;
}

export async function activateUser(user) {
  let response = await http.post(`${apiEndpoint}/activateuser`, user);
  return response;
}

export async function getAllComments() {
  const data = await http.get(`${apiEndpoint}/getAllComments`);
  return data;
}

export async function searchUser(value) {
  const { user } = await http.get(`${apiEndpoint}/searchUser/${value}`);
  console.log(user);
  return user;
}

export async function planFilter(value) {
  const user = await http.post(`${apiEndpoint}/planFilter/`, {
    value,
  });
  return user;
}

// ######################################
//Character limit
export async function getCharacterLimit() {
  const data = await http.get(`${apiEndpoint}/getCharacterLimit`);
  return data;
}

export async function setCharacterLimit(limit) {
  const data = await http.post(`${apiEndpoint}/setCharacterLimit`, {
    limit,
  });
  return data;
}
// ######################################

//  Sentence limit
export async function getSentenceLimit() {
  const data = await http.get(`${apiEndpoint}/getSentenceLimit`);
  return data;
}

export async function setSentenceLimit(limit) {
  const data = await http.post(`${apiEndpoint}/setSentenceLimit`, {
    limit,
  });
  return data;
}

//  Plagairism limit
export async function getPlagiarismLimit() {
  const data = await http.get(`${apiEndpoint}/getPlagiarismLimit`);
  return data;
}

export async function setPlagiarismLimit(limit) {
  const data = await http.post(`${apiEndpoint}/setPlagiarismLimit`, {
    limit,
  });
  return data;
}
// ######################################

// Paraphrase limit
export async function getParaphraseLimit() {
  const data = await http.get(`${apiEndpoint}/getParaphraseLimit`);
  return data;
}

export async function setParaphraseLimit(limit) {
  const data = await http.post(`${apiEndpoint}/setParaphraseLimit`, {
    limit,
  });
  return data;
}
// ########################################

export async function getPendingBlogs(pageItems, currentPage) {
  const data = await http.get(
    `${apiEndpoint}/getPendingBlogs/${pageItems}/${currentPage}`
  );
  return data;
}

export async function searchPendingBlogs(search) {
  const data = await http.get(`${apiEndpoint}/searchPendingBlogs/${search}`);
  return data;
}

export async function searchAdminblog(search) {
  const data = await http.get(`${apiEndpoint}/searchAdminblog/${search}`);
  return data;
}

export async function getAdminBlogs(pageItems, currentPage) {
  const data = await http.get(
    `${apiEndpoint}/getAdminblog/${pageItems}/${currentPage}`
  );
  return data;
}
export async function acceptPendingBlog(blog) {
  const data = await http.post(`${apiEndpoint}/acceptPendingBlog`, blog);
  return data;
}

export async function rejectPendingBlog(blog) {
  const data = await http.post(`${apiEndpoint}/rejectPendingBlog`, blog);
  return data;
}

// API - Blog publish
export async function publishMyBlogs(blogId) {
  const data = await http.post(`${apiEndpoint}/publishAdminBlog`, {
    id: blogId,
  });
  return data;
}
// API - Blog unpublish
export async function unpublishMyBlogs(blogId) {
  const data = await http.post(`${apiEndpoint}/unpublishAdminBlog`, {
    id: blogId,
  });
  return data;
}

// API - Get blog by ID
export async function getBlogById(blogId) {
  const data = await http.post(`${apiEndpoint}/getblogbyId`, {
    id: blogId,
  });
  return data;
}

export async function getHomeBlogs(pageItems, currentPage) {
  const data = await http.get(
    `${apiEndpoint}/getAdminHomeBlogs/${pageItems}/${currentPage}`
  );
  return data;
}

// API - Delete blog permanently

export async function deletePermanentlyBlog(data) {
  const response = await http.post(
    `${apiEndpoint}/deletePermanentlyBlog`,
    data
  );
  return response;
}

export async function getHomeCMS() {
  const data = await http.get(`${apiEndpoint}/HomeCMS`);
  return data;
}

export async function postHomeCMS(data) {
  const response = await http.post(`${apiEndpoint}/HomeCMS`, data);
  return response;
}

export async function getFaqCMS() {
  const data = await http.get(`${apiEndpoint}/FaqCMS`);
  return data;
}

export async function postFaqCMS(data) {
  const response = await http.post(`${apiEndpoint}/FaqCMS`, data);
  return response;
}

export async function getTermsOfServiceCMS() {
  const data = await http.get(`${apiEndpoint}/TermsOfServiceCMS`);
  return data;
}

export async function postTermsOfServiceCMS(data) {
  const response = await http.post(`${apiEndpoint}/TermsOfServiceCMS`, data);
  return response;
}

export async function getPrivacyCMS() {
  const data = await http.get(`${apiEndpoint}/PrivacyCMS`);
  return data;
}

export async function postPrivacyCMS(data) {
  const response = await http.post(`${apiEndpoint}/PrivacyCMS`, data);
  return response;
}

export async function downloadDocDocx(data) {
  let response = await http.post(`${apiEndpoint}/downloadDocumentDocx`, data);
  return response;
}
export async function acceptPublicBlogComment(commentID) {
  const data = await http.post(`${apiEndpoint}/acceptPublicCommentForBlog`, {
    commentID,
  });
  return data;
}
export async function rejectPublicBlogComment(commentID) {
  const data = await http.post(`${apiEndpoint}/rejectPublicCommentForBlog`, {
    commentID,
  });
  return data;
}
export default {
  getAllManageUsers,
  getSubscription,
  addSubscription,
  updateSubscription,
  deleteSubscription,
  getPayments,
  getPaymentsById,
  getPaymentsByUserId,
  freezeuser,
  getAllDocuments,
  getAllUsers,
  removecomment,
  downloadPdf,
  getDetailsAdminDash,
  sendAlerts,
  sendNewsletter,
  activateUser,
  getAllComments,
  downloadDocPDF,
  downDocumentPdf,
  planFilter,
  getCharacterLimit,
  getSentenceLimit,
  setSentenceLimit,
  setCharacterLimit,
  getPlagiarismLimit,
  setPlagiarismLimit,
  getParaphraseLimit,
  setParaphraseLimit,
  downloadGrammarReportPdf,
  downloadGrammarReport,
  getPendingBlogs,
  getAdminBlogs,
  acceptPendingBlog,
  rejectPendingBlog,
  publishMyBlogs,
  unpublishMyBlogs,
  getBlogById,
  getHomeBlogs,
  searchPendingBlogs,
  searchAdminblog,
  getHomeCMS,
  postHomeCMS,
  getFaqCMS,
  postFaqCMS,
  getTermsOfServiceCMS,
  postTermsOfServiceCMS,
  getPrivacyCMS,
  postPrivacyCMS,
  downloadDocumentAnalyzerPdf,
  downloadDocumentAnalyzer,
  confirmUser,
  downloadDocDocx,
  getPaidUserDetails,
  getFreeUserDetails,
  deletePermanentlyBlog,
  acceptPublicBlogComment,
  rejectPublicBlogComment,
};
