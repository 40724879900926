import React, { Component } from "react";
import Rating from "react-rating";
import avatar from "../../static/Avatar.png";
import lastModified from "../../utils/lastModfied";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { getCommentsForDocs } from "../../services/feedbackServices";
import { removecomment, getAllComments } from "../../services/adminServices";
import { getCurrentUser } from "../../services/authService";
import TextField from "@material-ui/core/TextField";
import { addAdminComments } from "../../services/feedbackServices";

class CommentsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      commands: [],
      isAdmin: false,
      loading: true,
      userID: "",
      hidden: true,
      currentUser: "",
      reply: "",
      commentKey: {}
    };
  }

  async componentDidMount() {
    await this.getCurrentUser_();
    if (this.state.isAdmin === false) {
      this.getAllCommentsForDocs();
    } else {
      this.getAllCommentsForDocs();
    }
  }

  getCurrentUser_ = async () => {
    const res = await getCurrentUser();
    this.setState({ userID: res._id, currentUser: this.props.currentUser });
    //console.log(res);
    if (res.isAdmin) this.setState({ isAdmin: true });
  };

  getAllCommentsForDocs = async () => {
    let type = this.props.data.documentType;
    let res = await getCommentsForDocs(this.state._id, type);
    if (res.status === 200) {
      this.setState({ commands: res.data, loading: false });
      console.log(this.state.commands);
      this._calculate(res.data);
    }
  };

  _calculate = async (data) => {
    let main = data.filter((d) => d.reply === false);
    let TotalComments = main.length;
    let AverageRating = 0;
    main.map((m) => (AverageRating = AverageRating + m.rating));
    let MaxRatings = AverageRating;
    AverageRating =
      TotalComments > 1
        ? Math.round(AverageRating / TotalComments)
        : AverageRating;
    this.props._calculateRatings(TotalComments, AverageRating, MaxRatings);
  };

  // getallComments = async () => {
  //   let res = await getAllComments();
  //   if (res.status === 200) {
  //     this.setState({ commands: res.data, loading: false });
  //     console.log(res.data);
  //   }
  // };

  removecomment_ = async (id, belongsTo, rating, replies) => {
    let data = {
      document_id: id,
      comment_id: belongsTo,
      commentRating: rating,
      commentReplies: replies,
      documentType: this.props.data.documentType,
    };
    console.log(data);
    this.setState({ loading: true });
    const res = await removecomment(data);
    // const res = await removecomment(id, belongsTo, replies);
    if (res.status === 200) toast.success(res.message);
    else toast.error("Oops something went wrong..!");
    if (this.state.isAdmin === true) {
      this.getAllCommentsForDocs();
    } else {
      this.getAllCommentsForDocs();
    }
  };

  hideONOFF = async (id) => {
    let commentKey = this.state.commentKey;
    if (commentKey[id] === id) {
      this.setState({
        commentKey: {}
      });
    } else {
      this.setState({
        commentKey: {
          [id]: id
        }
      });
    }
  };
  postReply = async (data) => {
    console.log(data)
    this.setState({ loading: true });
    if (this.state.reply.length < 1) {
      toast("Empty Comment");
      this.setState({ loading: false });
    } else {
      const sendData = {
        comment: this.state.reply,
        documentId: data.documentID,
        rating: 0,
        date: new Date(),
        reply: true,
        commentsID: data._id,
        documentType: this.props.documentType
      };

      // const response = await addComments(sendData);
      const response = await addAdminComments(sendData);
      if (response.status === 200) {
        toast.success(response.message);
        this.getAllCommentsForDocs();
        this.setState({ commentKey: {}, loading: false });
      } else {
        toast.error("Oops something went wrong");
        this.setState({ loading: false });
      }
    }
  };
  render() {
    const { commands, isAdmin, userID, hidden, currentUser } = this.state;
    const { documentType } = this.props
    const mdate = new Date(commands.date);
    const today = new Date();

    return (
      <div>
        <Loader loading={this.state.loading} />
        {commands.map((obj) => (
          <>
            {obj.reply === false ? (
              <>
                <div
                  key={obj._id}
                  className="d-flex justify-content-between pb-2"
                >
                  <div className="d-flex">
                    <img
                      src={obj.postedBy ? obj.postedBy.display_picture : avatar}
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                      }}
                    />

                    <div className="mx-3 ft_xs">
                      <p className="m-0" style={{ fontWeight: "900" }}>
                        {obj.postedBy && obj.postedBy.username}
                        <small className="ml-2">
                          {lastModified(today, new Date(obj.date))}
                        </small>
                      </p>
                      <Rating
                        initialRating={obj.rating}
                        emptySymbol={
                          <i className="fa fa-star-o mt-2" aria-hidden="true"></i>
                        }
                        fullSymbol={
                          <i className="fa fa-star mt-2" aria-hidden="true"></i>
                        }
                        style={{ color: "orange" }}
                        readonly
                      />
                      {(documentType === "document" && this.state.userID === this.props.data.editor && this.props.data.status === "original") || (this.props.blogType === "my" && documentType === "blog" && this.props.data.editor._id === this.state.userID) ? (<i
                        className="fa fa-reply ft_sm text-primary point ml-4 mt-2"
                        aria-hidden="true"
                        onClick={() => this.hideONOFF(obj._id)}
                      >
                        &nbsp; reply
                      </i>) : ""}
                      <br />
                      <p className="ft_sm mt-2"> {obj.comment} </p>
                    </div>
                  </div>



                  {isAdmin === true ? (
                    <i
                      onClick={() =>
                        this.removecomment_(
                          obj.documentID,
                          obj._id,
                          obj.rating,
                          obj.replies
                        )
                      }
                      className="fa fa-trash ft_md text-primary point mr-0 mr-sm-4"
                      aria-hidden="true"
                    ></i>
                  ) : obj.postedBy._id === userID ? (
                    <i
                      onClick={() =>
                        this.removecomment_(
                          obj.documentID,
                          obj._id,
                          obj.rating,
                          obj.replies
                        )
                      }
                      className="fa fa-trash ft_md text-primary point mr-0 mr-sm-4"
                      aria-hidden="true"
                    ></i>
                  ) : (
                        ""
                      )}
                </div>
                {this.state.commentKey[obj._id] ? (

                  <div>
                    <div className="d-flex ml-5 mb-4">
                      <img
                        src={currentUser.display_picture ? currentUser.display_picture : avatar}
                        alt=""
                        style={{
                          marginTop: "2%",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                        }}
                      />

                      <form noValidate autoComplete="off">
                        <TextField
                          id="standard-basic"
                          label="Reply"
                          onChange={(e) =>
                            this.setState({ reply: e.target.value })
                          }
                          multiline
                          style={{ width: 400, marginLeft: "5%" }}
                        />
                      </form>
                      <i
                        className="fa fa-check ft_sm text-primary point ml-5"
                        onClick={() => this.postReply(obj)}
                        aria-hidden="true"
                        style={{
                          marginTop: "3%",
                        }}
                      >
                        &nbsp; Post
    </i>
                    </div>
                  </div>
                ) : ""}
                {/* Replies */}

                {obj.replies.length > 0 ? (
                  <>
                    {commands.map((comms) => (
                      <>
                        {obj.replies.map((reply) => (
                          <>
                            {reply === comms._id ? (
                              <>
                                <div className="d-flex ml-5">
                                  <img
                                    src={comms.postedBy.display_picture}
                                    alt=""
                                    style={{
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                  <p
                                    className="m-2"
                                    style={{ fontWeight: "900" }}
                                  >
                                    {comms.postedBy && comms.postedBy.username}
                                    <small className="ml-2">
                                      {lastModified(
                                        today,
                                        new Date(comms.date)
                                      )}
                                    </small>
                                  </p>
                                </div>
                                <p
                                  className="ft_sm "
                                  style={{ marginLeft: "7%" }}
                                >
                                  {" "}
                                  {comms.comment}{" "}
                                </p>
                              </>
                            ) : (
                                ""
                              )}
                          </>
                        ))}
                      </>
                    ))}
                  </>
                ) : (
                    ""
                  )}
              </>
            ) : (
                ""
              )}
          </>
        ))}
      </div>
    );
  }
}

export default CommentsCard;
