import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import { OutlinedInput } from "@material-ui/core";

export default function InputBoxTwo(props) {
    return (
        <div>
            <OutlinedInput
        style={{ height: "30px", width: "90%" }}
        id="input-with-icon-adornment"
        {...props}
        // endAdornment={
        //   <InputAdornment position="end">
        //     <img src={star} />
        //   </InputAdornment>
        // }
      />
        </div>
    )
}
