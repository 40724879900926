import React from "react";
import SideNavBar from "../SideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import { Plagiarism } from "../Plagiarism/Plagiarism";
import PlagiarismContextProvider from "../../contexts/PlagiarismContext";

const PlagiarismPage = (props) => {

  return (
    <React.Fragment>
      <SideNavBar>
        <PlagiarismContextProvider>
          <Plagiarism />
        </PlagiarismContextProvider>
      </SideNavBar>
    </React.Fragment>
  );
};

export default NetworkDetector(PlagiarismPage);
