import React from "react";
import AdminSideNavBar from "../AdminSideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
// import NumberWidget from "../NumberWidget";
// import Invoice from "../Invoice";
import AdminBlogs from "../AdminBlogs";
import queryString from "query-string";

const AdminBlogPage= (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
  return (
    <AdminSideNavBar>
      <AdminBlogs />
    </AdminSideNavBar>
  );
};

export default NetworkDetector(AdminBlogPage);
