import React, { useEffect, useState } from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import "./PricePlans/plans.css";
import BasicPlan from "./PricePlans/BasicPlan";
import BreadCrumb from "./Cards/BreadCrumbs";
import { getAllPlan } from "../services/plans"

export default function PriceDetail({breadCrumb}) {
  const [planList, setplanList] = useState([]);
  useEffect(() => {
    // onLoad();
    initalCall();
  }, []);


  const initalCall = async() =>{
    const data = await getAllPlan();
    if(data.status !== 200)
      return
    
    const plans = data.plan;
    let html = plans.map((plan) =>{
      const content = unOrderList(plan.content);
      const planName = plan.planName;
      const nameOfTerms = plan.nameOfTerms;
      const planMode = { mode: plan.planMode };
      const termDetails = convertToObj(plan.termDetails);
      return (
        <BasicPlan
          heading={planName}
          ul={content}
          btn_list={nameOfTerms}
          price_detail={termDetails}
          plan_mode={planMode}
          hide ={false}
        />
      );
    });
    setplanList(html);
  }

  const convertToObj = (termDetails) =>{
    let termObj = termDetails.reduce((newObj, obj) =>{
      const {term, price, user_count} = obj;
      if(!newObj[obj.term]){
        newObj = {...newObj,  [term]:[] }
      }
      let term_arr = newObj[term];
      term_arr.push({price, user_count})
      return newObj
  },{})

  return termObj;
  }


  const unOrderList = contentObj => {
    if (contentObj) {
      const ul = Object.keys(contentObj).map(key => {
        let get_data = contentObj[key];
        if (typeof get_data == "object") {
          const lh = <lh>{key}</lh>;
          const li = get_data.map(text => <li>{text}</li>);
          return (
            <ul>
              {" "}
              {lh} {li}{" "}
            </ul>
          );
        }
      });
      return ul;
    }
  };

  return (
    <div>
      {breadCrumb && <BreadCrumb
        l1="Dashboard"
        l2="Subcriptions"
        l3="pricedetails"
        dashboardlink="admindashboard"
        documentLink="pricing"
      />}
      <div className="container plans">
        <ScrollUpButton />
        <div className="d-flex justify-content-center">
          <h2 className="text-center text-dark w-50 my-4">
            Get Instant Access to the Professional Editing Tool Choose Your Plan
            and Get Started
          </h2>
        </div>

        {planList}
      </div>
    </div>
  );
}
